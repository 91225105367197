// ProgressBar.js
interface ProgressBarProps {
  currentStep: number;
}

function ProgressBar({ currentStep }: ProgressBarProps) {
  const steps = [
    { stepNumber: 'STEP 01', stepText: '약관동의' },
    { stepNumber: 'STEP 02', stepText: '본인인증' },
    { stepNumber: 'STEP 03', stepText: '정보입력' },
  ];

  return (
    <>
      <div className='text-start'>
        <h1 className='text-2xl font-bold'>회원가입</h1>
      </div>
      <div className='bg-white mt-4 pb-2 flex justify-center'>
        <div className='flex w-full max-w-lg'>
          {steps.map((step, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center px-4 py-2  border border-gray basis-1/3 ${
                index === currentStep
                  ? 'border-hotduk_light bg-hotduk_light text-white font-bold'
                  : 'border-gray-300 bg-white text-gray-700'
              }`}>
              <span className='text-gray-700 font-semibold text-sm'>
                {step.stepNumber}
              </span>
              <span className='text-gray-500 text-sm'>{step.stepText}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProgressBar;
