import React, { useState, useEffect } from "react";
import { UserPost, UserComment, UserBookmarks } from "../../constants/types";
import { useNavigate } from "react-router-dom";

interface RenderUserHistoryProps {
  posts?: UserPost[];
  comments?: UserComment[];
  bookmarks?: UserPost[];
}

function RenderUserHistory({
  posts,
  comments,
  bookmarks,
}: RenderUserHistoryProps) {
  const navigate = useNavigate();

  const handlePostClick = (post: UserPost) => {
    //todo 여기 운영자일땐 이동하도록 isAdmin 넣어줘야함
    if (post.isBlocked) {
      alert("다수의 신고를 받아 가려진 게시물입니다.");
      return;
    }
    navigate(`/post/${post.boardNumber}/${post.id}`);
  };

  const handleCommentClick = (
    postId?: string,
    boardNumber?: number,
    isDeleted?: boolean
  ) => {
    if (!postId) {
      alert("글이 삭제되었습니다.");
    } else if (isDeleted) {
      //todo 삭제된 댓글입니다 다음에, 프로필에서 댓글 그만보기 로직 구현 가능하다면
      alert("삭제된 댓글입니다.");
    } else {
      navigate(`/post/${boardNumber}/${postId}`);
    }
  };

  if (posts) {
    return (
      <>
        <table className="w-full table-fixed text-textcolor">
          <thead>
            <tr className="bg-[#FAFAFA]">
              <th className="w-1/2 text-sm py-[10px] font-semibold">제목</th>
              <th className="text-sm py-[10px] font-semibold"> 작성일</th>
            </tr>
          </thead>
          {posts.length !== 0 ? (
            <>
              <tbody>
                {posts.map((post) => (
                  <tr
                    key={post.id}
                    className="border-b border-[#DDE4EC] cursor-pointer text-sm "
                    onClick={() => handlePostClick(post)}
                  >
                    {post.title.length < 25 ? (
                      <td className="py-[10px] pl-5 flex items-center">
                        <div className="flex  font-normal "> {post.title}</div>
                        <div className="flex text-hotduk font-bold text-xs pl-[6px]">
                          {post.commentCount > 0 ? `${post.commentCount}` : ""}
                        </div>
                      </td>
                    ) : (
                      <td className="py-[10px] pl-5 flex items-center">
                        <div className="flex  font-normal ">
                          {" "}
                          {post.title.slice(0, 25)}...
                        </div>
                        <div className="flex text-hotduk font-bold text-xs pl-[6px]">
                          {post.commentCount > 0 ? `${post.commentCount}` : ""}
                        </div>
                      </td>
                    )}
                    <td className="py-[10px] px-1 text-center font-normal">
                      {Math.floor(
                        (Date.now() - Date.parse(post.createdAt)) / 3600000
                      ) < 24 ? (
                        <div>
                          {`${new Date(new Date(post.createdAt))
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${new Date(
                            new Date(post.createdAt)
                          )
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`}{" "}
                        </div>
                      ) : (
                        <>
                          {Math.floor(
                            (Date.now() - Date.parse(post.createdAt)) / 86400000
                          ) > 364 ? (
                            <div>
                              {`${new Date(
                                new Date(post.createdAt)
                              ).getFullYear()}.${(
                                new Date(new Date(post.createdAt)).getMonth() +
                                1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(post.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <div>
                              {`${(
                                new Date(new Date(post.createdAt)).getMonth() +
                                1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(post.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td className="py-2 pl-5 text-sm text-textcolor font-normal">
                  게시물이 존재하지 않습니다.
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </>
    );
  }

  if (comments) {
    return (
      <>
        <table className="w-full table-fixed text-textcolor">
          <thead>
            <tr className="bg-[#FAFAFA]">
              <th className="w-1/2 text-sm py-[10px] font-semibold">제목</th>
              <th className="text-sm py-[10px] font-semibold"> 작성일</th>
            </tr>
          </thead>
          {comments.length !== 0 ? (
            <>
              <tbody>
                {comments.map((comment) => (
                  <tr
                    key={comment.id}
                    className="border-b border-[#DDE4EC] cursor-pointer text-sm"
                    onClick={() =>
                      handleCommentClick(
                        comment.postId,
                        comment.boardNumber,
                        comment.isDeleted
                      )
                    }
                  >
                    {comment.text.trim() === "" && comment.image ? (
                      <td className="py-[10px] pl-5 font-normal items-center">
                        이미지 댓글입니다
                      </td>
                    ) : comment.text.length < 25 ? (
                      <td className="py-[10px] pl-5 font-normal items-center">
                        {comment.text}
                      </td>
                    ) : (
                      <td className="py-[10px] pl-5 font-normal items-center">
                        {comment.text.slice(0, 25)}...
                      </td>
                    )}
                    <td className="py-2 px-1 text-center">
                      {Math.floor(
                        (Date.now() - Date.parse(comment.createdAt)) / 3600000
                      ) < 24 ? (
                        <div>
                          {`${new Date(new Date(comment.createdAt))
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${new Date(
                            new Date(comment.createdAt)
                          )
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`}{" "}
                        </div>
                      ) : (
                        <>
                          {Math.floor(
                            (Date.now() - Date.parse(comment.createdAt)) /
                              86400000
                          ) > 364 ? (
                            <div>
                              {`${new Date(
                                new Date(comment.createdAt)
                              ).getFullYear()}.${(
                                new Date(
                                  new Date(comment.createdAt)
                                ).getMonth() + 1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(comment.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <div>
                              {`${(
                                new Date(
                                  new Date(comment.createdAt)
                                ).getMonth() + 1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(comment.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td className="py-2 pl-5 text-sm text-textcolor font-normal">
                  게시물이 존재하지 않습니다.
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </>
    );
  }

  if (bookmarks) {
    return (
      <>
        <table className="w-full table-fixed text-textcolor">
          <thead>
            <tr className="bg-[#FAFAFA]">
              <th className="w-1/2 text-sm py-[10px] font-semibold">제목</th>
              <th className="text-sm py-[10px] font-semibold"> 작성일</th>
            </tr>
          </thead>
          {bookmarks.length !== 0 ? (
            <>
              <tbody>
                {bookmarks.map((bookmark) => (
                  <tr
                    key={bookmark.id}
                    className="border-b border-[#DDE4EC] cursor-pointer text-sm"
                    onClick={() => handlePostClick(bookmark)}
                  >
                    {bookmark.title.length < 25 ? (
                      <td className="py-[10px] pl-5 flex items-center">
                        <div className="flex  font-normal ">
                          {" "}
                          {bookmark.title}
                        </div>
                        <div className="flex text-hotduk font-bold text-xs pl-[6px]">
                          {bookmark.commentCount > 0
                            ? `${bookmark.commentCount}`
                            : ""}
                        </div>
                      </td>
                    ) : (
                      <td className="py-[10px] pl-5 flex items-center">
                        <div className="flex  font-normal ">
                          {" "}
                          {bookmark.title.slice(0, 25)}...
                        </div>
                        <div className="flex text-hotduk font-bold text-xs pl-[6px]">
                          {bookmark.commentCount > 0
                            ? `${bookmark.commentCount}`
                            : ""}
                        </div>
                      </td>
                    )}
                    <td className="py-2 px-1 text-center">
                      {Math.floor(
                        (Date.now() - Date.parse(bookmark.createdAt)) / 3600000
                      ) < 24 ? (
                        <div>
                          {`${new Date(new Date(bookmark.createdAt))
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${new Date(
                            new Date(bookmark.createdAt)
                          )
                            .getMinutes()
                            .toString()
                            .padStart(2, "0")}`}{" "}
                        </div>
                      ) : (
                        <>
                          {Math.floor(
                            (Date.now() - Date.parse(bookmark.createdAt)) /
                              86400000
                          ) > 364 ? (
                            <div>
                              {`${new Date(
                                new Date(bookmark.createdAt)
                              ).getFullYear()}.${(
                                new Date(
                                  new Date(bookmark.createdAt)
                                ).getMonth() + 1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(bookmark.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <div>
                              {`${(
                                new Date(
                                  new Date(bookmark.createdAt)
                                ).getMonth() + 1
                              )
                                .toString()
                                .padStart(2, "0")}.${new Date(
                                new Date(bookmark.createdAt)
                              )
                                .getDate()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <tbody>
              <tr>
                <td className="py-2 pl-5 text-sm text-textcolor font-normal">
                  게시물이 존재하지 않습니다.
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </>
    );
  }

  return (
    <table className="w-full table-fixed text-textcolor">
      <thead>
        <tr className="bg-[#FAFAFA]">
          <th className="w-1/2 text-sm py-[10px] font-semibold">제목</th>
          <th className="text-sm py-[10px] font-semibold"> 작성일</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="py-2 pl-5 text-sm text-textcolor font-normal">
            게시물이 존재하지 않습니다.
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default RenderUserHistory;
