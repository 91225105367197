import { useEffect, useState } from "react";
import { getBoardPosts, getBoardPost } from "../../apis/board";
import {
  voteBoardDetailData,
  IntegratedAnnouncements,
  Announcements,
} from "../../constants/types";
import Pagination from "../Common/Pagination";
import RenderVoteBoard from "./RenderVoteBoard";
import { useNavigate, useParams } from "react-router-dom";

function VoteBoard() {
  const { pageNumber } = useParams<{ pageNumber: string }>();

  const [posts, setPosts] = useState<voteBoardDetailData[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    getBoardPosts(currentPage)
      .then((res) => {
        setAnnouncements(res.announcements);
        setIntegratedAnnouncements(res.integratedAnnouncements);
        setPosts(res.voteBoardPost);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const goToCreateVotePost = () => {
    navigate("/board/create");
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/board/voteboard/${page}`);
    window.location.reload();
  };

  return (
    <>
      <div className="flex mt-5 justify-between">
        <div className=" mb-5 text-textcolor text-2xl font-bold">
          게시판 추가 투표
        </div>
        <div className="mt-2 pr-4">
          <button
            id="createVotePostButton"
            type="button"
            onClick={goToCreateVotePost}
            className="bg-[#222222] text-white font-semibold text-sm rounded-[100px] px-3 py-1"
          >
            글쓰기
          </button>
        </div>
      </div>
      <div>
        <RenderVoteBoard
          posts={posts}
          announcements={announcements}
          integratedAnnouncements={integratedAnnouncements}
        />
      </div>
      <div className="flex flex-row-reverse mr-1/3 my-6 pr-2">
        <button
          id="createVotePostButton"
          type="button"
          onClick={goToCreateVotePost}
          className="bg-[#222222] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28"
        >
          글쓰기
        </button>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </>
  );
}

export default VoteBoard;
