import React, { useState } from "react";
import { bookmarkPost, deleteBookmarkPost } from "../../apis/post";
import Btn from "../Common/Btn";
import { getPostBookmark } from "../../apis/user";

interface PostBookmarkProps {
  postId: string;
  isLogin: boolean;
}

function PostBookmark({ postId, isLogin }: PostBookmarkProps) {
  const [bookmark, setBookmark] = useState(false);

  if (isLogin) {
    getPostBookmark(postId)
      .then((res) => {
        //todo 콘솔이 세번씩 찍히는데, 이거 해결 해야함
        setBookmark(res.isBookmarkPost);
      })
      .catch((err) => {});
  }

  const handleBookmark = () => {
    bookmarkPost(postId)
      .then((res) => {
        setBookmark(true);
        alert("북마크 완료");
        window.location.reload();
      })
      .catch((err) => {
        if (
          err.response.status === 401 &&
          err.response.data.message === "Access Token does not exist"
        ) {
          alert("로그인이 필요합니다.");
        }
        if (
          err.response.status === 403 &&
          err.response.data.message === "This post is already bookmarked"
        ) {
          alert("이미 북마크한 게시글입니다.");
        }
      });
  };

  const handleDeleteBookmark = () => {
    deleteBookmarkPost(postId)
      .then((res) => {
        setBookmark(false);
        alert("북마크 삭제 완료");
        window.location.reload();
      })
      .catch((err) => {
        if (
          err.response.status === 401 &&
          err.response.data.message === "Access Token does not exist"
        ) {
          alert("로그인이 필요합니다.");
        }
        if (
          err.response.status === 403 &&
          err.response.data.message === "This post is not bookmarked"
        ) {
          alert("북마크하지 않은 게시글입니다.");
        }
      });
  };

  return (
    <>
      {bookmark ? (
        <button
          type="button"
          onClick={handleDeleteBookmark}
          className="flex items-center border border-[#D9D9D9] bg-[#EDEDED] px-3 py-2 text-sm font-normal text-textcolor"
        >
          <img
            src="/assets/icon/bookmark.svg"
            alt="bookmark"
            className=" items-center w-3"
          />
          <p className="flex pl-2">북마크</p>
        </button>
      ) : (
        <button
          type="button"
          onClick={handleBookmark}
          className="flex items-center border border-[#D9D9D9] px-3 py-2 text-sm font-normal text-textcolor hover:bg-[#D9D9D9]"
        >
          <img
            src="/assets/icon/bookmark.svg"
            alt="bookmark"
            className=" items-center w-3"
          />
          <p className="flex pl-2">북마크</p>
        </button>
      )}
    </>
  );
}

export default PostBookmark;
