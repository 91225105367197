import React, { useState, useEffect } from "react";
import { getUserPosts } from "../../apis/user";
import { useNavigate } from "react-router-dom";
import { UserPost } from "../../constants/types";
import Pagination from "../Common/Pagination";
import MobileRenderUserHistory from "./MobileRenderUserHistory";

function MobileProfileUserPost() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [posts, setPosts] = useState<UserPost[]>([]);

  useEffect(() => {
    getUserPosts(currentPage)
      .then((res) => {
        setPosts(res.postList);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <MobileRenderUserHistory posts={posts} />
        <div className="mt-5">
          {totalPage > 0 ? (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
export default MobileProfileUserPost;
