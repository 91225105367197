import { axiosFileInstance, axiosInstance } from "./instance";

// 게시판 이름 조회
export const getBoardName = async (boardNumber?: number) => {
  let url = `/boardName`;
  if (boardNumber !== undefined) {
    url = `/boardName/${boardNumber}`;
  }
  const response = await axiosInstance.get(url);
  return response.data;
};

// 게시판 생성 글 작성
export const createBoardPost = async (formData: FormData) => {
  const response = await axiosFileInstance.post(`/voteBoard`, formData);
  return response.data;
};

// 게시판 생성 투표하기
export const voteBoard = async (postId: string) => {
  const response = await axiosInstance.post(`/voteBoard/voteUp/${postId}`);
  return response.data;
};

// 게시판 생성 글 목록 조회
export const getBoardPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/voteBoard/${pageNumber}`);
  return response.data;
};

// 게시판 생성 진행중 글 목록 조회
export const getBoardPostsInProgress = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/voteBoard/${pageNumber}`);
  return response.data.voteBoardPost.filter(
    (post: any) => post.isExpired === false
  );
};

// 게시판 생성 글 상세 조회
export const getBoardPost = async (voteBoardPostId: string) => {
  const response = await axiosInstance.get(
    `/voteBoard/voteBoardPost/${voteBoardPostId}`
  );
  return response.data;
};

// 게시판 수동 생성
export const createBoard = async (boardName: string) => {
  const response = await axiosInstance.post(`/boardName`, {
    boardName: boardName,
  });
  return response.data;
};

// 게시판 즐겨찾기 추가
export const addBoardBookmark = async (
  boardNumber: number,
  boardName: string
) => {
  const res = await axiosInstance.post<any>(`/boardName/bookmark`, {
    boardNumber: boardNumber,
    boardName: boardName,
  });
  return res.data;
};

// 게시판 즐겨찾기 삭제
export const deleteBoardBookmark = async (boardNumber: number) => {
  const res = await axiosInstance.delete<any>(`/boardName/bookmark`, {
    data: {
      boardNumber: boardNumber,
    },
  });
  return res.data;
};
