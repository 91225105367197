import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Menu() {
  const [showSubMenu, setShowSubMenu] = useState<string | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState<string | null>(null);
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const menuItems = [
    { text: "유저 관리", url: "/admin/page/user" },
    { text: "게시글 관리", url: "/admin/page/post" },
    {
      text: "댓글 관리",
      url: "/admin/page/comment",
      subMenu: [
        { text: "댓글", url: "/admin/page/comment" },
        { text: "대댓글", url: "/admin/page/comment/subcomment" },
      ],
    },
    { text: "공지사항", url: "/admin/page/announcement" },
    {
      text: "게시판 관리",
      url: "/admin/page/board",
    },
  ];

  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuItemClick = (url: string, hasSubMenu: boolean) => {
    if (hasSubMenu) {
      setShowSubMenu(showSubMenu === url ? null : url);
    } else {
      navigate(url);
      setShowSubMenu(null);
      setSelectedMenu(url);
      setSelectedSubMenu(null);
      setShowMenu(false);
    }
  };

  const handleSubMenuItemClick = (url: string) => {
    navigate(url);
    setSelectedSubMenu(url);
    setShowMenu(false);
  };

  return (
    <div className='relative'>
      <div
        className='flex items-center cursor-pointer p-4 text-navy'
        onClick={() => setShowMenu(!showMenu)}>
        <img
          src='/assets/icon/hamburger_mobile.svg'
          alt='hamburger'
          width={15}
        />{" "}
      </div>
      {showMenu && (
        <div className='absolute top-full left-0 bg-white shadow-lg z-20'>
          <ul className='flex flex-col p-4'>
            {menuItems.map((item, index) => (
              <li key={index} className='mb-4 text-sm'>
                <div
                  className={`flex items-center cursor-pointer whitespace-nowrap ${
                    location.pathname.startsWith(item.url) ? "font-bold" : ""
                  }`}
                  onClick={() => handleMenuItemClick(item.url, !!item.subMenu)}>
                  <img
                    src='/assets/icon/next.svg'
                    width={10}
                    height={10}
                    className='mr-1'
                    alt='Next Icon'
                  />
                  {item.text}
                </div>
                {showSubMenu === item.url && item.subMenu && (
                  <ul className='pl-4'>
                    {item.subMenu.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        className={`mt-2 text-sm flex items-center cursor-pointer whitespace-nowrap ${
                          selectedSubMenu === subItem.url ? "font-bold" : ""
                        }`}
                        onClick={() => handleSubMenuItemClick(subItem.url)}>
                        <img
                          src='/assets/icon/next.svg'
                          width={10}
                          height={10}
                          className='mr-1'
                          alt='Next Icon'
                        />
                        {subItem.text}
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default Menu;
