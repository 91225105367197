import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../../constants/types";

const ProtectedRoute = ({ children }: any) => {
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);

  if (!isAdminAdmin) {
    // 관리자 권한이 없는 경우, 홈 페이지로
    return <Navigate to="/" />;
  }

  // 관리자 권한이 있는 경우, 해당 컴포넌트 렌더링
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
