import { useEffect, useState } from "react";
import { getUserInfo } from "../../apis/user";
import UserInfo from "../../components/Profile/UserInfo";
import { User } from "../../constants/types";
import UserActivity from "../../components/Profile/UserActivity";
import UserHistory from "../../components/Profile/UserHistory";
import MobileUserInfo from "../../components/Profile/MobileUserInfo";
import MobileUserActivity from "../../components/Profile/MobileUserActivity";
import MobileUserHistory from "../../components/Profile/MobileUserHistory";

function MobileProfilePage() {
  const [userInfo, setUserInfo] = useState<User>();

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUserInfo(res.user);
      })
      .catch((err) => {});
  }, []);

  if (!userInfo) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="mx-4">
        <div className=" mb-8">
          <MobileUserInfo userCreatedAt={userInfo.createdAt} />
        </div>
        <div className=" my-8">
          <MobileUserActivity
            userPostCount={userInfo.postCount}
            userCommentCount={userInfo.commentCount}
            userVoteUpCount={userInfo.votedUpCount}
            userVoteDownCount={userInfo.votedDownCount}
            userBookmarkedCount={userInfo.bookmarkedCount}
          />
        </div>
        <div className=" my-8">
          <MobileUserHistory />
        </div>
      </div>
    </>
  );
}

export default MobileProfilePage;
