import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllComments } from "../../../apis/admin/adminComment";
import { AdminComment, Comment } from "../../../constants/types";
import Pagination from "../../Common/Pagination";

function AdminCommentList() {
  const navigate = useNavigate();
  const [comments, setComments] = useState<AdminComment[]>([]);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);

  const handleCommentClick = (
    boardNumber: number,
    postId: string,
    commentId: string
  ) => {
    navigate(`/post/${boardNumber}/${postId}?commentId=${commentId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/admin/page/comment?page=${page}`);
    window.location.reload();
  };

  useEffect(() => {
    getAllComments(currentPage)
      .then((res) => {
        setComments(res.comments);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  const renderPostHeader = () => {
    return (
      <thead className='bg-gray-200'>
        <tr>
          <th className='py-3 px-4 text-center'>내용</th>
          <th className='py-3 px-4 text-center'>작성자</th>
          <th className='py-3 px-4 text-center'>조치일</th>
          <th className='py-3 px-4 text-center'>상태</th>
        </tr>
      </thead>
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const renderActionDate = (comment: AdminComment) => {
    const actionDate = comment.deletedAt || comment.blockedAt;
    if (!actionDate) return null;
    return formatDate(actionDate);
  };

  const renderComment = (comments: AdminComment[]) => {
    return (
      <tbody>
        {comments.map((comment) => (
          <tr
            key={comment._id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() =>
              handleCommentClick(
                comment.boardNumber,
                comment.postId,
                comment._id
              )
            }>
            <td className='py-[10px] pl-4 flex items-center font-normal'>
              <div className='ml-4'>
                <p className='font-semibold'>{comment.text}</p>
              </div>
              {comment.image && " (이미지 댓글입니다.)"}
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='text-center font-normal'>
                {comment.userId.substring(0, 8)}
              </div>
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {renderActionDate(comment)}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {comment.isDeleted ? "삭제" : comment.isBlocked ? "차단" : ""}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>댓글 관리</h3>
      </div>
      <table className='w-full'>
        {renderPostHeader()}
        {comments.length > 0 ? (
          renderComment(comments)
        ) : (
          <tbody>
            <tr>
              <td colSpan={4} className='text-center'>
                Loading announcements...
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default AdminCommentList;
