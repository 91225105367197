function TermsOfUsePage() {
  return (
    <div className='my-5 p-4 max-w-2xl mx-auto whitespace-normal'>
      <h1 className='text-4xl font-semibold mb-4'>10duk 약관</h1>

      {/* 제1장 총칙 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>제1장 총칙</h2>

        {/* 제1조 (목적) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제1조 (목적)</h3>
          <p>
            이 약관은 10duk(이하 "회사"라 합니다)이 제공하는 인터넷
            서비스(www.10duk.com, 이하 "서비스")의 이용 조건 및 절차, 회사와
            회원의 권리, 의무 및 책임 사항 등 기본적인 사항을 규정함을 목적으로
            합니다.
          </p>
        </article>

        {/* 제2조 (정의) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제2조 (정의)</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>
              <strong>회원</strong>: 이 약관에 동의하고 회사와 이용계약을
              체결하여 서비스를 이용하는 자를 말합니다.
            </li>
            <li>
              <strong>아이디(ID)</strong>: 회원의 식별과 서비스 이용을 위해
              회원이 설정하고 회사가 승인한 문자와 숫자의 조합을 말합니다.
            </li>
            <li>
              <strong>비밀번호</strong>: 회원의 개인정보 보호를 위해 회원 자신이
              설정한 문자와 숫자의 조합을 말합니다.
            </li>
            <li>
              <strong>게시물</strong>: 회원이 서비스를 이용하면서 게시한 글,
              사진, 동영상, 파일, 링크 등의 정보를 말합니다.
            </li>
            <li>
              이 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 관계
              법령 및 서비스별 안내에서 정하는 바에 따릅니다.
            </li>
          </ul>
        </article>

        {/* 제3조 (약관의 효력과 변경) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제3조 (약관의 효력과 변경)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              이 약관은 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력을
              발생합니다.
            </li>
            <li>
              이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로
              공지함으로써 효력이 발생합니다.
            </li>
            <li>
              회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을
              개정할 수 있습니다.
            </li>
            <li>
              회사가 약관을 개정하는 경우 적용일자 및 개정사유를 명시하여 현행
              약관과 함께 서비스 초기 화면 또는 공지사항에 그 적용일자 7일
              전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의
              개정의 경우에는 최소 30일 이상의 사전 유예기간을 두고 공지합니다.
            </li>
            <li>
              회원은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관의
              효력 발생일로부터 15일 이내에 거부 의사를 표명하지 아니하고
              서비스를 계속 이용하는 경우 약관의 변경에 동의한 것으로
              간주됩니다.
            </li>
          </ol>
        </article>

        {/* 제4조 (준용규정) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제4조 (준용규정)</h3>
          <p>
            이 약관에 명시되지 않은 사항은 관련 법령 또는 상관례에 따릅니다.
          </p>
        </article>
      </section>

      {/* 제2장 서비스 이용계약 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제2장 서비스 이용계약
        </h2>

        {/* 제5조 (이용계약의 성립) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제5조 (이용계약의 성립)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              이용계약은 회원이 되고자 하는 자가 약관의 내용에 대하여 동의를 한
              다음 회원가입 신청을 하고, 회사가 이러한 신청에 대하여
              승낙함으로써 성립됩니다.
            </li>
            <li>
              회사는 회원의 신청에 대하여 승낙함을 원칙으로 합니다. 다만, 회사는
              다음 각 호에 해당하는 신청에 대해서는 승낙을 하지 않거나 사후에
              이용계약을 해지할 수 있습니다.
              <ol className='list-decimal ml-5 my-2'>
                <li>타인의 명의를 이용한 경우</li>
                <li>
                  허위의 정보를 기재하거나, 회사가 요청하는 내용을 기재하지 않은
                  경우
                </li>
                <li>관련 법령에 위반되거나 부당한 이용신청임이 확인된 경우</li>
              </ol>
            </li>
          </ol>
        </article>

        {/* 제6조 (회원정보의 변경) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제6조 (회원정보의 변경)
          </h3>
          <p>
            회원은 회원가입 시 기재한 사항이 변경되었을 경우, 서비스 내 개인정보
            수정 화면을 통하여 즉시 해당 내용을 수정하여야 합니다. 변경하지
            않음으로 인해 발생하는 불이익에 대한 책임은 회원에게 있습니다.
          </p>
        </article>
      </section>

      {/* 제3장 서비스의 이용 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제3장 서비스의 이용
        </h2>

        {/* 제7조 (서비스의 제공 및 변경) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제7조 (서비스의 제공 및 변경)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>회사는 다음과 같은 서비스를 제공합니다.</li>
            <ul className='list-disc ml-5 my-2'>
              <li>콘텐츠 제공 서비스</li>
              <li>커뮤니티 및 게시판 서비스</li>
              <li>
                기타 회사가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
                회원들에게 제공하는 일체의 서비스
              </li>
            </ul>
            <li>
              회사는 서비스의 내용, 이용방법, 이용시간을 변경할 수 있으며,
              변경사항은 변경 7일 전에 회원에게 공지합니다.
            </li>
          </ol>
        </article>

        {/* 제8조 (서비스의 중단) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제8조 (서비스의 중단)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를
              제한하거나 중단할 수 있습니다.
              <ol className='list-decimal ml-5 my-2'>
                <li>설비의 보수 등 공사로 인한 부득이한 경우</li>
                <li>
                  전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를
                  중지한 경우
                </li>
                <li>기타 불가항력적 사유가 있는 경우</li>
              </ol>
            </li>
            <li>
              회사는 서비스 중단의 경우 사전에 회원에게 공지하며, 사전 공지가
              불가능한 경우 지체 없이 통지합니다.
            </li>
          </ol>
        </article>

        {/* 제9조 (회원에 대한 통지) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제9조 (회원에 대한 통지)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사가 회원에 대한 통지를 하는 경우, 회원이 제공한 이메일 주소
              또는 서비스 내 쪽지 등을 활용할 수 있습니다.
            </li>
            <li>
              불특정다수 회원에 대한 통지의 경우 7일 이상 서비스 내 공지사항에
              게시함으로써 개별 통지에 갈음할 수 있습니다.
            </li>
          </ol>
        </article>

        {/* 제10조 (개인정보의 보호) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제10조 (개인정보의 보호)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기
              위해 노력합니다.
            </li>
            <li>
              개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의
              개인정보처리방침이 적용됩니다.
            </li>
          </ol>
        </article>
      </section>

      {/* 제4장 계약당사자의 의무 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제4장 계약당사자의 의무
        </h2>

        {/* 제11조 (회사의 의무) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제11조 (회사의 의무)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 관련 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를
              하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위해 최선을
              다합니다.
            </li>
            <li>
              회사는 회원의 개인정보를 안전하게 보호하며, 개인정보처리방침을
              공시하고 준수합니다.
            </li>
            <li>
              회사는 서비스 이용과 관련하여 회원으로부터 제기된 의견이나 불만이
              정당하다고 인정할 경우 이를 신속히 처리합니다.
            </li>
          </ol>
        </article>

        {/* 제12조 (회원의 의무) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제12조 (회원의 의무)
          </h3>
          <p>회원은 다음 행위를 하여서는 안 됩니다.</p>
          <ol className='list-decimal ml-5 my-2'>
            <li>회원가입 신청 또는 회원정보 변경 시 허위 내용의 등록</li>
            <li>타인의 정보 도용</li>
            <li>회사의 운영진이나 직원을 사칭하는 행위</li>
            <li>회사가 게시한 정보의 변경</li>
            <li>
              회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는
              게시
            </li>
            <li>회사 및 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>
              회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </li>
            <li>
              외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는
              정보를 서비스에 공개 또는 게시하는 행위
            </li>
            <li>기타 불법적이거나 부당한 행위</li>
          </ol>
          <p>
            회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스상에 공지한
            주의사항을 준수하여야 합니다.
          </p>
        </article>
      </section>

      {/* 제5장 게시물 관련 사항 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제5장 게시물 관련 사항
        </h2>

        {/* 제13조 (게시물의 저작권) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제13조 (게시물의 저작권)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회원이 서비스 내에 게시한 게시물의 저작권은 해당 저작권자에게
              귀속됩니다.
            </li>
            <li>
              회사는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 회원의
              게시물을 사용할 수 있으며, 필요한 범위 내에서 게시물의 편집, 복제,
              전시 등을 할 수 있습니다.
            </li>
            <li>
              회사는 전항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우,
              사전에 회원의 동의를 얻어야 합니다.
            </li>
          </ol>
        </article>

        {/* 제14조 (게시물의 관리) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제14조 (게시물의 관리)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회원의 게시물이 정보통신망법 및 저작권법 등 관련 법령에 위반되는
              내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당
              게시물의 삭제 등을 요청할 수 있으며, 회사는 관련 법령에 따라
              조치를 취합니다.
            </li>
            <li>
              회사는 사전에 통지하지 않고 회원의 게시물이 다음 각 호에 해당하는
              경우 삭제하거나 이동 또는 등록을 거부할 수 있습니다.
              <ol className='list-decimal ml-5 my-2'>
                <li>불법적이거나 부당한 내용의 게시물</li>
                <li>타인의 명예를 훼손하거나 권리를 침해하는 내용</li>
                <li>음란물 또는 청소년에게 유해한 내용</li>
                <li>기타 회사 정책 및 관련 법령에 위반되는 내용</li>
              </ol>
            </li>
          </ol>
        </article>
      </section>

      {/* 제6장 계약해지 및 이용제한 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제6장 계약해지 및 이용제한
        </h2>

        {/* 제15조 (회원 탈퇴 및 자격 상실 등) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제15조 (회원 탈퇴 및 자격 상실 등)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회원은 언제든지 서비스 내 회원탈퇴 기능을 통하여 이용계약 해지를
              신청할 수 있으며, 회사는 즉시 회원탈퇴를 처리합니다.
            </li>
            <li>
              회원이 다음 각 호의 사유에 해당하는 경우, 회사는 사전 통보 없이
              회원자격을 제한 또는 정지시킬 수 있습니다.
              <ol className='list-decimal ml-5 my-2'>
                <li>가입 신청 시 허위 내용을 등록한 경우</li>
                <li>서비스 이용 중 부정한 행위를 한 경우</li>
                <li>
                  다른 회원의 서비스 이용을 방해하거나 그 정보를 도용하는 등
                  전자거래 질서를 위협하는 경우
                </li>
                <li>
                  서비스를 이용하여 법령 및 이 약관이 금지하거나 공서양속에
                  반하는 행위를 하는 경우
                </li>
              </ol>
            </li>
            <li>
              회사가 회원자격을 제한·정지시킨 후 동일한 행위가 2회 이상
              반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우, 회사는
              회원자격을 상실시킬 수 있습니다.
            </li>
            <li>
              회사가 회원자격을 상실시키는 경우 회원등록을 말소하며, 회원에게
              이를 통지합니다.
            </li>
          </ol>
        </article>

        {/* 제16조 (이용제한 절차) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제16조 (이용제한 절차)
          </h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 이용제한을 하고자 하는 경우 그 사유, 일시 및 기간을 정하여
              이메일 또는 기타 방법으로 해당 회원에게 통지합니다.
            </li>
            <li>
              다만, 회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는
              경우에는 즉시 서비스 이용을 제한할 수 있습니다.
            </li>
          </ol>
        </article>
      </section>

      {/* 제7장 손해배상 및 면책조항 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>
          제7장 손해배상 및 면책조항
        </h2>

        {/* 제17조 (손해배상) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제17조 (손해배상)</h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회원이 이 약관의 규정을 위반하여 회사에 손해가 발생한 경우, 회원은
              회사에 발생한 모든 손해를 배상하여야 합니다.
            </li>
            <li>
              회사는 무료로 제공하는 서비스와 관련하여 회원에게 발생한 손해에
              대하여 책임을 지지 않습니다.
            </li>
          </ol>
        </article>

        {/* 제18조 (면책조항) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제18조 (면책조항)</h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 천재지변 또는 이에 준하는 불가항력으로 인해 서비스를 제공할
              수 없는 경우 책임이 면제됩니다.
            </li>
            <li>
              회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을
              지지 않습니다.
            </li>
            <li>
              회사는 회원이 서비스 내에 게시한 정보, 자료 등의 신뢰도, 정확성
              등에 대해서는 책임을 지지 않습니다.
            </li>
          </ol>
        </article>
      </section>

      {/* 제8장 기타 */}
      <section>
        <h2 className='text-3xl font-semibold mt-6 mb-2'>제8장 기타</h2>

        {/* 제19조 (분쟁해결) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>제19조 (분쟁해결)</h3>
          <ol className='list-decimal ml-5 my-2'>
            <li>
              회사는 회원이 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
              보상 처리하기 위해 최선을 다합니다.
            </li>
            <li>
              회사는 회원으로부터 제출되는 불만사항 및 의견을 우선적으로
              처리하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 회원에게
              통보합니다.
            </li>
          </ol>
        </article>

        {/* 제20조 (재판권 및 준거법) */}
        <article>
          <h3 className='text-xl font-semibold mt-6 mb-2'>
            제20조 (재판권 및 준거법)
          </h3>
          <p>
            이 약관은 대한민국 법령에 의하여 규정되고 이행되며, 서비스 이용과
            관련하여 회사와 회원 간에 발생한 분쟁에 대해서는 민사소송법상의 관할
            법원에 제소합니다.
          </p>
        </article>

        {/* 부칙 */}
        <section>
          <h2 className='text-xl font-semibold mt-6 mb-2'>부칙</h2>
          <p>
            이 약관은 2024년 11월 19일부터 시행합니다.
            <br />
            회사는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을
            개정할 수 있으며, 개정 내용은 적용일자 7일 전부터 서비스 내
            공지사항을 통해 공지합니다.
          </p>
        </section>
      </section>
    </div>
  );
}

export default TermsOfUsePage;
