import React from "react";
import { TextColors } from "../../constants/style";

interface ButtonProps {
  text: string;
  btnFunc: () => void;
  textColor: string;
  keyFunc?: () => void;
  borderStyle?: string;
  width?: string;
  padding?: string;
  className?: string;
}

/**
 *
 * @param text 버튼 텍스트
 * @param btnFunc 클릭시 실행할 함수
 * @param borderStyle 버튼 테두리 스타일
 * @param width 버튼 너비
 * @param padding 버튼 패딩
 * @param className 기타 클래스
 */

function Btn({
  textColor,
  text,
  btnFunc,
  keyFunc,
  width,
  padding,
  className,
  borderStyle,
}: ButtonProps) {
  const border = borderStyle ? borderStyle : "border-2 border-navy";
  return (
    <>
      <button
        type="button"
        onClick={btnFunc}
        className={`${TextColors[textColor]} ${width} ${padding} ${borderStyle} ${className}  px-3 py-1`}
      >
        {text}
      </button>
    </>
  );
}

export default Btn;
