import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../constants/types";
import { getAnnouncements } from "../../../apis/admin/announcement";
import { getBoardName } from "../../../apis/board";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../Common/Pagination";
import { Announcements } from "../../../constants/types";

interface AnnouncementsListsProps {
  boardNumber: number | null;
}

interface Announcement {
  id: string;
  title: string;
  viewCount: number;
  createdAt: string;
  annType: string;
  boardNumber?: number;
}

interface BoardNameResponse {
  boardName: string;
}

function AdminAnnouncementList({ boardNumber }: AnnouncementsListsProps) {
  const navigate = useNavigate();
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [boardNames, setBoardNames] = useState<{ [key: number]: string }>({});
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);

  const handleAnnouoncementClick = (postId: string) => {
    if (boardNumber) {
      navigate(`/announcement/${boardNumber}/${postId}`);
    } else {
      navigate(`/announcement/${postId}`);
    }
    window.location.reload();
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/admin/page/announcement?page=${page}`);
    window.location.reload();
  };

  const goToCreateAnnouncement = () => {
    navigate("/admin/page/announcement/create");
  };

  useEffect(() => {
    getAnnouncements(currentPage)
      .then((res) => {
        setAnnouncements(res.announcements);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  useEffect(() => {
    const fetchBoardNames = async () => {
      const names: { [key: number]: string } = {};
      for (const post of announcements) {
        if (
          post.annType === "board" &&
          post.boardNumber &&
          !names[post.boardNumber]
        ) {
          const board: BoardNameResponse = await getBoardName(post.boardNumber);
          names[post.boardNumber] = board.boardName;
        }
      }
      setBoardNames(names);
    };
    fetchBoardNames();
  }, [announcements]);

  const renderAnnouncementHeader = () => {
    return (
      <thead className='bg-gray-200'>
        <tr>
          <th className='py-3 px-4 text-center'>제목</th>
          <th className='py-3 px-4 text-center'>작성자</th>
          <th className='py-3 px-4 text-center'>조회수</th>
          <th className='py-3 px-4 text-center'>작성일</th>
          <th className='py-3 px-4 text-center'>유형</th>
        </tr>
      </thead>
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const renderAnnouncementPost = (posts: Announcement[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handleAnnouoncementClick(post.id)}>
            <td className='py-[10px] pl-4 flex items-center font-normal'>
              <p className='font-semibold px-2'>{post.title}</p>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='text-center font-normal'>운영자덕</div>
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {formatDate(post.createdAt)}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.annType === "integrate" && "통합"}
              {post.annType === "popular" && "핫"}
              {post.annType === "ranker" && "랭커"}
              {post.annType === "voteBoard" && "게시판 투표"}
              {post.annType === "board" &&
                post.boardNumber !== undefined &&
                boardNames[post.boardNumber]}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>공지사항 관리</h3>
      </div>
      <table className='w-full'>
        {renderAnnouncementHeader()}
        {announcements.length > 0 ? (
          renderAnnouncementPost(announcements)
        ) : (
          <tbody>
            <tr>
              <td colSpan={4} className='text-center'>
                Loading announcements...
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <div className='flex flex-row-reverse mr-1/3 my-6 pr-2'>
        <button
          id='createPostButton'
          type='button'
          onClick={goToCreateAnnouncement}
          className='bg-[#222222] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28'>
          공지사항 작성
        </button>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default AdminAnnouncementList;
