import { useSearchParams } from "react-router-dom";
import SearchResult from "../../components/Search/SearchResult";
import MobileSearchResult from "../../components/Search/MobileSearchResult";

function MobileSearchResultPage() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "all";
  const query = searchParams.get("query") || "";

  return (
    <>
      <div>
        <MobileSearchResult type={type} query={query} />
      </div>
    </>
  );
}

export default MobileSearchResultPage;
