const Checkbox = ({ label, checked, onChange }: any) => (
  <div className='flex items-center'>
    <input
      id='rememberMe' // input에 id 추가
      type='checkbox'
      checked={checked}
      onChange={onChange}
      className='w-4 h-4 text-hotduk border-hotduk rounded focus:ring-hotduk'
    />
    <label
      htmlFor='rememberMe'
      className='text-sm text-hotduk hover:underline ml-2'>
      {label}
    </label>{' '}
    {/* label에 htmlFor로 연결 */}
  </div>
);

export default Checkbox;
