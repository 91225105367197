import { useEffect, useState } from "react";
import { getNiceStatus } from "../../apis/user";
import { useNavigate } from "react-router-dom";
import TopLogoBar from "../../components/NativeLogin/TopLogoBar";
import { resetPassword } from "../../apis/user";

function ResetPasswordPage() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const searchParams = new URLSearchParams(window.location.search);
  const loginId = searchParams.get("loginId");

  const [passwordMessage, setPasswordMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [passwordMatchMessage, setPasswordMatchMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const res = await getNiceStatus();
        if (!res.isVerified) {
          //비어있으면
          navigate("/reset/verify");
          alert("본인인증을 완료해야 합니다.");
        } else {
          setIsAuthenticated(true);
          alert("본인인증이 완료되었습니다!");
        }
      } catch (error) {
        console.error("Auth check error:", error);
      }
    };
    checkAuthStatus();
  }, [navigate]);

  if (!isAuthenticated) {
    return <div></div>;
  }

  // 비밀번호 규칙 검사
  const validatePasswordRules = (password: string) => {
    if (!password) {
      setPasswordMessage({ text: "비밀번호를 입력하세요.", color: "text-red" });
      setIsPasswordValid(false);
      return;
    }
    if (password.length < 8 || password.length > 20) {
      setPasswordMessage({
        text: "비밀번호는 8~20자로 구성되어야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      setPasswordMessage({
        text: "비밀번호는 최소 1개의 영문 및 1개의 숫자를 포함해야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':",./<>?~`]/.test(password)) {
      setPasswordMessage({
        text: "허용되지 않는 특수문자가 포함되어 있습니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }

    setPasswordMessage({
      text: "사용 가능한 비밀번호입니다.",
      color: "text-blue",
    });
    setIsPasswordValid(true);
  };

  // 비밀번호 일치 여부 검사
  const validatePasswordMatch = (password: string, confirmPassword: string) => {
    if (confirmPassword === "") {
      setPasswordMatchMessage({ text: "", color: "" });
      setIsPasswordMatch(false);
      return;
    }
    if (password === confirmPassword) {
      setPasswordMatchMessage({
        text: "비밀번호가 일치합니다.",
        color: "text-blue",
      });
      setIsPasswordMatch(true);
    } else {
      setPasswordMatchMessage({
        text: "비밀번호가 일치하지 않습니다.",
        color: "text-red",
      });
      setIsPasswordMatch(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePasswordRules(newPassword); // 비밀번호 규칙 검사
    validatePasswordMatch(newPassword, confirmPassword); // 비밀번호 일치 검사
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    validatePasswordMatch(password, newConfirmPassword); // 비밀번호 일치 검사
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isPasswordValid || !isPasswordMatch) {
      setError("비밀번호가 규칙에 맞지 않거나 일치하지 않습니다.");
      return;
    }

    try {
      // 비밀번호 변경 요청
      await resetPassword(password);
      navigate("/reset/password/success", { state: { fromSubmit: true } });
    } catch (error) {
      console.error("비밀번호 변경 오류:", error);
      setError("비밀번호 변경 중 오류가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <>
      <TopLogoBar />
      <div className='pt-20 max-w-3xl mx-auto'>
        <h1 className='text-2xl font-bold text-center mt-10'>
          {`당신의 아이디는 "${loginId}"입니다.`}
        </h1>
        <p className='text-center mt-10 mb-5'>
          새로운 비밀번호를 설정해주세요.
        </p>
        <form
          onSubmit={handleSubmit}
          className='border rounded-md border-gray flex flex-col items-center justify-center px-4 max-w-md mx-auto'>
          <div className='mt-5 w-full'>
            <div className='flex items-center'>
              <label className='text-sm font-medium w-28 text-right'>
                비밀번호
              </label>
              <input
                type='password'
                value={password}
                onChange={handlePasswordChange}
                className='px-2 py-1 border rounded-md border-gray ml-5 w-64'
              />
            </div>
            {passwordMessage.text && (
              <p className={`text-sm ${passwordMessage.color} mt-1 ml-36`}>
                {passwordMessage.text}
              </p>
            )}
          </div>

          <div className='mt-5 w-full'>
            <div className='flex items-center'>
              <label className='text-sm font-medium w-28 text-right'>
                비밀번호 확인
              </label>
              <input
                type='password'
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                className='px-2 py-1 border rounded-md border-gray ml-5 w-64'
              />
            </div>
            {passwordMatchMessage.text && (
              <p className={`text-sm ${passwordMatchMessage.color} mt-1 ml-36`}>
                {passwordMatchMessage.text}
              </p>
            )}
          </div>

          {error && <div className='text-red text-sm mt-5'>{error}</div>}

          <button
            type='submit'
            className='px-4 py-2 mb-5 mt-5 text-white bg-hotduk_light rounded hover:bg-hotduk w-64'>
            비밀번호 변경
          </button>
        </form>
      </div>
    </>
  );
}

export default ResetPasswordPage;
