import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getNotifications, readNotification } from "../apis/notification";

interface Notification {
  announcementMessage: string;
  boardNumber: number;
  commentContent: string;
  commentId: string;
  commentNickname: string;
  createdAt: string;
  isRead: boolean;
  notiId: string;
  notiType: string;
  postId: string;
  postTitle: string;
  _id: string;
}

interface NotificationState {
  notifications: Notification[];
  unreadNotifications: string[];
}

const initialState: NotificationState = {
  notifications: [],
  unreadNotifications: [],
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
      state.unreadNotifications = action.payload
        .filter((notification) => !notification.isRead)
        .map((notification) => notification.notiId);
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.notifications.push(action.payload);
      if (!action.payload.isRead) {
        state.unreadNotifications.push(action.payload.notiId);
      }
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      state.notifications = state.notifications.filter(
        (notification) => notification.notiId !== action.payload
      );
      state.unreadNotifications = state.unreadNotifications.filter(
        (notiId) => notiId !== action.payload
      );
    },
  },
});

export const { setNotifications, addNotification, removeNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
