import {
  Announcements,
  IntegratedAnnouncements,
  Post,
} from '../../constants/types';
import { useNavigate } from 'react-router-dom';
import { getPost } from '../../apis/post';

interface MobileRenderPostsProps {
  posts?: Post[];
  isHot?: boolean;
  announcements?: Announcements[];
  integratedAnnouncements?: IntegratedAnnouncements[];
}

function MobileRenderPosts({
  posts,
  isHot,
  announcements,
  integratedAnnouncements,
}: MobileRenderPostsProps) {
  const navigate = useNavigate();

  const handlePostClick = (postId: string) => {
    getPost(postId)
      .then((res) => {
        navigate(`/post/${res.boardNumber}/${postId}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {});
    //todo 추후에 boardnumber 또는 boardname 받아서 post detail 로 navigate 해야함
    // navigate(`/post/${postId}`);
  };

  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  if (!posts || !Array.isArray(posts)) {
    return (
      <div className='w-full pl-4 text-textcolor text-lg'>
        게시물이 존재하지 않습니다.
      </div>
    );
  }

  const hotPosts = (posts: Post[]) => {
    return (
      <div>
        {posts.map((post) => (
          <div
            key={post.id}
            className='items-center border-b border-[#A4A4A4]'
            onClick={() => handlePostClick(post.id)}
          >
            <div className='pb-2 pt-3 pl-2 flex items-center'>
              <div className='flex'>
                {post.ranker ? (
                  <div className='flex items-center'>
                    <div className=' flex-shrink-0'>
                      <img
                        src='/assets/icon/rank_label_mobile.svg'
                        alt='rank_label_mobile'
                        className='mr-1 w-12'
                      />
                    </div>
                    <div className='flex text-base font-semibold'>
                      <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-1 w-3'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='flex'>
                    <div className=' flex-shrink-0'>
                      <div className='pl-1'></div>
                    </div>
                    <div className='flex text-base font-normal'>
                      <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-1 w-3'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='flex pl-2 pb-1'>
              <div className='flex px-1 pb-2 items-center'>
                {post.ranker ? (
                  <div className='flex items-center'>
                    <p className='flex text-xs text-[#6B6B6B] font-normal'>
                      익명
                    </p>
                    <img
                      src='/assets/icon/ranker_crown.svg'
                      alt='ranker_crown'
                      className='w-6 px-1 mr-1'
                    />
                  </div>
                ) : (
                  <div className='pr-7 text-xs text-[#6B6B6B] font-normal'>
                    익명
                  </div>
                )}
              </div>
              <div className='flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]'>
                <img
                  src='/assets/icon/eye.svg'
                  alt='eye'
                  className='w-4 mr-1'
                />
                {post.viewCount}
              </div>
              <div className='flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]'>
                <img
                  src='/assets/icon/thumbsup_title.svg'
                  alt='like'
                  className='w-[14px] mr-1'
                />
                {post.voteUpCount}
              </div>
              <div className='flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]'>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 3600000
                ) < 24 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt))
                      .getHours()
                      .toString()
                      .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                      .getMinutes()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                ) : (
                  <>
                    {Math.floor(
                      (Date.now() - Date.parse(post.createdAt)) / 86400000
                    ) > 364 ? (
                      <div>
                        {`${new Date(
                          new Date(post.createdAt)
                        ).getFullYear()}/${(
                          new Date(new Date(post.createdAt)).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, '0')}`}{' '}
                      </div>
                    ) : (
                      <div>
                        {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, '0')}`}{' '}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const rankPosts = (posts: Post[]) => {
    return (
      <div>
        {posts.map((post) => (
          <div
            key={post.id}
            className='items-center border-b border-[#A4A4A4]'
            onClick={() => handlePostClick(post.id)}
          >
            <div className='pb-2 pt-3 pl-2 flex items-center'>
              <div className='flex items-center'>
                {/* 여기서 hot populartiy 수정  */}
                {post.viewCount > 149 ? (
                  <div className='flex items-center'>
                    <div className=' flex-shrink-0'>
                      <img
                        src='/assets/icon/hot_label_mobile.svg'
                        alt='rank_label_mobile'
                        className='w-14'
                      />
                    </div>
                    <div className='flex text-base font-semibold'>
                      <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-1 w-3'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='flex'>
                    <div className=' flex-shrink-0'>
                      <div className='pl-1'></div>
                    </div>
                    <div className='flex text-base font-normal '>
                      <div className='flex text-[15px] font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-1 w-3'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className='flex pl-2 pb-1'>
              <div className='flex px-1 pb-2 items-center'>
                {post.ranker ? (
                  <div className='flex items-center'>
                    <p className='flex text-xs text-[#6B6B6B] font-normal'>
                      익명
                    </p>
                    <img
                      src='/assets/icon/ranker_crown.svg'
                      alt='ranker_crown'
                      className='w-6 px-1 mr-1'
                    />
                  </div>
                ) : (
                  <div className='pr-7 text-xs text-[#6B6B6B] font-normal'>
                    익명
                  </div>
                )}
              </div>
              <div className='flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]'>
                <img
                  src='/assets/icon/eye.svg'
                  alt='eye'
                  className='w-4 mr-1'
                />
                {post.viewCount}
              </div>
              <div className='flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]'>
                <img
                  src='/assets/icon/thumbsup_title.svg'
                  alt='like'
                  className='w-[14px] mr-1'
                />
                {post.voteUpCount}
              </div>
              <div className='flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]'>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 3600000
                ) < 24 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt))
                      .getHours()
                      .toString()
                      .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                      .getMinutes()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                ) : (
                  <>
                    {Math.floor(
                      (Date.now() - Date.parse(post.createdAt)) / 86400000
                    ) > 364 ? (
                      <div>
                        {`${new Date(
                          new Date(post.createdAt)
                        ).getFullYear()}/${(
                          new Date(new Date(post.createdAt)).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, '0')}`}{' '}
                      </div>
                    ) : (
                      <div>
                        {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                          .toString()
                          .padStart(2, '0')}/${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, '0')}`}{' '}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <div>
        <div
          key={post.id}
          className='items-center bg-[#F1F1F1] border-b border-[#A4A4A4]'
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <div className='py-4 pl-2 flex items-center '>
            <div className=' flex-shrink-0 items-center'>
              <img
                src='/assets/icon/notice_label_mobile.svg'
                alt='notice_label_mobile'
                className='w-[54px] mr-1'
              />
            </div>
            <div className='flex text-base font-semibold items-center'>
              {post.title}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const slicedPosts = posts.slice(0, 2);
    return (
      <div>
        {slicedPosts.map((post, index) => (
          <div
            key={post.id}
            className='items-center bg-[#F1F1F1] border-b border-[#A4A4A4]'
            onClick={() => handleAnnouoncementClick(post.id)}
          >
            <div className='py-4 pl-2 flex items-center '>
              <div className=' flex-shrink-0 items-center'>
                <img
                  src='/assets/icon/notice_label_mobile.svg'
                  alt='notice_label_mobile'
                  className='w-[54px] mr-1'
                />
              </div>
              <div className='flex text-base font-semibold items-center'>
                {post.title}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className='border-b border-[#A4A4A4]'></div>
      {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
        <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
      ) : (
        <></>
      )}
      {announcements && announcements.length !== 0 ? (
        <>{renderAnnouncementPost(announcements)}</>
      ) : (
        <></>
      )}
      {isHot ? hotPosts(posts) : rankPosts(posts)}
    </>
  );
}

export default MobileRenderPosts;
