import BoardMain from "../../components/Main/BoardMain";
import { useParams } from "react-router-dom";

function BoardMainPage() {
  const { boardNumber } = useParams<{ boardNumber: string }>();

  if (!boardNumber) {
    return <div>Loading...</div>;
  }

  const parseBoardNumber = parseInt(boardNumber);

  return (
    <div className="w-full">
      <BoardMain boardNumber={parseBoardNumber} />
    </div>
  );
}

export default BoardMainPage;
