import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../constants/types";
import { uploadImage } from "../../apis/post";
import { createBoardPost } from "../../apis/board";
import { SERVER_URL } from "../../constants/url";
import Btn from "../Common/Btn";

function CreateBoardPost() {
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [boardName, setBoardName] = useState<string>("");
  const [ranker, setRanker] = useState<boolean>(false);
  const imgLink = `${SERVER_URL}/temp`;

  const userId = useAppSelector((state) => state.auth.userId);

  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file: any) => {
            formData.append("image", file);
            uploadImage(formData)
              .then((res) => {
                resolve({
                  default: res,
                });
              })
              .catch((err) => {});
          });
        });
      },
    };
  };

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return customUploadAdapter(loader);
    };
  }

  const handleSubmitBoardPost = (): void => {
    if (!boardName || !title || !content) {
      alert("모든 항목을 입력해주세요.");
      return;
    }

    if (boardName.trim().length > 20) {
      alert("게시판 이름은 20자 이하로 입력해주세요.");
      return;
    }

    if (title.trim().length > 50) {
      alert("제목은 50자 이하로 입력해주세요.");
      return;
    }

    const formData = new FormData();
    const boardPostContent = JSON.stringify({
      boardName: boardName.trim(),
      title: title.trim(),
      text: content,
      ranker: ranker,
    });
    formData.append("content", boardPostContent);

    createBoardPost(formData)
      .then((res) => {
        alert("게시글이 작성되었습니다.");
        navigate(`/board/voteboard`);
      })
      .catch((err) => {});
  };

  const validateAndSetBoardName = (value: string) => {
    const sanitizedValue = value.replace(/\n/g, "");
    setBoardName(sanitizedValue);
  };

  const validateAndSetTitle = (value: string) => {
    const sanitizedValue = value.replace(/\n/g, "");
    setTitle(sanitizedValue);
  };

  const goToVoteBoard = () => {
    navigate("/board/voteboard");
  };

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex">
          <div
            className="flex text-textcolor text-2xl font-bold mt-8 mb-5 cursor-pointer hover:opacity-50"
            onClick={goToVoteBoard}
          >
            게시판 추가 투표
          </div>
          <div className="flex text-textcolor text-2xl font-bold mt-8 mb-5 ml-2">
            글쓰기
          </div>
        </div>
        <div className="w-full text-[#6B6B6B] mt-3 flex items-center">
          <div className="flex items-center pr-2 whitespace-nowrap">
            게시판 이름:{" "}
          </div>
          <div className="flex items-center w-full">
            <textarea
              className="flex items-center border border-[#EBF0F6] my-1 py-2 px-1 w-full h-10 rounded-md resize-none focus:outline-none font-normal text-sm"
              placeholder="ex) 일상, 운동 (20자 이내)"
              value={boardName}
              onChange={(e) => validateAndSetBoardName(e.target.value)}
            ></textarea>
            <div className="flex items-center pl-2 whitespace-nowrap">
              {boardName.trim().length}/20
            </div>
          </div>
        </div>
        <div className="w-full text-[#6B6B6B] mt-3 flex items-center">
          <div className="flex items-center w-full">
            <textarea
              className="border border-[#EBF0F6] my-1 p-2 w-full h-10 rounded-md resize-none focus:outline-none font-normal text-sm"
              placeholder="글 제목을 입력해주세요. (50자 이내)"
              value={title}
              onChange={(e) => validateAndSetTitle(e.target.value)}
            ></textarea>
            <div className="flex items-center pl-2 whitespace-nowrap">
              {title.trim().length}/50
            </div>
          </div>
        </div>
        <div className=" my-2">
          <CKEditor
            editor={ClassicEditor as any}
            config={{
              extraPlugins: [uploadPlugin],
              placeholder: "글을 작성해주세요.",
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "imageUpload",
                  "mediaEmbed",
                  "link",
                  "|",
                  "bold",
                  "italic",
                  "fontColor",
                  "fontBackgroundColor",
                  "alignment",
                  "|",
                  "undo",
                  "redo",
                ],
              },
              language: "ko",
              mediaEmbed: {
                previewsInData: true,
              },
            }}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
            onBlur={(event, editor) => {}}
            onFocus={(event, editor) => {}}
          />
        </div>
        <div className="flex items-center justify-center my-5">
          <div className="px-2">
            <button
              type="button"
              onClick={goToVoteBoard}
              className="bg-[#707070] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28"
            >
              취소
            </button>
          </div>
          <div className="px-2">
            <button
              id="createPostButton"
              type="button"
              onClick={handleSubmitBoardPost}
              className="bg-[#222222] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28"
            >
              글쓰기
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateBoardPost;
