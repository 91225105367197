/*eslint quote-props: ["error", "consistent"]*/

type ColorType = {
  [navy: string]: string;
  gray: string;
  ivory: string;
  beige: string;
  yellow: string;
  red: string;
  green: string;
  lemon: string;
};

export const TextColors: ColorType = {
  navy: 'text-navy',
  gray: 'text-gray',
  ivory: 'text-ivory',
  beige: 'text-beige',
  yellow: 'text-yellow',
  red: 'text-red',
  green: 'text-green',
  lemon: 'text-lemon',
  white: 'text-white',
};

export const BgColors: ColorType = {
  navy: 'bg-navy',
  gray: 'bg-gray',
  ivory: 'bg-ivory',
  beige: 'bg-beige',
  yellow: 'bg-yellow',
  red: 'bg-red',
  green: 'bg-green',
  lemon: 'bg-lemon',
};
