import { useParams } from "react-router-dom";
import MobileAnnouncementDetail from "../../components/Announcements/MobileAnnouncementDetail";

function MobileAnnouncementDetailPage() {
  const { postId } = useParams<{ postId: string }>();
  const { boardNumber } = useParams<{ boardNumber: string }>();

  if (!boardNumber) {
    return <div>boardNumber Loading...</div>;
  }
  const parseBoardNumber = parseInt(boardNumber);

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  return (
    <div>
      <MobileAnnouncementDetail
        postId={postId}
        boardNumber={parseBoardNumber}
      />
    </div>
  );
}

export default MobileAnnouncementDetailPage;
