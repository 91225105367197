import React from "react";
import AdminAnnouncementList from "../../../components/Admin/Announcement/AdminAnnouncementList";
import { useParams } from "react-router-dom";

function AdminAnnouncementPage() {
  const { boardNumber } = useParams<{ boardNumber: string }>();

  const parseBoardNumber = boardNumber ? parseInt(boardNumber, 10) : null;

  return (
    <>
      <AdminAnnouncementList boardNumber={parseBoardNumber} />
    </>
  );
}

export default AdminAnnouncementPage;
