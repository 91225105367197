interface MobileUserActivityProps {
  userPostCount: number;
  userCommentCount: number;
  userVoteUpCount: number;
  userVoteDownCount: number;
  userBookmarkedCount: number;
}

function MobileUserActivity({
  userPostCount,
  userCommentCount,
  userVoteUpCount,
  userVoteDownCount,
  userBookmarkedCount,
}: MobileUserActivityProps) {
  const activityItems = [
    {
      src: "/assets/icon/post.svg",
      alt: "post",
      text: "글",
      count: userPostCount,
    },
    {
      src: "/assets/icon/comment_profile.svg",
      alt: "comment_profile",
      text: "댓글",
      count: userCommentCount,
    },
    {
      src: "/assets/icon/thumbsup_profile.svg",
      alt: "thumbsup_profile",
      text: "좋아요",
      count: userVoteUpCount,
    },
    {
      src: "/assets/icon/thumbsdown_profile.svg",
      alt: "thumbsdown_profile",
      text: "싫어요",
      count: userVoteDownCount,
    },
    {
      src: "/assets/icon/bookmark.svg",
      alt: "bookmark",
      text: "북마크",
      count: userBookmarkedCount,
    },
  ];
  return (
    <>
      <div className="text-2xl text-textcolor font-semibold my-5">
        나의 활동
      </div>
      <div className="flex rounded-xl bg-[#EFEFEF] text-textcolor py-4 px-4 items-center justify-center">
        <div className="grid grid-cols-2 gap-y-4 gap-x-4 w-full">
          {activityItems.map((item, index) => (
            <div key={index} className="items-center justify-center ">
              <div className="bg-white flex rounded-xl items-center justify-center py-2 px-4">
                <img src={item.src} alt={item.alt} className="h-[22px]" />
                <div className="text-base font-normal pl-3 pr-1 pt-1">
                  {item.text}
                </div>
                <div className=" text-2xl font-semibold pl-1">{item.count}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MobileUserActivity;
