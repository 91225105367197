import { useEffect, useState } from "react";
import { getBoardPosts, getBoardPost } from "../../apis/board";
import {
  voteBoardDetailData,
  IntegratedAnnouncements,
  Announcements,
} from "../../constants/types";
import Pagination from "../Common/Pagination";
import RenderVoteBoard from "./RenderVoteBoard";
import { useNavigate, useParams } from "react-router-dom";
import MobileRenderVoteBoard from "./MobileRenderVoteBoard";

function MobileVoteBoard() {
  const { pageNumber } = useParams<{ pageNumber: string }>();
  const [posts, setPosts] = useState<voteBoardDetailData[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);
  const navigate = useNavigate();

  useEffect(() => {
    getBoardPosts(currentPage)
      .then((res) => {
        setAnnouncements(res.announcements);
        setIntegratedAnnouncements(res.integratedAnnouncements);
        setPosts(res.voteBoardPost);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const goToCreateVotePost = () => {
    navigate("/board/create");
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/board/voteboard/${page}`);
    window.location.reload();
  };

  return (
    <>
      <div className="flex mt-5 justify-between">
        <div className="pl-4 mb-5 text-textcolor text-2xl font-bold">
          게시판 추가 투표
        </div>
        <div className="pr-4 mt-2">
          <button
            id="createVotePostButton"
            type="button"
            onClick={goToCreateVotePost}
            className="bg-[#222222] text-white items-center font-semibold text-sm rounded-[100px] px-3 py-1"
          >
            글쓰기
          </button>
        </div>
      </div>
      <div>
        <MobileRenderVoteBoard
          posts={posts}
          announcements={announcements}
          integratedAnnouncements={integratedAnnouncements}
        />
      </div>
      <div className="flex justify-center items-center mt-5">
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
      <div className="flex justify-center items-center mx-auto mt-5 pr-2">
        <button
          id="createVotePostButton"
          type="button"
          onClick={goToCreateVotePost}
          className="bg-[#222222] text-white items-center font-semibold text-xl rounded-[100px] h-12 w-36"
        >
          글쓰기
        </button>
      </div>
    </>
  );
}

export default MobileVoteBoard;
