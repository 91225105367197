import { axiosInstance } from "../instance";

//게시판 이름 목록 조회
export const getBoardNames = async () => {
  const response = await axiosInstance.get<any>("admin/boardName/all");
  return response.data;
};

//게시판 생성
export const createBoard = async (boardName: string) => {
  const response = await axiosInstance.post<any>("admin/boardName", {
    boardName,
  });
  return response.data;
};

//게시판 이름 변경
export const changeBoardName = async (
  boardNumber: number,
  oldBoardName: string,
  newBoardName: string
) => {
  const response = await axiosInstance.post<any>("admin/boardName/change", {
    boardNumber,
    oldBoardName,
    newBoardName,
  });
  return response.data;
};

//게시판 삭제
export const deleteBoard = async (boardNumber: number) => {
  const response = await axiosInstance.patch<any>(
    `admin/boardName/delete/${boardNumber}`
  );
  return response.data;
};

//게시판 삭제 해제
export const undeleteBoard = async (boardNumber: number) => {
  const response = await axiosInstance.patch<any>(
    `admin/boardName/undelete/${boardNumber}`
  );
  return response.data;
};

//게시판 순서 변경
export const changeBoardOrder = async (order: number[]) => {
  const response = await axiosInstance.post<any>("admin/boardName/order", {
    order,
  });
  return response.data;
};
