import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AdminBoardNameList } from "../../../constants/types";
import {
  getBoardNames,
  createBoard,
  changeBoardName,
  undeleteBoard,
  deleteBoard,
  changeBoardOrder,
} from "../../../apis/admin/adminBoard";

function AdminManageBoard() {
  const navigate = useNavigate();
  const [boardNames, setBoardNames] = useState<AdminBoardNameList[]>([]);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedBoard, setSelectedBoard] = useState<AdminBoardNameList | null>(
    null
  );
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [newBoardName, setNewBoardName] = useState<string>("");
  const [createModalIsOpen, setCreateModalIsOpen] = useState<boolean>(false);
  const [createBoardName, setCreateBoardName] = useState<string>("");
  const [changeBoardOrderModalIsOpen, setChangeBoardOrderModalIsOpen] =
    useState<boolean>(false);
  const [activeBoards, setActiveBoards] = useState<AdminBoardNameList[]>([]);
  const [deletedBoards, setDeletedBoards] = useState<AdminBoardNameList[]>([]);
  const [order, setOrder] = useState<number[]>([]);

  useEffect(() => {
    getBoardNames()
      .then((res) => {
        setOrder(res.order);
        const sortedActiveBoards = res.undeletedBoardNames.sort(
          (a: AdminBoardNameList, b: AdminBoardNameList) =>
            res.order.indexOf(a.boardNumber) - res.order.indexOf(b.boardNumber)
        );
        setActiveBoards(sortedActiveBoards);
        setDeletedBoards(res.deletedBoardNames);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  //게시판 생성 버튼
  const goToCreateBoardName = () => {
    setCreateModalIsOpen(true);
  };

  //게시판 순서 변경 버튼
  const goToChangeBoardOrder = () => {
    setChangeBoardOrderModalIsOpen(true);
  };

  //게시판 이름 클릭
  const handleBoardNameClick = (board: AdminBoardNameList) => {
    setSelectedBoard(board);
    setModalIsOpen(true);
  };

  //모달 X 버튼
  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedBoard(null);
    setIsEditing(false);
  };

  //게시판 이름 수정 버튼
  const handleEdit = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      setNewBoardName("");
    }
  };

  //게시판 이름 수정 확인 버튼
  const handleConfirmEdit = () => {
    if (selectedBoard) {
      if (window.confirm("게시판 이름을 수정하시겠습니까?")) {
        setIsEditing(false);
        setSelectedBoard({ ...selectedBoard, boardName: newBoardName });
        changeBoardName(
          selectedBoard.boardNumber,
          selectedBoard.boardName,
          newBoardName
        )
          .then((res) => {})
          .catch((err) => {
            console.error(err);
          });
        window.location.reload();
      }
    }
  };

  //게시판 삭제 버튼
  const handleDelete = () => {
    if (selectedBoard) {
      const confirmMessage = selectedBoard.isDeleted
        ? "게시판을 삭제해제 하시겠습니까?"
        : "게시판을 삭제 하시겠습니까?";

      if (window.confirm(confirmMessage)) {
        const apiCall = selectedBoard.isDeleted ? undeleteBoard : deleteBoard;

        apiCall(selectedBoard.boardNumber).then((res) => {
          const updatedBoardNames = boardNames.map((board) =>
            board.boardNumber === selectedBoard.boardNumber
              ? { ...board, isDeleted: !board.isDeleted }
              : board
          );
          setBoardNames(updatedBoardNames);
        });
        window.location.reload();
      }
    }
  };

  //게시판 생성 확인 버튼
  const handleCreateBoard = () => {
    if (window.confirm("새로운 게시판을 생성하시겠습니까?")) {
      createBoard(createBoardName).then((res) => {
        setCreateModalIsOpen(false);
        getBoardNames().then((res) => {
          setBoardNames(res.boardNames);
        });
        window.location.reload();
      });
    }
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(activeBoards);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setActiveBoards(items);
    const newOrder = items.map((board) => board.boardNumber);
    setOrder(newOrder);
  };

  const handleSubmitOrder = () => {
    if (window.confirm("게시판 순서를 변경하시겠습니까?")) {
      changeBoardOrder(order).then((res) => {
        setChangeBoardOrderModalIsOpen(false);
        window.location.reload();
      });
    }
  };

  const renderBoardHeader = () => (
    <thead className='bg-gray-200'>
      <tr>
        <th className='py-3 px-4 text-center'>게시판 이름</th>
        <th className='py-3 px-4 text-center'>게시판 번호</th>
        <th className='py-3 px-4 text-center'>삭제여부</th>
      </tr>
    </thead>
  );

  const renderChangeBoardHeader = () => (
    <thead className='bg-gray-200'>
      <tr>
        <th className='py-3 px-4 text-center'>게시판 이름</th>
        <th className='py-3 px-4 text-center'>게시판 번호</th>
      </tr>
    </thead>
  );

  const renderBoardNames = (boards: AdminBoardNameList[]) =>
    boards.map((boardName) => (
      <tr
        key={boardName.boardNumber}
        className='border-b border-gray-200 hover:bg-[#F8F8F8]'
        onClick={() => handleBoardNameClick(boardName)}>
        <td className='py-3 px-4 text-center'>{boardName.boardName}</td>
        <td className='py-3 px-4 text-center'>{boardName.boardNumber}</td>
        <td className='py-3 px-4 text-center'>
          {boardName.isDeleted ? "삭제됨" : "정상"}
        </td>
      </tr>
    ));

  const renderChangeBoardNames = (boards: AdminBoardNameList[]) =>
    boards.map((boardName, index) => (
      <Draggable
        key={boardName.boardNumber}
        draggableId={boardName.boardNumber.toString()}
        index={index}>
        {(provided) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className='border-b border-gray-200 hover:bg-[#F8F8F8]'>
            <td className='py-3 px-4 text-center'>
              <span className='mr-2'>☰</span>
              {boardName.boardName}
            </td>
            <td className='py-3 px-4 text-center'>{boardName.boardNumber}</td>
          </tr>
        )}
      </Draggable>
    ));

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>게시판 관리</h3>
      </div>
      <div className='flex justify-between'>
        <div className='w-1/2 pr-2'>
          <h2 className='text-lg font-bold mt-4'>정상 게시판</h2>
          <table className='w-full'>
            {renderBoardHeader()}
            <tbody>
              {activeBoards.length > 0 ? (
                renderBoardNames(activeBoards)
              ) : (
                <tr>
                  <td className='py-3 px-4 text-center' colSpan={3}>
                    게시판이 존재하지 않습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='w-1/2 pl-2'>
          <h2 className='text-lg font-bold mt-4'>삭제된 게시판</h2>
          <table className='w-full'>
            {renderBoardHeader()}
            <tbody>
              {deletedBoards.length > 0 ? (
                renderBoardNames(deletedBoards)
              ) : (
                <tr>
                  <td className='py-3 px-4 text-center' colSpan={3}>
                    게시판이 존재하지 않습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className='flex justify-end mt-4'>
        <button
          onClick={goToChangeBoardOrder}
          className='py-2 px-4 bg-blue-500 text-white rounded mr-2 hover:bg-blue-700'>
          게시판 순서 변경하기
        </button>
        <button
          onClick={goToCreateBoardName}
          className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700'>
          게시판 생성
        </button>
      </div>
      {modalIsOpen && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='relative bg-white p-6 rounded shadow-lg w-96'>
            {selectedBoard && (
              <div>
                <button
                  className='absolute top-2 right-2 bg-none border-none text-2xl cursor-pointer'
                  onClick={closeModal}>
                  &times;
                </button>
                <div className='flex items-center'>
                  <h4>{selectedBoard.boardName}</h4>
                  {isEditing && (
                    <>
                      <span className='mx-2'>→</span>
                      <input
                        type='text'
                        value={newBoardName}
                        onChange={(e) => setNewBoardName(e.target.value)}
                        className='mr-2 p-1 rounded border border-gray-300'
                      />
                      <button
                        onClick={handleConfirmEdit}
                        className='py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-700'>
                        확인
                      </button>
                    </>
                  )}
                </div>
                <p>게시판 번호: {selectedBoard.boardNumber}</p>
                <p>
                  삭제 여부:{" "}
                  {selectedBoard.isDeleted ? "삭제된 게시판" : "정상 게시판"}
                </p>
                <div className='flex mt-4'>
                  <button
                    className='py-2 px-4 bg-blue-500 text-white rounded mr-2 hover:bg-blue-700'
                    onClick={handleEdit}>
                    게시판 이름 수정
                  </button>
                  <button
                    className='py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700'
                    onClick={handleDelete}>
                    {selectedBoard.isDeleted
                      ? "게시판 삭제 해제"
                      : "게시판 삭제"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {createModalIsOpen && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='relative bg-white p-6 rounded shadow-lg w-96'>
            <button
              className='absolute top-2 right-2 bg-none border-none text-2xl cursor-pointer'
              onClick={() => setCreateModalIsOpen(false)}>
              &times;
            </button>
            <h4>생성할 게시판 이름</h4>
            <input
              type='text'
              value={createBoardName}
              onChange={(e) => setCreateBoardName(e.target.value)}
              className='mr-2 p-1 rounded border border-gray-300'
            />
            <button
              onClick={handleCreateBoard}
              className='py-1 px-2 bg-blue-500 text-white rounded hover:bg-blue-700'>
              확인
            </button>
          </div>
        </div>
      )}
      {changeBoardOrderModalIsOpen && (
        <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center'>
          <div className='relative bg-white p-6 rounded shadow-lg w-96'>
            <button
              className='absolute top-2 right-2 bg-none border-none text-2xl cursor-pointer max-h-[80%] overflow-y-auto'
              onClick={() => setChangeBoardOrderModalIsOpen(false)}>
              &times;
            </button>
            <h4>게시판 순서 변경</h4>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='orderBoards'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className='max-h-[60vh] overflow-y-auto'>
                    <table className='w-full'>
                      {renderChangeBoardHeader()}
                      <tbody>
                        {activeBoards.length > 0 ? (
                          renderChangeBoardNames(activeBoards)
                        ) : (
                          <tr>
                            <td className='py-3 px-4 text-center' colSpan={3}>
                              게시판이 존재하지 않습니다.
                            </td>
                          </tr>
                        )}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <button
              onClick={handleSubmitOrder}
              className='mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700'>
              순서 저장
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminManageBoard;
