import React, { useEffect, useState, useRef } from 'react';
import {
  getPost,
  deletePost,
  bookmarkPost,
  deleteBookmarkPost,
  reportPost,
} from '../../apis/post';
import {
  PostData,
  PostDetailData,
  CommentData,
  Comment,
  useAppSelector,
  Poll,
} from '../../constants/types';
import CreateComment from '../Comments/CreateComment';
import CommetDetail from '../Comments/CommetDetail';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { getBoardName } from '../../apis/board';
import VotePost from './VotePost';
import PostBookmark from './PostBookmark';
import MobileCommentDetail from '../Comments/MobileCommentDetail';
import MobileCreateComment from '../Comments/MobileCreateComment';
import { is } from 'immer/dist/internal';
import { blockPostForever, unblockPost } from '../../apis/admin/adminPost';
import MobilePollResult from '../Poll/MobilePollResult';
import MobilePollDetail from '../Poll/MobilePollDetail';
import AdminMobileCommentDetail from '../Admin/Comment/AdminMobileCommentDetail';

interface MobilePostDetailProps {
  postId: string;
  boardNumber: number;
}

function MobilePostDetail({ postId, boardNumber }: MobilePostDetailProps) {
  const [boardName, setBoardName] = useState<string | null>(null);
  const [post, setPost] = useState<PostDetailData | null>(null);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [hasVotedToPoll, setHasVotedToPoll] = useState<boolean>(
    post?.hasVotedToPoll || false
  );
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentData[] | null>(null);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const userId = useAppSelector((state) => state.auth.userId);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const navigate = useNavigate();
  const postDetailUrl = window.location.href;

  const moreRef = useRef<HTMLDivElement>(null);
  const deleteRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<HTMLDivElement>(null);
  const reportRef = useRef<HTMLDivElement>(null);

  const [voteUpCount, setVoteUpCount] = useState<number>(
    post?.voteUpCount || 0
  );
  const [voteDownCount, setVoteDownCount] = useState<number>(
    post?.voteDownCount || 0
  );
  const [showVoteResult, setShowVoteResult] = useState<boolean>(false);

  const toggleMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (moreRef.current && !moreRef.current.contains(e.target as Node)) {
      if (post && userId === post.userId) {
        if (
          deleteRef.current &&
          !deleteRef.current.contains(e.target as Node) &&
          editRef.current &&
          !editRef.current.contains(e.target as Node)
        ) {
          setIsMoreOpen(false);
        }
      } else {
        if (
          reportRef.current &&
          !reportRef.current.contains(e.target as Node)
        ) {
          setIsMoreOpen(false);
        }
      }
    }
  };

  useEffect(() => {
    getPost(postId)
      .then((res) => {
        setPost(res);
        setComments(res.comments);
        setVoteUpCount(res.voteUpCount);
        setVoteDownCount(res.voteDownCount);
        setPoll(res.poll);
        setHasVotedToPoll(res.hasVotedToPoll);
      })
      .catch((err) => {});

    getBoardName(boardNumber)
      .then((res) => {
        setBoardName(res.boardName);
      })
      .catch((err) => {});

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [postId, boardNumber, hasVotedToPoll]);

  if (!post) return <div> 글 정보 불러오는 중..... </div>;

  if (post.isDeleted) {
    alert('삭제된 게시물입니다.');
    navigate(`/board/${boardNumber}`);
  }

  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${dateTime
    .getDate()
    .toString()
    .padStart(2, '0')} ${dateTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;

  const editedTime = new Date(post.editedAt);
  const editedDateTime = new Date(editedTime);
  const formattedEditedDate = `${editedDateTime.getFullYear()}/${(
    editedDateTime.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}/${editedDateTime
    .getDate()
    .toString()
    .padStart(2, '0')} ${editedDateTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${editedDateTime
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;

  const goToBoard = () => {
    navigate(`/board/${boardNumber}`);
    window.scrollTo(0, 0);
  };

  const handleDeletePost = () => {
    // 글 삭제 시 한 번 더 확인
    const deleteConfirm = window.confirm('정말 삭제하시겠습니까?');
    if (deleteConfirm) {
      deletePost(postId).then((res) => {
        alert('삭제되었습니다');
      });
      navigate(`/board/${boardNumber}`);
      window.scrollTo(0, 0);
    } else {
      alert('삭제가 취소되었습니다');
    }
  };

  const handleEditPost = () => {
    navigate(`/post/edit/${postId}`);
  };

  const handleReportPost = () => {
    // 글 신고 시 한 번 더 확인
    const reportConfirm = window.confirm('정말 신고하시겠습니까?');
    if (reportConfirm) {
      reportPost(postId)
        .then((res) => {
          alert('신고되었습니다');
        })
        .catch((err) => {
          // 이미 신고한 게시글일 경우
          if (
            err.response.status === 403 &&
            err.response.data.message === 'This user has already reported'
          ) {
            alert('이미 신고한 게시글입니다.');
          }
        });
    }
  };

  const handleCopyUrl = () => {
    // 클립보드 API를 사용하여 URL을 복사
    navigator.clipboard
      .writeText(postDetailUrl)
      .then(() => {
        alert('URL이 복사되었습니다.');
      })
      .catch((error) => {});
  };

  const handleBlockPost = () => {
    const blockConfirm = window.confirm('정말 차단하시겠습니까?');
    if (blockConfirm) {
      blockPostForever(postId)
        .then((res) => {
          alert('차단 되었습니다');
          window.location.reload();
        })
        .catch((err) => {});
    }
  };

  const handleUnblockPost = () => {
    const unblockConfirm = window.confirm('정말 차단 해제하시겠습니까?');
    if (unblockConfirm) {
      unblockPost(postId)
        .then((res) => {
          alert('차단 해제 되었습니다');
          window.location.reload();
        })
        .catch((err) => {});
    }
  };

  const toggleVoteResult = () => {
    setShowVoteResult((prev) => !prev);
  };

  const updateHasVotedToPoll = () => {
    setHasVotedToPoll(true);
  };

  if (post.isBlocked && !isAdmin) {
    return (
      <div className='flex flex-col w-full'>
        <div className='pl-4 text-textcolor text-2xl font-bold my-5'>
          {boardName}
        </div>
        <div className='flex flex-col w-full'>
          <div className=' bg-[#F4F4F4] pl-5 py-3 border-y border-[#A4A4A4]'>
            <div className='flex pb-1 items-center'>
              <div className='flex text-textcolor text-lg font-semibold opacity-50'>
                가려진 게시물입니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className='pl-4 text-textcolor text-2xl font-bold my-5'
        onClick={goToBoard}>
        {boardName}
      </div>
      <div className='flex flex-col w-full'>
        <div className=' bg-[#F4F4F4] px-3 py-3 border-y border-[#A4A4A4]'>
          <div className='flex pb-1 items-center'>
            <div className='flex text-textcolor text-lg font-semibold'>
              {post.title}
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <div className='flex text-sm pr-1 text-[#6B6B6B] font-medium items-center'>
                익명
              </div>
              <div className=' pr-1 border-l border-[#898989] h-3 items-center'></div>
              <div className='flex text-[#898989] text-xs items-center font-normal pr-1'>
                {post.editedAt
                  ? `${formattedDate} (${formattedEditedDate} 수정)`
                  : formattedDate}
              </div>
              <div className=' pr-1 border-l border-[#898989] h-3 items-center'></div>
              <div className='flex items-center'>
                <div className='text-[#6B6B6B] text-xs font-normal mr-[3px]'>
                  조회수
                </div>
                <div className='text-[#6B6B6B] text-xs font-normal'>
                  {post.viewCount}
                </div>
              </div>
            </div>
            <div className='flex pr-1' onClick={toggleMoreOpen} ref={moreRef}>
              <img src='/assets/icon/more_mobile.svg' />{' '}
            </div>
          </div>
          <div className=' flex relative '>
            {isMoreOpen &&
              (userId === post.userId ? (
                <div className='absolute right-1 top-0 flex flex-col border border-gray border-opacity-20 bg-white p-1 rounded-md'>
                  <div
                    className=' bg-[#FFF4F4] text-hotduk px-3 py-2 z-20'
                    onClick={handleEditPost}
                    ref={editRef}>
                    <div className='flex justify-center items-center'>
                      <div className=' text-xs font-semibold'>글 수정</div>
                    </div>
                  </div>
                  <div
                    className=' bg-white text-gray px-3 py-2 z-20'
                    onClick={handleDeletePost}
                    ref={deleteRef}>
                    <div className='flex justify-center items-center'>
                      <div className=' text-xs font-semibold'>글 삭제</div>
                    </div>
                  </div>
                </div>
              ) : (
                isLogin && (
                  <div className='absolute right-1 top-0 flex flex-col border border-gray border-opacity-20 bg-white p-1 rounded-md'>
                    <div
                      className=' bg-white text-gray px-3 py-2 z-20'
                      onClick={handleReportPost}
                      ref={reportRef}>
                      <div className='flex justify-center items-center'>
                        <div className=' text-xs font-semibold'>글 신고</div>
                      </div>
                    </div>
                  </div>
                )
              ))}
          </div>
        </div>
        <div className='text-xs text-left opacity-30 mt-2 ml-2'>
          {postDetailUrl}
        </div>
        <div className='my-5 text-base text-textcolor px-5'>
          <div className=' break-words'>{HTMLReactParser(post.text)}</div>
        </div>
        <div className='mb-10 px-4'>
          {poll &&
            (hasVotedToPoll || showVoteResult ? (
              <MobilePollResult
                pollData={poll}
                toggleVoteResult={toggleVoteResult}
                hasVotedToPoll={hasVotedToPoll}
              />
            ) : (
              <MobilePollDetail
                pollData={poll}
                toggleVoteResult={toggleVoteResult}
                updateHasVotedToPoll={updateHasVotedToPoll}
              />
            ))}
        </div>
        {isAdmin &&
          (post.isBlocked ? (
            <div>
              <button
                className='flex bg-green justify-center items-center mx-auto p-3 text-white font-semibold text-lg'
                onClick={handleUnblockPost}>
                글 차단 해제하기
              </button>
            </div>
          ) : (
            <div>
              <button
                className='flex bg-green justify-center items-center mx-auto p-3 text-white font-semibold text-lg'
                onClick={handleBlockPost}>
                글 차단하기
              </button>
            </div>
          ))}
        <div className='px-7 py-4 mt-8 mx-auto'>
          <div className='pb-3'>
            <VotePost
              postId={postId}
              voteUpCount={voteUpCount}
              voteDownCount={voteDownCount}
              onVoteChange={(newVoteUpCount, newVoteDownCount) => {
                setVoteUpCount(newVoteUpCount);
                setVoteDownCount(newVoteDownCount);
              }}
            />
          </div>
          <div className='flex justify-center items-center'>
            <div className='my-2 mx-3'>
              <PostBookmark postId={postId} isLogin={isLogin} />
            </div>
            <div className='my-2 mx-3'>
              <button
                type='button'
                onClick={handleCopyUrl}
                className='flex items-center border border-[#D9D9D9] px-3 py-2 text-sm font-normal text-textcolor hover:bg-[#D9D9D9]'>
                <img
                  src='/assets/icon/share.svg'
                  alt='share'
                  className=' items-center w-4'
                />
                <p className='flex pl-2'>퍼가기</p>
              </button>
            </div>
          </div>
        </div>

        <div>
          {isAdmin ? (
            <AdminMobileCommentDetail
              postId={postId}
              commentLength={post.commentCount}
              boardNumber={post.boardNumber}
              ranker={post.ranker}
            />
          ) : (
            <div>
              <MobileCommentDetail
                postId={postId}
                commentLength={post.commentCount}
                boardNumber={boardNumber}
                ranker={post.ranker}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default MobilePostDetail;
