import { axiosInstance, axiosFileInstance } from "../instance";

// 공지사항 작성
export const createAnnouncement = async (formData: FormData) => {
  const response = await axiosFileInstance.post(
    "/admin/announcement",
    formData
  );
  return response.data;
};

//공지사항 이미지 업로드
export const uploadImage = async (formData: FormData) => {
  const response = await axiosFileInstance.post(
    "/admin/announcement/uploadImage",
    formData
  );
  return response.data;
};

// 공지사항 임시 이미지 삭제
export const deleteImage = async () => {
  const response = await axiosInstance.delete(
    "/admin/announcement/deleteTempImage"
  );
  return response.data;
};

// 공지사항 영구 삭제 (그냥 삭제랑 똑같)
// 삭제되면 delete 된 db가 새로 생기는데, 공지사항은 그 부분은 딱히 불필요하지 않나 해서 patch 가 됨
export const deleteAnnouncementForever = async (announcementId: string) => {
  const response = await axiosInstance.patch(
    `/admin/announcement/deleteForever/${announcementId}`
  );
  return response.data;
};

// 공지사항 삭제 해제
export const undeleteAnnouncement = async (announcementId: string) => {
  const response = await axiosInstance.patch(
    `/admin/announcement/undelete/${announcementId}`
  );
  return response.data;
};

// 공지사항 조회
export const getAnnouncements = async (pageNumber: number) => {
  const response = await axiosInstance.get(
    `/admin/announcement/all?page=${pageNumber}`
  );
  return response.data;
};

// 공지사항 제목으로 검색
export const searchAnnouncementsByTitle = async (
  announcementTitle: string,
  pageNumber: number
) => {
  const response = await axiosInstance.get(
    `/admin/announcement/all/${pageNumber}/search?title=${announcementTitle}`
  );
  return response.data;
};

// 공지사항 내용으로 검색
export const searchAnnouncementsByContent = async (
  announcementContent: string,
  pageNumber: number
) => {
  const response = await axiosInstance.get(
    `/admin/announcement/all/${pageNumber}/search?text=${announcementContent}`
  );
  return response.data;
};

// 공지사항 제목 + 내용으로 검색
export const searchAnnouncementsByAll = async (
  announcementText: string,
  pageNumber: number
) => {
  const response = await axiosInstance.get(
    `/admin/announcement/all/${pageNumber}/search?all=${announcementText}`
  );
  return response.data;
};

// 공지사항 상세
export const getAnnouncementDetail = async (announcementId: string) => {
  const response = await axiosInstance.get(
    `/admin/announcement/${announcementId}`
  );
  return response.data;
};

//공지사항 수정
export const editAnnouncement = async (
  announcementId: string,
  formData: FormData
) => {
  const response = await axiosFileInstance.post(
    `/admin/announcement/edit/${announcementId}`,
    formData
  );
  return response.data;
};

//수정할 공지사항 조회
export const getEditAnnouncement = async (announcementId: string) => {
  const response = await axiosInstance.get(
    `/admin/announcement/edit/${announcementId}`
  );
  // console.log(response.data);
  return response.data;
};
