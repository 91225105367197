import LinkBtn from "../Common/LinkBtn";
import { useNavigate } from "react-router-dom";
type Props = { post: any };
export default function PostCard({ post }: Props) {
  const navigate = useNavigate();
  const goToVoteBoard = () => {
    navigate(`/board/voteboard/voteboardpost/${post.id}`);
  };
  return (
    <div
      className="flex justify-center items-center rounded-3xl cursor-pointer bg-[#FED363] bg-opacity-[15%] mx-4 "
      onClick={goToVoteBoard}
    >
      <div className=" flex bg-rankduk text-md leading-none text-white font-normal mr-2 px-[2px]">
        NEW
      </div>
      <p className="flex text-main text-md font-bold mr-1">
        {post.boardName}게시판
      </p>
      <p className="flex text-[#6B6B6B] text-md font-normal mr-2">
        투표 진행중
      </p>
    </div>
  );
}
