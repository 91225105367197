import React, { useEffect } from "react";
import PostTempSave from "./PostTempSave";

interface PostTempModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function PostTempSaveModal({ isOpen, onClose }: PostTempModalProps) {
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      onClick={handleOutsideClick}
    >
      <div className="bg-white rounded-lg shadow-xl p-4">
        <div className="flex justify-between">
          <h2 className="text-xl font-semibold">임시보관함</h2>
          <button className="" onClick={onClose}>
            <img
              src="/assets/icon/modal_exit.svg"
              alt="modal_exit"
              className=" w-4"
            />
          </button>
        </div>
        <div className="mt-4">
          <PostTempSave />
        </div>
      </div>
    </div>
  );
}

export default PostTempSaveModal;
