import VoteBoard from "../../components/VoteBoard/VoteBoard";

function VoteBoardPage() {
  return (
    <div className="w-full">
      <VoteBoard />
    </div>
  );
}

export default VoteBoardPage;
