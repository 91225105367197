import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { User } from "../../../constants/types";
import { getAllUsers, getUserCount } from "../../../apis/admin/adminUser";
import Pagination from "../../Common/Pagination";

function UserList() {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);
  const [userCount, setUserCount] = useState<number>(0);

  const handleUserClick = (id: string) => {
    navigate(`/admin/page/user/${id}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/admin/page/user?page=${page}`);
    window.location.reload();
  };

  useEffect(() => {
    getAllUsers(currentPage)
      .then((res) => {
        setUsers(res.users);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getUserCount()
      .then((count) => {
        setUserCount(count.userCount);
      })
      .catch((err) => {});
  }, []);

  const renderUserHeader = () => {
    return (
      <thead className='bg-gray-200'>
        <tr>
          <th className='py-3 px-4 text-center'>유저</th>
          <th className='py-3 px-4 text-center'>생성일</th>
          <th className='py-3 px-4 text-center'>글 수 </th>
          <th className='py-3 px-4 text-center'>댓글 수</th>
          <th className='py-3 px-4 text-center'>차단</th>
          <th className='py-3 px-4 text-center'>관리자</th>
        </tr>
      </thead>
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const renderCreatedAt = (user: User) => {
    return formatDate(user.createdAt);
  };

  const renderUser = (users: User[]) => {
    return (
      <tbody>
        {users.map((user) => (
          <tr
            key={user.id}
            onClick={() => handleUserClick(user.userId)}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'>
            <td className='py-3 px-4 text-center '>
              {user.userId.substring(0, 8)}
            </td>
            <td className='py-3 px-4 text-center'>{renderCreatedAt(user)}</td>
            <td className='py-3 px-4 text-center'>{user.postCount}</td>
            <td className='py-3 px-4 text-center'>{user.commentCount}</td>
            <td className='py-3 px-4 text-center'>
              {user.isBlocked ? "차단됨" : ""}
            </td>
            <td className='py-3 px-4 text-center'>
              {user.isAdmin ? "관리자" : ""}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>
          유저 수: <span className='text-red'>{userCount}</span> 명
        </h3>
      </div>
      <table className='w-full'>
        {renderUserHeader()}
        {renderUser(users)}
      </table>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default UserList;
