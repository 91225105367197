import CreateAnnouncement from "../../../components/Admin/Announcement/CreateAnnouncement";

function MobileCreateAnnouncementPage() {
  return (
    <>
      <div className="flex mx-4">
        <CreateAnnouncement />
      </div>
    </>
  );
}

export default MobileCreateAnnouncementPage;
