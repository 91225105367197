import React, { useEffect, useState } from "react";
import { deleteAccount, getSignupInfo, logout } from "../../apis/user";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../constants/types";
import {
  updateAccessToken,
  updateAdminAdminStatus,
  updateAdminStatus,
  updateBlockStatus,
  updateLoginStatus,
  updateUserId,
} from "../../store/authReducer";

interface UserInfoDto {
  loginId: string;
  email: string;
  createdAt: string;
}

const UserInfoPage = () => {
  const [userData, setUserData] = useState<UserInfoDto | null>(null); // 유저 데이터 상태 정의
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleBack = () => {
    navigate("/profile");
  };

  const handleEmailChange = () => {
    if (!userData?.loginId) {
      alert("소셜 로그인으로 가입한 회원입니다.");
    } else {
      navigate("/profile/info/email");
    }
  };

  const handlePasswordChange = () => {
    if (!userData?.loginId) {
      alert("소셜 로그인으로 가입한 회원입니다.");
    } else {
      navigate("/profile/info/password");
    }
  };

  const handleDeleteAccount = async () => {
    if (
      window.confirm("정말로 탈퇴하시겠습니까? 이 작업은 되돌릴 수 없습니다.")
    ) {
      try {
        await deleteAccount(); // 회원 탈퇴 API 호출
        alert("회원 탈퇴가 완료되었습니다.");
        navigate("/"); // 홈 화면으로 이동 또는 로그아웃 처리
        await logout();
        dispatch(updateLoginStatus(false));
        dispatch(updateAccessToken(""));
        dispatch(updateUserId(""));
        dispatch(updateAdminStatus(false));
        dispatch(updateAdminAdminStatus(false));
        dispatch(updateBlockStatus(false));
      } catch (error) {
        console.error("회원 탈퇴 오류:", error);
        alert("회원 탈퇴 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await getSignupInfo(); // 데이터 호출
        setUserData(res); // 받아온 데이터를 userData에 저장
      } catch (error) {
        console.error("Error fetching user data:", error); // 오류 처리
      } finally {
        setIsLoading(false); // 데이터 로딩이 완료되면 로딩 상태를 false로 변경
      }
    };
    fetchUserData();
  }, []);

  if (isLoading) {
    // 로딩 중일 때 표시할 내용
    return (
      <div className='bg-white rounded-lg border border-gray mt-20 shadow-lg max-w-xl mx-auto overflow-hidden'>
        <div className='p-6 text-center'>
          <p>정보를 불러오는 중입니다...</p>
        </div>
      </div>
    );
  }

  // 한국 시간대로 변환한 날짜 포맷 함수
  const formatKoreanDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 월을 2자리로 맞춤
    const day = String(date.getDate()).padStart(2, "0"); // 일을 2자리로 맞춤
    return `${year}-${month}-${day}`; // "년-월-일" 형식으로 반환
  };

  return (
    <div className='bg-white rounded-lg border border-gray mt-20 shadow-lg max-w-xl mx-auto overflow-hidden'>
      {/* Header */}
      <div className='p-6 border-b border-gray'>
        <div className='flex items-center gap-4'>
          <img
            src='/assets/icon/next.svg'
            alt='last'
            className='scale-x-[-1] w-4 hover:invert'
            onClick={handleBack}
          />

          <h1 className='text-2xl font-bold'>가입 정보</h1>
        </div>
      </div>

      {/* Content */}
      <div className='p-6 space-y-6'>
        <div className='space-y-2'>
          <p className='text-sm text-gray_text'>아이디</p>
          <p className='text-lg font-medium'>
            {userData?.loginId || "소셜 로그인 사용자"}
          </p>
        </div>

        <div className='space-y-2'>
          <p className='text-sm text-gray_text'>이메일 주소</p>
          <p className='text-lg font-medium'>
            {userData?.email || "소셜 로그인 사용자"}
          </p>
        </div>

        <div className='space-y-2'>
          <p className='text-sm text-gray_text'>가입일자</p>
          <p className='text-lg font-medium'>
            {userData?.createdAt ? formatKoreanDate(userData.createdAt) : ""}
          </p>
        </div>
      </div>

      <div className='pt-3 border-t border-gray'>
        <div className='flex justify-end gap-2 mb-3 mr-3'>
          <button
            className='px-4 py-1 text-sm bg-hotduk_light text-white rounded-lg hover:bg-hotduk transition-colors'
            onClick={handleEmailChange}>
            이메일 변경
          </button>
          <button
            className='px-4 py-1 text-sm bg-hotduk_light text-white rounded-lg hover:bg-hotduk transition-colors'
            onClick={handlePasswordChange}>
            비밀번호 변경
          </button>
          <button
            className='px-3 py-1 text-sm bg-gray text-white rounded-lg hover:bg-black transition-colors'
            onClick={handleDeleteAccount}>
            탈퇴
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoPage;
