import { useParams } from "react-router-dom";
import VoteBoardDetail from "../../components/VoteBoard/VoteBoardDetail";

function VoteBoardDetailPage() {
  const { voteBoardPostId } = useParams<{ voteBoardPostId: string }>();

  if (!voteBoardPostId) {
    return <div>postId Loading...</div>;
  }
  return (
    <>
      <div className="w-full mt-10">
        <VoteBoardDetail voteBoardPostId={voteBoardPostId} />
      </div>
    </>
  );
}

export default VoteBoardDetailPage;
