const InputField = ({ label, type, value, onChange, placeholder }: any) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className='w-full px-3 py-3 border border-gray rounded focus:outline-none focus:ring-2 focus:ring-hotduk'
    />
  </div>
);

export default InputField;
