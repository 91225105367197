import React, { useEffect, useState } from 'react';
import {
  deleteSelectedTempPosts,
  getTempPost,
  getTempPosts,
} from '../../apis/post';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import ModalPagination from '../Common/ModalPagination';

function PostTempSave() {
  const navigate = useNavigate();

  const [postTempSaveList, setPostTempSaveList] = useState<any[]>([]);
  const [selectedPostIds, setSelectedPostIds] = useState<string[]>([]);
  const [expandedPostId, setExpandedPostId] = useState<string | null>(null);
  const [expandedPostText, setExpandedPostText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const handlePostText = (postId: string) => {
    if (expandedPostId === postId) {
      setExpandedPostId(null);
      setExpandedPostText('');
    } else {
      getTempPost(postId).then((res) => {
        setExpandedPostId(postId);
        setExpandedPostText(res.text);
      });
    }
  };

  const deleteSelectedPost = () => {
    window.confirm('선택한 글을 삭제하시겠습니까?');
    deleteSelectedTempPosts(selectedPostIds).then((res) => {
      setSelectedPostIds([]);
      getTempPosts(1).then((res) => {
        setPostTempSaveList(res);
      });
    });
  };

  const handleCheckboxChange = (postId: string) => {
    const index = selectedPostIds.indexOf(postId);
    if (index > -1) {
      setSelectedPostIds(selectedPostIds.filter((id) => id !== postId));
    } else {
      setSelectedPostIds([...selectedPostIds, postId]);
    }
  };

  useEffect(() => {
    getTempPosts(currentPage).then((res) => {
      setPostTempSaveList(res.tempPosts);
      setTotalPage(res.totalPageNumber);
    });
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEditPost = (postId: string) => {
    const tempPostData = {
      isTempPost: true,
      tempPostId: postId,
    };
    navigate(`/post/edit/${postId}`, { state: { tempPostData: tempPostData } });
  };

  return (
    <>
      <div
        className=' mb-3 text-hotduk text-xs cursor-pointer w-fit px-2'
        onClick={deleteSelectedPost}
      >
        선택 삭제
      </div>
      <div className='flex flex-col'>
        <div className='flex items-center bg-[#D9D9D9] py-2 px-2 text-xs text-[#6B6B6B]'>
          <div className='w-4 flex-shrink-0'></div>
          <div className='ml-4 w-40 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
            게시판
          </div>
          <div className='ml-4 w-96 flex-shrink-0 flex-1 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
            임시 저장글
          </div>
          <div className='ml-4 w-48 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
            저장일시
          </div>
          <div className='ml-4 w-12 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
            이어서
          </div>
        </div>
        {postTempSaveList.length > 0 ? (
          postTempSaveList.map((post) => (
            <div
              key={post.id}
              className='flex items-center border-b border-opacity-50 border-[#6B6B6B] py-4 px-2'
            >
              <label className='flex items-center w-4 flex-shrink-0'>
                <input
                  type='checkbox'
                  className='form-checkbox h-4 w-4 text-hotduk'
                  checked={selectedPostIds.includes(post.id)}
                  onChange={() => handleCheckboxChange(post.id)}
                />
              </label>
              <div className='ml-4 w-40 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] text-[11px] text-[#6B6B6B] pl-2'>
                {post.boardName}
              </div>
              <div className='ml-4 w-96 flex-1 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
                <div
                  className='text-[15px] font-bold text-[#6B6B6B] cursor-pointer'
                  onClick={() => handlePostText(post.id)}
                >
                  {expandedPostId === post.id
                    ? post.title
                    : post.title.length > 20
                    ? post.title.slice(0, 20) + '...'
                    : post.title}
                </div>
                {expandedPostId === post.id && (
                  <div className='text-[11px] text-[#6B6B6B] text-opacity-70 w-full max-h-40 overflow-y-auto'>
                    {HTMLReactParser(expandedPostText)}
                  </div>
                )}
              </div>
              <div className='ml-4 w-48 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] pl-2'>
                <div className='text-[10px] text-[#6B6B6B]'>
                  {new Date(post.updatedAt)
                    .toLocaleString('ko-KR', {
                      timeZone: 'Asia/Seoul',
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })
                    .replace(/. /g, '.')
                    .replace(/. /g, ' ')}
                </div>
              </div>
              <button
                className='ml-4 w-12 flex-shrink-0 border-l border-opacity-50 border-[#6B6B6B] pl-4 justify-center items-center'
                onClick={() => handleEditPost(post.id)}
              >
                <img
                  src='/assets/icon/temp_edit.svg'
                  alt='edit'
                  className='h-[18px]'
                />
              </button>
            </div>
          ))
        ) : (
          <div className='flex items-center justify-center py-8'>
            <div className='text-[#6B6B6B]'>저장된 글이 없습니다.</div>
          </div>
        )}
      </div>
      <ModalPagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </>
  );
}

export default PostTempSave;
