import { axiosInstance, axiosFileInstance } from "../instance";

//댓글 차단
export const blockComment = async (commentId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/comment/blockForever/${commentId}`
  );
  return response.data;
};

//댓글 차단 해제
export const unblockComment = async (commentId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/comment/unblock/${commentId}`
  );
  return response.data;
};

//대댓글 차단
export const blockSubComment = async (subCommentId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/subComment/blockForever/${subCommentId}`
  );
  return response.data;
};

//대댓글 차단 해제
export const unblockSubComment = async (subCommentId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/subComment/unblock/${subCommentId}`
  );
  return response.data;
};

//조치된 댓글 조회(삭제, 차단)
export const getAllComments = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/comment/all?page=${pageNumber}`
  );
  return response.data;
};

//조치된 대댓글 조회(삭제, 차단)
export const getAllSubComments = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/subComment/all?page=${pageNumber}`
  );
  // console.log(response.data);
  return response.data;
};

/*
// 차단된 댓글 조회
export const getAdminBlockedComments = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/comment/blocked/${pageNumber}`
  );
  return response.data;
};

// 차단된 댓글 내용으로 검색
export const searchAdminBlockedCommentsByText = async (
  text: string,
  pageNumber: number
) => {
  const response = await axiosInstance.get<any>(
    `/admin/comment/blocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 차단된 대댓글 조회
export const getAdminBlockedSubComments = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/subComment/blocked/${pageNumber}`
  );
  return response.data;
};

// 차단된 대댓글 내용으로 검색
export const searchAdminBlockedSubCommentsByText = async (
  text: string,
  pageNumber: number
) => {
  const response = await axiosInstance.get<any>(
    `/admin/subComment/blocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 차단된 댓글 상세
export const getAdminBlockedCommentDetail = async (commentId: string) => {
  const response = await axiosInstance.get<any>(`/admin/comment/${commentId}`);
  return response.data;
};
*/
