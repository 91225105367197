import React from "react";
import { Outlet } from "react-router-dom";
import Menu from "../Admin/Common/Menu";
import BoardNavBar from "../NavBar/BoardNavBar";

function AdminLayout() {
  return (
    <div className='flex flex-col w-full min-h-screen'>
      <Menu />
      <div className=''>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminLayout;
