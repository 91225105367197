import { SERVER_URL } from '../constants/url';
import axios from 'axios';
import { useAppSelector, useAppDispatch } from '../constants/types';
import { useNavigate } from 'react-router-dom';
import { updateAccessToken, updateLoginStatus } from '../store/authReducer';

export const axiosInstance = axios.create({
  baseURL: `${SERVER_URL}/api`,
  // baseURL: 'http://localhost:8080/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const axiosFileInstance = axios.create({
  baseURL: `${SERVER_URL}/api`,
  // baseURL: 'http://localhost:8080/api',
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});
