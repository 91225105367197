import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserPost } from "../../../constants/types";
import { getUserPostListAsAdmin } from "../../../apis/admin/adminUser";
import AdminRenderUserHistory from "./AdminRenderUserHistory";
import Pagination from "../../Common/Pagination";

interface AdminProfileUserBookmarkProps {
  userId: string;
}

function AdminProfileUserPost({ userId }: AdminProfileUserBookmarkProps) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [posts, setPosts] = useState<UserPost[]>([]);

  useEffect(() => {
    getUserPostListAsAdmin(currentPage, userId).then((res) => {
      setPosts(res.postList);
      setTotalPage(res.totalPageNumber);
    });
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <AdminRenderUserHistory posts={posts} />
        <div className="mt-5">
          {totalPage > 0 ? (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminProfileUserPost;
