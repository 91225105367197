import MobileHotPost from "../../components/Posts/MobileHotPost";

function MobileHotDukPage() {
  return (
    <div>
      <MobileHotPost />
    </div>
  );
}

export default MobileHotDukPage;
