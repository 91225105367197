import { useParams } from "react-router-dom";
import AnnouncementDetail from "../../components/Announcements/AnnouncementDetail";

function IntegratedAnnouncementDetailPage() {
  const { postId } = useParams<{ postId: string }>();

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  return (
    <>
      <div className=" w-full mt-10">
        <AnnouncementDetail postId={postId} />
      </div>
    </>
  );
}

export default IntegratedAnnouncementDetailPage;
