import { axiosInstance, axiosFileInstance } from "../instance";

//유저 목록 조회
export const getAllUsers = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/user/all?page=${pageNumber}`
  );
  return response.data;
};

//유저 수 조회
export const getUserCount = async () => {
  const response = await axiosInstance.get<any>(`/admin/user/count`);
  return response.data;
};

//유저 프로필 조회
export const getUserProfileAsAdmin = async (userId: string) => {
  const response = await axiosInstance.get<any>(`/admin/user/${userId}`);
  return response.data;
};

//유저가 작성한 게시글 조회
export const getUserPostListAsAdmin = async (
  pageNumber: number,
  userId: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/user/${userId}/post?page=${pageNumber}`
  );
  return response.data;
};

//유저가 작성한 댓글 조회
export const getUserCommentListAsAdmin = async (
  pageNumber: number,
  userId: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/user/${userId}/comment?page=${pageNumber}`
  );
  return response.data;
};

//유저가 작성한 북마크 조회
export const getUserBookmarkListAsAdmin = async (
  pageNumber: number,
  userId: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/user/${userId}/bookmark?page=${pageNumber}`
  );
  return response.data;
};

// 랭커 임명
export const addRanker = async (userId: string, rankerBoardNumber: number) => {
  const res = await axiosInstance.patch<any>(
    `/admin/user/addRanker/${userId}/${rankerBoardNumber}`
  );
  return res.data;
};

// 랭커 임명 해제
export const removeRanker = async (
  userId: string,
  rankerBoardNumber: number
) => {
  const res = await axiosInstance.patch<any>(
    `/admin/user/removeRanker/${userId}/${rankerBoardNumber}`
  );
  return res.data;
};

// 관리자 임명
export const addAdmin = async (userId: string) => {
  const res = await axiosInstance.patch<any>(`/admin/user/addAdmin/${userId}`);
  return res.data;
};

// 관리자 임명 해제
export const removeAdmin = async (userId: string) => {
  const res = await axiosInstance.patch<any>(
    `/admin/user/removeAdmin/${userId}`
  );
  return res.data;
};

// 전체 유저 조회
export const getAdminUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(`/admin/user/all/${pageNumber}`);
  return res.data;
};

// 랭커 유저 조회
export const getAdminRankerUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(`/admin/user/ranker/${pageNumber}`);
  return res.data;
};

// 일반 유저 조회
export const getAdminNormalUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(`/admin/user/normal/${pageNumber}`);
  return res.data;
};

//전체_차단된 유저 조회
export const getAdminBlockedUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/admin/user/all/blocked/${pageNumber}`
  );
  return res.data;
};

//랭커_차단된 유저 조회
export const getAdminRankerBlockedUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/admin/user/ranker/blocked/${pageNumber}`
  );
  return res.data;
};

//일반_차단된 유저 조회
export const getAdminNormalBlockedUsers = async (pageNumber: number) => {
  const res = await axiosInstance.get<any>(
    `/admin/user/normal/blocked/${pageNumber}`
  );
  return res.data;
};

// 유저 상세 조회
export const getAdminUserDetail = async (userId: string) => {
  const res = await axiosInstance.get<any>(`/admin/user/${userId}`);
  return res.data;
};

// 유저 기간 차단 - (왜 스트링인지, 데이터처럼 실어서 보내는거 불가한지?)
export const blockUser = async (userId: string, blockDays: string) => {
  const res = await axiosInstance.patch<any>(
    `/admin/user/block/${userId}?period=${blockDays}`
  );
  return res.data;
};

// 유저 영구 차단
export const blockUserForever = async (userId: string) => {
  const res = await axiosInstance.patch<any>(
    `/admin/user/blockForever/${userId}`
  );
  return res.data;
};

// 유저 차단 해제(수동)
export const unblockUser = async (userId: string) => {
  const res = await axiosInstance.patch<any>(`/admin/user/unblock/${userId}`);
  return res.data;
};
