import ProgressBar from "../../components/NativeLogin/ProgressBar";
import TopLogoBar from "../../components/NativeLogin/TopLogoBar";
import PhoneVerify from "../../components/NativeLogin/PhoneVerify";

function ResetVerifyBtnPage() {
  return (
    <>
      <TopLogoBar />
      <div className='pt-20 max-w-3xl mx-auto'>
        <h1 className='text-2xl font-bold text-center mt-10'>
          아이디/비밀번호 찾기
        </h1>
        <PhoneVerify type='reset' />
      </div>
    </>
  );
}

export default ResetVerifyBtnPage;
