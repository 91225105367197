import React, { useEffect, useState } from 'react';
import {
  deleteSelectedTempPosts,
  getTempPost,
  getTempPosts,
} from '../../apis/post';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import ModalPagination from '../Common/ModalPagination';

function MobilePostTempSave() {
  const navigate = useNavigate();

  const [postTempSaveList, setPostTempSaveList] = useState<any[]>([]);
  const [selectedPostIds, setSelectedPostIds] = useState<string[]>([]);
  const [expandedPostId, setExpandedPostId] = useState<string | null>(null);
  const [expandedPostText, setExpandedPostText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const handlePostText = (postId: string) => {
    if (expandedPostId === postId) {
      setExpandedPostId(null);
      setExpandedPostText('');
    } else {
      getTempPost(postId).then((res) => {
        setExpandedPostId(postId);
        setExpandedPostText(res.text);
      });
    }
  };

  const deleteSelectedPost = () => {
    window.confirm('선택한 글을 삭제하시겠습니까?');
    deleteSelectedTempPosts(selectedPostIds).then((res) => {
      setSelectedPostIds([]);
      getTempPosts(1).then((res) => {
        setPostTempSaveList(res);
      });
    });
  };

  const handleAllCheckboxSelect = () => {
    if (selectedPostIds.length === postTempSaveList.length) {
      setSelectedPostIds([]);
    } else {
      setSelectedPostIds(postTempSaveList.map((post) => post.id));
    }
  };

  const handleCheckboxChange = (postId: string) => {
    const index = selectedPostIds.indexOf(postId);
    if (index > -1) {
      setSelectedPostIds(selectedPostIds.filter((id) => id !== postId));
    } else {
      setSelectedPostIds([...selectedPostIds, postId]);
    }
  };

  useEffect(() => {
    getTempPosts(currentPage).then((res) => {
      setPostTempSaveList(res.tempPosts);
      setTotalPage(res.totalPageNumber);
    });
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleEditPost = (postId: string) => {
    const tempPostData = {
      isTempPost: true,
      tempPostId: postId,
    };
    navigate(`/post/edit/${postId}`, { state: { tempPostData: tempPostData } });
  };

  return (
    <>
      <div className='flex justify-between'>
        <div
          className='text-[#6B6B6B] text-xs w-fit px-2'
          onClick={handleAllCheckboxSelect}
        >
          전체선택
        </div>
        <div
          className=' mb-3 text-hotduk text-xs cursor-pointer w-fit px-2'
          onClick={deleteSelectedPost}
        >
          선택삭제
        </div>
      </div>
      <div className='flex flex-col w-72'>
        {postTempSaveList.length > 0 ? (
          postTempSaveList.map((post) => (
            <div
              key={post.id}
              className='flex items-center border-b border-opacity-50 border-[#6B6B6B] py-2 px-2'
            >
              <div className='flex flex-col'>
                <div className='flex'>
                  <label className='flex items-center w-4 flex-shrink-0'>
                    <input
                      type='checkbox'
                      className='form-checkbox h-4 w-4 text-hotduk'
                      checked={selectedPostIds.includes(post.id)}
                      onChange={() => handleCheckboxChange(post.id)}
                    />
                  </label>
                  <div className='ml-4 w-max-[90px] flex-shrink-0 text-[10px] text-[#6B6B6B]'>
                    {post.boardName}
                  </div>
                  <div className='ml-2 w-20 flex-shrink-0'>
                    <div className='text-[9px] text-[#6B6B6B]'>
                      {new Date(post.updatedAt)
                        .toLocaleString('ko-KR', {
                          timeZone: 'Asia/Seoul',
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: false,
                        })
                        .replace(/. /g, '.')
                        .replace(/. /g, ' ')}
                    </div>
                  </div>
                </div>
                <div className='flex justify-between'>
                  <div className='ml-6 w-48 pl-2 pt-1'>
                    <div
                      className='text-xs font-semibold text-[#000000] w-fit'
                      onClick={() => handleEditPost(post.id)}
                    >
                      {expandedPostId === post.id
                        ? post.title
                        : post.title.length > 14
                        ? post.title.slice(0, 14) + '...'
                        : post.title}
                    </div>
                    {expandedPostId === post.id && (
                      <div className='text-[11px] text-[#6B6B6B] text-opacity-70 w-full max-h-20 overflow-y-auto'>
                        {HTMLReactParser(expandedPostText)}
                      </div>
                    )}
                  </div>
                  <div className='flex'>
                    <button
                      className='ml-4 pl-2 w-8 flex-shrink-0 justify-center items-center'
                      onClick={() => handlePostText(post.id)}
                    >
                      <img
                        src='/assets/icon/temp_down.svg'
                        alt='temp_down'
                        className='w-3'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='flex items-center justify-center py-8'>
            <div className='text-[#6B6B6B]'>저장된 글이 없습니다.</div>
          </div>
        )}
      </div>
      <ModalPagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </>
  );
}

export default MobilePostTempSave;
