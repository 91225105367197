import React, { useRef, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createPost,
  createTempPost,
  deleteImage,
  deleteSelectedTempPosts,
  editTempPost,
  uploadImage,
} from '../../apis/post';
import { PollData, useAppSelector } from '../../constants/types';
import { getBoardName } from '../../apis/board';
import Btn from '../Common/Btn';
import { SERVER_URL } from '../../constants/url';
import { MediaEmbed } from '@ckeditor/ckeditor5-media-embed';
import './CkeditorStyle.css';
import { Boards } from '../../constants/types';
import MobilePostTempSaveModal from '../Modal/MobilePostTempSaveModal';
import MobileCreatePollModal from '../Modal/MobileCreatePollModal';

function MobileCreatePost() {
  //todo 게시판 선택을 string 으로 하는게 맞나,,,?
  const isRanker = useAppSelector((state) => state.auth.isRanker);

  const navigate = useNavigate();
  const { boardNum } = useParams();
  const [boards, setBoards] = useState<Boards>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [saveTime, setSaveTime] = useState<string>('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [pollModalOpen, setPollModalOpen] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [ranker, setRanker] = useState<boolean>(false);
  const boardNumToNum = boardNum ? parseInt(boardNum, 10) : null;
  const userId = useAppSelector((state) => state.auth.userId);
  const [isCreating, setIsCreating] = useState(false);
  const [tempPostId, setTempPostId] = useState<string>('');
  const isBlocked = useAppSelector((state) => state.auth.isBlocked);
  const [pollData, setPollData] = useState<PollData | null>(null);

  const handleRankerToggle = () => {
    setRanker(!ranker); // 토글된 상태로 업데이트
  };

  useEffect(() => {
    if (boardNumToNum === null) {
      return;
    } else {
      getBoardName(boardNumToNum)
        .then((res) => {
          setBoards(res);
        })
        .catch((err) => {});
    }
  }, []);

  const autoSaveInterval = 2 * 60 * 1000; // 2분

  useEffect(() => {
    if (title && content) {
      const autoSaveTimer = setInterval(() => {
        handleSavePost();
      }, autoSaveInterval);

      return () => {
        clearInterval(autoSaveTimer);
      };
    }
  }, [title, content, ranker, tempPostId]);

  const handleSaveTime = () => {
    const date = new Date();
    const formattedDate = `${date.getFullYear()}. ${String(
      date.getMonth() + 1
    ).padStart(2, '0')}. ${String(date.getDate()).padStart(2, '0')} ${String(
      date.getHours()
    ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    setSaveTime(formattedDate);
  };

  const showSaveMessage = () => {
    setShowMessage(true);
    // setTimeout(() => {
    //   setShowMessage(false);
    // }, 3000);
  };

  const openTempPostModal = () => {
    setModalOpen(true);
  };

  const openPollModal = () => {
    setPollModalOpen(true);
  };

  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file: any) => {
            formData.append('image', file);
            uploadImage(formData)
              .then((res) => {
                resolve({
                  default: res,
                });
              })
              .catch((err) => {});
          });
        });
      },
    };
  };
  function uploadPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (
      loader: any
    ) => {
      return customUploadAdapter(loader);
    };
  }

  const handleSubmitPost = (): void => {
    if (!title || !content) {
      alert('제목과 내용을 입력하세요.');
      return;
    }

    if (title.trim().length > 50) {
      alert('제목은 50자 이하로 입력해주세요.');
      return;
    }

    if (isBlocked) {
      alert('차단된 사용자입니다.');
      return;
    }

    setIsCreating(true);

    const formData = new FormData();

    const postContent = JSON.stringify({
      boardNumber: boardNum,
      title: title.trim(),
      text: content,
      ranker: ranker,
      pollData: pollData,
    });
    formData.append('content', postContent);

    createPost(formData)
      .then((res) => {
        deleteImage();
        navigate(`/post/${boardNum}/${res._id}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {})
      .finally(() => {
        setIsCreating(false);
      });
  };

  const handleSavePost = (): void => {
    if (!title || !content) {
      alert('제목과 내용을 입력하세요.');
      return;
    }

    if (title.trim().length > 50) {
      alert('제목은 50자 이하로 입력해주세요.');
      return;
    }

    setShowMessage(false);

    setIsCreating(true);

    const formData = new FormData();

    const postContent = JSON.stringify({
      boardNumber: boardNum,
      title: title.trim(),
      text: content,
      ranker: ranker,
    });
    formData.append('content', postContent);

    if (tempPostId) {
      editTempPost(tempPostId, formData)
        .then((res) => {
          deleteImage();
          handleSaveTime();
          showSaveMessage();
        })
        .catch((err) => {})
        .finally(() => {
          setIsCreating(false);
        });
    } else {
      createTempPost(formData)
        .then((res) => {
          deleteImage();
          handleSaveTime();
          showSaveMessage();
          setTempPostId(res._id);
        })
        .catch((err) => {})
        .finally(() => {
          setIsCreating(false);
        });
    }
  };

  const handleSubmitTempPost = (): void => {
    if (!title || !content) {
      alert('제목과 내용을 입력하세요.');
      return;
    }

    if (title.trim().length > 50) {
      alert('제목은 50자 이하로 입력해주세요.');
      return;
    }

    setIsCreating(true);

    const formData = new FormData();

    const postContent = JSON.stringify({
      boardNumber: boardNum,
      title: title.trim(),
      text: content,
      ranker: ranker,
    });
    formData.append('content', postContent);

    createPost(formData)
      .then((res) => {
        deleteImage();
        navigate(`/post/${boardNum}/${res._id}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {})
      .finally(() => {
        deleteSelectedTempPosts([tempPostId]).then((res) => {});
        setIsCreating(false);
      });
  };

  const validateAndSetTitle = (value: string) => {
    const sanitizedValue = value.replace(/\n/g, '');
    setTitle(sanitizedValue);
  };

  const goToBoard = () => {
    deleteImage();
    navigate(`/board/${boardNum}`);
    window.scrollTo(0, 0);
  };

  const handleDeletePoll = () => {
    const confirmDelete = window.confirm('투표를 삭제하시겠습니까?');
    if (confirmDelete) {
      setPollData(null);
    }
  };

  const renderPollSection = () => {
    if (pollData) {
      return (
        <div className='flex flex-col bg-gray-100 px-4 rounded-lg'>
          <div className='text-[10px] text-hotduk text-opacity-80'>
            투표는 임시 저장과 글 작성 후 수정이 불가합니다.
          </div>
          <div className='flex mt-[2px] items-center'>
            <img
              src='/assets/icon/poll_icon.svg'
              alt='poll_icon'
              className='w-4 h-4 mr-2'
            />
            <div className='font-semibold text-[#6B6B6B]'>
              {pollData.subject}
            </div>
            <button
              className='text-black text-xs underline ml-3'
              onClick={openPollModal}
            >
              투표 수정
            </button>
            <button onClick={handleDeletePoll}>
              <img
                src='/assets/icon/close.svg'
                alt='poll_delete'
                className='h-2 ml-2'
              />
            </button>
          </div>
          <div className='text-[10px] text-[#6B6B6B] pl-6 mb-2'>
            {pollData.isMultiSelectEnabled
              ? '복수 선택 가능'
              : '한 개만 선택 가능'}
          </div>
          <ul className='pl-6'>
            {pollData.options.map((option, index) => (
              <div
                key={index}
                className='mb-1 flex items-center text-[#6B6B6B]'
              >
                <span className='mr-2 border p-1 rounded-full'></span> {option}
              </div>
            ))}
          </ul>
        </div>
      );
    } else {
      return (
        <div className='flex-col items-center'>
          <div className='text-[10px] text-hotduk text-opacity-80'>
            투표는 임시 저장과 글 작성 후 수정이 불가합니다.
          </div>
          <div className='flex items-center'>
            <img
              src='/assets/icon/poll_icon.svg'
              alt='poll_icon'
              className='w-4 h-4 mr-2'
            />
            <button
              className='text-black text-sm hover:underline'
              onClick={openPollModal}
            >
              투표 작성하기
            </button>
            <img
              src='/assets/icon/poll_create.svg'
              alt='poll_create'
              className='h-3 ml-2'
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className='w-full flex flex-col'>
        <div className='flex text-2xl font-bold mt-8 mb-5'>
          <div className='flex text-textcolor'>
            "{boards ? boards.boardName : ''}"
          </div>{' '}
          <div className='flex text-textcolor pl-2'> 게시판 글쓰기</div>
        </div>
        <div className='bg-[#FAFAFA] p-6'>
          <div className='flex justify-between'>
            <div>
              {isRanker[boardNum as keyof typeof isRanker] ? (
                <label className='relative inline-flex items-center cursor-pointer'>
                  <input
                    type='checkbox'
                    value=''
                    className='sr-only peer'
                    checked={ranker}
                    onChange={handleRankerToggle}
                  />
                  <span className=' me-3 text-sm font-semibold text-textcolor'>
                    랭커 글쓰기
                  </span>
                  <div
                    className={`${
                      ranker ? 'bg-[#FF7900]' : 'bg-[#757171]'
                    } w-12 h-6 rounded-full transition-all duration-300 ease-in-out`}
                  >
                    <div
                      className={`${
                        ranker
                          ? 'transform translate-x-full'
                          : 'transform translate-x-0'
                      } w-6 h-6 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out`}
                    />
                  </div>
                </label>
              ) : (
                <label className=' opacity-50 relative inline-flex items-center'>
                  <input
                    type='checkbox'
                    value=''
                    className='sr-only peer'
                    disabled
                  />
                  <span className=' me-3 text-sm font-semibold text-textcolor'>
                    랭커 글쓰기
                  </span>
                  <div className='w-12 h-6 bg-[#757171] rounded-full cursor-not-allowed'>
                    <div className='w-6 h-6 bg-white rounded-full shadow-md' />
                  </div>
                </label>
              )}
            </div>
            <div className='flex'>
              {' '}
              <button
                type='button'
                onClick={openTempPostModal}
                className='bg-[#707070] bg-opacity-50 text-white font-semibold text-xs rounded-[100px] px-3 py-1 justify-center items-center hover:bg-opacity-75 mr-2 focus:outline-none'
              >
                보관함
              </button>
              <button
                type='button'
                onClick={handleSavePost}
                className='bg-[#707070] text-white font-semibold text-xs rounded-[100px] px-3 py-1 justify-center items-center hover:bg-opacity-75 focus:outline-none'
              >
                임시저장
              </button>
            </div>
          </div>
          {showMessage && (
            <div className='flex justify-end text-xs text-[#707070] my-2 mx-1 opacity-0 animate-fade-in'>
              {saveTime}&nbsp; &nbsp;임시 저장됨
            </div>
          )}
          <div className='w-full text-[#6B6B6B] mt-3 flex items-center'>
            <div className='flex items-center w-full'>
              <textarea
                className='border border-[#EBF0F6] my-1 p-2 w-full h-10 rounded-md resize-none focus:outline-none font-normal text-sm'
                placeholder='제목을 입력해주세요. (50자 이내)'
                value={title}
                onChange={(e) => validateAndSetTitle(e.target.value)}
              ></textarea>
              <div className='flex items-center pl-2 whitespace-nowrap'>
                {title.trim().length}/50
              </div>
            </div>
          </div>

          <div className=' my-2'>
            <CKEditor
              editor={ClassicEditor as any}
              config={{
                // plugins: [ImageToolbar],
                extraPlugins: [uploadPlugin],
                placeholder: '글을 작성해주세요.',
                toolbar: {
                  items: [
                    'heading',
                    '|',
                    'imageUpload',
                    'mediaEmbed',
                    'link',
                    '|',
                    'bold',
                    'italic',
                    'fontColor',
                    'fontBackgroundColor',
                    'alignment',
                    '|',
                    'undo',
                    'redo',
                  ],
                },
                language: 'ko',
                // image: {
                //   toolbar: [
                //     "imageTextAlternative",
                //     "imageStyle:full",
                //     "imageStyle:side",
                //   ],
                // },
                mediaEmbed: {
                  previewsInData: true,
                  extraProviders: [
                    {
                      name: 'x',
                      url: /^https?:\/\/(?:www\.)?(?:twitter\.com|x\.com)\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\S+)?$/,
                      html: (match) => {
                        const cleanUrl = `https://twitter.com/${match[1]}/status/${match[2]}`;
                        return `
                        <div style="position: relative; padding-bottom: 75%; height: 0;">
                          <iframe
                            src="https://twitframe.com/show?url=${encodeURIComponent(
                              cleanUrl
                            )}"
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0;"
                            allowfullscreen
                            scrolling="auto"
                            frameborder="0"
                          ></iframe>
                        </div>
                      `;
                      },
                    },
                    {
                      name: 'youtubeShorts',
                      url: /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/shorts\/(\w+)(?:\S+)?$/,
                      html: (match) => `
                        <div style="position: relative; width: 100%; max-width: 350px; aspect-ratio: 9/16;">
                          <iframe 
                            width="100%" 
                            height="100%" 
                            src="https://www.youtube.com/embed/${match[1]}?feature=share" 
                            title="YouTube video player" 
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                            allowfullscreen
                            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                          ></iframe>
                        </div>
                      `,
                    },
                  ],
                },
              }}
              onReady={(editor) => {}}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
          </div>
          <div className='flex mt-4'>{renderPollSection()}</div>

          <div className='flex items-center justify-center my-5'>
            <div className='px-2'>
              <button
                type='button'
                onClick={goToBoard}
                className='bg-[#707070] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28'
              >
                취소
              </button>
            </div>
            <div className='px-2'>
              <button
                id='createPostButton'
                type='button'
                onClick={tempPostId ? handleSubmitTempPost : handleSubmitPost}
                className={`bg-[#222222] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28 ${
                  isCreating ? 'cursor-not-allowed opacity-50' : ''
                }`}
                disabled={isCreating}
              >
                {isCreating ? '작성중...' : '글쓰기'}
              </button>
            </div>
          </div>
        </div>
      </div>
      {modalOpen && (
        <MobilePostTempSaveModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
      {pollModalOpen && (
        <MobileCreatePollModal
          isOpen={pollModalOpen}
          onClose={() => setPollModalOpen(false)}
          // onPollDataChange={(newPollData) => setPollData(newPollData)}
          onPollDataChange={setPollData}
          initialPollData={pollData}
        />
      )}
    </>
  );
}

export default MobileCreatePost;
