import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserComments } from "../../apis/user";
import RenderUserHistory from "./RenderUserHistory";
import Pagination from "../Common/Pagination";
import { UserComment } from "../../constants/types";
import MobileRenderUserHistory from "./MobileRenderUserHistory";

function MobileProfileUserComment() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [comments, setComments] = useState<UserComment[]>([]);

  useEffect(() => {
    getUserComments(currentPage)
      .then((res) => {
        setComments(res.commentList);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <MobileRenderUserHistory comments={comments} />

        <div className="mt-5">
          {totalPage > 0 ? (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default MobileProfileUserComment;
