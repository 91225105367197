import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useNavigate } from "react-router-dom";
import {
  createAnnouncement,
  uploadImage,
  deleteImage,
} from "../../../apis/admin/announcement";
import { useAppSelector } from "../../../constants/types";
import { SERVER_URL } from "../../../constants/url";
import { getBoardNames } from "../../../apis/admin/adminBoard";

function CreateAnnouncement() {
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.auth.userId);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [annType, setAnnType] = useState<string>(""); // "integrate" "popular","ranker","voteBoard","board",
  const [boardNumber, setBoardNumber] = useState<string>("");
  const [isCreating, setIsCreating] = useState(false);
  const [boardList, setBoardList] = useState<any[]>([]); // [{boardNumber: number, boardName: string}
  const imgLink = `${SERVER_URL}/temp`;

  const customUploadAdapter = (loader: any) => {
    return {
      upload() {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          loader.file.then((file: any) => {
            formData.append("image", file);
            uploadImage(formData)
              .then((res) => {
                resolve({
                  default: res,
                });
              })
              .catch((err) => {});
          });
        });
      },
    };
  };

  useEffect(() => {
    const fetchBoardNames = async () => {
      try {
        const result = await getBoardNames();
        const boards = result.undeletedBoardNames.map((board: any) => ({
          boardNumber: board.boardNumber,
          boardName: board.boardName,
        }));

        const sortedBoards = result.order
          .map((boardNumber: number) => {
            return boards.find(
              (board: any) => board.boardNumber === boardNumber
            );
          })
          .filter((board: any) => board !== undefined);

        setBoardList(sortedBoards);
      } catch (error) {
        console.error("Failed to fetch board names:", error);
      }
    };

    fetchBoardNames();
  }, []);

  function uploadPlugin(editor: any) {
    editor.plugins.get("FileRepository").createUploadAdapter = (
      loader: any
    ) => {
      return customUploadAdapter(loader);
    };
  }

  const handleSubmitPost = () => {
    if (!title || !content) {
      alert("제목과 내용을 입력하세요");
      return;
    }
    if (title.trim().length > 50) {
      alert("제목은 50자 이하로 입력해주세요.");
      return;
    }
    setIsCreating(true);
    const formData = new FormData();

    if (annType === "") {
      alert("공지사항 유형을 선택하세요.");
      setIsCreating(false);
      return;
    }

    const postContent = JSON.stringify({
      title: title.trim(),
      text: content,
      annType: annType,
      boardNumber: boardNumber,
    });
    formData.append("content", postContent);

    createAnnouncement(formData)
      .then((res) => {
        deleteImage();
        alert("공지사항이 작성되었습니다.");
        if (boardNumber) {
          navigate(`/announcement/${boardNumber}/${res._id}`);
        } else {
          navigate(`/announcement/${res._id}`);
        }
      })
      .catch(console.error)
      .finally(() => setIsCreating(false));
  };

  const validateAndSetTitle = (value: string) => {
    const sanitizedValue = value.replace(/\n/g, "");
    setTitle(sanitizedValue);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="flex text-2xl font-bold mt-8 mb-5">
        <div className="flex text-textcolor pl-2">공지사항 작성</div>
      </div>
      <div className="bg-[#FAFAFA] p-6">
        <select
          className="bg-[#EBF0F6] text-[#222] font-semibold h-10 px-4 rounded-md w-full mb-4"
          onChange={(e) => {
            const type = e.target.value;
            setAnnType(type);
            if (type !== "board") {
              setBoardNumber("");
            }
          }}
          value={annType}>
          <option value="">공지사항 유형을 선택하세요</option>
          <option value="integrate">통합 공지사항</option>
          <option value="popular">핫게시판 공지사항</option>
          <option value="ranker">랭커게시판 공지사항</option>
          <option value="voteBoard">게시판투표 공지사항</option>
          <option value="board">게시판별 공지사항</option>
        </select>
        {annType === "board" && (
          <select
            className="bg-[#EBF0F6] text-[#222] font-semibold h-10 px-4 rounded-md w-full mb-4"
            value={boardNumber}
            onChange={(e) => {
              const selectedBoardNumber = e.target.value;
              setBoardNumber(selectedBoardNumber);
            }}>
            <option value="">게시판을 선택하세요</option>
            {boardList.map((board) => (
              <option key={board.boardNumber} value={board.boardNumber}>
                {`(${board.boardNumber}) ${board.boardName}`}
              </option>
            ))}
          </select>
        )}

        <textarea
          className="border border-[#EBF0F6] my-1 p-2 w-full h-10 rounded-md resize-none focus:outline-none font-normal text-sm"
          placeholder="제목을 입력해주세요. (50자 이내)"
          value={title}
          onChange={(e) => validateAndSetTitle(e.target.value)}></textarea>
        <div className="w-full">
          <CKEditor
            editor={ClassicEditor as any}
            config={{
              extraPlugins: [uploadPlugin],
              placeholder: "글을 작성해주세요.",
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "imageUpload",
                  "mediaEmbed",
                  "link",
                  "|",
                  "bold",
                  "italic",
                  "fontColor",
                  "fontBackgroundColor",
                  "alignment",
                  "|",
                  "undo",
                  "redo",
                ],
              },
              language: "ko",
              // image: {
              //   toolbar: [
              //     "imageTextAlternative",
              //     "imageStyle:full",
              //     "imageStyle:side",
              //   ],
              // },
              mediaEmbed: {
                previewsInData: true,
              },
            }}
            onReady={(editor) => {}}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
            onBlur={(event, editor) => {}}
            onFocus={(event, editor) => {}}
          />
        </div>
        <div className="flex items-center justify-center my-5">
          <button
            id="createPostButton"
            type="button"
            onClick={handleSubmitPost}
            disabled={isCreating}
            className={`bg-[#222222] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28 ${
              isCreating ? "cursor-not-allowed opacity-50" : ""
            }`}>
            {isCreating ? "Posting..." : "작성하기"}
          </button>
          <button
            type="button"
            className="bg-[#707070] text-white font-semibold text-[15px] rounded-[100px] h-10 w-28"
            onClick={() => navigate(-1)}>
            취소
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateAnnouncement;
