import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserPost } from "../../../constants/types";
import { getUserBookmarkListAsAdmin } from "../../../apis/admin/adminUser";
import RenderUserHistory from "../../Profile/RenderUserHistory";
import AdminRenderUserHistory from "./AdminRenderUserHistory";
import Pagination from "../../Common/Pagination";

interface AdminProfileUserBookmarkProps {
  userId: string;
}

function AdminProfileUserBookmark({ userId }: AdminProfileUserBookmarkProps) {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [posts, setPosts] = useState<UserPost[]>([]);

  useEffect(() => {
    getUserBookmarkListAsAdmin(currentPage, userId)
      .then((res) => {
        setPosts(res.bookmarkList);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <AdminRenderUserHistory bookmarks={posts} />
        <div className="mt-5">
          {totalPage > 0 ? (
            <Pagination
              currentPage={currentPage}
              totalPage={totalPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default AdminProfileUserBookmark;
