import React from "react";
import AdminPostList from "../../../components/Admin/Post/PostList";

function AdminPostPage() {
  return (
    <>
      <AdminPostList />
    </>
  );
}

export default AdminPostPage;
