import { useEffect, useState, useCallback } from "react";
import { getUserInfo } from "../../../apis/user";
import UserInfo from "../../../components/Profile/UserInfo";
import { User, useAppDispatch } from "../../../constants/types";
import UserActivity from "../../../components/Profile/UserActivity";
import UserHistory from "../../../components/Profile/UserHistory";
import { updateRankerStatus } from "../../../store/authReducer";
import { useParams } from "react-router-dom";
import { getUserProfileAsAdmin } from "../../../apis/admin/adminUser";
import AdminUserHistory from "../../../components/Admin/User/AdminUserHistory";
import AdminUserInfo from "../../../components/Admin/User/AdminUserInfo";
import AdminUserActivity from "../../../components/Admin/User/AdminUserActivity";

function AdminProfilepage() {
  const { id }: any = useParams();
  const [userInfo, setUserInfo] = useState<User>();
  const dispatch = useAppDispatch();
  const updateUserStatus = useCallback(
    (res: any) => {
      const isRanker = res.user.isRanker;
      dispatch(updateRankerStatus(isRanker));
    },
    [dispatch]
  );

  useEffect(() => {
    getUserProfileAsAdmin(id)
      .then((res) => {
        setUserInfo(res.user);
        if (res.user.isRanker && typeof res.user.isRanker === "object") {
          updateUserStatus(res);
        }
      })
      .catch((err) => {});
  }, []);

  if (!userInfo) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="w-full">
        <div className=" my-8">
          <AdminUserInfo
            userId={userInfo.userId}
            userCreatedAt={userInfo.createdAt}
            userIsAdmin={userInfo.isAdmin}
            userIsBlocked={userInfo.isBlocked}
          />
        </div>
        <div className=" my-8">
          <AdminUserActivity
            userId={userInfo.userId}
            userPostCount={userInfo.postCount}
            userCommentCount={userInfo.commentCount}
            userVoteUpCount={userInfo.votedUpCount}
            userVoteDownCount={userInfo.votedDownCount}
            userBookmarkedCount={userInfo.bookmarkedCount}
          />
        </div>
        <div className=" my-8">
          <AdminUserHistory userId={userInfo.userId} />
        </div>
      </div>
    </>
  );
}

export default AdminProfilepage;
