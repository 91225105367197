import { useState, useEffect } from "react";
import { getRankerPosts } from "../../apis/post";
import {
  Post,
  Announcements,
  IntegratedAnnouncements,
} from "../../constants/types";
import RenderPosts from "../Common/RenderPosts";
import Pagination from "../Common/Pagination";
import { useParams } from "react-router-dom";

function RankPost() {
  const { pageNumber } = useParams<{ pageNumber: string }>();

  const [posts, setPosts] = useState<Post[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );

  const [totalPage, setTotalPage] = useState<number>(1);

  useEffect(() => {
    getRankerPosts(currentPage)
      .then((res) => {
        setAnnouncements(res.announcements);
        setIntegratedAnnouncements(res.integratedAnnouncements);
        setPosts(res.posts.rankerPosts);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const handlePageChange = (page: number) => {
    if (page <= totalPage && page >= 1) {
      setCurrentPage(page);
      window.history.pushState(null, "", `/post/rankduk/${page}`);
      window.location.reload();
    } else {
      alert("존재하지 않는 페이지입니다.");
    }
  };

  return (
    <>
      <div className="flex my-5">
        <div className="flex items-baseline justify-center text-2xl font-bold">
          오늘의 RANK 덕
          <img
            src="/assets/icon/rankduk.svg"
            alt="rankduk"
            className="w-8 ml-2"
          />
        </div>
      </div>
      <div className="mb-6">
        <RenderPosts
          posts={posts}
          announcements={announcements}
          integratedAnnouncements={integratedAnnouncements}
        />
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </>
  );
}

export default RankPost;
