import { useSearchParams } from "react-router-dom";
import SearchResult from "../../components/Search/SearchResult";

function SearchResultPage() {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type") || "all";
  const query = searchParams.get("query") || "";

  return (
    <>
      <div className="w-full mt-10">
        <SearchResult type={type} query={query} />
      </div>
    </>
  );
}

export default SearchResultPage;
