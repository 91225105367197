import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "../store/store";

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export interface PostData {
  postList: Post[];
}

export interface Post {
  boardName: string;
  boardNumber: number;
  commentCount: number;
  createdAt: string;
  id: string;
  isBlocked: boolean;
  isDeleted: boolean;
  isCommentBlocked: boolean;
  mainImage: string;
  popularity: number;
  postNumber: number;
  ranker: boolean;
  text: string;
  title: string;
  userId: string;
  viewCount: number;
  voteDownCount: number;
  voteUpCount: number;
  _id: string;
}

export interface MainPost {
  boardName: string;
  boardNumber: number;
  commentCount: number;
  createdAt: string;
  id: string;
  isBlocked: boolean;
  isCommentBlocked: boolean;
  mainImage: string;
  postNumber: number;
  title: string;
  userId: string;
  _id: string;
}

export interface Announcements {
  annType: string;
  boardNumber: number;
  createdAt: string;
  id: string;
  image: any[];
  text: string;
  title: string;
  userId: string;
  viewCount: number;
  _id: string;
}

export interface AnnouoncementDetail {
  annType: string;
  boardNumber: number;
  createdAt: string;
  id: string;
  image: any[];
  isDeleted: boolean;
  text: string;
  title: string;
  updatedAt: string;
  userId: string;
  viewCount: number;
  _id: string;
}

export interface IntegratedAnnouncements {
  createdAt: string;
  id: string;
  integratedAnn: boolean;
  text: string;
  title: string;
  userId: string;
  viewCount: number;
  _id: string;
}

export interface TotalPostData {
  boardName: string;
  boardNumber: number;
  commentCount: number;
  createdAt: string;
  id: string;
  mainImage: string;
  popularity: number;
  postNumber: number;
  ranker: boolean;
  text: string;
  title: string;
  userId: string;
  viewCount: number;
  voteDownCount: number;
  voteUpCount: number;
  _id: string;
}

export interface PostDetailData {
  blockAt: string;
  boardName: string;
  boardNumber: number;
  bookmarkUserList: bookmarkUserList[];
  commentBlockedAt: string;
  commentCount: number;
  comments: Comment[];
  createdAt: string;
  deletedAt: string;
  editedAt: string;
  hasVotedToPoll: boolean;
  id: string;
  isBlocked: boolean;
  isCommentBlocked: boolean;
  isDeleted: boolean;
  mainImage: string;
  poll: Poll;
  pollId: string;
  popularity: number;
  postNumber: number;
  ranker: boolean;
  reportCount: number;
  reportPostUserList: reportPostUserList[];
  text: string;
  title: string;
  updatedAt: string;
  userId: string;
  viewCount: number;
  voteDownCount: number;
  voteDownUserList: voteDownUserList[];
  voteUpCount: number;
  voteUpUserList: voteUpUserList[];
}

export interface Poll {
  id: string;
  isDeleted: boolean;
  isMultiSelectEnabled: boolean;
  options: PollOption[];
  postId: string;
  subject: string;
  totalVoters: number;
}

export interface PollOption {
  option: string;
  votes: number;
  _id: string;
}

export interface bookmarkUserList {
  userId: string;
  _id: string;
}

export interface reportPostUserList {
  id: string;
}

export interface voteDownUserList {
  id: string;
}

export interface voteUpUserList {
  id: string;
}

export interface CommentData {
  commentList: Comment[];
}

export interface Comment {
  blockedAt: string;
  boardNumber: number;
  createdAt: string;
  deletedAt: string;
  editedAt: string;
  id: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  popularity: number;
  postId: string;
  randomNickname: string;
  ranker: boolean;
  reportCommentUserList: reportCommentUserList[];
  reportCount: number;
  subCommentList: SubComment[];
  text: string;
  userId: string;
  voteDownCount: number;
  voteDownUserList: voteDownUserList[];
  voteUpCount: number;
  voteUpUserList: voteUpUserList[];
}

export interface AdminComment {
  boardNumber: number;
  _id: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  blockedAt: string;
  deletedAt: string;
  userId: string;
  postId: string;
  text: string;
}

export interface AdminSubCommment {
  id: string;
  parentCommentId: string;
  parentPostId: string;
  subCombinedDate: string;
  subCommentList: {
    blockedAt: string | null;
    boardNumber: number;
    createdAt: string;
    deletedAt: string | null;
    id: string;
    isBlocked: boolean;
    isDeleted: boolean;
    popularity: number;
    ranker: boolean;
    reportCount: number;
    text: string;
    image: string;
    userId: string;
    voteDownCount: number;
    voteUpCount: number;
  };
  _id: string;
}

export interface AdminPost {
  boardNumber: number;
  commentCount: number;
  createdAt: string;
  _id: string;
  isBlocked: boolean;
  isDeleted: boolean;
  deletedAt: string;
  blockedAt: string;
  isCommentBlocked: boolean;
  mainImage: string;
  popularity: number;
  postNumber: number;
  ranker: boolean;
  text: string;
  title: string;
  userId: string;
  viewCount: number;
  voteDownCount: number;
  voteUpCount: number;
}

export interface AdminBoardNameList {
  _id: string;
  boardNumber: number;
  boardName: string;
  createdAt: string;
  isDeleted: boolean;
  updatedAt: string;
  todayPostCount: number;
}

export interface reportCommentUserList {
  id: string;
}

export interface SubComment {
  blockedAt: string;
  boardNumber: number;
  createdAt: string;
  deletedAt: string;
  editedAt: string;
  id: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  popularity: number;
  postId: string;
  randomNickname: string;
  ranker: boolean;
  reportCommentUserList: reportCommentUserList[];
  reportCount: number;
  text: string;
  userId: string;
  voteDownCount: number;
  voteDownUserList: voteDownUserList[];
  voteUpCount: number;
  voteUpUserList: voteUpUserList[];
}

export interface voteBoardDetailData {
  adminComment: string;
  boardName: string;
  createdAt: string;
  expiredAt: string;
  id: string;
  image: string;
  isExpired: boolean;
  isResolved: boolean;
  isSuccess: boolean;
  ranker: boolean;
  text: string;
  title: string;
  updatedAt: string;
  userId: string;
  viewCount: number;
  voteUpCount: number;
  voteUpUserList: voteUpUserList[];
}

export interface User {
  blockedAt: string;
  blockedCount: number;
  blockedUntil: string;
  boardNameList: string[];
  bookmarkedCount: number;
  commentCount: number;
  createdAt: string;
  id: string;
  isAdmin: boolean;
  isAdminAdmin: boolean;
  isBlocked: boolean;
  isRanker: {};
  postCount: number;
  totalReportCount: number;
  updatedAt: string;
  userId: string;
  votedDownCount: number;
  votedUpCount: number;
}

export interface UserPost {
  boardName: string;
  boardNumber: number;
  commentCount: number;
  createdAt: string;
  id: string;
  isBlocked: boolean;
  isCommentBlocked: boolean;
  popularity: number;
  postNumber: number;
  ranker: boolean;
  title: string;
  userId: string;
  viewCount: number;
  voteDownCount: number;
  voteUpCount: number;
}

export interface UserComment {
  blockedAt: Date;
  boardNumber: number;
  createdAt: string;
  deletedAt: Date;
  id: string;
  image: string;
  isBlocked: boolean;
  isDeleted: boolean;
  popularity: number;
  postId: string;
  randomNickname: string;
  ranker: boolean;
  reportCommentUserList: reportCommentUserList[];
  reportCount: number;
  text: string;
  userId: string;
  voteDownCount: number;
  voteDownUserList: voteDownUserList[];
  voteUpCount: number;
  voteUpUserList: voteUpUserList[];
}

export interface UserComments {
  commentList: UserComment;
  _id: string;
}

export interface UserBookmarks {
  bookmarkList: UserPost[];
  _id: string;
}

export interface Boards {
  boardName: string;
  boardNumber: number;
  createdAt: string;
  id: string;
  isDeleted: boolean;
  updateAt: string;
}

export interface NotificationType {
  announcementMessage: string;
  boardNumber: number;
  commentContent: string;
  commentId: string;
  commentNickname: string;
  createdAt: string;
  isRead: boolean;
  notiId: string;
  notiType: string;
  postId: string;
  postTitle: string;
  _id: string;
}

export interface PollData {
  subject: string;
  options: string[];
  isMultiSelectEnabled: boolean;
}
