import { useState } from "react";
import ReactMarkdown from "react-markdown";

interface AgreementSectionProps {
  title: string;
  content: string;
  onAgreeChange: (isAgreed: boolean) => void;
  isAgreed: boolean; // 추가: 외부에서 상태 받기
}

function AgreementSection({
  title,
  content,
  onAgreeChange,
  isAgreed, // 추가: 전달받은 상태 사용
}: AgreementSectionProps) {
  return (
    <div className=''>
      <div className='flex flex-col items-start mb-2'>
        <h3 className='font-semibold text-lg'>{title}</h3>
      </div>
      <div className='bg-ivory p-4 rounded-lg text-sm max-h-80 overflow-y-auto mb-2 border border-gray'>
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
      <label className='flex items-center text-sm ml-1'>
        <input
          type='checkbox'
          checked={isAgreed} // 외부에서 전달받은 상태로 설정
          onChange={() => onAgreeChange(!isAgreed)}
          className='mr-2'
        />
        <span className='font-bold'>[필수] </span> {title}에 동의합니다
      </label>
    </div>
  );
}

export default AgreementSection;
