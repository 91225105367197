import RankPost from "../../components/Posts/RankPost";

function RankDukPage() {
  return (
    <>
      <div className="w-full">
        <RankPost />
      </div>
    </>
  );
}

export default RankDukPage;
