import React, { useEffect, useState } from "react";
import { changeEmail, sendVerificationCode, verifyCode } from "../../apis/user";
import { useNavigate } from "react-router-dom";

function EmailChangePage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    verificationCode: "",
  });
  const [emailMessage, setEmailMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [verificationMessage, setVerificationMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isResend, setIsResend] = useState(false); // 재전송 상태
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState<number | null>(null);

  // 이메일 형식 검증
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  //타이머 관리 함수
  const startResendTimer = () => {
    const expirationTime = Date.now() + 60000;
    localStorage.setItem("resendTimerExpiration", expirationTime.toString());
    updateResendTimer();
  };

  const calculateRemainingTime = () => {
    const expirationTime = localStorage.getItem("resendTimerExpiration");
    if (!expirationTime) return null;

    const remainingTime = Math.max(0, parseInt(expirationTime) - Date.now());
    return remainingTime > 0 ? Math.ceil(remainingTime / 1000) : null;
  };

  //이매일 재전송
  const updateResendTimer = () => {
    const remainingTime = calculateRemainingTime();
    if (remainingTime !== null) {
      setResendTimer(remainingTime);

      const timerId = setInterval(() => {
        const updatedRemainingTime = calculateRemainingTime();
        if (updatedRemainingTime === null) {
          clearInterval(timerId);
          setResendTimer(null);
        } else {
          setResendTimer(updatedRemainingTime);
        }
      }, 1000);
    } else {
      setResendTimer(null);
    }
  };

  useEffect(() => {
    updateResendTimer();
  }, []);

  // 이메일 인증코드 발송
  const handleSendVerificationCode = async () => {
    if (!isValidEmail(formData.email)) {
      setEmailMessage({
        text: "올바른 이메일 형식이 아닙니다.",
        color: "text-red",
      });
      return;
    }

    try {
      setIsButtonDisabled(true);
      const result = await sendVerificationCode(formData.email);

      if (result.status === 200) {
        setEmailMessage({
          text: "인증 코드가 발송되었습니다.",
          color: "text-blue",
        });
        setIsResend(true);
        startResendTimer();
      } else if (result.status === 208) {
        setEmailMessage({
          text: "이미 인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsEmailVerified(true);
        setVerificationMessage({
          text: "인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsResend(true);
      } else {
        setEmailMessage({
          text: "인증 코드 발송에 실패했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } catch (error: any) {
      console.error("인증 코드 요청 오류:", error);
      if (error.response?.status === 429) {
        setEmailMessage({
          text: "너무 많은 요청을 보냈습니다. 잠시 후 다시 시도해주세요.",
          color: "text-red",
        });
      } else {
        setEmailMessage({
          text: "오류가 발생했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } finally {
      setIsButtonDisabled(false);
    }
  };

  // 인증 코드 확인
  const handleVerifyCode = async () => {
    try {
      const result = await verifyCode(
        formData.email,
        formData.verificationCode
      );
      if (result.status === 200) {
        setVerificationMessage({
          text: "인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsEmailVerified(true);
        setIsResend(true);
      } else {
        setVerificationMessage({
          text: "인증에 실패했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } catch (error) {
      console.error("인증 오류:", error);
      setVerificationMessage({
        text: "오류가 발생했습니다. 다시 시도해주세요.",
        color: "text-red",
      });
    }
  };

  const handleConfirmChange = async () => {
    if (isEmailVerified) {
      try {
        await changeEmail(formData.email);
        alert("이메일 변경이 완료되었습니다!");
        navigate("/profile/info");
      } catch (error) {
        console.error("이메일 변경 오류:", error);
        alert("이메일 변경에 실패했습니다. 다시 시도해주세요.");
      }
    } else {
      alert("이메일 인증을 완료해주세요.");
    }
  };

  const handleCancel = async () => {
    navigate("/profile/info");
  };

  return (
    <div className='max-w-3xl mx-auto mt-10 px-6'>
      <div className='py-4'>
        <h2 className='text-base border-b border-gray pb-2 mb-8'>
          이메일 변경
        </h2>
        <form>
          <div className='border-b border-t border-gray'>
            {/* 이메일 주소 입력 */}
            <div className='flex border-b border-gray'>
              <label
                htmlFor='email'
                className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                변경할 이메일 주소
              </label>
              <div className='flex-1 p-4 space-y-1'>
                <div className='flex gap-2'>
                  <input
                    id='email'
                    name='email'
                    type='email'
                    className='w-1/2 border border-gray rounded'
                    value={formData.email}
                    onChange={(e) => {
                      setFormData({ ...formData, email: e.target.value });
                      setEmailMessage({ text: "", color: "" });
                    }}
                    disabled={isEmailVerified}
                    style={{
                      backgroundColor: isEmailVerified ? "#f0f0f0" : "",
                    }}
                  />
                  <button
                    type='button'
                    className={`px-2 text-white rounded ${
                      isEmailVerified
                        ? "bg-gray cursor-not-allowed"
                        : isButtonDisabled || resendTimer
                        ? "bg-gray cursor-not-allowed"
                        : "bg-hotduk_light hover:bg-hotduk"
                    }`}
                    onClick={handleSendVerificationCode}
                    disabled={
                      isEmailVerified || isButtonDisabled || !!resendTimer
                    }
                    style={{
                      pointerEvents:
                        isEmailVerified || isButtonDisabled || resendTimer
                          ? "none"
                          : "auto",
                    }}>
                    {isEmailVerified
                      ? "재전송"
                      : resendTimer
                      ? `${resendTimer}초 후 재전송 가능`
                      : isResend
                      ? "재전송"
                      : "인증코드 요청"}
                  </button>
                </div>
                {emailMessage.text && (
                  <p className={`text-sm ${emailMessage.color}`}>
                    {emailMessage.text}
                  </p>
                )}
              </div>
            </div>

            {/* 인증코드 입력 */}
            <div className='flex'>
              <label
                htmlFor='verificationCode'
                className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                인증코드
              </label>
              <div className='flex-1 p-4'>
                <div className='flex gap-2'>
                  <input
                    id='verificationCode'
                    name='verificationCode'
                    type='text'
                    className='w-1/2 border border-gray rounded'
                    value={formData.verificationCode}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        verificationCode: e.target.value,
                      })
                    }
                    disabled={isEmailVerified}
                    style={{
                      backgroundColor: isEmailVerified ? "#f0f0f0" : "",
                    }}
                  />
                  <button
                    type='button'
                    className={`px-2 text-white rounded ${
                      isEmailVerified
                        ? "bg-gray cursor-not-allowed"
                        : "bg-hotduk_light hover:bg-hotduk"
                    }`}
                    onClick={handleVerifyCode}
                    disabled={isEmailVerified}
                    style={{
                      pointerEvents: isEmailVerified ? "none" : "auto",
                    }}>
                    인증하기
                  </button>
                </div>
                {verificationMessage.text && (
                  <p className={`text-sm mt-1 ${verificationMessage.color}`}>
                    {verificationMessage.text}
                  </p>
                )}
              </div>
            </div>
          </div>
        </form>

        <div className='flex justify-end pt-4'>
          <button
            type='button'
            className='px-4 py-1 border border-gray rounded hover:bg-gray mr-2'
            onClick={handleCancel}>
            취소
          </button>

          <button
            type='button'
            className='px-4 py-1 bg-hotduk text-white rounded hover:bg-hotduk_light'
            onClick={handleConfirmChange}>
            변경
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailChangePage;
