import React, { useState } from "react";
import { voteBoard } from "../../apis/board";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import { updateLoginRedirectUrl } from "../../store/authReducer";

interface LikeVoteBoardProps {
  postId: string;
  voteUpCount: number;
}

function LikeVoteBoard({ postId, voteUpCount }: LikeVoteBoardProps) {
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const [voteUp, setVoteUp] = useState<number>(voteUpCount);
  const dispatch = useAppDispatch();

  const voteCount = (voteUpCount: number) => {
    if (voteUpCount < 30) {
      return `게시판 추가까지 ${30 - voteUpCount}표`;
    } else {
      return `게시판 추가 성공! ${voteUpCount}표`;
    }
  };

  const handleVoteUp = () => {
    if (!isLogin) {
      const voteUpLogin = window.confirm(
        "로그인 후 투표 가능합니다. 지금 로그인 하시겠습니까?"
      );
      if (voteUpLogin) {
        dispatch(updateLoginRedirectUrl(window.location.pathname));
        window.location.replace("/login");
      } else {
        return;
      }
    }

    const voteUpConfirm = window.confirm("투표하시겠습니까?");
    if (!voteUpConfirm) {
      return;
    } else {
      voteBoard(postId)
        .then((res) => {
          setVoteUp(voteUp + 1);
          alert("투표 완료");
          window.location.reload();
        })
        .catch((err) => {
          //todo 로그인 안한거 처리해주기

          // 이미 투표한 게시글일 경우
          if (
            err.response.status === 403 &&
            err.response.data.message === "This user has already voted"
          ) {
            alert("이미 투표한 게시글입니다.");
            window.location.reload();
          }
        });
    }
  };

  return (
    <>
      <div className="flex flex-col w-full mx-auto items-center justify-center">
        {/* <button
          className="flex flex-col rounded-full bg-[#FF8F2B] w-20 h-20 mx-4 mt-8 items-center justify-center hover:bg-orange-600"
          onClick={handleVoteUp}
        >
          <img
            src="/assets/icon/thumbsup.svg"
            alt="thumbsup"
            className="w-6 pt-1"
          />
          <div className="mx-3 pt-[2px] text-white text-lg  font-semibold">
            {voteUp}
          </div>
        </button> */}

        <div className="flex text-zinc-700 text-xl font-semibold my-5">
          {voteCount(voteUp)}
        </div>

        <button
          className="bg-main text-white font-semibold hover:opacity-60 py-2 px-4 border rounded"
          onClick={handleVoteUp}
        >
          찬성!
        </button>
      </div>
    </>
  );
}

export default LikeVoteBoard;
