import {
  Announcements,
  IntegratedAnnouncements,
  Post,
} from '../../constants/types';
import { useNavigate } from 'react-router-dom';
import { getPost } from '../../apis/post';

interface RenderPostsProps {
  posts?: Post[];
  isHot?: boolean;
  announcements?: Announcements[];
  integratedAnnouncements?: IntegratedAnnouncements[];
}

function RenderPosts({
  posts,
  isHot,
  announcements,
  integratedAnnouncements,
}: RenderPostsProps) {
  const navigate = useNavigate();

  const handlePostClick = (postId: string) => {
    getPost(postId)
      .then((res) => {
        navigate(`/post/${res.boardNumber}/${postId}`);
        window.scrollTo(0, 0);
      })
      .catch((err) => {});
    //todo 추후에 boardnumber 또는 boardname 받아서 post detail 로 navigate 해야함
    // navigate(`/post/${postId}`);
  };

  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  if (!posts || !Array.isArray(posts)) {
    return (
      <table className='w-full table-fixed mx-auto text-textcolor'>
        <thead>
          <tr className='bg-[#FAFAFA] text-sm font-semibold'>
            <th className='w-7/12 xl:w-1/2 py-[10px]'>제목</th>
            <th className=' py-[10px]'> 글쓴이</th>
            <th className=' py-[10px]'> 조회수</th>
            <th className=' py-[10px]'> 추천</th>
            <th className='w-2/12 py-[10px]'> 작성일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>게시물이 존재하지 않습니다.</td>
          </tr>
        </tbody>
      </table>
    );
  }

  const hotPosts = (posts: Post[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handlePostClick(post.id)}
          >
            <td className='py-[10px] pl-4 flex items-center'>
              <div className='flex'>
                {/* 여기서 hot populartiy 수정  */}
                {post.ranker ? (
                  <div className='flex items-center'>
                    <div className=' flex-shrink-0'>
                      <img
                        src='/assets/icon/rank_label.svg'
                        alt='rank_label'
                        className='mr-2 w-20'
                      />
                    </div>
                    <div className=' font-semibold px-2 flex'>
                      <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-[6px] w-[14px]'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='flex'>
                    <div className=' flex-shrink-0'>
                      <div className='pl-24'></div>
                    </div>
                    <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                      {post.boardName}
                    </div>
                    {post.title}
                    {post.mainImage && (
                      <img
                        src='/assets/icon/10duk_photo.svg'
                        alt='image'
                        className='ml-[6px] w-[14px]'
                      />
                    )}
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              {post.ranker ? (
                <div className='flex justify-center items-center'>
                  <img
                    src='/assets/icon/ranker_crown.svg'
                    alt='ranker_crown'
                    className='w-5 pr-1'
                  />
                  <p className='flex font-normal'>익명</p>
                </div>
              ) : (
                <div className='pl-5 text-center font-normal'>익명</div>
              )}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.voteUpCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
              24 ? (
                <div>
                  {`${new Date(new Date(post.createdAt))
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}{' '}
                </div>
              ) : (
                <>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 86400000
                  ) > 364 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                        new Date(new Date(post.createdAt)).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <div>
                      {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const rankPosts = (posts: Post[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handlePostClick(post.id)}
          >
            <td className='py-[10px] pl-4 flex items-center'>
              <div className='flex'>
                {/* 여기서 hot populartiy 수정  */}
                {post.viewCount > 149 ? (
                  <div className='flex items-center'>
                    <div className=' flex-shrink-0'>
                      <img
                        src='/assets/icon/hot_label.svg'
                        alt='rank_label'
                        className='mr-2 w-20 py-1'
                      />
                    </div>
                    <div className=' font-semibold px-2 flex'>
                      <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                        {post.boardName}
                      </div>
                      {post.title}
                      {post.mainImage && (
                        <img
                          src='/assets/icon/10duk_photo.svg'
                          alt='image'
                          className='ml-[6px] w-[14px]'
                        />
                      )}
                    </div>
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className='flex'>
                    <div className=' flex-shrink-0'>
                      <div className='pl-24'></div>
                    </div>
                    <div className='flex text-sm font-normal items-start opacity-50 pr-2 whitespace-nowrap'>
                      {post.boardName}
                    </div>
                    {/* <div className="flex items-center"> */}
                    {post.title}
                    {post.mainImage && (
                      <img
                        src='/assets/icon/10duk_photo.svg'
                        alt='image'
                        className='ml-[6px] w-[14px]'
                      />
                    )}
                    <div className='flex items-center'>
                      {post.commentCount !== 0 && (
                        <div className=' text-hotduk font-bold px-2'>
                          [{post.commentCount}]
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              {post.ranker ? (
                <div className='flex justify-center items-center'>
                  <img
                    src='/assets/icon/ranker_crown.svg'
                    alt='ranker_crown'
                    className='w-5 pr-1'
                  />
                  <p className='flex font-normal'>익명</p>
                </div>
              ) : (
                <div className='pl-5 text-center font-normal'>익명</div>
              )}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.voteUpCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
              24 ? (
                <div>
                  {`${new Date(new Date(post.createdAt))
                    .getHours()
                    .toString()
                    .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`}{' '}
                </div>
              ) : (
                <>
                  {Math.floor(
                    (Date.now() - Date.parse(post.createdAt)) / 86400000
                  ) > 364 ? (
                    <div>
                      {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                        new Date(new Date(post.createdAt)).getMonth() + 1
                      )
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  ) : (
                    <div>
                      {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                        .toString()
                        .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                        .getDate()
                        .toString()
                        .padStart(2, '0')}`}{' '}
                    </div>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <tbody>
        <tr
          key={post.id}
          className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <td className='py-[10px] pl-4 flex items-center font-normal'>
            <div className=' flex-shrink-0'>
              <img
                src='/assets/icon/notice_label.svg'
                alt='notice_label'
                className='mr-2 w-20 py-1'
              />
            </div>
            <p className=' font-semibold px-2'>{post.title}</p>
          </td>
          <td className='py-2 px-1 items-center justify-center'>
            <div className='pl-5 text-center font-normal'>익명</div>
          </td>
          <td className='py-2 px-1 text-center font-normal'>
            {post.viewCount}
          </td>
          <td className='py-2 px-1 text-center font-normal'>0</td>
          <td className='py-2 px-1 text-center font-normal'>
            {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
            24 ? (
              <div>
                {`${new Date(new Date(post.createdAt))
                  .getHours()
                  .toString()
                  .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`}{' '}
              </div>
            ) : (
              <>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 86400000
                ) > 364 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                      new Date(new Date(post.createdAt)).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                ) : (
                  <div>
                    {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const post = posts[0];
    return (
      <tbody>
        <tr
          key={post.id}
          className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <td className='py-[10px] pl-4 flex items-center font-normal'>
            <div className=' flex-shrink-0'>
              <img
                src='/assets/icon/notice_label.svg'
                alt='notice_label'
                className='mr-2 w-20 py-1'
              />
            </div>
            <p className=' font-semibold px-2'>{post.title}</p>
          </td>
          <td className='py-2 px-1 items-center justify-center'>
            <div className='pl-5 text-center font-normal'>익명</div>
          </td>
          <td className='py-2 px-1 text-center font-normal'>
            {post.viewCount}
          </td>
          <td className='py-2 px-1 text-center font-normal'>0</td>
          <td className='py-2 px-1 text-center font-normal'>
            {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
            24 ? (
              <div>
                {`${new Date(new Date(post.createdAt))
                  .getHours()
                  .toString()
                  .padStart(2, '0')}:${new Date(new Date(post.createdAt))
                  .getMinutes()
                  .toString()
                  .padStart(2, '0')}`}{' '}
              </div>
            ) : (
              <>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 86400000
                ) > 364 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                      new Date(new Date(post.createdAt)).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                ) : (
                  <div>
                    {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                      .toString()
                      .padStart(2, '0')}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, '0')}`}{' '}
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <>
      <table className='w-full table-fixed mx-auto text-textcolor'>
        <thead>
          <tr className='bg-[#FAFAFA] text-sm font-semibold'>
            <th className='w-7/12 py-[10px]'>제목</th>
            <th className=' py-[10px]'> 글쓴이</th>
            <th className=' py-[10px]'> 조회수</th>
            <th className=' py-[10px]'> 추천</th>
            <th className='py-[10px]'> 작성일</th>
          </tr>
        </thead>
        {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
          <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
        ) : (
          <></>
        )}
        {announcements && announcements.length !== 0 ? (
          <>{renderAnnouncementPost(announcements)}</>
        ) : (
          <></>
        )}
        {isHot ? hotPosts(posts) : rankPosts(posts)}
      </table>
    </>
  );
}

export default RenderPosts;
