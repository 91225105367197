import React from "react";

interface ModalPaginationProps {
  currentPage: number;
  totalPage: number;
  onPageChange: (page: number) => void;
}

function ModalPagination({
  currentPage,
  totalPage,
  onPageChange,
}: ModalPaginationProps) {
  const currentPageUnit = Math.ceil(currentPage / 10);
  const startPage = (currentPageUnit - 1) * 10 + 1;
  const endPage = Math.min(startPage + 9, totalPage);
  const pageButtons = [];
  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    pageButtons.push(
      <button
        key={pageNumber}
        onClick={() => onPageChange(pageNumber)}
        className={`m-2 items-center justify-center ${
          currentPage === pageNumber
            ? "text-hotduk font-normal text-sm items-center justify-center"
            : "text-[#6B6B6B] font-normal text-sm"
        }`}
      >
        {pageNumber}
      </button>
    );
  }

  return (
    <>
      <div className="mx-auto justify-center items-center flex">
        <button
          onClick={() => onPageChange(Math.max(1, currentPage - 1))}
          className="m-1 p-1"
        >
          <div className="text-[#6B6B6B]">{`<`}</div>
        </button>
        {pageButtons}
        <button
          onClick={() => onPageChange(Math.min(totalPage, currentPage + 1))}
          className="m-1 p-1"
        >
          <div className="text-[#6B6B6B]">{`>`}</div>
        </button>
      </div>
    </>
  );
}

export default ModalPagination;
