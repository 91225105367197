// TopLogoBar.js
import { useNavigate } from 'react-router-dom';

function TopLogoBar() {
  const navigate = useNavigate();

  const goToMain = () => {
    navigate(`/`);
  };

  return (
    <div className='bg-hotduk_light_light text-white py-5 fixed top-0 left-0 w-full z-50'>
      <div className='flex justify-start items-center max-w-screen-xl mx-auto px-4'>
        <img
          src='/assets/icon/10duk_newlogo_fin_02.svg'
          alt='로고이미지'
          onClick={goToMain}
          className='cursor-pointer h-8'
          id='logo'
        />
      </div>
    </div>
  );
}

export default TopLogoBar;
