import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../constants/types";
import { closeLoginModal } from "../../store/loginModalSlice";
import { setSignupRedirectUrl } from "../../store/twitterReferrerSlice";

const LinksSection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    const currentPath = window.location.pathname + window.location.search;
    dispatch(setSignupRedirectUrl(currentPath)); // 현재 URL 저장
    navigate("/signup/agreement");
    dispatch(closeLoginModal()); // 모달 닫기
  };

  const handleFindClick = () => {
    const currentPath = window.location.pathname + window.location.search;
    dispatch(setSignupRedirectUrl(currentPath)); // 현재 URL 저장
    navigate("/reset/verify");
    dispatch(closeLoginModal());
  };

  return (
    <div className='text-sm text-right space-x-2'>
      <a onClick={handleSignUpClick} className='text-hotduk hover:underline'>
        회원가입
      </a>
      <a onClick={handleFindClick} className='text-hotduk hover:underline'>
        ID/PW 찾기
      </a>
    </div>
  );
};

export default LinksSection;
