import React, { useEffect, useState } from "react";
import EditAnnouncement from "../../../components/Admin/Announcement/EditAnnouncement";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../constants/types";
import { getEditAnnouncement } from "../../../apis/admin/announcement";

function EditAnnouncementPage() {
  const defaultUserId = useAppSelector((state) => state.auth.userId);
  const { announcementId } = useParams<{ announcementId: string }>();
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [annType, setAnnType] = useState<string>(""); // "integrate" "popular","ranker","voteBoard","board",
  const [boardNumber, setBoardNumber] = useState<string>("");
  const [userId, setUserId] = useState<string | null>(null);
  const [isCreating, setIsCreating] = useState(false);

  useEffect(() => {
    if (!announcementId) {
      return;
    }
    getEditAnnouncement(announcementId)
      .then((res) => {
        setTitle(res.title);
        setContent(res.text);
        setUserId(res.userId);
        setAnnType(res.annType);
        setBoardNumber(res.boardNumber);
      })
      .catch((err) => {});
  }, [announcementId, title, content, annType, boardNumber]);
  if (!announcementId) {
    return <div>announcementId Loading...</div>;
  }

  if (!content) {
    return <div>content Loading...</div>;
  }
  return (
    <div className="w-full flex">
      <EditAnnouncement
        announcementId={announcementId}
        title={title}
        content={content}
        annType={annType}
        boardNumber={boardNumber}
      />
    </div>
  );
}

export default EditAnnouncementPage;
