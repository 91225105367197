import { useEffect, useRef, useState } from "react";
import { deletePost } from "../../apis/post";
import { useAppSelector, AnnouoncementDetail } from "../../constants/types";
import HTMLReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { getBoardName } from "../../apis/board";
import { getAnnouncementDetail } from "../../apis/admin/announcement";
import { deleteAnnouncementForever } from "../../apis/admin/announcement";

interface AnnouncementDetailProps {
  postId: string;
  boardNumber?: number;
}

function AnnouncementDetail({ postId, boardNumber }: AnnouncementDetailProps) {
  const [boardName, setBoardName] = useState<string | null>(null);
  const [post, setPost] = useState<AnnouoncementDetail | null>(null);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLDivElement>(null);

  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const userId = useAppSelector((state) => state.auth.userId);
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);

  const navigate = useNavigate();

  useEffect(() => {
    getAnnouncementDetail(postId)
      .then((res) => {
        setPost(res);
      })
      .catch((err) => {});

    if (boardNumber) {
      getBoardName(boardNumber)
        .then((res) => {
          setBoardName(res.boardName);
        })
        .catch((err) => {});
    }
  }, [postId]);

  if (!post) return <div> 글 정보 불러오는 중..... </div>;

  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")} ${dateTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateTime.getMinutes().toString().padStart(2, "0")}`;

  const goToBoard = () => {
    navigate(`/board/${boardNumber}`);
  };

  const handleDeletePost = () => {
    // 글 삭제 시 한 번 더 확인
    const deleteConfirm = window.confirm("정말 삭제하시겠습니까?");
    if (deleteConfirm) {
      deleteAnnouncementForever(postId).then((res) => {
        alert("삭제되었습니다");
      });
      navigate(`/admin/page/announcement`);
    } else {
      alert("삭제가 취소되었습니다");
    }
  };

  const handleEditPost = (postId: string) => {
    navigate(`/admin/page/announcement/edit/${postId}`);
  };

  const toggleMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const handleCopyUrl = () => {
    const postDetailUrl = window.location.href;

    // 클립보드 API를 사용하여 URL을 복사
    navigator.clipboard
      .writeText(postDetailUrl)
      .then(() => {
        alert("URL이 복사되었습니다.");
      })
      .catch((error) => {});
  };

  const handleReportPost = () => {
    alert("신고 되었습니다");
  };

  return (
    <>
      <div className="flex flex-col w-full">
        {boardName ? (
          <div
            className="w-40 text-[#222222] text-2xl font-bold mb-5 cursor-pointer hover:text-gray"
            onClick={goToBoard}>
            {boardName}
          </div>
        ) : (
          <div className="w-40 text-[#222222] text-2xl font-bold mb-5">
            공지사항
          </div>
        )}
        <div className="flex py-2 bg-[#E9E9E9] justify-between items-center">
          <div className="flex pl-5 text-textcolor text-lg font-semibold">
            {post.title}
          </div>
          <div className="flex text-[#555555] text-xs mr-4">
            {formattedDate}
          </div>
        </div>
        <div className="flex justify-between border-b border-[#ECECEC] py-3">
          <div className="flex text-sm text-[#6B6B6B] font-medium items-center pl-5">
            운영자덕
          </div>
          <div className="flex mr-4 items-center flex-grow justify-end">
            <img src="/assets/icon/eye.svg" alt="eye" className="w-4 mr-1" />
            <div className="text-[#222222] text-xs font-normal mr-[3px]">
              조회
            </div>
            <div className="text-[#555555] text-xs font-semibold mr-4">
              {post.viewCount}
            </div>
            {isAdminAdmin && ( // 관리자일 때만 "더보기" 버튼 표시
              <div
                className="flex cursor-pointer px-2"
                onClick={isAdminAdmin ? toggleMoreOpen : undefined}
                ref={moreRef}>
                <img src="/assets/icon/more.svg" alt="more" className="h-4" />
              </div>
            )}
          </div>
        </div>
        {isMoreOpen && isAdminAdmin && (
          <div className="flex justify-end space-x-2 p-2">
            <button
              onClick={() => handleEditPost(postId)}
              className="text-blue-500 hover:text-blue-700">
              수정
            </button>
            <button
              onClick={handleDeletePost}
              className="text-red-500 hover:text-red-700">
              삭제
            </button>
          </div>
        )}
        <div className="my-5 text-base text-textcolor">
          <div className="break-words">{HTMLReactParser(post.text)}</div>
        </div>
      </div>
    </>
  );
}

export default AnnouncementDetail;
