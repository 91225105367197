import HotPost from "../../components/Posts/HotPost";

function HotDukPage() {
  return (
    <>
      <div className="w-full ">
        <HotPost />
      </div>
    </>
  );
}

export default HotDukPage;
