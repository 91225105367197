import React, { useEffect, useState } from "react";
import { getBoardBookmarks } from "../../apis/user";

function BookmarkedBoardName() {
  const [boards, setBoards] = useState<[]>([]);

  useEffect(() => {
    getBoardBookmarks()
      .then((res) => {
        setBoards(res);
      })
      .catch((err) => {});
  }, []);

  const goToBoard = (boardNumber: number) => {
    window.location.href = `/board/${boardNumber}`;
  };

  return (
    <>
      <div className="flex flex-col">
        {boards.map((board: any) => (
          <div
            key={board.boardNumber}
            className="flex justify-center text-navy text-xs font-semibold mx-4 cursor-pointer"
            onClick={() => goToBoard(board.boardNumber)}
          >
            {board.boardName}
          </div>
        ))}
      </div>
    </>
  );
}

export default BookmarkedBoardName;
