import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkIdDup,
  getNiceStatus,
  sendVerificationCode,
  verifyCode,
  sendSignup,
} from "../../apis/user";

function InputPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    password: "",
    passwordConfirm: "",
    email: "",
    verificationCode: "",
  });
  const [idMessage, setIdMessage] = useState<{ text: string; color: string }>({
    text: "",
    color: "",
  });
  const [passwordMessage, setPasswordMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [passwordMatchMessage, setPasswordMatchMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [emailMessage, setEmailMessage] = useState<{
    text: string;
    color: string;
  }>({
    text: "",
    color: "",
  });
  const [verificationMessage, setVerificationMessage] = useState({
    text: "",
    color: "",
  });
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isIdValid, setIsIdValid] = useState(false);
  const [isIdDuplicate, setIsIdDuplicate] = useState(true);
  const [isCheckIdButtonDisabled, setIsCheckIdButtonDisabled] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isResend, setIsResend] = useState(false);
  const [resendTimer, setResendTimer] = useState<number | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!isIdValid) {
      alert("아이디가 올바르지 않습니다.");
      return;
    }
    if (isIdDuplicate) {
      alert("아이디 중복 검사를 완료해주세요.");
      return;
    }
    if (!isPasswordValid) {
      alert("비밀번호가 올바르지 않습니다.");
      return;
    }
    if (!isPasswordMatch) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }
    if (!isEmailVerified) {
      alert("이메일 인증을 완료해주세요.");
      return;
    }

    try {
      const result = await sendSignup(
        formData.id,
        formData.password,
        formData.email
      );
      navigate("/success", { state: { fromSubmit: true } });
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        alert("이미 존재하는 회원입니다.");
      } else {
        alert("회원가입 중 오류가 발생했습니다. 다시 시도해주세요.");
      }
    }
  };

  const handleCancle = async () => {
    navigate("/");
  };

  // 아이디 규칙 검사
  const validateIdRules = (id: string) => {
    if (!id) {
      setIdMessage({ text: "아이디를 입력하세요.", color: "text-red" });
      setIsIdValid(false);
      return;
    }
    if (!/^[a-z]/.test(id)) {
      setIdMessage({
        text: "아이디는 영문 소문자로 시작해야 합니다.",
        color: "text-red",
      });
      setIsIdValid(false);
      return;
    }
    if (!/^[a-z0-9]+$/.test(id)) {
      setIdMessage({
        text: "아이디는 영문 소문자와 숫자로만 구성되어야 합니다.",
        color: "text-red",
      });
      setIsIdValid(false);
      return;
    }
    if (id.length < 3 || id.length > 20) {
      setIdMessage({
        text: "아이디는 3~20자로 구성되어야 합니다.",
        color: "text-red",
      });
      setIsIdValid(false);
      return;
    }
    setIdMessage({
      text: "사용 가능한 형식의 아이디입니다.",
      color: "text-blue",
    });
    setIsIdValid(true);
    setIsIdDuplicate(true);
  };

  // 아이디 중복 확인
  const handleCheckIdDup = async () => {
    if (isIdValid) {
      try {
        const result = await checkIdDup(formData.id);
        if (!result.isExists) {
          setIdMessage({
            text: "사용 가능한 아이디입니다.",
            color: "text-blue",
          });
          setIsIdDuplicate(false);
          setIsCheckIdButtonDisabled(true);
        } else {
          setIdMessage({
            text: "이미 사용 중인 아이디입니다.",
            color: "text-red",
          });
          setIsIdDuplicate(true);
        }
      } catch (error) {
        console.error("아이디 중복 확인 오류:", error);
        setIdMessage({
          text: "아이디 중복 확인 중 오류가 발생했습니다.",
          color: "text-red",
        });
        setIsIdDuplicate(true);
      }
    } else {
      alert("아이디가 올바르지 않습니다.");
    }
  };

  // 아이디 입력 시 버튼 활성화
  const handleIdChange = (id: string) => {
    setFormData({ ...formData, id });
    validateIdRules(id);
    setIsCheckIdButtonDisabled(false);
  };

  // 비밀번호 규칙 검사
  const validatePasswordRules = (password: string) => {
    if (!password) {
      setPasswordMessage({ text: "비밀번호를 입력하세요.", color: "text-red" });
      setIsPasswordValid(false);
      return;
    }
    if (password.length < 8 || password.length > 20) {
      setPasswordMessage({
        text: "비밀번호는 8~20자로 구성되어야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      setPasswordMessage({
        text: "비밀번호는 최소 1개의 영문 및 1개의 숫자를 포함해야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':",./<>?~`]/.test(password)) {
      setPasswordMessage({
        text: "허용되지 않는 특수문자가 포함되어 있습니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }

    setPasswordMessage({
      text: "사용 가능한 비밀번호입니다.",
      color: "text-blue",
    });
    setIsPasswordValid(true);
  };

  // 비밀번호 일치 여부 검사
  const validatePasswordMatch = (password: string, passwordConfirm: string) => {
    if (passwordConfirm === "") {
      setPasswordMatchMessage({ text: "", color: "" });
      setIsPasswordMatch(false);
      return;
    }
    if (password === passwordConfirm) {
      setPasswordMatchMessage({
        text: "비밀번호가 일치합니다.",
        color: "text-blue",
      });
      setIsPasswordMatch(true);
    } else {
      setPasswordMatchMessage({
        text: "비밀번호가 일치하지 않습니다.",
        color: "text-red",
      });
      setIsPasswordMatch(false);
    }
  };

  //인증코드 요청 전에 이메일 형식 확인.
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  //이매일 재전송
  const updateResendTimer = () => {
    const remainingTime = calculateRemainingTime();
    if (remainingTime !== null) {
      setResendTimer(remainingTime);

      const timerId = setInterval(() => {
        const updatedRemainingTime = calculateRemainingTime();
        if (updatedRemainingTime === null) {
          clearInterval(timerId);
          setResendTimer(null);
        } else {
          setResendTimer(updatedRemainingTime);
        }
      }, 1000);
    } else {
      setResendTimer(null);
    }
  };

  useEffect(() => {
    updateResendTimer();
  }, []);

  //타이머 관리 함수
  const startResendTimer = () => {
    const expirationTime = Date.now() + 60000;
    localStorage.setItem("resendTimerExpiration", expirationTime.toString());
    updateResendTimer();
  };

  const calculateRemainingTime = () => {
    const expirationTime = localStorage.getItem("resendTimerExpiration");
    if (!expirationTime) return null;

    const remainingTime = Math.max(0, parseInt(expirationTime) - Date.now());
    return remainingTime > 0 ? Math.ceil(remainingTime / 1000) : null;
  };

  //이메일 인증코드 발송
  const handleSendVerificationCode = async () => {
    if (!isValidEmail(formData.email)) {
      setEmailMessage({
        text: "올바른 이메일 형식이 아닙니다.",
        color: "text-red",
      });
      return;
    }

    try {
      setIsButtonDisabled(true);
      const result = await sendVerificationCode(formData.email);
      if (result.status === 200) {
        setEmailMessage({
          text: "인증 코드가 발송되었습니다.",
          color: "text-blue",
        });
        setIsResend(true);
        startResendTimer();
      } else if (result.status === 208) {
        setEmailMessage({
          text: "이미 인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsEmailVerified(true);
        setVerificationMessage({
          text: "인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsResend(true);
      } else {
        setEmailMessage({
          text: "인증 코드 발송에 실패했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } catch (error: any) {
      if (error.response.status === 429) {
        setEmailMessage({
          text: "너무 많은 요청을 보냈습니다. 잠시 후 다시 시도해주세요.",
          color: "text-red",
        });
      } else {
        setEmailMessage({
          text: "오류가 발생했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } finally {
      setIsButtonDisabled(false);
    }
  };

  //이메일 검증
  const handleVerifyCode = async () => {
    try {
      const result = await verifyCode(
        formData.email,
        formData.verificationCode
      );
      if (result.status === 200) {
        setVerificationMessage({
          text: "인증이 완료되었습니다.",
          color: "text-blue",
        });
        setIsEmailVerified(true);
        setIsResend(true);
      } else {
        setVerificationMessage({
          text: "인증에 실패했습니다. 다시 시도해주세요.",
          color: "text-red",
        });
      }
    } catch (error) {
      console.error("인증 오류:", error);
      setVerificationMessage({
        text: "오류가 발생했습니다. 다시 시도해주세요.",
        color: "text-red",
      });
    }
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const res = await getNiceStatus();
        if (!res.isVerified) {
          navigate("/signup/verify");
          alert("본인인증을 완료해야 합니다.");
        } else {
          setIsAuthenticated(true);
          alert("본인인증이 완료되었습니다!");
        }
      } catch (error) {
        console.error("Auth check error:", error);
      }
    };
    checkAuthStatus();
  }, [navigate]);

  if (!isAuthenticated) {
    return <div></div>;
  }

  return (
    <>
      <div className='max-w-3xl mx-auto mt-10 px-6'>
        <div className='py-4'>
          <h2 className='text-base border-b border-gray pb-2 mb-8'>
            기본 정보
          </h2>

          <form className=''>
            <div className='border border-gray rounded border-l-0 border-r-0'>
              {/* 아이디 입력 */}
              <div className='border-b border-gray'>
                <div className='flex'>
                  <label
                    htmlFor='userId'
                    className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                    아이디
                  </label>
                  <div className='flex-1 p-4 space-y-1'>
                    <div className='flex gap-2'>
                      <input
                        id='userId'
                        name='id'
                        type='text'
                        className='w-1/2 border border-gray rounded'
                        autoComplete='username'
                        value={formData.id}
                        onChange={(e) => handleIdChange(e.target.value)}
                      />
                      <button
                        type='button'
                        className={`px-2 rounded ${
                          isCheckIdButtonDisabled
                            ? "bg-gray text-white cursor-not-allowed"
                            : "bg-hotduk_light text-white hover:bg-hotduk"
                        }`}
                        onClick={handleCheckIdDup}
                        disabled={isCheckIdButtonDisabled}>
                        중복 검사
                      </button>
                    </div>
                    {idMessage.text && (
                      <p className={`text-sm ${idMessage.color}`}>
                        {idMessage.text}
                      </p>
                    )}
                    <p className='text-sm text-gray_text'>
                      사용자 ID는 3~20자 사이의 영문+숫자로 이루어져야 합니다.
                    </p>
                    <p className='text-sm text-gray_text'>
                      영문 소문자로 시작되어야 합니다.
                    </p>
                  </div>
                </div>
              </div>

              {/* 비밀번호 입력 */}
              <div className='border-b border-gray'>
                <div className='flex'>
                  <label
                    htmlFor='password'
                    className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                    비밀번호
                  </label>
                  <div className='flex-1 p-4 space-y-1'>
                    <input
                      id='password'
                      name='password'
                      type='password'
                      className='w-1/2 border border-gray rounded'
                      autoComplete='new-password'
                      value={formData.password}
                      onChange={(e) => {
                        setFormData({ ...formData, password: e.target.value });
                        validatePasswordRules(e.target.value);
                        validatePasswordMatch(
                          e.target.value,
                          formData.passwordConfirm
                        );
                      }}
                    />
                    {passwordMessage.text && (
                      <p className={`text-sm ${passwordMessage.color}`}>
                        {passwordMessage.text}
                      </p>
                    )}
                    <p className='text-sm text-gray_text'>
                      비밀번호는 8~20자의 영문/숫자 조합으로 구성되어야 합니다.
                    </p>
                    <p className='text-sm text-gray_text'>
                      최소 1개 영문 및 최소 1개 숫자를 포함해야 합니다.
                    </p>
                    <p className='text-sm text-gray_text'>
                      허용되는 특수문자: !@#$%^&*()_+-=[]{};':",./&lt;&gt;?~`
                    </p>
                    <p className='text-sm text-gray_text'>
                      허용되지 않는 특수문자: 공백, 한글, 이모지, 허용된
                      특수문자 외 모든 문자
                    </p>
                  </div>
                </div>
              </div>

              {/* 비밀번호 확인 */}
              <div className='border-b border-gray'>
                <div className='flex'>
                  <label
                    htmlFor='passwordConfirm'
                    className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                    비밀번호 확인
                  </label>
                  <div className='flex-1 p-4'>
                    <input
                      id='passwordConfirm'
                      name='passwordConfirm'
                      type='password'
                      className='w-1/2 border border-gray rounded'
                      autoComplete='new-password'
                      value={formData.passwordConfirm}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          passwordConfirm: e.target.value,
                        });
                        validatePasswordMatch(
                          formData.password,
                          e.target.value
                        );
                      }}
                    />
                    {passwordMatchMessage.text && (
                      <p
                        className={`text-sm mt-1 ${passwordMatchMessage.color}`}>
                        {passwordMatchMessage.text}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* 이메일 주소 */}
              <div className='border-b border-gray'>
                <div className='flex'>
                  <label
                    htmlFor='email'
                    className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                    이메일 주소
                  </label>
                  <div className='flex-1 p-4 space-y-1'>
                    <div className='flex gap-2'>
                      <input
                        id='email'
                        name='email'
                        type='email'
                        className='w-1/2 border border-gray rounded'
                        autoComplete='email'
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                          setEmailMessage({ text: "", color: "" });
                        }}
                        disabled={isEmailVerified}
                        style={{
                          backgroundColor: isEmailVerified ? "#f0f0f0" : "",
                        }}
                      />
                      <button
                        type='button'
                        className={`px-2 text-white rounded ${
                          isEmailVerified
                            ? "bg-gray cursor-not-allowed"
                            : isButtonDisabled || resendTimer
                            ? "bg-gray cursor-not-allowed"
                            : "bg-hotduk_light hover:bg-hotduk"
                        }`}
                        onClick={handleSendVerificationCode}
                        disabled={
                          isButtonDisabled || !!resendTimer || isEmailVerified
                        }>
                        {isEmailVerified
                          ? "재전송"
                          : resendTimer
                          ? `${resendTimer}초 후 재전송 가능`
                          : isResend
                          ? "재전송"
                          : "인증코드 요청"}
                      </button>
                    </div>
                    {emailMessage.text && (
                      <p className={`text-sm ${emailMessage.color}`}>
                        {emailMessage.text}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* 인증코드 입력 */}
              <div>
                <div className='flex'>
                  <label
                    htmlFor='verificationCode'
                    className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                    인증코드
                  </label>
                  <div className='flex-1 p-4'>
                    <div className='flex gap-2'>
                      <input
                        id='verificationCode'
                        name='verificationCode'
                        type='text'
                        className='w-1/2 border border-gray rounded'
                        autoComplete='off'
                        value={formData.verificationCode}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            verificationCode: e.target.value,
                          })
                        }
                        disabled={isEmailVerified}
                        style={{
                          backgroundColor: isEmailVerified ? "#f0f0f0" : "",
                        }}
                      />
                      <button
                        type='button'
                        className={`px-2 text-white rounded ${
                          isEmailVerified
                            ? "bg-gray cursor-not-allowed"
                            : "bg-hotduk_light hover:bg-hotduk"
                        }`}
                        onClick={handleVerifyCode}
                        disabled={isEmailVerified}
                        style={{
                          pointerEvents: isEmailVerified ? "none" : "auto",
                        }}>
                        인증하기
                      </button>
                    </div>
                    {verificationMessage.text && (
                      <p
                        className={`text-sm mt-1 ${verificationMessage.color}`}>
                        {verificationMessage.text}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* 버튼 영역 */}
            <div className='flex justify-end gap-2 pt-8'>
              <button
                type='button'
                className='px-4 py-2 border border-gray rounded hover:bg-gray'
                onClick={handleCancle}>
                취소
              </button>
              <button
                type='submit'
                className='px-4 py-2 bg-hotduk text-white rounded hover:bg-hotduk_light'
                onClick={handleSubmit}>
                등록
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default InputPage;
