import { useEffect } from "react";
import TotalPost from "../../components/Posts/TotalPost";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../constants/types";
import { openLoginModal } from "../../store/loginModalSlice";

function TotalPostPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const loginId = searchParams.get("loginId");
    const statusParam = searchParams.get("status");

    if (statusParam === "user-not-found") {
      alert("등록된 회원이 아닙니다. 회원가입을 진행해주세요.");
      dispatch(openLoginModal()); // Redux 액션 호출
      navigate("/"); // 메인 페이지로 리다이렉트
    } else if (statusParam === "rejoin-restricted") {
      alert(
        "회원 탈퇴 후 30일 동안 재가입이 제한됩니다. 나중에 다시 시도해주세요."
      );
      navigate("/");
    }

    if (loginId) {
      alert(`이미 가입한 유저입니다. 아이디: ${loginId}`); // 이미 인증된 경우 Alert 메시지 표시
      dispatch(openLoginModal()); // Redux 액션 호출
      navigate("/"); // 메인 페이지로 리다이렉트
    }
  }, [navigate, openLoginModal]);

  return (
    <>
      <div className='w-full'>
        <div className='my-5'>
          <div className=' text-textcolor text-2xl font-bold'>전체게시판</div>
        </div>
        <div>
          <TotalPost />
        </div>
      </div>
    </>
  );
}

export default TotalPostPage;
