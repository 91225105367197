import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AdminSubCommment } from "../../../constants/types";
import { getAllSubComments } from "../../../apis/admin/adminComment";
import { render } from "react-dom";
import Pagination from "../../Common/Pagination";

function AdminSubCommentList() {
  const navigate = useNavigate();
  const [subComments, setSubComments] = useState<AdminSubCommment[]>([]);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);

  const handleCommentClick = (
    boardNumber: number,
    postId: string,
    subCommentId: string
  ) => {
    navigate(`/post/${boardNumber}/${postId}?subCommentId=${subCommentId}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(
      null,
      "",
      `/admin/page/comment/subcomment?page=${page}`
    );
    window.location.reload();
  };

  useEffect(() => {
    getAllSubComments(currentPage)
      .then((res) => {
        setSubComments(res.subComments);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, [currentPage]);

  const renderPostHeader = () => {
    return (
      <thead className='bg-gray-200'>
        <tr>
          <th className='py-3 px-4 text-center'>내용</th>
          <th className='py-3 px-4 text-center'>작성자</th>
          <th className='py-3 px-4 text-center'>조치일</th>
          <th className='py-3 px-4 text-center'>상태</th>
        </tr>
      </thead>
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const renderActionDate = (subComment: AdminSubCommment) => {
    const actionDate =
      subComment.subCommentList.deletedAt ||
      subComment.subCommentList.blockedAt;
    if (!actionDate) return null;
    return formatDate(actionDate);
  };

  const renderSubComment = (subComments: AdminSubCommment[]) => {
    return (
      <tbody>
        {subComments.map((subComment) => (
          <tr
            key={subComment.subCommentList.id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() =>
              handleCommentClick(
                subComment.subCommentList.boardNumber,
                subComment.parentPostId,
                subComment.subCommentList.id
              )
            }>
            <td className='py-[10px] pl-4 flex items-center font-normal'>
              <div className='ml-4'>
                <p className='font-semibold'>
                  {subComment.subCommentList.text}
                </p>
              </div>
              {subComment.subCommentList.image && " (이미지 댓글입니다.)"}
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='text-center font-normal'>
                {subComment.subCommentList.userId.substring(0, 8)}
              </div>
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {renderActionDate(subComment)}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {subComment.subCommentList.isDeleted
                ? "삭제"
                : subComment.subCommentList.isBlocked
                ? "차단"
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>대댓글 관리</h3>
      </div>
      <table className='w-full'>
        {renderPostHeader()}
        {subComments.length > 0 ? (
          renderSubComment(subComments)
        ) : (
          <tbody>
            <tr>
              <td className='py-3 px-4 text-center' colSpan={4}>
                대댓글이 없습니다.
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
}

export default AdminSubCommentList;
