import React, { useState, useEffect } from "react";
import { AdminBoardNameList, useAppSelector } from "../../../constants/types";
import { getBoardName } from "../../../apis/board";
import {
  addAdmin,
  blockUserForever,
  removeAdmin,
  unblockUser,
  addRanker,
  removeRanker,
} from "../../../apis/admin/adminUser";
import { getBoardNames } from "../../../apis/admin/adminBoard";

interface userInfoProps {
  userId: string;
  userCreatedAt: string;
  userIsAdmin: boolean;
  userIsBlocked: boolean;
}

function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options).replace(/\//g, ".");
}

function AdminUserInfo({
  userId,
  userCreatedAt,
  userIsAdmin,
  userIsBlocked,
}: userInfoProps) {
  const formattedDate = formatDate(userCreatedAt);
  const isRanker = useAppSelector((state) => state.auth.isRanker);
  const [rankerBoards, setRankerBoards] = useState<string[]>([]);
  const [admin, setAdmin] = useState<boolean>(userIsAdmin);
  const [blocked, setBlocked] = useState<boolean>(userIsBlocked);
  const [rankerModalIsOpen, setRankerModalIsOpen] = useState<boolean>(false);
  const [activeBoards, setActiveBoards] = useState<AdminBoardNameList[]>([]);

  useEffect(() => {
    if (Object.keys(isRanker).length > 0) {
      const rankerBoardPromises: Promise<string>[] = [];

      for (let boardNum in isRanker) {
        if (Object.prototype.hasOwnProperty.call(isRanker, boardNum)) {
          const value = isRanker[boardNum as keyof typeof isRanker];
          if (value) {
            rankerBoardPromises.push(
              getBoardName(parseInt(boardNum, 10)).then((res) => res.boardName)
            );
          }
        }
      }

      Promise.all(rankerBoardPromises).then((resolvedBoards) => {
        setRankerBoards(resolvedBoards);
      });
    }
  }, [isRanker]);

  useEffect(() => {
    getBoardNames().then((res) => {
      const sortedActiveBoards = res.undeletedBoardNames.sort(
        (a: AdminBoardNameList, b: AdminBoardNameList) =>
          res.order.indexOf(a.boardNumber) - res.order.indexOf(b.boardNumber)
      );
      setActiveBoards(sortedActiveBoards);
    });
  }, []);

  const handleAdminButton = async () => {
    if (admin) {
      if (window.confirm("관리자를 해제하시겠습니까?")) {
        await removeAdmin(userId);
        setAdmin(false);
      }
    } else {
      if (window.confirm("관리자로 임명하시겠습니까?")) {
        await addAdmin(userId);
        setAdmin(true);
      }
    }
  };

  const handleBlockButton = async () => {
    if (blocked) {
      if (window.confirm("유저를 차단 해제하시겠습니까?")) {
        await unblockUser(userId);
        setBlocked(false);
      }
    } else {
      if (window.confirm("유저를 차단하시겠습니까?")) {
        await blockUserForever(userId);
        setBlocked(true);
      }
    }
  };

  const handleBlockToggleButton = () => {
    setRankerModalIsOpen(!rankerModalIsOpen);
  };

  const closeModal = () => {
    setRankerModalIsOpen(false);
  };

  const handleBoardNameClick = async (board: AdminBoardNameList) => {
    const rankerBoardNumber = board.boardNumber;
    if (rankerBoards.includes(board.boardName)) {
      if (
        window.confirm(`"${board.boardName}" 게시판의 랭커를 해제하시겠습니까?`)
      ) {
        await removeRanker(userId, rankerBoardNumber);
        setRankerBoards(rankerBoards.filter((b) => b !== board.boardName));
      }
    } else {
      if (
        window.confirm(`"${board.boardName}" 게시판의 랭커로 임명하시겠습니까?`)
      ) {
        await addRanker(userId, rankerBoardNumber);
        setRankerBoards([...rankerBoards, board.boardName]);
      }
    }
  };

  const renderBoardHeader = () => (
    <thead className="bg-gray-200">
      <tr>
        <th className="py-3 px-4 text-center">게시판 이름</th>
        <th className="py-3 px-4 text-center">랭커 여부</th>
      </tr>
    </thead>
  );

  const renderBoardList = () => (
    <tbody>
      {activeBoards.map((board) => (
        <tr
          key={board.boardNumber}
          onClick={() => handleBoardNameClick(board)}
          className="cursor-pointer hover:bg-[#F8F8F8]">
          <td className="py-3 px-4 text-center">{board.boardName}</td>
          <td className="py-3 px-4 text-center">
            {rankerBoards.includes(board.boardName) ? "O" : "X"}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      <div className="flex items-center text-xl text-[#222222] font-semibold my-5">
        {userId} 님의 프로필
        <div className="ml-2">
          <button
            className={`px-2 py-1 rounded bg-blue-500 text-white hover:bg-blue-700 ${
              blocked ? "bg-red" : "bg-blue-500"
            }`}
            onClick={handleBlockButton}>
            {blocked ? "차단 해제" : "차단"}
          </button>
        </div>
      </div>
      <div className="w-4/5 flex bg-[#FAFAFA] text-textcolor text-base p-3 items-center justify-center">
        <img src="/assets/icon/profile.svg" alt="profile" className="w-16" />
        <div>
          <div className="pl-5 text-sm">가입일 : {formattedDate}</div>
          {rankerBoards.length > 0 ? (
            <div className="pt-2 pl-5 text-xs">
              {`${rankerBoards
                .map((board) => `"${board}" 게시판`)
                .join(", ")} 랭커입니다`}
            </div>
          ) : (
            <div className="pt-2 pl-5 text-xs text-zinc-600">
              랭커가 아닙니다
            </div>
          )}
        </div>
        <div className="mt-3 ml-10">
          <button
            className={`px-2 py-1 rounded text-white hover:bg-blue-700 ${
              admin ? "bg-red" : "bg-blue-500"
            }`}
            onClick={handleAdminButton}>
            {admin ? "관리자 해제" : "관리자 임명"}
          </button>
        </div>
        <div className="mt-3 ml-10">
          <button
            className="px-2 py-1 rounded text-white bg-blue-500 hover:bg-blue-700"
            onClick={handleBlockToggleButton}>
            랭커 임명
          </button>
        </div>
      </div>
      {rankerModalIsOpen && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="relative bg-white p-6 rounded shadow-lg w-96 max-h-[80%] overflow-y-auto">
            <button
              className="absolute top-2 right-2 bg-none border-none text-2xl cursor-pointer"
              onClick={closeModal}>
              &times;
            </button>
            <div className="text-xl font-semibold">랭커 임명</div>
            <div className="text-sm text-gray-500">
              랭커로 임명할 게시판을 선택해주세요
            </div>
            <div className="max-h-[60vh] overflow-y-auto">
              <table className="w-full">
                {renderBoardHeader()}
                {renderBoardList()}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdminUserInfo;
