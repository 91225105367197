import React, { useEffect, useState } from "react";
import { getBoardName } from "../../apis/board";

const parseDate = (dateString: any) => {
  return new Date(dateString);
};

interface AllBoardNameProps {
  toggleBoardName: () => void;
}

function AllBoardName({ toggleBoardName }: AllBoardNameProps) {
  const [boards, setBoards] = useState<[]>([]);

  useEffect(() => {
    getBoardName()
      .then((res) => {
        setBoards(res);
      })
      .catch((err) => {});
  }, []);

  const goToBoard = (boardNumber: number) => {
    window.location.href = `/board/${boardNumber}`;
  };

  const currentDate = new Date();

  const isNewBoard = (createdAt: string): boolean => {
    const boardDate = parseDate(createdAt);
    const diffInMs = currentDate.getTime() - boardDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays < 7;
  };

  return (
    <div className="flex ml-5 text-[#6B6B6B] ">
      <div className="grid grid-rows-4 grid-flow-col gap-4 py-3 pr-4">
        {boards.map((board: any) => (
          <div
            key={board.boardNumber}
            className="flex p-1 text-sm font-medium cursor-pointer hover:text-black hover:bg-gray hover:bg-opacity-10"
            onClick={() => goToBoard(board.boardNumber)}
          >
            {isNewBoard(board.createdAt) && (
              <div className="flex justify-center items-center mr-1">
                <div className="text-hotduk font-semibold items-start">N</div>
              </div>
            )}
            {board.boardName}
            {board.todayPostCount > 0 && (
              <div className="flex justify-center items-center ml-1">
                <div className="text-rankduk font-semibold items-start">
                  +{board.todayPostCount}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className="flex p-3 justify-end cursor-pointer hover:opacity-50"
        onClick={toggleBoardName}
      >
        <img src="/assets/icon/close.svg" alt="close" className="w-3 h-3" />
      </div>
    </div>
  );
}

export default AllBoardName;
