import { useState } from "react";
import AgreementSection from "../../components/NativeLogin/AgreementSection";
import TopLogoBar from "../../components/NativeLogin/TopLogoBar";
import ProgressBar from "../../components/NativeLogin/ProgressBar";
import content from "../../components/NativeLogin/TermsContent";
import { useNavigate } from "react-router-dom";

interface AgreementState {
  terms: boolean;
  privacy: boolean;
}

function TermsAgreementPage() {
  const navigate = useNavigate();
  const [allAgreed, setAllAgreed] = useState(false);
  const [agreements, setAgreements] = useState<AgreementState>({
    terms: false,
    privacy: false,
  });

  // 개별 동의 체크박스 변경 시 호출
  const handleAgreeChange = (key: keyof AgreementState, isAgreed: boolean) => {
    const updatedAgreements = { ...agreements, [key]: isAgreed };
    setAgreements(updatedAgreements);

    // 1번과 2번이 모두 체크되면 3번(모든 약관 동의)을 체크
    setAllAgreed(updatedAgreements.terms && updatedAgreements.privacy);
  };

  // 모든 약관 동의 체크박스 변경 시 호출
  const handleAllAgreeChange = () => {
    const newAllAgreed = !allAgreed;
    setAllAgreed(newAllAgreed);
    setAgreements({
      terms: newAllAgreed,
      privacy: newAllAgreed,
    });
  };

  const handleSubmit = () => {
    if (!agreements.terms || !agreements.privacy) {
      alert(
        "회원가입약관 및 개인정보처리방침 내용에 동의해야 회원가입 하실 수 있습니다."
      );
      return;
    }
    navigate("/signup/verify");
  };

  return (
    <>
      <div className='max-w-3xl mx-auto'>
        <AgreementSection
          title='서비스 이용약관'
          content={content.termsContent}
          onAgreeChange={(isAgreed) => handleAgreeChange("terms", isAgreed)}
          isAgreed={agreements.terms} // 추가: 상태 전달
        />
        <div className='mt-7'></div>
        <AgreementSection
          title='개인정보 수집 및 이용 동의'
          content={content.privacyContent}
          onAgreeChange={(isAgreed) => handleAgreeChange("privacy", isAgreed)}
          isAgreed={agreements.privacy} // 추가: 상태 전달
        />
        <div className='mt-5'></div>
        <label className='flex ml-1 items-center text-sm text-gray-600 mt-4'>
          <input
            type='checkbox'
            checked={allAgreed}
            onChange={handleAllAgreeChange}
            className='mr-2'
          />
          위 모든 약관에 동의합니다
        </label>

        {/* 다음 버튼 */}
        <div className='flex justify-end mt-4'>
          <button
            className='bg-hotduk_light text-white py-2 px-10 rounded-lg'
            onClick={handleSubmit}>
            다음
          </button>
        </div>
      </div>
    </>
  );
}

export default TermsAgreementPage;
