import { axiosInstance, axiosFileInstance } from './instance';

// 단일 투표하기
export const singleVotePoll = async (pollId: string, optionIndex: number) => {
  const response = await axiosInstance.post(`/poll/single-vote/${pollId}`, {
    optionIndex: optionIndex,
  });
  return response.data;
};

// 복수 투표하기
export const multiVotePoll = async (
  pollId: string,
  optionIndices: number[]
) => {
  const response = await axiosInstance.post(`/poll/multiple-vote/${pollId}`, {
    optionIndices: optionIndices,
  });
  return response.data;
};

// 투표 삭제하기
export const deletePoll = async (pollId: string) => {
  const response = await axiosInstance.delete(`/poll/${pollId}`);
  return response.data;
};
