import React, { useEffect, useRef, useState, useCallback } from "react";
import InputField from "./InputField";
import Checkbox from "./Checkbox";
import LinksSection from "./LinksSection";
import SubmitButton from "./SubmitButton";
import NaverBtn from "../Login/NaverBtn";
import KakaoBtn from "../Login/KakaoBtn";
import GoogleBtn from "../Login/GoogleBtn";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../constants/types";
import {
  updateAccessToken,
  updateAdminAdminStatus,
  updateAdminStatus,
  updateBlockStatus,
  updateLoginStatus,
  updateRankerStatus,
  updateUserId,
} from "../../store/authReducer";
import { localLogin } from "../../apis/user";

interface NativeLoginFormProps {
  onClose?: () => void;
}

function NativeLoginForm({ onClose }: NativeLoginFormProps) {
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateUserStatus = useCallback(
    (res: any) => {
      const accessToken = res.accessToken;
      const userId = res.userId;
      const isAdmin = res.isAdmin;
      const isAdminAdmin = res.isAdminAdmin;
      const isRanker = res.isRanker;
      const isBlocked = res.isBlocked;
      dispatch(updateLoginStatus(true));
      dispatch(updateAccessToken(accessToken));
      dispatch(updateUserId(userId));
      dispatch(updateAdminStatus(isAdmin));
      dispatch(updateAdminAdminStatus(isAdminAdmin));
      dispatch(updateRankerStatus(isRanker));
      dispatch(updateBlockStatus(isBlocked));
    },
    [dispatch]
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // 로그인 처리 로직

    if (!loginId || !password) {
      alert("아이디와 비밀번호를 입력해주세요");
      return;
    }

    localLogin(loginId, password)
      .then((res) => {
        updateUserStatus(res);
        if (onClose) onClose();
        alert("로그인 성공");
      })
      .catch((err) => {
        // 에러 응답의 상태 코드와 메시지를 확인하여 alert로 표시
        if (err.response) {
          const status = err.response.status;
          const message = err.response.data.message;

          if (loginId === undefined || password === undefined) {
            alert("아이디와 비밀번호를 입력해주세요.");
          }
          if (status === 404 && message === "User not found") {
            alert("아이디와 비밀번호를 다시 확인해주세요.");
          } else if (status === 404 && message === "Password doesn't exist") {
            alert("아이디와 비밀번호를 다시 확인해주세요.");
          } else if (
            status === 400 &&
            message === "LoginId or password is not correct"
          ) {
            alert("아이디와 비밀번호를 다시 확인해주세요.");
          } else {
            alert("로그인에 실패했습니다. 다시 시도해주세요.");
          }
        } else {
          alert("서버에 문제가 발생했습니다. 잠시 후 다시 시도해주세요.");
        }
      });
  };

  const goToMain = () => {
    navigate(`/`);
  };

  return (
    <form onSubmit={handleSubmit} className='space-y-4 max-w-sm mx-auto'>
      <div className='flex justify-center items-center'></div>
      <InputField
        type='text'
        value={loginId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLoginId(e.target.value)
        }
        placeholder='아이디'
      />
      <InputField
        type='password'
        value={password}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setPassword(e.target.value)
        }
        placeholder='비밀번호'
      />

      <SubmitButton text='로그인' />
      <div className='flex items-center justify-between'>
        <Checkbox
          label='로그인 유지'
          checked={rememberMe}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setRememberMe(e.target.checked)
          }
        />
        <LinksSection />
      </div>

      <p className='flex items-center justify-center my-8 text-gray'>
        <span className='flex-grow border-t border-gray mr-6'></span>
        소셜 계정 로그인{" "}
        <span className='flex-grow border-t border-gray ml-6'></span>
      </p>

      <div className='justify-center'>
        <div className='my-3'>
          <NaverBtn />
        </div>
        <div className='my-3'>
          <KakaoBtn />
        </div>
        <div className='my-3'>
          <GoogleBtn />
        </div>
      </div>
    </form>
  );
}

export default NativeLoginForm;
