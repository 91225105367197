import React, { useEffect, useState } from "react";
import { getBoardPostsInProgress } from "../../apis/board";
import CarouselCard from "./CarouselCard";
import MultiCarousel from "./MultiCarousel";

export default function CarouselPosts() {
  // const posts = await getBoardPostsInProgress(1);

  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    async function fetchPosts() {
      const fetchedPosts = await getBoardPostsInProgress(1);
      setPosts(fetchedPosts);
    }
    fetchPosts();
  }, []);

  if (!posts) {
    return null;
  }

  return (
    <section className="my-1">
      <MultiCarousel>
        {posts.map((post) => (
          <CarouselCard key={post.id} post={post} />
        ))}
      </MultiCarousel>
    </section>
  );
}
