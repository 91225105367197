import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBoardPost } from "../../apis/board";
import { useAppSelector, voteBoardDetailData } from "../../constants/types";
import HTMLReactParser from "html-react-parser";
import LikeVoteBoard from "./LikeVoteBoard";

interface MobileVoteBoardDetailProps {
  voteBoardPostId: string;
}

function MobileVoteBoardDetail({
  voteBoardPostId,
}: MobileVoteBoardDetailProps) {
  const navigate = useNavigate();
  const [post, setPost] = useState<voteBoardDetailData | null>(null);
  const userId = useAppSelector((state) => state.auth.userId);

  useEffect(() => {
    getBoardPost(voteBoardPostId)
      .then((res) => {
        setPost(res);
      })
      .catch((err) => {});
  }, [voteBoardPostId]);

  const getTimeDifference = (expiredAt: string) => {
    const expirationTime = new Date(expiredAt).getTime();
    const currentTime = Date.now();
    const timeDifference = expirationTime - currentTime;

    if (timeDifference <= 0) {
      return "투표종료";
    } else if (timeDifference < 3600000) {
      // Less than 1 hour
      return `${Math.floor(timeDifference / 60000)}분 전`;
    } else if (timeDifference < 86400000) {
      // Less than 24 hours
      return `${Math.floor(timeDifference / 3600000)}시간 전`;
    } else {
      const dDay = Math.ceil(timeDifference / 86400000);
      return `D-${dDay}`;
    }
  };

  const getVoteUpDifference = (voteUpCount: number) => {
    if (voteUpCount < 30) {
      return `추가까지 ${30 - voteUpCount}표`;
    }
    return "추가 성공!";
  };

  const goToBoard = () => {
    navigate(`/board/voteboard`);
    window.scrollTo(0, 0);
  };

  if (!post) {
    return <div>게시판 투표 글 로딩중</div>;
  }
  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")} ${dateTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateTime.getMinutes().toString().padStart(2, "0")}`;

  return (
    <>
      <div
        className="px-2 text-textcolor text-2xl font-bold my-5"
        onClick={goToBoard}
      >
        게시판 추가 투표
      </div>
      <div className="flex flex-col w-full">
        <div className=" bg-[#F4F4F4] px-2 py-3 border-y border-[#A4A4A4]">
          <div className="flex pb-1 items-center">
            <div className="flex text-textcolor text-lg font-semibold">
              {post.title}
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="text-xs flex items-center">
                [{getTimeDifference(post.expiredAt)},{" "}
                {getVoteUpDifference(post.voteUpCount)}]
              </div>
            </div>
            <div className="flex items-center">
              <div className="flex text-xs pr-1 text-[#6B6B6B] font-medium items-center">
                익명
              </div>
              <div className=" pr-1 border-l border-[#898989] h-3 items-center"></div>
              <div className="flex text-[#898989] text-xs items-center font-normal pr-1">
                {formattedDate}
              </div>
              <div className=" pr-1 border-l border-[#898989] h-3 items-center"></div>
              <div className="flex items-center">
                <div className="text-[#6B6B6B] text-xs font-normal mr-[3px]">
                  조회수
                </div>
                <div className="text-[#6B6B6B] text-xs font-normal">
                  {post.viewCount}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5 text-base text-textcolor px-3">
          <div className="break-words text-lg pb-2 font-semibold">
            게시판 이름 : "{post.boardName}"
          </div>
          <div className=" break-words">{HTMLReactParser(post.text)}</div>
        </div>
        <div className="border border-zinc-500 mx-auto px-7 pb-4">
          <LikeVoteBoard
            postId={voteBoardPostId}
            voteUpCount={post.voteUpCount}
          />
        </div>
      </div>
    </>
  );
}
export default MobileVoteBoardDetail;
