import React, { useState } from "react";
import ProfileUserPost from "./ProfileUserPost";
import ProfileUserComment from "./ProfileUserComment";
import ProfileUserBookmark from "./ProfileUserBookmark";
import MobileProfileUserPost from "./MobileProfileUserPost";
import MobileProfileUserComment from "./MobileProfileUserComment";
import MobileProfileUserBookmark from "./MobileProfileUserBookmark";

function MobileUserHistory() {
  const [showUserPost, setShowUserPost] = useState<boolean>(true);
  const [showUserComment, setShowUserComment] = useState<boolean>(false);
  const [showUserBookmark, setShowUserBookmark] = useState<boolean>(false);

  const togglePosts = () => {
    setShowUserPost(true);
    setShowUserComment(false);
    setShowUserBookmark(false);
  };

  const toggleComments = () => {
    setShowUserPost(false);
    setShowUserComment(true);
    setShowUserBookmark(false);
  };

  const toggleBookmarks = () => {
    setShowUserPost(false);
    setShowUserComment(false);
    setShowUserBookmark(true);
  };

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex py-5 mb-2">
          <button
            className={`text-base mr-5 pb-3 ${
              showUserPost
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : " text-[#AEAEAE] font-semibold"
            }`}
            onClick={togglePosts}
          >
            작성 글 목록
          </button>
          <button
            className={`text-base pb-3 ${
              showUserComment
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : "text-[#AEAEAE] font-semibold"
            }`}
            onClick={toggleComments}
          >
            작성 댓글 목록
          </button>
          <button
            className={`text-base ml-5 pb-3 ${
              showUserBookmark
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : "text-[#AEAEAE] font-semibold"
            }`}
            onClick={toggleBookmarks}
          >
            북마크 목록
          </button>
        </div>
        <div className="w-full">
          {showUserPost && <MobileProfileUserPost />}
          {showUserComment && <MobileProfileUserComment />}
          {showUserBookmark && <MobileProfileUserBookmark />}
        </div>
      </div>
    </>
  );
}

export default MobileUserHistory;
