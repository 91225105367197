import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  isLogin: boolean;
  accessToken: string;
  routeHistory: string;
  userId: string;
  isAdmin: boolean;
  isRanker: { [key: string]: boolean };
  loginRedirectUrl: string | null;
  isBlocked: boolean;
}

const authReducer = createSlice({
  name: "auth",
  initialState: {
    isLogin: false,
    accessToken: "",
    routeHistory: "",
    userId: "",
    isAdmin: false,
    isAdminAdmin: false,
    isRanker: {},
    loginRedirectUrl: null as string | null,
    isBlocked: false,
  },
  reducers: {
    updateLoginStatus: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    updateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    updateRouteHistory: (state, action: PayloadAction<string>) => {
      state.routeHistory = action.payload;
    },
    updateUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    updateAdminStatus: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    updateAdminAdminStatus: (state, action: PayloadAction<boolean>) => {
      state.isAdminAdmin = action.payload;
    },
    updateRankerStatus: (
      state,
      action: PayloadAction<{ [key: string]: boolean }>
    ) => {
      state.isRanker = action.payload;
    },
    updateLoginRedirectUrl: (state, action: PayloadAction<string | null>) => {
      state.loginRedirectUrl = action.payload;
    },
    clearLoginRedirectUrl: (state) => {
      state.loginRedirectUrl = null;
    },
    updateBlockStatus: (state, action: PayloadAction<boolean>) => {
      state.isBlocked = action.payload;
    },
  },
});

export const {
  updateLoginStatus,
  updateAccessToken,
  updateRouteHistory,
  updateUserId,
  updateAdminStatus,
  updateAdminAdminStatus,
  updateRankerStatus,
  updateLoginRedirectUrl,
  clearLoginRedirectUrl,
  updateBlockStatus,
} = authReducer.actions;

export default authReducer;
