import { useEffect, useState, useRef } from "react";
import { getNiceToken } from "../../apis/user";

interface PhoneVerifyProps {
  type: "signup" | "reset";
}

function PhoneVerify({ type }: PhoneVerifyProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const [authResult, setAuthResult] = useState<{
    message: string;
    isExistingUser: boolean;
  } | null>(null);

  const onClickCertify = async () => {
    try {
      const result = await getNiceToken(type);
      const token_version_id = result.tokenVersionId;
      const enc_data = result.encData;
      const integrity_value = result.integrityValue;

      if (!token_version_id || !enc_data || !integrity_value) return;

      if (formRef.current) {
        // 폼 필드에 데이터 설정
        (
          formRef.current.elements.namedItem(
            "token_version_id"
          ) as HTMLInputElement
        ).value = token_version_id;
        (
          formRef.current.elements.namedItem("enc_data") as HTMLInputElement
        ).value = enc_data;
        (
          formRef.current.elements.namedItem(
            "integrity_value"
          ) as HTMLInputElement
        ).value = integrity_value;

        // 현재 창에서 인증 페이지로 리다이렉트하여 인증창 띄우기
        formRef.current.submit();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='mt-10 flex justify-center' onClick={onClickCertify}>
      <div className='w-full max-w-xs aspect-square flex flex-col border border-gray items-center rounded-md justify-center'>
        {/* 가운데 정렬을 위한 flex 설정 */}
        <img
          src='/assets/icon/phone_icon.svg'
          alt='휴대폰 아이콘'
          className='w-60'
        />
        <span className='w-[60%] border-t border-gray'></span>{" "}
        {/* 아이콘과 텍스트 중간의 선 */}
        <p className='py-2 mt-4 mb-4 text-center'>휴대폰 인증</p>
      </div>

      <form
        ref={formRef}
        name='form'
        id='form'
        action='https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb'>
        <input type='hidden' id='m' name='m' value='service' />
        <input
          type='hidden'
          id='token_version_id'
          name='token_version_id'
          value=''
        />
        <input type='hidden' id='enc_data' name='enc_data' value='' />
        <input
          type='hidden'
          id='integrity_value'
          name='integrity_value'
          value=''
        />
      </form>
    </div>
  );
}

export default PhoneVerify;
