import { useParams, useSearchParams } from "react-router-dom";
import PostDetail from "../../components/Posts/PostDetail";
import BoardMain from "../../components/Main/BoardMain";
import { useAppSelector } from "../../constants/types";
import { useEffect } from "react";

function DetailPage() {
  const [searchParams] = useSearchParams();
  const { postId } = useParams<{ postId: string }>();
  const page = searchParams.get("page");
  const { boardNumber } = useParams<{ boardNumber: string }>();
  const isTwitterReferrer = useAppSelector(
    (state) => state.twitterReferrer.isTwitterReferrer
  );
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  useEffect(() => {
    if (isTwitterReferrer && !isLogin) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isTwitterReferrer, isLogin]);

  if (!boardNumber) {
    return <div>boardNumber Loading...</div>;
  }
  const parseBoardNumber = parseInt(boardNumber);

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  return (
    <>
      <div className=" w-full mt-10">
        <PostDetail postId={postId} boardNumber={parseBoardNumber} />
        <div className="mt-24">
          <BoardMain boardNumber={parseBoardNumber} />
        </div>
      </div>
    </>
  );
}

export default DetailPage;
