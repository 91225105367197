import React from "react";

interface PaginationProps {
  currentPage: number;
  totalPage: number;
  onPageChange: (page: number) => void;
}

function Pagination({ currentPage, totalPage, onPageChange }: PaginationProps) {
  const currentPageUnit = Math.ceil(currentPage / 10);
  const startPage = (currentPageUnit - 1) * 10 + 1;
  const endPage = Math.min(startPage + 9, totalPage);
  const pageButtons = [];
  for (let pageNumber = startPage; pageNumber <= endPage; pageNumber++) {
    pageButtons.push(
      <button
        key={pageNumber}
        onClick={() => onPageChange(pageNumber)}
        className={`m-2 items-center justify-center ${
          currentPage === pageNumber
            ? "text-white bg-[#333333] rounded-full font-normal text-sm w-8 h-8 items-center justify-center"
            : "text-[#222222] font-normal text-sm"
        }`}
      >
        {pageNumber}
      </button>
    );
  }

  return (
    <>
      <div className="mx-auto justify-center items-center flex">
        <button onClick={() => onPageChange(Math.max(1, currentPage - 10))}>
          <img
            src="/assets/icon/nextnext.svg"
            alt="last"
            className="scale-x-[-1] w-2"
          />
        </button>
        <button onClick={() => onPageChange(Math.max(1, currentPage - 1))}>
          <img
            src="/assets/icon/next.svg"
            alt="right_arrow"
            className="ml-3 mr-2 scale-x-[-1] w-2"
          />
        </button>
        {pageButtons}
        <button
          onClick={() => onPageChange(Math.min(totalPage, currentPage + 1))}
          className="m-1 p-1"
        >
          {/* todo 얘가 너비가 좁아지면 사라지는데,,,어떻게하면 텍스트처럼 계속 안 사라지게 할 수 있을지..? */}
          <img src="/assets/icon/next.svg" alt="right_arrow" className="w-2" />
        </button>
        <button
          onClick={() =>
            onPageChange(Math.min(totalPage, currentPage + 10, totalPage))
          }
        >
          <img
            className="ml-2 w-2"
            src="/assets/icon/nextnext.svg"
            alt="last"
          />
        </button>
      </div>
    </>
  );
}

export default Pagination;
