import React, { useEffect, useRef, useState } from "react";
import {
  NotificationType,
  useAppDispatch,
  useAppSelector,
} from "../../constants/types";
import {
  deleteNotification,
  getNotifications,
  deleteAllNotification,
  readNotification,
  getNotificationsPagination,
} from "../../apis/notification";
import { useNavigate } from "react-router-dom";
import { EventSourcePolyfill } from "event-source-polyfill";
import { SERVER_URL } from "../../constants/url";
import {
  addNotification,
  removeNotification,
  setNotifications,
} from "../../store/notificationSlice";

interface NotificationProps {
  toggleNotification: () => void;
}

function Notification({ toggleNotification }: NotificationProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const notifications = useAppSelector(
    (state) => state.notification.notifications
  );
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const eventSourceRef = useRef<EventSource | null>(null);
  const isConnectedRef = useRef<boolean>(false);
  const [nowNotifications, setNowNotifications] = useState<NotificationType[]>(
    []
  );
  const unreadNotifications = useAppSelector(
    (state) => state.notification.unreadNotifications
  );

  useEffect(() => {
    setNowNotifications(notifications.slice().reverse().slice(0, 10));
  }, [notifications]);

  useEffect(() => {
    if (accessToken && isLogin) {
      const headers: Record<string, string> = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
      };

      const url = `${SERVER_URL}/api/notification/sse`;
      eventSourceRef.current = new EventSourcePolyfill(url, {
        withCredentials: true,
        headers,
        heartbeatTimeout: 1800000,
      });

      eventSourceRef.current.onopen = () => {
        isConnectedRef.current = true;
      };

      eventSourceRef.current.onerror = (error: any) => {
        if (!isConnectedRef.current) {
          eventSourceRef.current = new EventSourcePolyfill(url, {
            withCredentials: true,
            headers,
          });
        }
      };

      eventSourceRef.current.addEventListener("message", (event: any) => {
        const notificationData = JSON.parse(event.data);
        dispatch(addNotification(notificationData));
      });

      getNotifications().then((data) => {
        dispatch(setNotifications(data.notifications));
      });

      // return () => {
      //   if (eventSourceRef.current) {
      //     eventSourceRef.current.close();
      //   }
      //   isConnectedRef.current = false;
      // };
    }
  }, [accessToken, dispatch, isLogin]);

  const fetchNotifications = async (notiId: string, direction: string) => {
    try {
      const data = await getNotificationsPagination(notiId, direction);
      if (data.notifications && data.notifications.length > 0) {
        dispatch(setNotifications(data.notifications));
      }
    } catch (error) {}
  };

  const handlePrevPage = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    if (notifications.length > 0) {
      const latestNotiId = nowNotifications[nowNotifications.length - 1]._id;
      fetchNotifications(latestNotiId, "prev");
    }
  };

  const handleNextPage = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    if (notifications.length > 0) {
      const oldestNotiId = nowNotifications[nowNotifications.length - 1]._id;
      fetchNotifications(oldestNotiId, "next");
    }
  };

  const goToNotificatedPost = (
    boardNumber: number,
    postId: string,
    notiId: string
  ) => {
    // 알림이 가리키는 게시물로 즉시 이동
    navigate(`/post/${boardNumber}/${postId}`);

    // 알림 읽음 처리
    readNotification(notiId)
      .then((res) => {
        // 알림 읽음 처리 완료 후 새로운 알림 데이터 가져오기
        return getNotifications();
      })
      .then((data) => {
        // 스토어에 새로운 알림 데이터 저장
        dispatch(setNotifications(data.notifications));
      })
      .catch((error) => {});
  };

  const handleDeleteNotification = (notiId: string) => {
    deleteNotification(notiId).then(() => {
      dispatch(removeNotification(notiId));

      const currentNotifications = nowNotifications.filter(
        (notification) => notification.notiId !== notiId
      );
      if (currentNotifications.length < 10) {
        setNowNotifications(notifications.slice().reverse().slice(0, 10));

        if (notifications.length < 10) {
          getNotifications().then((data) => {
            dispatch(setNotifications(data.notifications));
          });
        }
      }
    });
  };

  const handleDeleteAllNotifications = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    try {
      await deleteAllNotification();
      dispatch(setNotifications([]));
    } catch (error) {}
  };

  if (!isLogin) {
    return null;
  }

  return (
    <div onClick={toggleNotification}>
      <div className="p-1 w-full">
        <div className="flex text-hotduk text-xs py-1 mr-2 font-semibold">
          <div className="ml-auto">새로운 알림</div>
        </div>
        {nowNotifications.length > 0 ? (
          nowNotifications.map((notification: NotificationType) => (
            <div
              key={notification.notiId}
              className={`flex justify-between items-center py-[5px] text-xs font-medium cursor-pointer hover:bg-gray hover:bg-opacity-20 ${
                unreadNotifications.includes(notification.notiId)
                  ? "opacity-100"
                  : "opacity-50"
              }`}
              onClick={() =>
                goToNotificatedPost(
                  notification.boardNumber,
                  notification.postId,
                  notification.notiId
                )
              }
            >
              <div className="w-44 px-1">
                <div className="w-full whitespace-normal">
                  <span className="text-[#939393] font-semibold pr-1">
                    {notification.notiType === "comment" && "새 댓글"}
                    {notification.notiType === "subComment" && "새 대댓글"}
                    {notification.notiType === "votedUp" && "게시글"}
                  </span>
                  {notification.notiType === "votedUp" ? (
                    <>
                      <span className="text-textcolor font-bold">
                        [
                        {notification.postTitle &&
                        notification.postTitle.length > 8
                          ? notification.postTitle.slice(0, 8) + "..."
                          : notification.postTitle}
                        ]
                      </span>
                      <div className="text-textcolor">
                        게시물이 추천되었습니다
                      </div>
                    </>
                  ) : (
                    <span className="text-textcolor">
                      {notification.commentContent &&
                      notification.commentContent.length > 20
                        ? notification.commentContent.slice(0, 20) + "..."
                        : notification.commentContent}
                    </span>
                  )}
                </div>
              </div>
              <div
                className="flex pl-2 pr-1"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteNotification(notification.notiId);
                }}
              >
                <img
                  src="/assets/icon/close.svg"
                  alt="close"
                  className="w-2 h-2"
                />
              </div>
            </div>
          ))
        ) : (
          <div className="flex justify-center items-center text-xs font-medium py-1">
            알림이 존재하지 않습니다.
          </div>
        )}
      </div>
      {nowNotifications.length > 0 && (
        <div className="flex justify-between items-center m-1">
          <button
            className="p-1 text-[11px] text-[#939393] hover:bg-gray hover:bg-opacity-20 hover:opacity-70"
            onClick={handleDeleteAllNotifications}
          >
            알림 전체삭제
          </button>

          <div className="flex">
            <div className="p-1 cursor-pointer mr-1" onClick={handlePrevPage}>
              <img
                src="/assets/icon/notification_prev.svg"
                alt="prev"
                className=" h-3 "
              />
            </div>
            <div className="p-1 cursor-pointer" onClick={handleNextPage}>
              <img
                src="/assets/icon/notification_next.svg"
                alt="next"
                className=" h-3 "
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Notification;
