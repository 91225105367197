import { axiosInstance, axiosFileInstance } from './instance';

// 댓글 조회
export const getComments = async (postId: string) => {
  const response = await axiosInstance.get(`/comment/${postId}`);
  return response.data;
};

// 댓글 작성
export const createComment = async (postId: string, content: string) => {
  const response = await axiosInstance.post(`/comment/${postId}`, content);
  return response.data;
};

// 댓글 좋아요
export const voteUpComment = async (commentId: string) => {
  const response = await axiosInstance.post(`/comment/voteUp/${commentId}`);
  return response.data;
};

// 댓글 싫어요
export const voteDownComment = async (commentId: string) => {
  const response = await axiosInstance.post(`/comment/voteDown/${commentId}`);
  return response.data;
};

// 댓글 삭제
export const deleteComment = async (commentId: string) => {
  const response = await axiosInstance.delete(`/comment/${commentId}`);
  return response.data;
};

// 댓글 이미지 업로드
export const uploadCommentImage = async (file: any) => {
  const formData = new FormData();
  formData.append('image', file);
  const response = await axiosFileInstance.post(
    `/comment/uploadImage`,
    formData
  );
  return response.data.url;
};

// 댓글 수정
export const editComment = async (commentId: string, content: string) => {
  const response = await axiosInstance.post(
    `/comment/edit/${commentId}`,
    content
  );
  return response.data;
};

// 대댓글 수정
export const editSubComment = async (
  commentId: string,
  subCommentId: string,
  content: string
) => {
  const response = await axiosInstance.post(
    `/comment/edit/sub/${commentId}/${subCommentId}`,
    content
  );
  return response.data;
};
