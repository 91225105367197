import { axiosInstance } from "./instance";

// 제목으로 검색
export const searchByTitle = async (searchWord: string, pageNumber: number) => {
  const response = await axiosInstance.get(
    `/search/${pageNumber}?title=${searchWord}`
  );
  return response.data;
};

// 내용으로 검색
export const searchByText = async (searchWord: string, pageNumber: number) => {
  const response = await axiosInstance.get(
    `/search/${pageNumber}?text=${searchWord}`
  );
  return response.data;
};

// 제목 + 내용으로 검색
export const searchByAll = async (searchWord: string, pageNumber: number) => {
  const response = await axiosInstance.get(
    `/search/${pageNumber}?all=${searchWord}`
  );
  return response.data;
};
