import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  const goToPolicy = () => {
    navigate("/policy");
  };

  const goToTermsOfUse = () => {
    navigate("/terms-of-use");
  };

  return (
    <div className="border-t border-header_line py-12 mt-8">
      <div className="flex justify-center items-center">
        <div className="flex px-5">
          <img src="/assets/icon/logo_footer.svg" className="w-24" />
        </div>
        <div className="flex flex-col px-5">
          <div className="flex text-[#757B83] text-xs">
            <div>10duk 소개</div>
            <div className="mx-1 text-[#E1E3E3]">|</div>
            <div onClick={goToTermsOfUse} className=" cursor-pointer">
              이용약관
            </div>
            <div className="mx-1 text-[#E1E3E3]">|</div>
            <div onClick={goToPolicy} className=" cursor-pointer">
              개인정보처리방침
            </div>
            <div className="mx-1 text-[#E1E3E3]">|</div>
            <div>청소년 보호정책</div>
            <div className="mx-1 text-[#E1E3E3]">|</div>
            <div>문의 및 신고</div>
            <div className="mx-1 ">|</div>
            <div>문제 보고</div>
          </div>
          <div className="flex-col text-[#757B83] text-xs">
            문의메일 : 10duk.official@gmail.com
          </div>
          <div className="flex-col text-[#757B83] text-xs">
            ©2023 10duk. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
