import { Outlet, useLocation } from "react-router-dom";
import TopLogoBar from "../../components/NativeLogin/TopLogoBar";
import ProgressBar from "../../components/NativeLogin/ProgressBar";

function SignupLayout() {
  const location = useLocation();

  // 각 페이지의 단계 설정
  const stepMap: Record<string, number> = {
    "/signup/agreement": 0,
    "/signup/verify": 1,
    "/signup/input": 2,
  };

  const currentStep = stepMap[location.pathname] ?? 0;

  return (
    <>
      {" "}
      {/* 공통 배경 및 최소 화면 높이 */}
      <TopLogoBar />
      <div className='pt-20 max-w-3xl mx-auto'>
        <ProgressBar currentStep={currentStep} />
      </div>
      <Outlet /> {/* 자식 컴포넌트가 렌더링될 위치 */}
    </>
  );
}

export default SignupLayout;
