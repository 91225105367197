import React, { useEffect, useState } from 'react';
import { Boards } from '../../constants/types';
import { getBoardName } from '../../apis/board';

const parseDate = (dateString: any) => {
  return new Date(dateString);
};

function MobileHamburger() {
  const [boards, setBoards] = useState<Boards[]>([]);

  useEffect(() => {
    getBoardName()
      .then((res) => {
        setBoards(res);
      })
      .catch((err) => {});
  }, []);

  const goToHotduk = () => {
    window.location.href = '/post/hotduk';
  };
  const goToRankduk = () => {
    window.location.href = '/post/rankduk';
  };
  const goToTotalBoard = () => {
    window.location.href = '/post/totalpost';
  };
  const goToBoard = (boardNumber: number) => {
    window.location.href = `/board/${boardNumber}`;
  };

  const currentDate = new Date();

  const isNewBoard = (createdAt: string): boolean => {
    const boardDate = parseDate(createdAt);
    const diffInMs = currentDate.getTime() - boardDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays < 7;
  };

  //todo: 게시판 이름 몇글자까지?

  return (
    <div className='flex my-2'>
      <div className='flex flex-col'>
        <div
          className='px-5 py-2 text-sm text-hotduk font-semibold'
          onClick={goToHotduk}>
          오늘의 HOT 덕
        </div>
        <div
          className='px-5 py-2 text-sm text-rankduk font-semibold'
          onClick={goToRankduk}>
          오늘의 RANK 덕
        </div>
        <div
          className='px-5 py-2 text-sm text-textcolor font-normal'
          onClick={goToTotalBoard}>
          전체 게시판
        </div>
        {boards.map((board: any) => (
          <div
            key={board.boardNumber}
            className='px-5 py-2 text-sm text-[#6B6B6B] font-normal flex'
            onClick={() => goToBoard(board.boardNumber)}>
            {isNewBoard(board.createdAt) && (
              <div className='flex justify-center items-center mr-1'>
                <div className='text-hotduk font-semibold items-start'>N</div>
              </div>
            )}
            {board.boardName}
            {/*board.todayPostCount > 0 && (
              <div className="flex justify-center items-center ml-1">
                <div className="text-rankduk font-semibold items-start">
                  +{board.todayPostCount}
                </div>
              </div>
            )*/}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MobileHamburger;
