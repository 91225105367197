import React, { useEffect, useState } from "react";
import { getPopularPosts } from "../../apis/post";
import RenderPosts from "../Common/RenderPosts";
import {
  Announcements,
  IntegratedAnnouncements,
  Post,
} from "../../constants/types";
import Pagination from "../Common/Pagination";
import { useParams } from "react-router-dom";

function HotPost() {
  const { pageNumber } = useParams<{ pageNumber: string }>();

  const [posts, setPosts] = useState<Post[]>([]);
  const [integratedAnnouncements, setIntegratedAnnouncements] = useState<
    IntegratedAnnouncements[]
  >([]);
  const [announcements, setAnnouncements] = useState<Announcements[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);

  useEffect(() => {
    getPopularPosts(currentPage)
      .then((res) => {
        setAnnouncements(res.announcements);
        setIntegratedAnnouncements(res.integratedAnnouncements);
        setPosts(res.posts.popularPosts);
        setTotalPage(res.totalPageNumber);
        // if (
        //   currentPage > res.totalPageNumber ||
        //   res === undefined ||
        //   res === null
        // ) {
        //   alert("존재하지 않는 페이지입니다.");
        //   setCurrentPage(res.totalPageNumber);
        //   window.history.pushState(
        //     null,
        //     "",
        //     `/post/hotduk/${res.totalPageNumber}`
        //   );
        // }
        //boardNumb 들어오는거 navigate 전체적으로 다 수정해줘야함 (todo)
      })
      .catch((err) => {});
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const handlePageChange = (page: number) => {
    if (page <= totalPage && page >= 1) {
      setCurrentPage(page);
      window.history.pushState(null, "", `/post/hotduk/${page}`);
      window.location.reload();
    } else {
      alert("존재하지 않는 페이지입니다.");
    }
  };

  return (
    <>
      <div className="flex my-5">
        <div className="flex items-center justify-center text-2xl font-bold">
          오늘의 HOT 덕
          <img
            src="/assets/icon/hot_icon.svg"
            alt="hot_icon"
            className="w-14"
          />
        </div>
      </div>
      <div className="mb-6">
        <RenderPosts
          posts={posts}
          isHot
          announcements={announcements}
          integratedAnnouncements={integratedAnnouncements}
        />
      </div>
      <div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </>
  );
}

export default HotPost;
