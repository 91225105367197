import React from 'react';
import { useAppSelector } from '../../constants/types';
import LoginMain from '../Login/LoginMain';
import NativeLoginForm from '../NativeLogin/NativeLoginForm';

const LoginModal: React.FC = () => {
  const isTwitterReferrer = useAppSelector(
    (state) => state.twitterReferrer.isTwitterReferrer
  );
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  if (!isTwitterReferrer || isLogin) {
    return null;
  }

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      className='pt-20 fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
      onClick={handleModalClick}>
      <div className='bg-white p-4 rounded shadow-lg w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto'>
        <div className='text-center mb-8'>
          <div className='font-semibold text-gray-800'>
            로그인 후 해당 페이지를 볼 수 있습니다.
          </div>
        </div>
        <NativeLoginForm />
      </div>
    </div>
  );
};

export default LoginModal;
