import React, { useState } from 'react';
import { Poll, useAppSelector } from '../../constants/types';
import { multiVotePoll, singleVotePoll } from '../../apis/poll';

interface PollDetailProps {
  pollData: Poll;
  toggleVoteResult: () => void;
  updateHasVotedToPoll: () => void;
}

function PollDetail({
  pollData,
  toggleVoteResult,
  updateHasVotedToPoll,
}: PollDetailProps) {
  const [selectedOptions, setSelectedOptions] = useState<number[]>([]);
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  const handleOptionChange = (index: number) => {
    if (pollData.isMultiSelectEnabled) {
      setSelectedOptions((prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index]
      );
    } else {
      setSelectedOptions([index]);
    }
  };

  const votePoll = async () => {
    if (!isLogin) {
      alert('로그인 후 투표가 가능합니다.');
      setSelectedOptions([]);
      return;
    }

    if (!pollData || selectedOptions.length === 0) {
      alert('투표할 항목을 선택해주세요.');
      return;
    }

    try {
      if (pollData.isMultiSelectEnabled) {
        await multiVotePoll(pollData.id, selectedOptions);
      } else {
        await singleVotePoll(pollData.id, selectedOptions[0]);
      }
      alert('투표가 완료되었습니다.');
      updateHasVotedToPoll();

      // 여기에 투표 후 추가 작업을 수행할 수 있습니다 (예: 상태 업데이트, 페이지 리로드 등)
    } catch (error: any) {
      if (
        error.response.status === 400 &&
        error.response.data.message === 'User has already voted'
      ) {
        alert('이미 투표가 완료되었습니다.');
      } else {
        alert('투표 중 오류가 발생했습니다.');
      }
    }
  };

  const showVoteResult = () => {
    if (!isLogin) {
      alert('로그인 후 결과를 확인할 수 있습니다.');
      return;
    } else {
      toggleVoteResult();
    }
  };

  if (!pollData) {
    return null;
  }

  return (
    <div className='flex flex-col border border-[#D9D9D9] mt-2 w-[507px]'>
      <div className='flex bg-[#F1F1F1] px-4 py-4 items-center'>
        <div>
          <img src='/assets/icon/poll_detail.svg' className='pr-4' />
        </div>
        <div className='flex-col items-center'>
          <div className=' text-base font-semibold text-[#222222]'>
            {pollData.subject}
          </div>
          <div>
            {pollData.isMultiSelectEnabled ? (
              <div className='text-[11px] text-[#666666]'>복수 선택 가능</div>
            ) : (
              <div className='text-[11px] text-[#666666]'>
                한 개만 선택 가능
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=''>
        {pollData.options.map((option, index) => (
          <div
            key={option._id}
            className='flex items-center mt-2 cursor-pointer w-fit px-6 py-1'
            onClick={() => handleOptionChange(index)}
          >
            <div
              className={`w-3 h-3 rounded-full border border-[#6B6B6B] mr-2 flex items-center justify-center`}
            >
              {selectedOptions.includes(index) && (
                <div className='w-1 h-1 rounded-full bg-[#6B6B6B]'></div>
              )}
            </div>
            <label className=' text-[#6B6B6B] text-sm px-2 cursor-pointer'>
              {option.option}
            </label>
          </div>
        ))}
      </div>
      <div className='flex items-center justify-center mt-4'>
        <div className='flex items-center'>
          <button
            type='button'
            className='flex items-center bg-hotduk px-[40px] py-[10px] text-sm font-bold text-white rounded-3xl hover:bg-opacity-70'
            onClick={votePoll}
          >
            투표하기
            <img src='/assets/icon/pollbox.svg' className='pl-2' />
          </button>
        </div>
      </div>
      <button
        className='my-3 text-xs font-semibold text-textcolor underline cursor-pointer'
        onClick={showVoteResult}
      >
        결과 미리보기
      </button>
    </div>
  );
}

export default PollDetail;
