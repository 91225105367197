import CreateAnnouncement from "../../../components/Admin/Announcement/CreateAnnouncement";

function CreateAnnouncementPage() {
  return (
    <>
      <div className="w-full flex">
        <CreateAnnouncement />
      </div>
    </>
  );
}

export default CreateAnnouncementPage;
