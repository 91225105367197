import { axiosFileInstance, axiosInstance } from "./instance";

// 알림 목록 조회
export const getNotifications = async () => {
  const response = await axiosInstance.get(`/notification`);
  return response.data;
};

// 알림 목록 페이지네이션 조회
export const getNotificationsPagination = async (
  notiId: string,
  direction: string
) => {
  const responst = await axiosInstance.get(
    `/notification/page?notiId=${notiId}&direction=${direction}`
  );
  return responst.data;
};

// 알림 삭제
export const deleteNotification = async (notiId: string) => {
  const response = await axiosInstance.delete(`/notification/delete`, {
    data: { notiId },
  });
  return response.data;
};

// 알림 전체 삭제
export const deleteAllNotification = async () => {
  const response = await axiosInstance.delete(`/notification/delete-all`);
  return response.data;
};

// 알림 읽음 처리
export const readNotification = async (notiId: string) => {
  const response = await axiosInstance.post(`/notification/read`, { notiId });
  return response.data;
};
