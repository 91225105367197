import React from "react";
import MobileCreatePost from "../../components/Posts/MobileCreatePost";

function MobileCreatePostPage() {
  return (
    <div className="flex mx-4">
      <MobileCreatePost />
    </div>
  );
}

export default MobileCreatePostPage;
