import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AdminProtectedRoute from './components/Admin/ProtectedRoute/ProtectedRoute';
import AdminLayout from './components/Layout/AdminLayout';

// Pages
import AdminUserPage from './pages/Admin/UserPage/UserPage';
import AdminPostPage from './pages/Admin/PostPage/PostListPage';
import AdminCommentPage from './pages/Admin/CommentListPage/CommentListPage';
import AdminAnnouncementListPage from './pages/Admin/AnnouncementListPage/AnnouncementListPage';
import CreateAnnouncementPage from './pages/Admin/CreateAnnouncementPage/CreateAnnouncementPage';
import EditAnnouncementPage from './pages/Admin/EditAnnouncementPage/EditAnnouncementPage';
import AdminManageBoardPage from './pages/Admin/ManageBoardPage/ManageBoardPage';
import AdminProfilepage from './pages/Admin/ProfilePage/AdminProfilePage';
import AdminCSS from './components/Admin/AdminCSS/AdminCSS';
import MobileCreateAnnouncementPage from './pages/Admin/CreateAnnouncementPage/MobileCreateAnnouncementPage';
import { useMediaQuery } from 'react-responsive';

const AdminRoutes: React.FC = () => {
  const isPc = useMediaQuery({
    query: '(min-width: 768px)',
  });

  const createAnnouncementPageComponent = isPc ? (
    <CreateAnnouncementPage />
  ) : (
    <MobileCreateAnnouncementPage />
  );

  const editAnnouncementPageComponent = isPc ? (
    <EditAnnouncementPage />
  ) : (
    <EditAnnouncementPage />
  );

  return (
    <Routes>
      <Route element={<AdminProtectedRoute />}>
        <Route path='/' element={<AdminLayout />}>
          <Route path='user' element={<AdminUserPage />} />
          <Route path='post' element={<AdminPostPage />} />
          <Route path='comment/*' element={<AdminCommentPage />} />
          <Route path='announcement' element={<AdminAnnouncementListPage />} />
          <Route path='board' element={<AdminManageBoardPage />} />
          <Route path='user/:id' element={<AdminProfilepage />} />
          <Route
            path='announcement/create'
            element={createAnnouncementPageComponent}
          />
          <Route
            path='announcement/edit/:announcementId'
            element={editAnnouncementPageComponent}
          />
        </Route>
      </Route>
      <Route path='/css' element={<AdminCSS />} />
    </Routes>
  );
};

export default AdminRoutes;
