import { useEffect, useState } from "react";
import { searchByAll, searchByText, searchByTitle } from "../../apis/search";
import { Post } from "../../constants/types";
import RenderPosts from "../Common/RenderPosts";
import Pagination from "../Common/Pagination";
import { useParams } from "react-router";

interface SearchResultProps {
  type: string;
  query: string;
}

function SearchResult({ type, query }: SearchResultProps) {
  const { pageNumber } = useParams<{ pageNumber: string }>();
  const [data, setData] = useState<Post[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  useEffect(() => {
    async function fetchData() {
      try {
        let result = [];
        if (type === "all" && query !== "") {
          result = await searchByAll(query, currentPage);
        } else if (type === "text" && query !== "") {
          result = await searchByText(query, currentPage);
        } else if (type === "title" && query !== "") {
          result = await searchByTitle(query, currentPage);
        } else {
          result = [];
        }
        setData(result.posts);
        setTotalPage(result.totalPageNumber);
      } catch (error) {}
    }
    fetchData();
  }, [type, query, currentPage, totalPage]);

  useEffect(() => {
    setCurrentPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(
      null,
      "",
      `/search/${page}?type=${type}&query=${query}`
    );
    window.scrollTo(0, 0);
  };
  return (
    <>
      <div className="text-textcolor text-2xl font-bold my-5">{`"${query}" 검색 결과`}</div>
      {data.length === 0 ? (
        <div className="flex my-16 justify-center items-center">
          <div className="text-hotduk text-lg pr-3 font-semibold">{`"${query}"`}</div>
          <div className="text-textcolor font-medium">
            에 대한 검색 결과가 없습니다.
          </div>
        </div>
      ) : (
        <div className="mb-6">
          <RenderPosts posts={data} />
        </div>
      )}

      <div>
        <Pagination
          currentPage={currentPage}
          totalPage={totalPage}
          onPageChange={(page) => handlePageChange(page)}
        />
      </div>
    </>
  );
}

export default SearchResult;
