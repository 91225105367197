import React from "react";
import { redirectNaver } from "../../apis/user";
import { useAppDispatch } from "../../constants/types";
import { closeLoginModal } from "../../store/loginModalSlice";

function NaverBtn() {
  const dispatch = useAppDispatch();

  const handleNaverLogin = () => {
    redirectNaver();
    dispatch(closeLoginModal()); // 모달 닫기
  };

  return (
    <div className='flex justify-center'>
      <div
        onClick={handleNaverLogin}
        className='flex shadow bg-[#03C75A] rounded-md h-12 w-80  mx-auto cursor-pointer'>
        <img
          src='/assets/naver_logo.png'
          alt='naver-logo'
          className='max-h-12'
        />

        <div className='flex mx-auto'>
          <div className='flex text-white items-center text-[15px] pr-6'>
            네이버 로그인
          </div>
        </div>
      </div>
    </div>
  );
}

export default NaverBtn;
