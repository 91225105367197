import { axiosFileInstance, axiosInstance } from './instance';

// 메인 hot덕 목록 조회
export const getHotDuk = async () => {
  const response = await axiosInstance.get(`/main/popular`);
  return response.data;
};

// 메인 rank덕 목록 조회
export const getRankDuk = async () => {
  const response = await axiosInstance.get(`/main/ranker`);
  return response.data;
};

// 메인 게시판별 글 조회
export const getMainPosts = async (boardNumber: number) => {
  const response = await axiosInstance.get(`/main/board/${boardNumber}`);
  return response.data;
};

// 게시판별 글 목록 조회
export const getPosts = async (boardNumber: number, pageNumber: number) => {
  const response = await axiosInstance.get(
    `/post/board/${boardNumber}/${pageNumber}`
  );
  return response.data;
};

//전체게시판 글 목록 조회
export const getTotalPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/post/all/${pageNumber}`);
  return response.data;
};

// 인기 글 목록 조회
export const getPopularPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/post/popular/${pageNumber}`);
  return response.data;
};

// 랭커 글 목록 조회
export const getRankerPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/post/ranker/${pageNumber}`);
  return response.data;
};

// 글 상세 조회
export const getPost = async (postId: string) => {
  const response = await axiosInstance.get(`/post/${postId}`);
  return response.data;
};

// 글 좋아요
export const voteUpPost = async (postId: string) => {
  const response = await axiosInstance.post(`/post/voteUp/${postId}`);
  return response.data;
};

// 글 싫어요
export const voteDownPost = async (postId: string) => {
  const response = await axiosInstance.post(`/post/voteDown/${postId}`);
  return response.data;
};

// 글 북마크
export const bookmarkPost = async (postId: string) => {
  const response = await axiosInstance.post(`/post/bookmark/${postId}`);
  return response.data;
};

// 글 북마크 삭제
export const deleteBookmarkPost = async (postId: string) => {
  const response = await axiosInstance.delete(`/post/bookmark/${postId}`);
  return response.data;
};

// 글 작성
export const createPost = async (formData: FormData) => {
  const response = await axiosFileInstance.post('/post', formData);
  return response.data;
};

// 이미지 업로드
export const uploadImage = async (formData: FormData) => {
  const response = await axiosFileInstance.post('/post/uploadImage', formData);
  return response.data.url;
};

//임시 이미지 삭제
export const deleteImage = async () => {
  const response = await axiosInstance.delete(`/post/deleteTempImage`);
  return response.data;
};

// 글 삭제
export const deletePost = async (postId: string) => {
  const response = await axiosInstance.delete(`/post/${postId}`);
};

// 수정할 글 조회
export const getEditPost = async (postId: string) => {
  const response = await axiosInstance.get(`/post/edit/${postId}`);
  return response.data;
};

// 글 수정
export const editPost = async (postId: string, formData: FormData) => {
  const response = await axiosFileInstance.post(
    `/post/edit/${postId}`,
    formData
  );
  return response.data;
};

//글 신고
export const reportPost = async (postId: string) => {
  const response = await axiosInstance.post(`/report/post/${postId}`);
  return response.data;
};

//임시저장 글 작성
export const createTempPost = async (formData: FormData) => {
  const response = await axiosFileInstance.post('/tempPost', formData);
  return response.data;
};

//임시저장 글 수정
export const editTempPost = async (postId: string, formData: FormData) => {
  const response = await axiosFileInstance.post(
    `/tempPost/edit/${postId}`,
    formData
  );
  return response.data;
};

//임시저장 수정할 글 조회
export const getEditTempPost = async (postId: string) => {
  const response = await axiosInstance.get(`/tempPost/edit/${postId}`);
  return response.data;
};

//임시저장 글 목록 조회
export const getTempPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get(`/tempPost/all/${pageNumber}`);
  return response.data;
};

//임시저장 글 내용 조회
export const getTempPost = async (postId: string) => {
  const response = await axiosInstance.get(`/tempPost/${postId}`);
  return response.data;
};

//임시저장 선택한 글들 삭제
export const deleteSelectedTempPosts = async (tempPostIds: string[]) => {
  const response = await axiosInstance.delete(`/tempPost/selected`, {
    data: { tempPostIds },
  });
  return response.data;
};

// 실시간 최신 글 여부 조회
export const checkNewPosts = async () => {
  const response = await axiosInstance.get(`/post/check-new`);
  return response.data;
};
