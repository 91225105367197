import { useEffect, useState, useCallback } from "react";
import { getUserInfo } from "../../apis/user";
import UserInfo from "../../components/Profile/UserInfo";
import { User, useAppDispatch } from "../../constants/types";
import UserActivity from "../../components/Profile/UserActivity";
import UserHistory from "../../components/Profile/UserHistory";
import { updateRankerStatus } from "../../store/authReducer";

function Profilepage() {
  const [userInfo, setUserInfo] = useState<User>();
  const dispatch = useAppDispatch();
  const updateUserStatus = useCallback(
    (res: any) => {
      const isRanker = res.user.isRanker;
      dispatch(updateRankerStatus(isRanker));
    },
    [dispatch]
  );

  useEffect(() => {
    getUserInfo()
      .then((res) => {
        setUserInfo(res.user);
        updateUserStatus(res);
      })
      .catch((err) => {});
  }, []);

  if (!userInfo) {
    return <div>loading...</div>;
  }

  return (
    <>
      <div className="w-full">
        <div className=" my-8">
          <UserInfo userCreatedAt={userInfo.createdAt} />
        </div>
        <div className=" my-8">
          <UserActivity
            userPostCount={userInfo.postCount}
            userCommentCount={userInfo.commentCount}
            userVoteUpCount={userInfo.votedUpCount}
            userVoteDownCount={userInfo.votedDownCount}
            userBookmarkedCount={userInfo.bookmarkedCount}
          />
        </div>
        <div className=" my-8">
          <UserHistory />
        </div>
      </div>
    </>
  );
}

export default Profilepage;
