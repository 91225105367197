import { useParams } from "react-router-dom";
import AnnouncementDetail from "../../components/Announcements/AnnouncementDetail";

function AnnouncementDetailPage() {
  const { postId } = useParams<{ postId: string }>();
  const { boardNumber } = useParams<{ boardNumber: string }>();

  if (!boardNumber) {
    return <div>boardNumber Loading...</div>;
  }
  const parseBoardNumber = parseInt(boardNumber);

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  return (
    <>
      <div className=" w-full mt-10">
        <AnnouncementDetail postId={postId} boardNumber={parseBoardNumber} />
      </div>
    </>
  );
}

export default AnnouncementDetailPage;
