import React from "react";

interface UserActivityProps {
  userPostCount: number;
  userCommentCount: number;
  userVoteUpCount: number;
  userVoteDownCount: number;
  userBookmarkedCount: number;
}

function UserActivity({
  userPostCount,
  userCommentCount,
  userVoteUpCount,
  userVoteDownCount,
  userBookmarkedCount,
}: UserActivityProps) {
  const activityItems = [
    {
      src: "/assets/icon/post.svg",
      alt: "post",
      text: "글",
      count: userPostCount,
    },
    {
      src: "/assets/icon/comment_profile.svg",
      alt: "comment_profile",
      text: "댓글",
      count: userCommentCount,
    },
    {
      src: "/assets/icon/thumbsup_profile.svg",
      alt: "thumbsup_profile",
      text: "좋아요",
      count: userVoteUpCount,
    },
    {
      src: "/assets/icon/thumbsdown_profile.svg",
      alt: "thumbsdown_profile",
      text: "싫어요",
      count: userVoteDownCount,
    },
    {
      src: "/assets/icon/bookmark.svg",
      alt: "bookmark",
      text: "북마크",
      count: userBookmarkedCount,
    },
  ];
  return (
    <>
      <div className="text-xl text-[#222222] font-semibold my-5">나의 활동</div>
      <div className="flex bg-[#FAFAFA] text-textcolor py-4 items-center justify-center">
        <div className="flex w-full">
          {activityItems.map((item, index) => (
            <div
              key={index}
              className="flex w-1/5 items-center justify-center "
            >
              <div className="bg-white flex items-center py-2 px-4">
                <img src={item.src} alt={item.alt} className="w-[18px]" />
                <div className="text-xs pl-3 pr-1 pt-1">{item.text}</div>
                <div className="font-semibold text-lg pl-1">{item.count}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default UserActivity;
