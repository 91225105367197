import React from "react";
import { useNavigate } from "react-router";

function VoteBoardRemote() {
  const navigate = useNavigate();
  const goToVoteBoard = () => {
    navigate("/board/voteboard/1");
  };

  return (
    <div>
      <div
        className=" py-2 px-2 border border-[#DDE4EC] bg-vote_board rounded-lg w-[124px] h-44 cursor-pointer hover:opacity-60"
        onClick={goToVoteBoard}
      >
        <div className="pl-1 py-2">
          <img src="/assets/icon/voteboard_remote.svg" alt="voteboard_remote" />
        </div>
        <div className="flex justify-center text-white font-semibold text-base">
          게시판
        </div>
        <div className=" flex justify-center text-white font-semibold text-base">
          투표하러가기
        </div>
      </div>
    </div>
  );
}

export default VoteBoardRemote;
