import React, { useEffect, useState } from "react";
import { AdminPost, useAppSelector } from "../../../constants/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "../../Common/Pagination";
import { Post } from "../../../constants/types";
import { getAdminActionedPosts } from "../../../apis/admin/adminPost";
import RenderPosts from "../../Common/RenderPosts";

function AdminPostList() {
  const navigate = useNavigate();
  const [posts, setPosts] = useState<AdminPost[]>([]);
  const [searchParams] = useSearchParams();
  const pageNumber = searchParams.get("page");
  const [currentPage, setCurrentPage] = useState<number>(
    pageNumber ? parseInt(pageNumber, 10) : 1
  );
  const [totalPage, setTotalPage] = useState<number>(1);

  const handlePostClick = (boardNumber: number, id: string) => {
    navigate(`/post/${boardNumber}/${id}`);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.history.pushState(null, "", `/admin/page/post?page=${page}`);
    window.location.reload();
  };

  useEffect(() => {
    getAdminActionedPosts(currentPage)
      .then((res) => {
        setPosts(res.posts);
        setTotalPage(res.totalPageNumber);
      })
      .catch((err) => {});
  }, []);

  const renderPostHeader = () => {
    return (
      <thead className='bg-gray-200'>
        <tr>
          <th className='py-3 px-4 text-center'>제목</th>
          <th className='py-3 px-4 text-center'>작성자</th>
          <th className='py-3 px-4 text-center'>조회수</th>
          <th className='py-3 px-4 text-center'>추천</th>
          <th className='py-3 px-4 text-center'>조치일</th>
          <th className='py-3 px-4 text-center'>상태</th>
        </tr>
      </thead>
    );
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}.${month}.${day} ${hours}:${minutes}`;
  };

  const renderActionDate = (post: AdminPost) => {
    const actionDate = post.deletedAt || post.blockedAt;
    if (!actionDate) return null;
    return formatDate(actionDate);
  };

  const renderPost = (posts: AdminPost[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post._id}
            className='cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handlePostClick(post.boardNumber, post._id)}>
            <td className='py-3 px-4 flex items-center font-normal'>
              <p className='font-semibold px-2'>
                {" "}
                {post.title.length > 20
                  ? post.title.slice(0, 20) + "..."
                  : post.title}
              </p>
            </td>
            <td className='py-2 px-1 items-center justify-center'>
              <div className='text-center font-normal'>
                {post.userId.slice(0, 8)}
              </div>
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.viewCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.voteUpCount}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {renderActionDate(post)}
            </td>
            <td className='py-2 px-1 text-center font-normal'>
              {post.isDeleted
                ? "삭제"
                : post.isBlocked
                ? "차단"
                : post.isCommentBlocked
                ? "댓글차단"
                : ""}
            </td>
          </tr>
        ))}
      </tbody>
    );
  };

  return (
    <div className='w-full'>
      <div className='mt-5 mb-4 ml-5 text-xl'>
        <h3>게시글 관리</h3>
      </div>
      <table className='w-full'>
        {renderPostHeader()}
        {posts.length > 0 ? (
          renderPost(posts)
        ) : (
          <tbody>
            <tr>
              <td colSpan={4} className='text-center'>
                Loading announcements...
              </td>
            </tr>
          </tbody>
        )}
      </table>
      <Pagination
        currentPage={currentPage}
        totalPage={totalPage}
        onPageChange={(page) => handlePageChange(page)}
      />
    </div>
  );
}

export default AdminPostList;
