import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TwitterReferrerState {
  isTwitterReferrer: boolean;
  redirectUrl: string;
  signupRedirectUrl: string;
}

const initialState: TwitterReferrerState = {
  isTwitterReferrer: false,
  redirectUrl: "",
  signupRedirectUrl: "",
};

const twitterReferrerSlice = createSlice({
  name: "twitterReferrer",
  initialState,
  reducers: {
    setIsTwitterReferrer: (state, action: PayloadAction<boolean>) => {
      state.isTwitterReferrer = action.payload;
    },
    setRedirectUrl: (state, action: PayloadAction<string>) => {
      state.redirectUrl = action.payload;
    },
    clearRedirectUrl: (state) => {
      state.redirectUrl = "";
    },
    setSignupRedirectUrl: (state, action: PayloadAction<string>) => {
      state.signupRedirectUrl = action.payload;
    },
    clearSignupRedirectUrl: (state) => {
      state.signupRedirectUrl = "";
    },
  },
});

export const {
  setIsTwitterReferrer,
  setRedirectUrl,
  clearRedirectUrl,
  setSignupRedirectUrl,
  clearSignupRedirectUrl,
} = twitterReferrerSlice.actions;

export default twitterReferrerSlice.reducer;
