import React from 'react';
import { Poll } from '../../constants/types';

interface MobilePollResultProps {
  pollData: Poll;
  toggleVoteResult: () => void;
  hasVotedToPoll: boolean;
}

function MobilePollResult({
  pollData,
  toggleVoteResult,
  hasVotedToPoll,
}: MobilePollResultProps) {
  return (
    <div className='flex flex-col border border-[#D9D9D9] mt-2 max-w-full'>
      <div className='flex bg-[#F1F1F1] px-3 py-2 items-center'>
        <div>
          <img src='/assets/icon/poll_detail.svg' className='pr-2 h-5' />
        </div>
        <div className='flex-col items-center'>
          <div className=' text-sm font-semibold text-[#222222]'>
            {pollData.subject}
          </div>
          <div>
            {pollData.isMultiSelectEnabled ? (
              <div className='text-[10px] text-[#666666]'>복수 선택 가능</div>
            ) : (
              <div className='text-[10px] text-[#666666]'>
                한 개만 선택 가능
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mb-4'>
        {pollData.options.map((option) => {
          let roundedPercentage = 0;
          if (pollData.totalVoters > 0) {
            const votePercentage = (option.votes / pollData.totalVoters) * 100;
            roundedPercentage = Math.round(votePercentage * 10) / 10; // 소수점 첫째자리까지 반올림
          }

          return (
            <div
              key={option._id}
              className='flex-col items-center my-4 px-6 py-1'
            >
              <div className='flex justify-between'>
                <div className='text-[#6B6B6B] text-sm'>{option.option}</div>
                <div className='text-hotduk text-xs font-semibold'>
                  {roundedPercentage}%
                </div>
              </div>
              <div className='w-full bg-[#D9D9D9] h-[3px] mt-2 rounded-full'>
                <div
                  className='bg-hotduk h-full rounded-full'
                  style={{ width: `${roundedPercentage}%` }}
                ></div>
              </div>
            </div>
          );
        })}
      </div>
      {!hasVotedToPoll && (
        <div className='flex items-center justify-center mb-4'>
          <div className='flex items-center'>
            <button
              type='button'
              className='flex items-center bg-hotduk px-8 py-2 text-sm font-bold text-white rounded-3xl hover:bg-opacity-70'
              onClick={toggleVoteResult}
            >
              <img src='/assets/icon/poll_back.svg' className='pr-2 h-4' />
              투표참여하기
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MobilePollResult;
