import {
  Announcements,
  IntegratedAnnouncements,
  voteBoardDetailData,
} from "../../constants/types";
import { useNavigate } from "react-router-dom";

interface RenderVoteBoardProps {
  posts?: voteBoardDetailData[];
  announcements?: Announcements[];
  integratedAnnouncements?: IntegratedAnnouncements[];
}

function RenderVoteBoard({
  posts,
  announcements,
  integratedAnnouncements,
}: RenderVoteBoardProps) {
  const navigate = useNavigate();

  const getTimeDifference = (expiredAt: string) => {
    const expirationTime = new Date(expiredAt).getTime();
    const currentTime = Date.now();
    const timeDifference = expirationTime - currentTime;

    if (timeDifference <= 0) {
      return "투표종료";
    } else {
      // Calculate the end date
      const endDate = new Date(expirationTime);
      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();
      return `${endYear}년 ${endMonth}월 ${endDay}일 종료`;
    }
  };

  const getVoteUpDifference = (boardName: string, voteUpCount: number) => {
    if (voteUpCount < 30) {
      return `${boardName}게시판 추가까지 ${30 - voteUpCount}표`;
    }
    return `${boardName}게시판 추가 성공!`;
  };

  const handlePostClick = (
    voteBoardPostId: string,
    postExpiredAt: string,
    isExpired: boolean
  ) => {
    if (getTimeDifference(postExpiredAt) === "투표종료" || isExpired) {
      alert("투표가 종료된 게시물입니다.");
      return;
    }
    navigate(`/board/voteboard/voteboardpost/${voteBoardPostId}`);
  };

  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  if (!posts) {
    return (
      <table className="w-full table-fixed mx-auto text-textcolor">
        <thead>
          <tr className="bg-[#FAFAFA] text-sm font-semibold">
            <th className="w-7/12 py-[10px]">제목</th>
            <th className=" py-[10px]"> 글쓴이</th>
            <th className=" py-[10px]"> 조회수</th>
            <th className=" py-[10px]"> 추천</th>
            <th className="w-2/12 py-[10px]"> 작성일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>게시물이 존재하지 않습니다.</td>
          </tr>
        </tbody>
      </table>
    );
  }

  const voteBoardPosts = (posts: voteBoardDetailData[]) => {
    return (
      <tbody>
        {posts.map((post) => (
          <tr
            key={post.id}
            className="cursor-pointer text-sm items-center border-b border-[#FBF3FF] hover:bg-[#F8F8F8]"
            onClick={() =>
              handlePostClick(post.id, post.expiredAt, post.isExpired)
            }
          >
            {getTimeDifference(post.expiredAt) === "투표종료" ||
            post.isExpired ? (
              <td className="py-[10px] pl-2 flex items-center font-normal bg-zinc-200 opacity-40">
                <div className="flex">
                  <div className="pr-1 font-semibold flex items-center">
                    [{getTimeDifference(post.expiredAt)},{" "}
                    {getVoteUpDifference(post.boardName, post.voteUpCount)}]
                  </div>
                </div>
                {post.title.length > 20
                  ? post.title.slice(0, 20) + "..."
                  : post.title}
              </td>
            ) : (
              <td className="py-[10px] pl-2 flex items-center font-normal">
                <div className="flex">
                  <div className="pr-1 font-semibold flex items-center">
                    [{getTimeDifference(post.expiredAt)},{" "}
                    {getVoteUpDifference(post.boardName, post.voteUpCount)}]
                  </div>
                </div>
                {post.title.length > 20
                  ? post.title.slice(0, 20) + "..."
                  : post.title}
              </td>
            )}
            {getTimeDifference(post.expiredAt) === "투표종료" ||
            post.isExpired ? (
              <td className="py-2 px-1 items-center justify-center bg-zinc-200 opacity-40">
                {post.ranker ? (
                  <div className="flex justify-center items-center">
                    <img
                      src="/assets/icon/ranker_crown.svg"
                      alt="ranker_crown"
                      className="w-5 pr-1"
                    />
                    <p className="flex font-normal">익명</p>
                  </div>
                ) : (
                  <div className="pl-5 text-center font-normal">익명</div>
                )}
              </td>
            ) : (
              <td className="py-2 px-1 items-center justify-center">
                {post.ranker ? (
                  <div className="flex justify-center items-center">
                    <img
                      src="/assets/icon/ranker_crown.svg"
                      alt="ranker_crown"
                      className="w-5 pr-1"
                    />
                    <p className="flex font-normal">익명</p>
                  </div>
                ) : (
                  <div className="pl-5 text-center font-normal">익명</div>
                )}
              </td>
            )}
            {getTimeDifference(post.expiredAt) === "투표종료" ||
            post.isExpired ? (
              <td className="py-2 px-1 text-center font-normal bg-zinc-200 opacity-40">
                {post.viewCount}
              </td>
            ) : (
              <td className="py-2 px-1 text-center font-normal">
                {post.viewCount}
              </td>
            )}
            {getTimeDifference(post.expiredAt) === "투표종료" ||
            post.isExpired ? (
              <td className="py-2 px-1 text-center font-normal bg-zinc-200 opacity-40">
                {post.voteUpCount}
              </td>
            ) : (
              <td className="py-2 px-1 text-center font-normal">
                {post.voteUpCount}
              </td>
            )}
            {getTimeDifference(post.expiredAt) === "투표종료" ||
            post.isExpired ? (
              <td className="py-2 px-1 text-center font-normal bg-zinc-200 opacity-40">
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 3600000
                ) < 24 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt))
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${new Date(new Date(post.createdAt))
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                ) : (
                  <>
                    {Math.floor(
                      (Date.now() - Date.parse(post.createdAt)) / 86400000
                    ) > 364 ? (
                      <div>
                        {`${new Date(
                          new Date(post.createdAt)
                        ).getFullYear()}.${(
                          new Date(new Date(post.createdAt)).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}.${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}{" "}
                      </div>
                    ) : (
                      <div>
                        {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}.${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}{" "}
                      </div>
                    )}
                  </>
                )}
              </td>
            ) : (
              <td className="py-2 px-1 text-center font-normal">
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 3600000
                ) < 24 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt))
                      .getHours()
                      .toString()
                      .padStart(2, "0")}:${new Date(new Date(post.createdAt))
                      .getMinutes()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                ) : (
                  <>
                    {Math.floor(
                      (Date.now() - Date.parse(post.createdAt)) / 86400000
                    ) > 364 ? (
                      <div>
                        {`${new Date(
                          new Date(post.createdAt)
                        ).getFullYear()}.${(
                          new Date(new Date(post.createdAt)).getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}.${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}{" "}
                      </div>
                    ) : (
                      <div>
                        {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                          .toString()
                          .padStart(2, "0")}.${new Date(
                          new Date(post.createdAt)
                        )
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`}{" "}
                      </div>
                    )}
                  </>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <tbody>
        <tr
          key={post.id}
          className="cursor-pointer text-sm items-center border-b border-[#FBF3FF]"
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <td className="py-[10px] pl-2 flex items-center font-normal">
            <div className="flex">
              <img
                src="/assets/icon/notice_label.svg"
                alt="notice_label"
                className="mr-2 w-20 py-1"
              />
            </div>
            <p
              className={`font-semibold px-2 
                  text-[#B355CA]
                `}
            >
              {post.title}
            </p>
          </td>
          <td className="py-2 px-1 items-center justify-center">
            <div className="text-center font-normal">운영자덕</div>
          </td>
          <td className="py-2 px-1 text-center font-normal">
            {post.viewCount}
          </td>
          <td className="py-2 px-1 text-center font-normal">0</td>
          <td className="py-2 px-1 text-center font-normal">
            {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
            24 ? (
              <div>
                {`${new Date(new Date(post.createdAt))
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${new Date(new Date(post.createdAt))
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`}{" "}
              </div>
            ) : (
              <>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 86400000
                ) > 364 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                      new Date(new Date(post.createdAt)).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                ) : (
                  <div>
                    {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const post = posts[0];
    return (
      <tbody>
        <tr
          key={post.id}
          className="cursor-pointer text-sm items-center border-b border-[#FBF3FF]"
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <td className="py-[10px] pl-2 flex items-center font-normal">
            <div className="flex">
              <img
                src="/assets/icon/notice_label.svg"
                alt="notice_label"
                className="mr-2 w-20 py-1"
              />
            </div>
            <p className=" font-semibold px-2">
              {post.title.length > 25
                ? post.title.slice(0, 25) + "..."
                : post.title}
            </p>
          </td>
          <td className="py-2 px-1 items-center justify-center">
            <div className="pl-5 text-center font-normal">익명</div>
          </td>
          <td className="py-2 px-1 text-center font-normal">
            {post.viewCount}
          </td>
          <td className="py-2 px-1 text-center font-normal">0</td>
          <td className="py-2 px-1 text-center font-normal">
            {Math.floor((Date.now() - Date.parse(post.createdAt)) / 3600000) <
            24 ? (
              <div>
                {`${new Date(new Date(post.createdAt))
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${new Date(new Date(post.createdAt))
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`}{" "}
              </div>
            ) : (
              <>
                {Math.floor(
                  (Date.now() - Date.parse(post.createdAt)) / 86400000
                ) > 364 ? (
                  <div>
                    {`${new Date(new Date(post.createdAt)).getFullYear()}.${(
                      new Date(new Date(post.createdAt)).getMonth() + 1
                    )
                      .toString()
                      .padStart(2, "0")}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                ) : (
                  <div>
                    {`${(new Date(new Date(post.createdAt)).getMonth() + 1)
                      .toString()
                      .padStart(2, "0")}.${new Date(new Date(post.createdAt))
                      .getDate()
                      .toString()
                      .padStart(2, "0")}`}{" "}
                  </div>
                )}
              </>
            )}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <>
      <table className="w-full table-fixed mx-auto text-textcolor">
        <thead>
          <tr className="bg-[#FAFAFA] text-sm font-semibold">
            <th className="w-7/12 py-[10px]">제목</th>
            <th className=" py-[10px]"> 글쓴이</th>
            <th className=" py-[10px]"> 조회수</th>
            <th className=" py-[10px]"> 추천</th>
            <th className=" py-[10px]"> 작성일</th>
          </tr>
        </thead>
        {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
          <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
        ) : (
          <></>
        )}
        {announcements && announcements.length !== 0 ? (
          <>{renderAnnouncementPost(announcements)}</>
        ) : (
          <></>
        )}
        {posts && posts.length !== 0 ? <>{voteBoardPosts(posts)}</> : <></>}
      </table>
    </>
  );
}

export default RenderVoteBoard;
