import React, { useState } from "react";
import ProfileUserPost from "../../Profile/ProfileUserBookmark";
import ProfileUserComment from "../../Profile/ProfileUserBookmark";
import ProfileUserBookmark from "../../Profile/ProfileUserBookmark";
import AdminProfileUserBookmark from "./AdminProfileUserBookmark";
import AdminProfileUserComment from "./AdminProfileUserComment";
import AdminProfileUserPost from "./AdminProfileUserPost";

interface AdminUserHistoryProps {
  userId: string;
}

function AdminUserHistory({ userId }: AdminUserHistoryProps) {
  const [showUserPost, setShowUserPost] = useState<boolean>(true);
  const [showUserComment, setShowUserComment] = useState<boolean>(false);
  const [showUserBookmark, setShowUserBookmark] = useState<boolean>(false);

  const togglePosts = () => {
    setShowUserPost(true);
    setShowUserComment(false);
    setShowUserBookmark(false);
  };

  const toggleComments = () => {
    setShowUserPost(false);
    setShowUserComment(true);
    setShowUserBookmark(false);
  };

  const toggleBookmarks = () => {
    setShowUserPost(false);
    setShowUserComment(false);
    setShowUserBookmark(true);
  };

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="flex py-5 mb-2">
          <button
            className={`text-xl mr-4 pb-3 ${
              showUserPost
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : " text-[#AEAEAE] font-semibold"
            }`}
            onClick={togglePosts}>
            작성 글 목록
          </button>
          <button
            className={`text-xl pb-3 ${
              showUserComment
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : "text-[#AEAEAE] font-semibold"
            }`}
            onClick={toggleComments}>
            작성 댓글 목록
          </button>
          <button
            className={`text-xl ml-4 pb-3 ${
              showUserBookmark
                ? "text-[#222222] font-semibold border-b-2 border-hotduk"
                : "text-[#AEAEAE] font-semibold"
            }`}
            onClick={toggleBookmarks}>
            북마크 목록
          </button>
        </div>
        <div className="flex w-full">
          {showUserPost && <AdminProfileUserPost userId={userId} />}
          {showUserComment && <AdminProfileUserComment userId={userId} />}
          {showUserBookmark && <AdminProfileUserBookmark userId={userId} />}
        </div>
      </div>
    </>
  );
}

export default AdminUserHistory;
