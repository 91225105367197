import React, { useState, useEffect, useRef } from "react";
import {
  CommentData,
  Comment,
  useAppSelector,
  useAppDispatch,
} from "../../constants/types";
import { getComments, deleteComment } from "../../apis/comment";
import CreateSubComment from "../SubComments/CreateSubComment";
import SubCommentDetail from "../SubComments/SubCommentDetail";
import { formatAgo } from "../../util/date";
import CreateComment from "./CreateComment";
import { updateLoginRedirectUrl } from "../../store/authReducer";
import { closeLoginModal, openLoginModal } from "../../store/loginModalSlice";

interface CommentDetailProps {
  postId: string;
  commentLength: number;
  boardNumber: number;
  ranker: boolean;
}

function CommetDetail({
  postId,
  commentLength,
  boardNumber,
  ranker,
}: CommentDetailProps) {
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const userId = useAppSelector((state) => state.auth.userId);
  const [openMoreMenu, setOpenMoreMenu] = useState<string | null>(null);
  const [comments, setComments] = useState<Comment[] | null>(null);
  const [selectedCommentIds, setSelectedCommentIds] = useState<string[]>([]);
  const [commentLengthDetail, setCommentLengthDetail] =
    useState<number>(commentLength);
  const [subcommentLengthDetail, setSubcommentLengthDetail] =
    useState<number>(0);
  const dispatch = useAppDispatch();
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);

  useEffect(() => {
    getComments(postId)
      .then((res) => {
        setComments(res);
      })
      .catch((err) => {});
  }, [postId, commentLengthDetail, subcommentLengthDetail]);

  const makeUrlsClickable = (text: string) => {
    return text.replace(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );
  };

  const addComment = () => {
    setCommentLengthDetail(commentLengthDetail + 1);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (openMoreMenu && !(event.target as Element).closest(".relative")) {
        setOpenMoreMenu(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMoreMenu]);

  //댓글 더보기
  const handleCommentMore = (commentId: string) => {
    if (openMoreMenu === commentId) {
      setOpenMoreMenu(null);
    } else {
      setOpenMoreMenu(commentId);
    }
  };

  //댓글 삭제
  const handleDeleteComment = (commentId: string) => {
    const deleteCommentConfirm = window.confirm("댓글을 삭제하시겠습니까?");
    if (deleteCommentConfirm) {
      deleteComment(commentId)
        .then((res) => {
          setCommentLengthDetail(commentLengthDetail - 1);
        })
        .catch((err) => {});
    } else {
      return;
    }
  };

  const handleEditComment = (commentId: string) => {
    const commentToEdit = comments?.find((comment) => comment.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
    }
    setOpenMoreMenu(null);
  };

  const handleEditComplete = (editedComment: any) => {
    setComments(
      (prevComments) =>
        prevComments?.map((comment) =>
          comment.id === editedComment.id ? editedComment : comment
        ) || null
    );
    setEditingCommentId(null);
  };

  const handleReportComment = (commentId: string) => {
    // 댓글 신고 로직 구현
    setOpenMoreMenu(null);
  };

  const handleOpen = () => {
    dispatch(openLoginModal()); // Redux 상태를 변경해 모달 닫기
  };

  //로그인 하지 않을 시 댓글 숨기기
  const handleLoginAlert = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    dispatch(updateLoginRedirectUrl(window.location.pathname));
    handleOpen();
  };

  const toggleCommentVisibility = (commentId: string) => {
    if (selectedCommentIds.includes(commentId)) {
      // 선택되어 있다면, 선택 취소
      setSelectedCommentIds((prevState) =>
        prevState.filter((id) => id !== commentId)
      );
    } else {
      // 선택되지 않았다면, 선택
      setSelectedCommentIds((prevState) => [...prevState, commentId]);
    }
  };

  if (!comments) return <div>댓글이 존재하지 않습니다..</div>;

  return (
    <>
      {isLogin ? (
        <div>
          <div className='mt-5 mb-1 pb-3 flex items-center'>
            <img
              src='/assets/icon/comment.svg'
              alt='comment'
              className='ml-2 w-8 items-center'
            />
            <div className='ml-2 text-hotduk text-3xl font-semibold items-center'>
              {commentLength}
            </div>
            <div className='ml-2 text-textcolor text-xl font-medium items-center'>
              개의 댓글
            </div>
          </div>
          {comments.map((comment) => (
            <div
              key={comment.id}
              className='flex-col border-t border-[#C3C3C3] items-center mt-2'>
              <div className='flex mt-2 mb-1 items-center justify-between'>
                <div className='flex items-center'>
                  <div className='mr-1 text-textcolor text-[13px] font-semibold'>
                    {comment.randomNickname}
                  </div>
                  <div className='text-[#555555] text-xs pl-1'>
                    {formatAgo(comment.createdAt, "ko")}
                    {comment.editedAt && (
                      <span className='text-[#555555] text-xs pl-1'>
                        (수정됨)
                      </span>
                    )}
                  </div>
                </div>
                <div className='flex items-center mr-1'>
                  {!comment.isDeleted && !comment.isBlocked && (
                    <div className='relative'>
                      <div
                        className='cursor-pointer'
                        onClick={() => handleCommentMore(comment.id)}>
                        <img
                          src='/assets/icon/comment_more.svg'
                          alt='comment_more'
                          className='w-6 px-[2px] py-1'
                        />
                      </div>
                      {openMoreMenu === comment.id && (
                        <div className='absolute right-1 top-0 mt-2 p-1 w-[72px] bg-white rounded-md shadow-md z-10'>
                          {userId === comment.userId ? (
                            <>
                              <div
                                className='px-1 py-2 text-xs font-semibold text-hotduk bg-[#FFF4F4] cursor-pointer'
                                onClick={() => handleEditComment(comment.id)}>
                                댓글 수정
                              </div>
                              <div
                                className='px-1 py-2 text-xs font-semibold text-gray cursor-pointer'
                                onClick={() => handleDeleteComment(comment.id)}>
                                댓글 삭제
                              </div>
                            </>
                          ) : (
                            <div
                              className='px-1 py-2 text-xs font-semibold text-gray cursor-pointer'
                              onClick={() => handleReportComment(comment.id)}>
                              댓글 신고
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {editingCommentId === comment.id ? (
                <CreateComment
                  postId={postId}
                  boardNumber={boardNumber}
                  ranker={ranker}
                  addComment={handleEditComplete}
                  initialCommentText={comment.text}
                  initialCommentImage={comment.image}
                  commentId={comment.id}
                  isEditing={true}
                />
              ) : comment.isDeleted || comment.isBlocked ? (
                <div className='flex mb-3 '>
                  <div className='text-[#555555] text-sm pt-1'>
                    삭제된 댓글입니다.
                  </div>
                </div>
              ) : (
                <div className='flex items-center'>
                  <div className='flex-col max-w-[94%] mb-4'>
                    {comment.image && (
                      <div className='mb-2 max-w-full'>
                        <img
                          src={comment.image}
                          alt='Comment Image'
                          className='w-full h-auto max-w-[400px] max-h-[300px] object-contain'
                        />
                      </div>
                    )}
                    <div
                      className='text-textcolor text-sm font-normal'
                      dangerouslySetInnerHTML={{
                        __html: makeUrlsClickable(comment.text),
                      }}></div>
                  </div>
                  <div
                    className='ml-auto'
                    onClick={() => toggleCommentVisibility(comment.id)}>
                    <img
                      src='/assets/icon/replymark.svg'
                      alt='replymark'
                      className='w-8 cursor-pointer hover:opacity-50'
                    />
                  </div>
                </div>
              )}
              <>
                <SubCommentDetail
                  comment={comment}
                  setSubcommentLengthDetail={setSubcommentLengthDetail}
                  subCommentList={comment.subCommentList}
                  key={comment.subCommentList.length}
                />
                {!comment.isDeleted &&
                  selectedCommentIds.includes(comment.id) && (
                    <CreateSubComment
                      commentId={comment.id}
                      boardNumber={comment.boardNumber}
                      ranker={comment.ranker}
                      setSubcommentLengthDetail={setSubcommentLengthDetail}
                    />
                  )}
              </>
            </div>
          ))}
          <CreateComment
            postId={postId}
            boardNumber={boardNumber}
            ranker={ranker}
            addComment={addComment}
          />
        </div>
      ) : (
        <div>
          <div className='mt-5 mb-1 pb-3 flex items-center'>
            <img
              src='/assets/icon/comment.svg'
              alt='comment'
              className='ml-2 w-8 items-center'
            />
            <div className='ml-2 text-hotduk text-3xl font-semibold items-center'>
              {commentLength}
            </div>
            <div className='ml-2 text-textcolor text-xl font-medium items-center'>
              개의 댓글
            </div>
          </div>

          {comments.map((comment) => (
            <div
              key={comment.id}
              className='flex-col border-t border-[#C3C3C3] items-center mt-2'>
              <div className='flex mt-2 mb-1 items-center'>
                <div className='mr-1 text-textcolor text-[13px] font-semibold'>
                  {comment.randomNickname}
                </div>
                <div className='text-[#555555] text-xs pl-1'>
                  {formatAgo(comment.createdAt, "ko")}
                </div>
              </div>
              <div className='flex items-center mb-3'>
                <div className='flex  text-textcolor text-sm font-normal max-w-[94%]'>
                  댓글은{" "}
                  <div
                    className='flex px-1 cursor-pointer bg-[#FFD8DA] bg-opacity-[35%] hover:bg-opacity-10 hover:underline font-bold '
                    onClick={handleLoginAlert}>
                    {" "}
                    로그인
                  </div>
                  후 열람 가능합니다.
                </div>
              </div>
            </div>
          ))}
          <CreateComment
            postId={postId}
            boardNumber={boardNumber}
            ranker={ranker}
            addComment={addComment}
          />
        </div>
      )}
    </>
  );
}

export default CommetDetail;
