function PrivacyPolicyPage() {
  return (
    <div className='my-5 p-4 max-w-2xl mx-auto whitespace-normal'>
      <h1 className='text-4xl font-semibold mb-4'>개인정보 처리방침</h1>

      {/* 서문 */}
      <section>
        <p>
          10duk(이하 "회사")는 「개인정보 보호법」 등 관련 법령을 준수하며,
          이용자의 개인정보 보호와 권익을 보호하기 위해 다음과 같은 처리방침을
          두고 있습니다. 본 개인정보 처리방침은 회사가 제공하는 모든 서비스에
          적용됩니다.
        </p>
      </section>

      {/* 1. 수집하는 개인정보 항목 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          1. 수집하는 개인정보 항목
        </h2>
        <p>
          회사는 회원가입, 서비스 이용 등을 위해 다음과 같은 개인정보를
          수집합니다.
        </p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>일반 회원 가입 시</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>필수 항목: 아이디, 비밀번호, 이메일, 생년월일, 성별, DI값</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>SNS 회원 가입 시</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>필수 항목: 인증된 SNS 계정 ID</li>
          </ul>
        </article>
        <p>
          또한 서비스 이용 과정에서 IP 주소, 쿠키, 방문 일시, 서비스 이용 기록
          등의 정보가 자동으로 생성되어 수집될 수 있습니다.
        </p>
      </section>

      {/* 2. 개인정보의 수집 및 이용 목적 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          2. 개인정보의 수집 및 이용 목적
        </h2>
        <p>회사는 수집한 개인정보를 다음의 목적을 위해 사용합니다.</p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>회원 관리</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>
              본인 확인 절차, 통계 분석, 고객 문의 응대, 불량회원의 부정 이용
              방지 및 비인가 사용 방지
            </li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>서비스 제공</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>뉴스레터 및 공지사항 전달, 맞춤형 서비스 제공</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>고객 지원</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>문의 처리, 불만 처리</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>마케팅 및 광고</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>이벤트 정보 제공, 서비스 이용 통계 분석</li>
          </ul>
        </article>
      </section>

      {/* 3. 개인정보의 보유 및 이용 기간 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          3. 개인정보의 보유 및 이용 기간
        </h2>
        <p>
          당사는 회원 자격이 유지되는 동안 개인정보를 보유하며, 회원 탈퇴 시
          악의적 이용 및 서비스 이용 혼선 방지를 위해 아래와 같이 일정 기간
          보존합니다.
        </p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            회원 탈퇴 시 보존 정보
          </h3>
          <ul className='list-disc ml-5 my-2'>
            <li>
              성별, 생년월일, 이메일: 탈퇴 후 30일간 보존 (서비스 이용 혼선
              방지)
            </li>
            <li>DI값: 탈퇴 후 5년간 보존 (악의적 이용 방지)</li>
          </ul>
        </article>
      </section>

      {/* 4. 개인정보의 파기 절차 및 방법 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          4. 개인정보의 파기 절차 및 방법
        </h2>
        <p>
          회사는 원칙적으로 개인정보의 수집 및 이용 목적이 달성된 후 해당 정보를
          지체 없이 파기합니다.
        </p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>파기 절차</h3>
          <p>
            이용자가 입력한 정보는 목적 달성 후 별도의 DB로 이동하여 내부 방침
            및 기타 관련 법령에 따라 일정 기간 저장된 후 파기됩니다.
          </p>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>파기 방법</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>
              전자적 파일 형태의 정보: 복구 및 재생이 불가능한 방법으로 영구
              삭제
            </li>
            <li>종이 문서: 분쇄하거나 소각을 통해 파기</li>
          </ul>
        </article>
      </section>

      {/* 5. 개인정보의 제3자 제공 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          5. 개인정보의 제3자 제공
        </h2>
        <p>
          회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만,
          다음의 경우에는 예외로 합니다.
        </p>
        <ul className='list-disc ml-5 my-2'>
          <li>이용자가 사전에 동의한 경우</li>
          <li>
            법령의 규정에 의하거나, 수사 목적으로 법령에 정해진 절차와 방법에
            따라 수사기관의 요구가 있는 경우
          </li>
        </ul>
      </section>

      {/* 6. 개인정보 처리의 위탁 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          6. 개인정보 처리의 위탁
        </h2>
        <p>
          회사는 원활한 서비스 제공을 위해 다음과 같이 개인정보 처리 업무를
          위탁하고 있습니다.
        </p>
        <table className='w-full mt-4 border'>
          <thead>
            <tr className='bg-gray-100'>
              <th className='border px-2 py-1'>수탁 업체</th>
              <th className='border px-2 py-1'>위탁 업무 내용</th>
              <th className='border px-2 py-1'>보유 및 이용 기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='border px-2 py-1'>㈜NICE평가정보</td>
              <td className='border px-2 py-1'>본인 확인 서비스</td>
              <td className='border px-2 py-1'>
                해당 업체의 개인정보 처리방침에 따름
              </td>
            </tr>
            <tr>
              <td className='border px-2 py-1'>Google (Gmail API)</td>
              <td className='border px-2 py-1'>이메일 발송 처리</td>
              <td className='border px-2 py-1'>
                해당 업체의 개인정보 처리방침에 따름
              </td>
            </tr>
          </tbody>
        </table>
        <p className='mt-4'>
          회사는 위탁 계약 시 개인정보 보호의 안전을 기하기 위해 관련 법령에
          따라 필요한 사항을 규정하고 있습니다.
        </p>
      </section>

      {/* 7. 이용자 및 법정대리인의 권리와 그 행사 방법 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          7. 이용자 및 법정대리인의 권리와 그 행사 방법
        </h2>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            개인정보 열람 및 정정
          </h3>
          <p>
            이용자는 언제든지 자신의 개인정보를 조회하거나 수정할 수 있습니다.
          </p>
          <ul className='list-disc ml-5 my-2'>
            <li>
              서비스 내 "회원정보 수정" 메뉴를 통해 직접 열람 및 수정이
              가능합니다.
            </li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            동의 철회 및 회원 탈퇴
          </h3>
          <p>
            이용자는 개인정보의 수집 및 이용 동의를 철회하거나 회원 탈퇴를
            요청할 수 있습니다.
          </p>
          <ul className='list-disc ml-5 my-2'>
            <li>서비스 내 "회원 탈퇴" 기능을 통해 직접 탈퇴가 가능합니다.</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>법정대리인의 권리</h3>
          <p>
            만 14세 미만 아동의 법정대리인은 아동의 개인정보 열람, 정정, 삭제를
            요청할 수 있습니다.
          </p>
        </article>
      </section>

      {/* 8. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          8. 개인정보 자동 수집 장치의 설치, 운영 및 거부에 관한 사항
        </h2>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>쿠키의 사용 목적</h3>
          <p>
            이용자에게 개인화되고 맞춤화된 서비스를 제공하기 위해 쿠키를
            사용합니다.
          </p>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            쿠키의 설치 및 운영
          </h3>
          <p>
            이용자는 웹 브라우저 설정을 통해 쿠키 저장을 거부할 수 있습니다.
            쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
            있습니다.
          </p>
        </article>
      </section>

      {/* 9. 행태정보의 수집 및 거부 등에 관한 사항 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          9. 행태정보의 수집 및 거부 등에 관한 사항
        </h2>
        <p>
          회사는 온라인 맞춤형 광고 등을 위해 행태정보를 수집할 수 있습니다.
        </p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>수집 항목</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>방문 이력, 이용 형태, 검색 기록 등</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>수집 목적</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>이용자의 관심 분야에 맞는 맞춤형 서비스 및 광고 제공</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>수집 방법</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>웹 사이트 및 앱 방문 시 자동 수집</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            행태정보 거부 방법
          </h3>
          <p>
            웹 브라우저 설정 또는 모바일 기기 설정에서 쿠키 및 광고 식별자
            수집을 거부할 수 있습니다.
          </p>
        </article>
      </section>

      {/* 10. 개인정보의 안전성 확보 조치 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          10. 개인정보의 안전성 확보 조치
        </h2>
        <p>
          회사는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다.
        </p>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>관리적 조치</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>개인정보 보호 책임자 지정, 직원 교육 등</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>기술적 조치</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>개인정보의 암호화, 접근 통제 시스템 운영</li>
          </ul>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>물리적 조치</h3>
          <ul className='list-disc ml-5 my-2'>
            <li>전산실 및 자료 보관실의 접근 통제</li>
          </ul>
        </article>
      </section>

      {/* 11. 개인정보 보호책임자 및 담당자 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          11. 개인정보 보호책임자 및 담당자
        </h2>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            개인정보 보호책임자
          </h3>
          <p>이름: 강민규</p>
          <p>연락처: 10duk.official@gmail.com</p>
        </article>
        <article>
          <h3 className='text-xl font-semibold mt-4 mb-2'>
            개인정보 보호담당자
          </h3>
          <p>이름: 강민규</p>
          <p>연락처: 10duk.official@gmail.com</p>
        </article>
        <p className='mt-4'>
          이용자는 개인정보 관련 문의, 불만 처리, 피해 구제 등에 관한 사항을
          개인정보 보호책임자 및 담당자에게 문의할 수 있습니다.
        </p>
      </section>

      {/* 12. 권익침해 구제방법 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          12. 권익침해 구제방법
        </h2>
        <p>
          개인정보 침해로 인한 상담이 필요하신 경우 아래 기관에 문의하시기
          바랍니다.
        </p>
        <ul className='list-disc ml-5 my-2'>
          <li>
            개인정보침해신고센터: (국번 없이) 118,{" "}
            <a
              href='http://privacy.kisa.or.kr'
              className='text-blue-600 underline'>
              http://privacy.kisa.or.kr
            </a>
          </li>
          <li>
            대검찰청 사이버수사과: (국번 없이) 1301,{" "}
            <a href='http://www.spo.go.kr' className='text-blue-600 underline'>
              http://www.spo.go.kr
            </a>
          </li>
          <li>
            경찰청 사이버범죄 신고시스템: (국번 없이) 182,{" "}
            <a
              href='http://cyberbureau.police.go.kr'
              className='text-blue-600 underline'>
              http://cyberbureau.police.go.kr
            </a>
          </li>
        </ul>
      </section>

      {/* 13. 개인정보 처리방침의 변경 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>
          13. 개인정보 처리방침의 변경
        </h2>
        <p>
          본 개인정보 처리방침은 시행일로부터 적용되며, 법령 및 회사 정책에 따른
          변경 내용의 추가, 삭제 및 수정이 있을 경우 변경 사항의 시행 7일 전부터
          공지사항을 통해 고지할 것입니다.
        </p>
        <ul className='list-disc ml-5 my-2'>
          <li>공고일자: 2024년 11월 12일</li>
          <li>시행일자: 2024년 11월 19일</li>
        </ul>
      </section>

      {/* 부칙 */}
      <section>
        <h2 className='text-2xl font-semibold mt-6 mb-2'>부칙</h2>
        <p>본 방침은 2024년 11월 19일부터 시행됩니다.</p>
      </section>
    </div>
  );
}

export default PrivacyPolicyPage;
