import { useEffect, useRef, useState } from "react";
import { deletePost } from "../../apis/post";
import { useAppSelector, AnnouoncementDetail } from "../../constants/types";
import HTMLReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { getBoardName } from "../../apis/board";
import {
  deleteAnnouncementForever,
  getAnnouncementDetail,
} from "../../apis/admin/announcement";

interface MobileAnnouncementDetailProps {
  postId: string;
  boardNumber?: number;
}

function MobileAnnouncementDetail({
  postId,
  boardNumber,
}: MobileAnnouncementDetailProps) {
  const [boardName, setBoardName] = useState<string | null>(null);
  const [post, setPost] = useState<AnnouoncementDetail | null>(null);
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const moreRef = useRef<HTMLDivElement>(null);

  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const userId = useAppSelector((state) => state.auth.userId);
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);

  const navigate = useNavigate();

  useEffect(() => {
    getAnnouncementDetail(postId)
      .then((res) => {
        setPost(res);
      })
      .catch((err) => {});

    if (boardNumber) {
      getBoardName(boardNumber)
        .then((res) => {
          setBoardName(res.boardName);
        })
        .catch((err) => {});
    }
  }, [postId]);

  if (!post) return <div> 글 정보 불러오는 중..... </div>;

  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")} ${dateTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateTime.getMinutes().toString().padStart(2, "0")}`;

  const goToBoard = () => {
    navigate(`/board/${boardNumber}`);
  };

  const handleDeletePost = () => {
    // 글 삭제 시 한 번 더 확인
    const deleteConfirm = window.confirm("정말 삭제하시겠습니까?");
    if (deleteConfirm) {
      deleteAnnouncementForever(postId).then((res) => {
        alert("삭제되었습니다");
      });
      navigate(`/admin/page/announcement`);
    } else {
      alert("삭제가 취소되었습니다");
    }
  };

  const handleEditPost = (postId: string) => {
    navigate(`/admin/page/announcement/edit/${postId}`);
  };

  const toggleMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const handleCopyUrl = () => {
    const postDetailUrl = window.location.href;

    // 클립보드 API를 사용하여 URL을 복사
    navigator.clipboard
      .writeText(postDetailUrl)
      .then(() => {
        alert("URL이 복사되었습니다.");
      })
      .catch((error) => {});
  };

  const handleReportPost = () => {
    alert("신고 되었습니다");
  };

  return (
    <>
      {boardName ? (
        <div
          className='pl-4 text-textcolor text-2xl font-bold my-5'
          onClick={goToBoard}>
          {boardName}
        </div>
      ) : (
        <div className='pl-4 text-textcolor text-2xl font-bold my-5'>
          공지사항
        </div>
      )}
      <div className='flex flex-col w-full'>
        <div className=' bg-[#F4F4F4] px-3 py-3 border-y border-[#A4A4A4]'>
          <div className='flex pb-1 items-center'>
            <div className='flex text-textcolor text-lg font-semibold'>
              {post.title}
            </div>
          </div>
          <div className='flex justify-between items-center'>
            <div className='flex items-center'>
              <div className='flex text-sm pr-2 text-[#6B6B6B] font-medium items-center'>
                운영자덕
              </div>
              <div className=' pr-2 border-l border-[#898989] h-3 items-center'></div>
              <div className='flex text-[#898989] text-xs items-center font-normal pr-2'>
                {formattedDate}
              </div>
              <div className=' pr-2 border-l border-[#898989] h-3 items-center'></div>
              <div className='flex items-center'>
                <div className='text-[#6B6B6B] text-xs font-normal mr-[3px]'>
                  조회수
                </div>
                <div className='text-[#6B6B6B] text-xs font-normal'>
                  {post.viewCount}
                </div>
              </div>
            </div>
            <div className=' flex relative '>
              {isAdminAdmin && (
                <div className='flex ml-auto pr-2'>
                  <div
                    className='flex cursor-pointer px-2'
                    onClick={toggleMoreOpen}
                    ref={moreRef}>
                    <img
                      src='/assets/icon/more.svg'
                      alt='more'
                      className='h-4'
                    />
                  </div>
                  {isMoreOpen && isAdminAdmin && (
                    <div className='absolute right-0 mt-8 p-2 bg-white border border-gray-200 rounded-md shadow-lg z-50'>
                      <button
                        onClick={() => handleEditPost(postId)}
                        className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                        수정
                      </button>
                      <button
                        onClick={handleDeletePost}
                        className='block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'>
                        삭제
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='my-5 text-base text-textcolor px-5'>
          <div className=' break-words'>{HTMLReactParser(post.text)}</div>
        </div>
      </div>
    </>
  );
}

export default MobileAnnouncementDetail;
