import { useParams } from "react-router-dom";
import MobilePostDetail from "../../components/Posts/MobilePostDetail";
import MobileBoardMain from "../../components/Main/MobileBoardMain";
import MobileNewPostsRemote from "../../components/Common/MobileNewPostsRemote";

function MobileDetailPage() {
  const { postId } = useParams<{ postId: string }>();
  const { boardNumber } = useParams<{ boardNumber: string }>();

  if (!boardNumber) {
    return <div>boardNumber Loading...</div>;
  }
  const parseBoardNumber = parseInt(boardNumber);

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  return (
    <>
      <div>
        <MobilePostDetail postId={postId} boardNumber={parseBoardNumber} />
        <div className=" flex justify-center mt-14">
          <MobileNewPostsRemote />
        </div>
        <div className="mt-10">
          <MobileBoardMain boardNumber={parseBoardNumber} />
        </div>
      </div>
    </>
  );
}

export default MobileDetailPage;
