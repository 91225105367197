import React from "react";
import { Routes, Route } from "react-router-dom";
import AdminCommentList from "../../../components/Admin/Comment/CommentList";
import AdminSubCommentList from "../../../components/Admin/Comment/SubCommentList";

function AdminCommentPage() {
  return (
    <div style={{ width: "100%" }}>
      <Routes>
        <Route path="/" element={<AdminCommentList />} />
        <Route path="subcomment" element={<AdminSubCommentList />} />
      </Routes>
    </div>
  );
}

export default AdminCommentPage;
