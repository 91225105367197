import MobileRankPost from "../../components/Posts/MobileRankPost";

function MobileRankDukPage() {
  return (
    <div>
      <MobileRankPost />
    </div>
  );
}

export default MobileRankDukPage;
