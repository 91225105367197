import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";
import BoardNavBar from "../NavBar/BoardNavBar";
import MobileNavBar from "../NavBar/MobileNavBar";
import MobileBoardNavBar from "../NavBar/MobileBoardNavBar";
import MobileFooter from "../Footer/MobileFooter";
import CarouselPosts from "../Main/CarouselPosts";
import { useAppSelector } from "../../constants/types";

function MobileLayout() {
  const isAdminAdmin = useAppSelector((state) => state.auth.isAdminAdmin);
  const bgColor = isAdminAdmin ? "bg-[#D1E6FF]" : null;
  return (
    <>
      <div className={`flex flex-col min-h-[100vh] ${bgColor}`}>
        <MobileNavBar />
        <div className='my-2'>
          <CarouselPosts />
        </div>
        <div className=' flex-1'>
          <Outlet />
        </div>
        <MobileFooter />
      </div>
    </>
  );
}

export default MobileLayout;
