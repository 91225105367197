import React from "react";
import MobileVoteBoard from "../../components/VoteBoard/MobileVoteBoard";

function MobileVoteBoardPage() {
  return (
    <div>
      <MobileVoteBoard />
    </div>
  );
}

export default MobileVoteBoardPage;
