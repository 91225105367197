import { axiosInstance, axiosFileInstance } from "../instance";

// 게시글 영구 차단
export const blockPostForever = async (postId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/post/blockForever/${postId}`
  );
  return response.data;
};

// 게시글 차단 해제
export const unblockPost = async (postId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/post/unblock/${postId}`
  );
  return response.data;
};

//조치된 게시물 조회(삭제, 차단, 댓글 금지)
export const getAdminActionedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all?page=${pageNumber}`
  );
  return response.data;
};

/*
// 전체_삭제 게시물 조회
export const getAdminDeletedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/deleted/${pageNumber}`
  );
  return response.data;
};

// 전체_차단 게시물 조회
export const getAdminBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/blocked/${pageNumber}`
  );
  return response.data;
};

// 전체_댓글금지 게시물 조회
export const getAdminCommentBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/commentBlocked/${pageNumber}`
  );
  return response.data;
};

// 랭커_삭제 게시물 조회
export const getAdminRankerDeletedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/deleted/${pageNumber}`
  );
  return response.data;
};

// 랭커_차단 게시물 조회
export const getAdminRankerBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/blocked/${pageNumber}`
  );
  return response.data;
};

// 랭커_댓글금지 게시물 조회
export const getAdminRankerCommentBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/commentBlocked/${pageNumber}`
  );
  return response.data;
};

// 일반_삭제 게시물 조회
export const getAdminNormalDeletedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/deleted/${pageNumber}`
  );
  return response.data;
};

// 일반_차단 게시물 조회
export const getAdminNormalBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/blocked/${pageNumber}`
  );
  return response.data;
};

// 일반_댓글금지 게시물 조회
export const getAdminNormalCommentBlockedPosts = async (pageNumber: number) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/commentBlocked/${pageNumber}`
  );
  return response.data;
};

// 전체_삭제 게시물 검색 민규 _ 이거 맞아..? text 부분 _ 추후 수정해주기로함
export const searchAdminDeletedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/deleted/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 전체_차단 게시물 검색
export const searchAdminBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/blocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 전체_댓글금지 게시물 검색
export const searchAdminCommentBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/all/commentBlocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 랭커_삭제 게시물 검색
export const searchAdminRankerDeletedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/deleted/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 랭커_차단 게시물 검색
export const searchAdminRankerBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/blocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 랭커_댓글금지 게시물 검색
export const searchAdminRankerCommentBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/ranker/commentBlocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 일반_삭제 게시물 검색
export const searchAdminNormalDeletedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/deleted/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 일반_차단 게시물 검색
export const searchAdminNormalBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/blocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 일반_댓글금지 게시물 검색
export const searchAdminNormalCommentBlockedPosts = async (
  pageNumber: number,
  text: string
) => {
  const response = await axiosInstance.get<any>(
    `/admin/post/normal/commentBlocked/${pageNumber}/search?text=${text}`
  );
  return response.data;
};

// 게시글 상세 조회 민규_ 흠,,이게 필요한가,,,?
export const getAdminPostDetail = async (postId: string) => {
  const response = await axiosInstance.get<any>(`/admin/post/all/${postId}`);
  return response.data;
};

// 게시글 영구 댓글 금지
export const blockPostCommentForever = async (postId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/post/blockPostCommentForever/${postId}`
  );
  return response.data;
};

// 게시글 댓글 금지 해제
export const unblockPostComment = async (postId: string) => {
  const response = await axiosInstance.patch<any>(
    `/admin/post/unblockPostComment/${postId}`
  );
  return response.data;
};
*/
