import React from "react";
import { closeLoginModal } from "../../store/loginModalSlice"; // closeLoginModal 액션
import NativeLoginForm from "../../components/NativeLogin/NativeLoginForm";
import { useAppDispatch } from "../../constants/types";

const NativeLoginPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleModalClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation(); // 모달 내부 클릭 시 이벤트 전파 방지
  };

  const handleClose = () => {
    dispatch(closeLoginModal()); // Redux 상태를 변경해 모달 닫기
  };

  return (
    <div
      className='fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50'
      onClick={handleClose}>
      <div
        className='bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto'
        onClick={handleModalClick}>
        {/* 상단 닫기 버튼 및 흐린 구분선 */}
        <div className='flex justify-between items-center pb-3'>
          <div></div>
          <button
            onClick={handleClose}
            className='text-gray-500 hover:text-gray-700'>
            <img src='/assets/icon/close.svg' alt='close' className='w-4 h-4' />
          </button>
        </div>

        {/* 로그인 폼 */}
        <NativeLoginForm onClose={handleClose} />
      </div>
    </div>
  );
};

export default NativeLoginPage;
