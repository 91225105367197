import React, { ReactNode } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface CustomRightArrowProps {
  onClick: () => void;
  onMove: boolean;
  carouselState: {
    currentSlide: number;
    deviceType: string;
  };
}

interface CustomLeftArrowProps {
  onClick: () => void;
  onMove: boolean;
  carouselState: {
    currentSlide: number;
    deviceType: string;
  };
}

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

type Props = {
  children: ReactNode;
};
export default function MultiCarousel({ children }: Props) {
  return (
    <Carousel
      infinite
      draggable
      autoPlay
      responsive={responsive}
      // itemClass="px-1"
      arrows={false}
    >
      {children}
    </Carousel>
  );
}
