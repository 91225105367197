import React, { useState } from 'react';
import { voteUpPost, voteDownPost } from '../../apis/post';
import { useAppDispatch, useAppSelector } from '../../constants/types';
import { updateLoginRedirectUrl } from '../../store/authReducer';

interface VotePostProps {
  postId: string;
  voteUpCount: number;
  voteDownCount: number;
  onVoteChange: (newVoteUpCount: number, newVoteDownCount: number) => void;
}

function VotePost({
  postId,
  voteUpCount,
  voteDownCount,
  onVoteChange,
}: VotePostProps) {
  // const [voteUp, setVoteUp] = useState<number>(voteUpCount);
  // const [voteDown, setVoteDown] = useState<number>(voteDownCount);
  const dispatch = useAppDispatch();
  const isLogin = useAppSelector((state) => state.auth.isLogin);

  const handleVoteUp = () => {
    if (!isLogin) {
      handleLoginAlert();
      return;
    }

    voteUpPost(postId)
      .then((res) => {
        onVoteChange(voteUpCount + 1, voteDownCount);
        alert('투표 완료');
      })
      .catch((err) => {
        //todo 로그인 안한거 처리해주기

        // 이미 투표한 게시글일 경우
        if (
          err.response.status === 403 &&
          err.response.data.message === 'This user has already voted'
        ) {
          alert('이미 투표한 게시글입니다.');
          window.location.reload();
        }
      });
  };

  // const handleVoteDown = () => {
  //   if (!isLogin) {
  //     handleLoginAlert();
  //     return;
  //   }

  //   voteDownPost(postId)
  //     .then((res) => {
  //       onVoteChange(voteUpCount, voteDownCount + 1);
  //       alert('투표 완료');
  //     })
  //     .catch((err) => {
  //       // 이미 투표한 게시글일 경우
  //       if (
  //         err.response.status === 403 &&
  //         err.response.data.message === 'This user has already voted'
  //       ) {
  //         alert('이미 투표한 게시글입니다.');
  //         window.location.reload();
  //       }
  //     });
  // };

  const handleLoginAlert = () => {
    const commentLogin = window.confirm(
      '로그인이 필요합니다. 로그인 하시겠습니까?'
    );
    if (commentLogin) {
      dispatch(updateLoginRedirectUrl(window.location.pathname));
      window.location.replace('/login');
    } else {
      return;
    }
  };

  return (
    <>
      <div className='flex w-full mx-auto items-center justify-center'>
        {/* <div className='flex mr-2 font-semibold text-3xl text-hotduk'></div> */}
        <button
          className='flex flex-col rounded-full bg-hotduk w-28 h-28 mx-4 my-2 items-center justify-center hover:bg-opacity-80'
          onClick={handleVoteUp}
        >
          <img
            src='/assets/icon/thumbsup.svg'
            alt='thumbsup'
            className='w-8 pt-1'
          />
          <div className='mx-3 pt-1 text-white font-medium'>
            좋아요<div>{voteUpCount}</div>
          </div>
        </button>
        {/* <button
          className="flex flex-col rounded-full bg-[#555555] w-20 h-20 mx-4 my-2 items-center justify-center hover:bg-zinc-800"
          onClick={handleVoteDown}
        >
          <img
            src="/assets/icon/thumbsdown.svg"
            alt="thumbsdown"
            className="w-8 pt-1"
          />
          <div className="mx-3 pt-1 text-white font-medium">싫어요</div>
        </button> */}
        {/* <div className='flex mr-2 font-semibold text-3xl text-[#555555]'>
          {voteDownCount}
        </div> */}
      </div>
    </>
  );
}

export default VotePost;
