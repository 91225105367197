import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBoardPost } from "../../apis/board";
import { useAppSelector, voteBoardDetailData } from "../../constants/types";
import HTMLReactParser from "html-react-parser";
import LikeVoteBoard from "./LikeVoteBoard";

interface VoteBoardDetailProps {
  voteBoardPostId: string;
}

function VoteBoardDetail({ voteBoardPostId }: VoteBoardDetailProps) {
  const navigate = useNavigate();
  const [post, setPost] = useState<voteBoardDetailData | null>(null);
  const userId = useAppSelector((state) => state.auth.userId);

  useEffect(() => {
    getBoardPost(voteBoardPostId)
      .then((res) => {
        setPost(res);
      })
      .catch((err) => {});
  }, [voteBoardPostId]);

  const getTimeDifference = (expiredAt: string) => {
    const expirationTime = new Date(expiredAt).getTime();
    const currentTime = Date.now();
    const timeDifference = expirationTime - currentTime;

    if (timeDifference <= 0) {
      return "투표종료";
    } else if (timeDifference < 3600000) {
      // Less than 1 hour
      return `${Math.floor(timeDifference / 60000)}분 전`;
    } else if (timeDifference < 86400000) {
      // Less than 24 hours
      return `${Math.floor(timeDifference / 3600000)}시간 전`;
    } else {
      const dDay = Math.ceil(timeDifference / 86400000);
      return `D-${dDay}`;
    }
  };

  const getVoteUpDifference = (boardName: string, voteUpCount: number) => {
    if (voteUpCount < 30) {
      return `${boardName}게시판 추가까지 ${30 - voteUpCount}표`;
    }
    return `${boardName}게시판 추가 성공!`;
  };

  const goToBoard = () => {
    navigate(`/board/voteboard`);
    window.scrollTo(0, 0);
  };

  if (!post) {
    return <div>게시판 투표 글 로딩중</div>;
  }
  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dateTime
    .getDate()
    .toString()
    .padStart(2, "0")} ${dateTime
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateTime.getMinutes().toString().padStart(2, "0")}`;

  return (
    <>
      <div className="flex flex-col w-full">
        <div
          className=" w-60 text-[#222222] text-2xl font-bold mb-5 cursor-pointer hover:text-gray"
          onClick={goToBoard}
        >
          게시판 추가 투표
        </div>
        <div className="py-2 bg-[#E9E9E9]  items-center">
          <div className=" pl-3 text-textcolor text-lg font-semibold">
            {post.title}
          </div>
          <div className="flex justify-between">
            <div className="text-sm px-3 flex items-center">
              [{getTimeDifference(post.expiredAt)},{" "}
              {getVoteUpDifference(post.boardName, post.voteUpCount)}]
            </div>
            <div className=" text-[#555555] text-xs px-3">{formattedDate}</div>
          </div>
        </div>
        <div className="flex justify-between border-b border-[#ECECEC] py-3">
          <div className="flex text-sm text-[#6B6B6B] font-medium items-center pl-3">
            익명
          </div>
          <div className="flex pr-3 items-center">
            <img src="/assets/icon/eye.svg" alt="eye" className="w-4 mr-1" />
            <div className="text-[#222222] text-xs font-normal mr-[3px]">
              조회
            </div>
            <div className="text-[#555555] text-xs font-semibold mr-4">
              {post.viewCount}
            </div>
            <img
              src="/assets/icon/thumbsup_title.svg"
              alt="thumbsup_title"
              className="w-4 mr-1"
            />
            <div className="text-[#222222] text-xs font-normal mr-[3px]">
              추천수
            </div>
            <div className="text-[#555555] text-xs font-semibold">
              {post.voteUpCount}
            </div>
            {/* <img src="/assets/icon/more.svg" alt="more" className="h-4 mr-2" />
            <div className="text-[#222222] text-xs font-normal mr-[3px]">
              더보기
            </div> */}
          </div>
        </div>
        <div className="my-5 text-base text-textcolor">
          <div className="break-words text-lg pb-2 font-semibold">
            게시판 이름 : "{post.boardName}"
          </div>
          <div className=" break-words">{HTMLReactParser(post.text)}</div>
        </div>
        <div className="border border-zinc-500 mx-auto px-7 pb-4">
          <LikeVoteBoard
            postId={voteBoardPostId}
            voteUpCount={post.voteUpCount}
          />
        </div>{" "}
      </div>
    </>
  );
}

export default VoteBoardDetail;
