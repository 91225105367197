import { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { checkNewPosts, getTotalPosts } from '../../apis/post';
import Pagination from './Pagination';
import { TotalPostData, useAppSelector } from '../../constants/types';
import { url } from 'inspector';

function NewPostsRemote() {
  const [normalPostList, setNormalPostList] = useState<TotalPostData[]>([]);
  const normalPostListRef = useRef(normalPostList);
  const navigate = useNavigate();
  const handlePostClick = (boardNumber: number, postId: string) => {
    navigate(`/post/${boardNumber}/${postId}`);
    window.scrollTo(0, 0);
  };
  const url = window.location.href;

  useEffect(() => {
    normalPostListRef.current = normalPostList;
  }, [normalPostList]);

  useEffect(() => {
    const fetchData = async () => {
      if (normalPostListRef.current.length === 0) {
        const res = await getTotalPosts(1);
        setNormalPostList(res.posts.normalPosts.slice(0, 10));
      } else {
        try {
          const hasNewPost = (await checkNewPosts()).isNewPostExist;
          if (hasNewPost) {
            const res = await getTotalPosts(1);
            setNormalPostList(res.posts.normalPosts.slice(0, 10));
          } else {
            return;
          }
        } catch (e) {}
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 20000);
    return () => clearInterval(interval);
  }, [url]);

  const renderNormalPosts = (posts: TotalPostData[]) => {
    return (
      <div>
        {posts.map((post) => (
          <div
            key={post.id}
            className='flex cursor-pointer text-sm items-center w-64 border-b border-[#FBF3FF] hover:bg-[#F8F8F8]'
            onClick={() => handlePostClick(post.boardNumber, post.id)}
          >
            <div className='py-2 pl-2 flex items-center font-normal'>
              <div className='flex text-xs font-normal items-start opacity-50 pr-2'>
                {post.boardName}
              </div>
              <div className='flex text-sm'>
                {post.title.length > 10
                  ? post.title.slice(0, 10) + '...'
                  : post.title}
              </div>
              {post.mainImage && (
                <img
                  src='/assets/icon/10duk_photo.svg'
                  alt='image'
                  className='ml-[6px] w-[14px]'
                />
              )}
              {post.commentCount !== 0 && (
                <div className=' text-hotduk text-xs font-semibold pt-[2px] items-end ml-1'>
                  {post.commentCount}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='border border-[#DDE4EC] rounded-lg w-64'>
      <div className='py-2 pl-2 bg-special_duk opacity-80 text-white text-lg font-semibold rounded-t-lg'>
        실시간 최신 글
      </div>
      {renderNormalPosts(normalPostList)}
    </div>
  );
}

export default NewPostsRemote;
