import React from "react";
import { redirectKakao } from "../../apis/user";
import { useAppDispatch } from "../../constants/types";
import { closeLoginModal } from "../../store/loginModalSlice";

function KakaoBtn() {
  const dispatch = useAppDispatch();

  const handleKakaoLogin = () => {
    redirectKakao();
    dispatch(closeLoginModal()); // 모달 닫기
  };

  return (
    <div
    // onClick={handleKakaoLogin}
    // className="flex bg-[#FEE500] cursor-pointer rounded-[12px] mx-auto my-10 h-16 max-w-md justify-center items-center"
    >
      <img
        src='/assets/kakao_login.png'
        alt='kakao'
        className=' shadow rounded-md mx-auto max-h-12 justify-center items-center cursor-pointer'
        onClick={handleKakaoLogin}
      />
    </div>
  );
}

export default KakaoBtn;
