import React, { useState } from 'react';
import CreatePoll from './CreatePoll';
import { PollData } from '../../constants/types';
import MobileCreatePoll from './MobileCreatePoll';

interface MobileCreatePollModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPollDataChange: (pollData: PollData | null) => void;
  initialPollData?: PollData | null;
}

function MobileCreatePollModal({
  isOpen,
  onClose,
  onPollDataChange,
  initialPollData,
}: MobileCreatePollModalProps) {
  const [pollData, setPollData] = useState<PollData | null>(
    initialPollData || null
  );

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleCancel = () => {
    // setPollData(null);
    onPollDataChange(pollData);
    onClose();
  };

  const handleSubmit = (newPollData: PollData) => {
    setPollData(newPollData);
    onPollDataChange(newPollData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'
      onClick={handleOutsideClick}
    >
      <div className='bg-white rounded-lg shadow-xl py-4 px-5'>
        <div className='flex justify-between'>
          <h2 className=' text-xl font-semibold'>투표 작성하기</h2>
          <button className='' onClick={handleCancel}>
            <img
              src='/assets/icon/modal_exit.svg'
              alt='modal_exit'
              className=' w-4'
            />
          </button>
        </div>
        <div className='mt-1 text-[#6B6B6B] text-xs pb-2 border-b border-[#D9D9D9]'>
          항목은 6개까지 추가 가능합니다.
        </div>
        <div className='mt-4'>
          <MobileCreatePoll
            onPollDataChange={onPollDataChange}
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            initialPollData={pollData}
          />
        </div>
      </div>
    </div>
  );
}

export default MobileCreatePollModal;
