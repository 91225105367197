import { useRef, useState } from "react";
import React from "react";
import { createSubComment } from "../../apis/subcomment";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import Btn from "../Common/Btn";
import { updateLoginRedirectUrl } from "../../store/authReducer";
import { uploadCommentImage } from "../../apis/comment";
import { openLoginModal } from "../../store/loginModalSlice";

interface MobileCreateSubCommentProps {
  commentId: string;
  boardNumber: number;
  ranker: boolean;
  setSubcommentLengthDetail: React.Dispatch<React.SetStateAction<number>>;
}

function MobileCreateSubComment({
  commentId,
  boardNumber,
  ranker,
  setSubcommentLengthDetail,
}: MobileCreateSubCommentProps) {
  const [subcomment, setSubcomment] = useState<string>("");
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const dispatch = useAppDispatch();
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPreviewImage(URL.createObjectURL(file));
      try {
        const tempImgPath = await uploadCommentImage(file);
        setUploadedImage(tempImgPath);
      } catch (err) {
        // console.error("이미지 업로드 실패:", err);
      }
    }
  };

  const handleSubmitSubcomment = (e?: React.FormEvent): void => {
    e?.preventDefault();
    const trimmedSubcomment = subcomment.trim();
    if (!trimmedSubcomment && !uploadedImage) {
      alert("댓글 내용을 입력해주세요");
      return;
    }
    const tempImgPath = uploadedImage ? uploadedImage : null;
    const content = JSON.stringify({
      boardNumber: boardNumber,
      text: trimmedSubcomment,
      ranker: ranker,
      tempImgPath: tempImgPath,
    });

    createSubComment(commentId, content)
      .then((res) => {
        setSubcommentLengthDetail((prevState) => prevState + 1); // subcommentLengthDetail 상태 증가
        setSubcomment("");
        setUploadedImage(null);
        setPreviewImage(null);
      })
      .catch((err) => {});
  };

  const handleOpen = () => {
    dispatch(openLoginModal()); // Redux 상태를 변경해 모달 닫기
  };

  const handleLoginAlert = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    dispatch(updateLoginRedirectUrl(window.location.pathname));
    handleOpen();
  };

  return (
    <>
      {isLogin ? (
        <form
          action=''
          onSubmit={handleSubmitSubcomment}
          className='bg-[#FFF4F4] mt-5 mx-5'>
          {previewImage && (
            <div className='relative px-3 pt-3'>
              <div className='flex'>
                <div className='mb-2 max-w-full mr-2'>
                  <img
                    src={previewImage}
                    alt='preview'
                    className='w-full h-auto max-w-[240px] max-h-[160px] object-contain'
                  />
                </div>
                <button
                  type='button'
                  onClick={() => {
                    setPreviewImage(null);
                    setUploadedImage(null);
                  }}
                  className='flex items-center justify-center w-8 h-8 bg-white bg-opacity-70 rounded-full transition duration-200 hover:bg-opacity-100 hover:opacity-50'>
                  <img
                    src='/assets/icon/image_delete.svg'
                    alt='image_delete'
                    className='w-4 h-4'
                  />
                </button>
              </div>
            </div>
          )}
          <div className='flex flex-col p-3 items-center'>
            <textarea
              className='flex w-full h-16 p-2 text-sm resize-none focus:outline-none'
              value={subcomment}
              onChange={(e) => setSubcomment(e.target.value)}
              placeholder='댓글 내용을 입력하세요'></textarea>
            <div className='mt-4 mb-1 flex justify-end w-full'>
              <div
                className='px-4 py-[6px] text-xs bg-[#FFE2E3] text-white font-normal hover:opacity-70 whitespace-nowrap mr-2 cursor-pointer'
                onClick={() => fileInputRef.current?.click()}>
                이미지 업로드
              </div>
              <input
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                className='hidden'
                ref={fileInputRef}
              />
              <Btn
                text='대댓글 작성'
                textColor='white'
                btnFunc={handleSubmitSubcomment}
                className='px-4 py-[6px] text-xs bg-[#FFE2E3] font-normal hover:opacity-70 whitespace-nowrap'
              />
            </div>
          </div>
        </form>
      ) : (
        <div className='flex-col mt-5 mb-10'>
          <div className='flex w-full bg-[#FAFAFA] px-2 py-3 text-sm resize-none text-[#555555]'>
            대댓글은{" "}
            <div className='flex px-1 font-bold ' onClick={handleLoginAlert}>
              로그인
            </div>
            후 작성 가능합니다.
          </div>
        </div>
      )}
    </>
  );
}

export default MobileCreateSubComment;
