import React, { useEffect, useRef, useState } from "react";
import {
  createComment,
  editComment,
  uploadCommentImage,
} from "../../apis/comment";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import Btn from "../Common/Btn";
import { updateLoginRedirectUrl } from "../../store/authReducer";
import { openLoginModal } from "../../store/loginModalSlice";

interface MobileCreateCommentProps {
  postId: string;
  boardNumber: number;
  ranker: boolean;
  addComment: (newComment: any) => void;
  initialCommentText?: string;
  initialCommentImage?: string;
  commentId?: string;
  isEditing?: boolean;
}

function MobileCreateComment({
  postId,
  boardNumber,
  ranker,
  addComment,
  initialCommentText,
  initialCommentImage,
  commentId,
  isEditing,
}: MobileCreateCommentProps) {
  const [comment, setComment] = useState<string>("");
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const dispatch = useAppDispatch();
  const [uploadedImage, setUploadedImage] = useState<string | null>(
    initialCommentImage || null
  );
  const [previewImage, setPreviewImage] = useState<string | null>(
    initialCommentImage || null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPreviewImage(URL.createObjectURL(file));
      try {
        const tempImgPath = await uploadCommentImage(file);
        setUploadedImage(tempImgPath);
      } catch (err) {
        // console.error("이미지 업로드 실패:", err);
      }
    }
  };

  useEffect(() => {
    if (isEditing && initialCommentText !== undefined) {
      setComment(initialCommentText);
    }
    if (isEditing && initialCommentImage !== undefined) {
      setUploadedImage(initialCommentImage);
      setPreviewImage(initialCommentImage);
    }
  }, [isEditing, initialCommentText, initialCommentImage]);

  const handleSubmitComment = (e?: React.FormEvent): void => {
    e?.preventDefault();
    const trimmedComment = comment.trim();
    if (!trimmedComment && !uploadedImage) {
      alert("댓글 내용을 입력해주세요");
      return;
    }
    const tempImgPath = uploadedImage ? uploadedImage : null;
    const content = JSON.stringify({
      boardNumber: boardNumber,
      text: trimmedComment,
      ranker: ranker,
      tempImgPath: tempImgPath,
    });

    if (isEditing && commentId) {
      editComment(commentId, content)
        .then((res) => {
          addComment(res);
          setComment("");
          setUploadedImage(null);
          setPreviewImage(null);
        })
        .catch((err) => {
          // console.error("댓글 수정 실패:", err);
        });
    } else {
      createComment(postId, content)
        .then((res) => {
          addComment(res);
          setComment("");
          setUploadedImage(null);
          setPreviewImage(null);
        })
        .catch((err) => {
          // console.error("댓글 작성 실패:", err);
        });
    }
  };

  const handleOpen = () => {
    dispatch(openLoginModal()); // Redux 상태를 변경해 모달 닫기
  };

  const handleLoginAlert = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    dispatch(updateLoginRedirectUrl(window.location.pathname));
    handleOpen();
  };

  return (
    <>
      {isLogin ? (
        <form
          action=''
          onSubmit={handleSubmitComment}
          className='bg-[#FFD8DA] mt-4 mx-4'>
          {previewImage && (
            <div className='relative px-4 pt-4'>
              <div className='flex items-center'>
                <div className=' max-w-full mr-2'>
                  <img
                    src={previewImage}
                    alt='Preview'
                    className='w-full h-auto max-w-[300px] max-h-[200px] object-contain'
                  />
                </div>
                <button
                  type='button'
                  onClick={() => {
                    setPreviewImage(null);
                    setUploadedImage(null);
                  }}
                  className='flex items-center justify-center w-8 h-8 bg-white bg-opacity-70 rounded-full shadow-md transition duration-200 hover:bg-opacity-100'>
                  <img
                    src='/assets/icon/image_delete.svg'
                    alt='close'
                    className='w-4 h-4'
                  />
                </button>
              </div>
            </div>
          )}
          <div className='flex flex-col p-4 items-center'>
            <textarea
              className='flex h-20 w-full mx-4 p-3 text-sm resize-none focus:outline-none'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder='댓글 내용을 입력하세요'></textarea>
            <div className='mt-4 flex justify-end w-full'>
              <div
                className='px-4 py-[6px] text-xs bg-[#FF9FA5] text-white font-normal hover:opacity-70 whitespace-nowrap mr-2 cursor-pointer'
                onClick={() => fileInputRef.current?.click()}>
                이미지 업로드
              </div>
              <input
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                className='hidden'
                ref={fileInputRef}
              />
              <Btn
                text='댓글 작성'
                textColor='white'
                btnFunc={handleSubmitComment}
                className='px-4 py-[6px] text-xs bg-[#FF9FA5] font-normal hover:opacity-70 whitespace-nowrap'
              />
            </div>
          </div>
        </form>
      ) : (
        <div className='flex-col mt-5'>
          <div className='w-full flex bg-[#FFD8DA] px-2 py-3 text-sm resize-none text-[#555555]'>
            댓글은{" "}
            <div className='flex px-1 font-bold ' onClick={handleLoginAlert}>
              {" "}
              로그인
            </div>{" "}
            후 작성 가능합니다.
          </div>
        </div>
      )}
    </>
  );
}

export default MobileCreateComment;
