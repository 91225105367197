import { axiosFileInstance, axiosInstance } from "./instance";

// 대댓글 작성
export const createSubComment = async (commentId: string, content: string) => {
  const response = await axiosInstance.post(
    `/comment/sub/${commentId}`,
    content
  );
  return response.data;
};

// 대댓글 좋아요
export const voteUpSubComment = async (
  commentId: string,
  subCommentId: string
) => {
  const response = await axiosInstance.post(
    `comment/sub/voteUp/${commentId}/${subCommentId}`
  );
  return response.data;
};

// 대댓글 싫어요
export const voteDownSubComment = async (
  commentId: string,
  subCommentId: string
) => {
  const response = await axiosInstance.post(
    `comment/sub/voteDown/${commentId}/${subCommentId}`
  );
  return response.data;
};

// 대댓글 삭제
export const deleteSubComment = async (
  commentId: string,
  subCommentId: string
) => {
  const response = await axiosInstance.delete(
    `/comment/sub/${commentId}/${subCommentId}`
  );
  return response.data;
};
