import React from "react";
import CreateBoardPost from "../../components/VoteBoard/CreateBoardPost";

function MobileCreateBoardPostPage() {
  return (
    <div className="flex mx-4">
      <CreateBoardPost />
    </div>
  );
}

export default MobileCreateBoardPostPage;
