import {
  Announcements,
  IntegratedAnnouncements,
  voteBoardDetailData,
} from "../../constants/types";
import { useNavigate } from "react-router-dom";

interface MobileRenderVoteBoardProps {
  posts?: voteBoardDetailData[];
  announcements?: Announcements[];
  integratedAnnouncements?: IntegratedAnnouncements[];
}

function MobileRenderVoteBoard({
  posts,
  announcements,
  integratedAnnouncements,
}: MobileRenderVoteBoardProps) {
  const navigate = useNavigate();

  const getTimeDifference = (expiredAt: string) => {
    const expirationTime = new Date(expiredAt).getTime();
    const currentTime = Date.now();
    const timeDifference = expirationTime - currentTime;

    if (timeDifference <= 0) {
      return "투표종료";
    } else {
      // Calculate the end date
      const endDate = new Date(expirationTime);
      const endDay = endDate.getDate();
      const endMonth = endDate.getMonth() + 1;
      const endYear = endDate.getFullYear();
      return `${endYear}년 ${endMonth}월 ${endDay}일 종료`;
    }
  };

  const getVoteUpDifference = (boardName: string, voteUpCount: number) => {
    if (voteUpCount < 30) {
      return `${boardName}게시판 추가까지 ${30 - voteUpCount}표`;
    }
    return `${boardName}게시판 추가 성공!`;
  };

  const handlePostClick = (
    voteBoardPostId: string,
    postExpiredAt: string,
    isExpired: boolean
  ) => {
    if (getTimeDifference(postExpiredAt) === "투표종료" || isExpired) {
      alert("투표가 종료된 게시물입니다.");
      return;
    }
    navigate(`/board/voteboard/voteboardpost/${voteBoardPostId}`);
  };

  const handleAnnouoncementClick = (postId: string) => {
    navigate(`/announcement/${postId}`);
  };

  if (!posts) {
    return (
      <div className="w-full pl-4 text-textcolor">
        게시물이 존재하지 않습니다.
      </div>
    );
  }

  const voteBoardPosts = (posts: voteBoardDetailData[]) => {
    return (
      <>
        {posts.length !== 0 ? (
          <>
            <div>
              {posts.map((post) => (
                <>
                  {getTimeDifference(post.expiredAt) === "투표종료" ||
                  post.isExpired ? (
                    <div
                      key={post.id}
                      className="items-center border-b border-[#A4A4A4] bg-zinc-200 opacity-40"
                      onClick={() =>
                        handlePostClick(post.id, post.expiredAt, post.isExpired)
                      }
                    >
                      <div className="pt-3 px-3 flex items-center ">
                        <div className="font-semibold flex items-center">
                          [{getTimeDifference(post.expiredAt)},{" "}
                          {getVoteUpDifference(
                            post.boardName,
                            post.voteUpCount
                          )}
                          ]
                        </div>
                      </div>
                      <div className="py-2 px-3 flex items-center ">
                        <div className="flex text-base font-normal items-center whitespace-nowrap">
                          {post.title.length > 20
                            ? post.title.slice(0, 20) + "..."
                            : post.title}
                        </div>
                      </div>
                      <div className="flex pl-2 pb-1">
                        <div className="flex px-1 pb-2 items-center">
                          {post.ranker ? (
                            <div className="flex items-center">
                              <p className="flex text-xs text-[#6B6B6B] font-normal">
                                익명
                              </p>
                              <img
                                src="/assets/icon/ranker_crown.svg"
                                alt="ranker_crown"
                                className="w-6 px-1 mr-1"
                              />
                            </div>
                          ) : (
                            <div className="pr-7 text-xs text-[#6B6B6B] font-normal">
                              익명
                            </div>
                          )}
                        </div>
                        <div className="flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]">
                          <img
                            src="/assets/icon/eye.svg"
                            alt="eye"
                            className="w-4 mr-1"
                          />
                          {post.viewCount}
                        </div>
                        <div className="flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]">
                          <img
                            src="/assets/icon/thumbsup_title.svg"
                            alt="like"
                            className="w-[14px] mr-1"
                          />
                          {post.voteUpCount}
                        </div>
                        <div className="flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]">
                          {Math.floor(
                            (Date.now() - Date.parse(post.createdAt)) / 3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(post.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(post.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(post.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(post.createdAt)
                                  ).getFullYear()}/${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      key={post.id}
                      className="items-center border-b border-[#A4A4A4]"
                      onClick={() =>
                        handlePostClick(post.id, post.expiredAt, post.isExpired)
                      }
                    >
                      <div className="pt-3 px-3 flex items-center ">
                        <div className="font-semibold flex items-center">
                          [{getTimeDifference(post.expiredAt)},{" "}
                          {getVoteUpDifference(
                            post.boardName,
                            post.voteUpCount
                          )}
                          ]
                        </div>
                      </div>
                      <div className="py-2 px-3 flex items-center ">
                        <div className="flex text-base font-normal items-center whitespace-nowrap">
                          {post.title.length > 20
                            ? post.title.slice(0, 20) + "..."
                            : post.title}
                        </div>
                      </div>
                      <div className="flex pl-2 pb-1">
                        <div className="flex px-1 pb-2 items-center">
                          {post.ranker ? (
                            <div className="flex items-center">
                              <p className="flex text-xs text-[#6B6B6B] font-normal">
                                익명
                              </p>
                              <img
                                src="/assets/icon/ranker_crown.svg"
                                alt="ranker_crown"
                                className="w-6 px-1 mr-1"
                              />
                            </div>
                          ) : (
                            <div className="pr-7 text-xs text-[#6B6B6B] font-normal">
                              익명
                            </div>
                          )}
                        </div>
                        <div className="flex items-center pb-2 pr-4 font-normal text-xs text-[#6B6B6B]">
                          <img
                            src="/assets/icon/eye.svg"
                            alt="eye"
                            className="w-4 mr-1"
                          />
                          {post.viewCount}
                        </div>
                        <div className="flex items-center pb-2 pr-2 font-normal text-xs text-[#6B6B6B]">
                          <img
                            src="/assets/icon/thumbsup_title.svg"
                            alt="like"
                            className="w-[14px] mr-1"
                          />
                          {post.voteUpCount}
                        </div>
                        <div className="flex items-center pb-2 px-2 font-normal text-xs text-[#6B6B6B]">
                          {Math.floor(
                            (Date.now() - Date.parse(post.createdAt)) / 3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(post.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(post.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(post.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(post.createdAt)
                                  ).getFullYear()}/${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </div>
          </>
        ) : (
          <div>게시물이 존재하지 않습니다.</div>
        )}
      </>
    );
  };

  const renderAnnouncementPost = (posts: Announcements[]) => {
    const post = posts[0];
    return (
      <div>
        <div
          key={post.id}
          className="items-center bg-[#F1F1F1] border-b border-[#A4A4A4]"
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <div className="py-4 pl-2 flex items-center ">
            <div className="flex items-center">
              <img
                src="/assets/icon/notice_label_mobile.svg"
                alt="notice_label_mobile"
                className="w-[54px] mr-1"
              />
            </div>
            <div className="flex text-base font-semibold items-center whitespace-nowrap text-[#B355CA]">
              {post.title}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderIntegratedAnnouncementPost = (
    posts: IntegratedAnnouncements[]
  ) => {
    const post = posts[0];
    return (
      <div>
        <div
          key={post.id}
          className="items-center bg-[#F1F1F1] border-b border-[#A4A4A4]"
          onClick={() => handleAnnouoncementClick(post.id)}
        >
          <div className="py-4 pl-2 flex items-center ">
            <div className="flex items-center">
              <img
                src="/assets/icon/notice_label_mobile.svg"
                alt="notice_label_mobile"
                className="w-[54px] mr-1"
              />
            </div>
            <div className="flex text-base font-semibold items-center whitespace-nowrap">
              {post.title.length > 20
                ? post.title.slice(0, 20) + "..."
                : post.title}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="border-b border-[#A4A4A4]"></div>
      {integratedAnnouncements && integratedAnnouncements.length !== 0 ? (
        <>{renderIntegratedAnnouncementPost(integratedAnnouncements)}</>
      ) : (
        <></>
      )}
      {announcements && announcements.length !== 0 ? (
        <>{renderAnnouncementPost(announcements)}</>
      ) : (
        <></>
      )}
      {voteBoardPosts(posts)}
    </>
  );
}

export default MobileRenderVoteBoard;
