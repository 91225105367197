import React, { useEffect, useRef, useState } from "react";
import { Comment, SubComment, useAppSelector } from "../../../constants/types";
import { deleteSubComment } from "../../../apis/subcomment";
import {
  blockSubComment,
  unblockSubComment,
} from "../../../apis/admin/adminComment";
import { formatAgo } from "../../../util/date";
import { useSearchParams } from "react-router-dom";
import CreateSubComment from "../../SubComments/CreateSubComment";

interface Props {
  comment: Comment;
  subCommentList?: SubComment[];
  setSubcommentLengthDetail: React.Dispatch<React.SetStateAction<number>>;
}

function AdminSubCommentDetail({
  comment,
  setSubcommentLengthDetail,
  subCommentList,
}: Props) {
  const userId = useAppSelector((state) => state.auth.userId);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const [subComments, setSubComments] = useState<SubComment[]>(
    subCommentList || []
  );
  const [openMoreMenu, setOpenMoreMenu] = useState<string | null>(null);
  const [editingSubCommentId, setEditingSubCommentId] = useState<string | null>(
    null
  );
  const [searchParams] = useSearchParams();
  const subCommentId = searchParams.get("subCommentId");
  const hasScrolled = useRef(false);
  const commentId = comment.id;

  // 대댓글 삭제
  const handleDeleteSubComment = async (
    commentId: string,
    subCommentId: string
  ) => {
    const deleteSubCommentConfirm =
      window.confirm("대댓글을 삭제하시겠습니까?");
    if (deleteSubCommentConfirm) {
      try {
        await deleteSubComment(commentId, subCommentId).then((res) => {
          setSubComments(res.subCommentList);
        });
      } catch (err) {
        // 에러 처리
      }
    }
  };

  // 대댓글 차단
  const handleBlockSubComment = async (subCommentId: string) => {
    const blockSubCommentConfirm =
      window.confirm("대댓글을 차단 하시겠습니까?");
    if (blockSubCommentConfirm) {
      try {
        await blockSubComment(subCommentId);
        setSubComments((prevSubComments) =>
          prevSubComments.map((subComment) =>
            subComment.id === subCommentId
              ? { ...subComment, isBlocked: true }
              : subComment
          )
        );
        setSubcommentLengthDetail((prevState) => prevState - 1);
      } catch (err) {
        // 에러 처리
      }
    }
  };

  // 대댓글 차단 해제
  const handleUnblockSubComment = async (subCommentId: string) => {
    const unblockSubCommentConfirm =
      window.confirm("대댓글 차단을 해제 하시겠습니까?");
    if (unblockSubCommentConfirm) {
      try {
        await unblockSubComment(subCommentId);
        setSubComments((prevSubComments) =>
          prevSubComments.map((subComment) =>
            subComment.id === subCommentId
              ? { ...subComment, isBlocked: false }
              : subComment
          )
        );
        setSubcommentLengthDetail((prevState) => prevState + 1);
      } catch (err) {
        // 에러 처리
      }
    }
  };

  useEffect(() => {
    if (!hasScrolled.current && subCommentId && subComments?.length) {
      const subCommentElement = document.getElementById(subCommentId);
      if (subCommentElement) {
        subCommentElement.scrollIntoView();
        subCommentElement.classList.add("highlight");
        setTimeout(() => {
          subCommentElement.classList.remove("highlight");
        }, 2000);
        hasScrolled.current = true;
      }
    }
  }, [subCommentId, subComments]);

  const makeUrlsClickable = (text: string) => {
    return text.replace(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
      '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>'
    );
  };

  // 댓글 더보기
  const handleCommentMore = (subCommentId: string) => {
    if (openMoreMenu === subCommentId) {
      setOpenMoreMenu(null);
    } else {
      setOpenMoreMenu(subCommentId);
    }
  };

  const handleEditComment = (subCommentId: string) => {
    const commentToEdit = subComments?.find(
      (subComment) => subComment.id === subCommentId
    );
    if (commentToEdit) {
      setEditingSubCommentId(subCommentId);
    }
    setOpenMoreMenu(null);
  };

  const handleEditComplete = (editedComment: any) => {
    setSubComments((prevSubComments) =>
      prevSubComments.map((subComment) =>
        subComment.id === editedComment[0].id ? editedComment[0] : subComment
      )
    );
    setEditingSubCommentId(null);
  };

  const handleReportSubComment = (subCommentId: string) => {
    // 댓글 신고 로직 구현
    setOpenMoreMenu(null);
  };

  return (
    <>
      {subComments.map((subComment) => (
        <div
          key={subComment.id}
          className="pl-5 flex-col border-t border-[#C3C3C3] items-center mt-2">
          <div className="flex mt-2 mb-1 items-center justify-between">
            <div className="flex items-center">
              <img
                src="/assets/icon/subcomment.svg"
                alt="subcomment"
                className="w-4"
              />
              <div className="mr-1 text-textcolor text-[13px] font-semibold">
                {subComment.randomNickname}
              </div>
              <div className="text-[#555555] text-xs pl-1">
                {formatAgo(subComment.createdAt, "ko")}
                {subComment.editedAt && (
                  <span className="text-[#555555] text-xs pl-1">(수정됨)</span>
                )}
              </div>
            </div>
            <div className="flex items-center">
              {!subComment.isDeleted && ( // 삭제된 댓글이 아닐 때
                <div className="relative">
                  <div
                    className="cursor-pointer"
                    onClick={() => handleCommentMore(subComment.id)}>
                    <img
                      src="/assets/icon/comment_more.svg"
                      alt="comment_more"
                      className="w-6 px-[2px] py-1"
                    />
                  </div>
                  {openMoreMenu === subComment.id && (
                    <div className="absolute right-1 top-0 mt-2 p-1 w-[72px] bg-white rounded-md shadow-md z-10">
                      {userId === subComment.userId ? ( // 본인 댓글이면
                        subComment.isBlocked ? ( // 차단된 댓글이면
                          <>
                            <div
                              className="px-1 py-2 text-xs font-semibold text-gray cursor-pointer"
                              onClick={() =>
                                handleBlockSubComment(subComment.id)
                              }>
                              댓글 차단 해제
                            </div>
                          </>
                        ) : (
                          // 차단이 안된 댓글이면
                          <>
                            <div
                              className="px-1 py-2 text-xs font-semibold text-hotduk bg-[#FFF4F4] cursor-pointer"
                              onClick={() => handleEditComment(subComment.id)}>
                              댓글 수정
                            </div>
                            <div
                              className="px-1 py-2 text-xs font-semibold text-gray cursor-pointer"
                              onClick={() =>
                                handleDeleteSubComment(
                                  comment.id,
                                  subComment.id
                                )
                              }>
                              댓글 삭제
                            </div>
                          </>
                        )
                      ) : (
                        // 내 댓글이 아니면
                        <>
                          {subComment.isBlocked ? ( // 차단된 댓글이면
                            <div
                              className="px-1 py-2 text-xs font-semibold text-gray cursor-pointer"
                              onClick={() =>
                                handleUnblockSubComment(subComment.id)
                              }>
                              댓글 차단 해제
                            </div>
                          ) : (
                            // 차단이 안된 댓글이면
                            <>
                              <div
                                className="px-1 py-2 text-xs font-semibold text-gray cursor-pointer"
                                onClick={() =>
                                  handleReportSubComment(subComment.id)
                                }>
                                댓글 신고
                              </div>
                              <div
                                className="px-1 py-2 text-xs font-semibold text-gray cursor-pointer"
                                onClick={() =>
                                  handleBlockSubComment(subComment.id)
                                }>
                                댓글 차단
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {editingSubCommentId === subComment.id ? (
            <CreateSubComment
              commentId={commentId}
              boardNumber={comment.boardNumber}
              ranker={comment.ranker}
              setSubcommentLengthDetail={setSubcommentLengthDetail}
              initialSubCommentText={subComment.text}
              initialSubCommentImage={subComment.image}
              subCommentId={subComment.id}
              isEditing={true}
              handleEditSubComment={handleEditComplete}
              key={subComment.editedAt}
            />
          ) : (
            <div className="flex items-center">
              <div className="flex-col max-w-[94%] mb-4">
                {subComment.image && (
                  <div className="mb-2 mx-4 max-w-full">
                    <img
                      src={subComment.image}
                      alt="subComment Image"
                      className="w-full h-auto max-w-[300px] max-h-[200px] object-contain"
                    />
                  </div>
                )}
                <div
                  className="text-textcolor text-sm font-normal"
                  dangerouslySetInnerHTML={{
                    __html: makeUrlsClickable(
                      subComment.text +
                        (subComment.isDeleted
                          ? " (본인이 삭제한 댓글입니다.)"
                          : "") +
                        (subComment.isBlocked ? " (차단된 댓글입니다.)" : "")
                    ),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
}

export default AdminSubCommentDetail;
