import React, { useEffect, useState } from "react";
import { getPosts } from "../../apis/post";
import { getBoardName } from "../../apis/board";
import CreatePost from "../Posts/CreatePost";
import { useNavigate } from "react-router-dom";
import PostList from "../Posts/PostList";
import BoardBookmark from "../Board/BoardBookmark";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import MobilePostList from "../Posts/MobilePostList";
import { updateLoginRedirectUrl } from "../../store/authReducer";

interface MobileBoardMainProps {
  boardNumber: number;
}

function MobileBoardMain({ boardNumber }: MobileBoardMainProps) {
  const navigate = useNavigate();
  const [boardName, setBoardName] = useState<string>("");
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getBoardName(boardNumber)
      .then((res) => {
        if (res.boardName === undefined) {
          alert("존재하지 않는 게시판입니다.");
          navigate("/");
        }
        setBoardName(res.boardName);
      })
      .catch((err) => {});
  }, []);

  const goToCreatePost = () => {
    if (!isLogin) {
      const createPostLogin = window.confirm(
        "로그인이 필요합니다. 로그인 하시겠습니까?"
      );
      if (createPostLogin) {
        dispatch(updateLoginRedirectUrl(`/post/create/${boardNumber}`));
        window.location.replace("/login");
      } else {
        return;
      }
    } else {
      navigate(`/post/create/${boardNumber}`);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div className="flex mt-5 justify-between">
        <div className="pl-4 mb-5 text-textcolor text-2xl font-bold">
          {boardName}
        </div>
        {/* {isLogin && (
          <BoardBookmark boardNumber={boardNumber} boardName={boardName} />
        )} */}
        <div className="pr-4 mt-2">
          <button
            id="createPostButton"
            type="button"
            onClick={goToCreatePost}
            className="bg-[#222222] text-white text-sm items-center font-semibold rounded-[100px] px-3 py-1"
          >
            글쓰기
          </button>
        </div>
      </div>
      <div>
        <MobilePostList boardNumber={boardNumber} />
      </div>
    </>
  );
}

export default MobileBoardMain;
