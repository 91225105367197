import React, { useEffect, useState } from 'react';
import { PollData } from '../../constants/types';

interface MobileCreatePollProps {
  onPollDataChange: (pollData: PollData | null) => void;
  onCancel: () => void;
  onSubmit: (PollData: PollData) => void;
  initialPollData?: PollData | null;
}

function MobileCreatePoll({
  onPollDataChange,
  onCancel,
  onSubmit,
  initialPollData,
}: MobileCreatePollProps) {
  const [subject, setSubject] = useState<string>(
    initialPollData?.subject || ''
  );
  const [items, setItems] = useState<string[]>(
    initialPollData?.options || ['', '']
  );
  const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState<boolean>(
    initialPollData?.isMultiSelectEnabled || false
  );

  const addItem = () => {
    if (items.length < 6) {
      setItems([...items, '']);
    }
  };

  const removeItem = (index: number) => {
    if (items.length > 2) {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
    }
  };

  const handleItemChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newItems = [...items];
    newItems[index] = e.target.value;
    setItems(newItems);
  };

  const handleSubjectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(e.target.value);
  };

  const handleMultiSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMultiSelectEnabled(e.target.checked);
  };

  const handleCancel = () => {
    if (initialPollData) {
      setSubject(initialPollData.subject);
      setItems(initialPollData.options);
      setIsMultiSelectEnabled(initialPollData.isMultiSelectEnabled);
    } else {
      setSubject('');
      setItems(['', '']);
      setIsMultiSelectEnabled(false);
    }
    onCancel();
  };

  const handleSubmit = () => {
    const pollData: PollData = {
      subject,
      options: items.filter((item) => item.trim() !== ''),
      isMultiSelectEnabled,
    };
    if (pollData.subject && pollData.options.length >= 2) {
      onSubmit(pollData);
    } else {
      alert('주제와 2개 이상의 항목을 입력해주세요.');
    }
  };

  useEffect(() => {
    const pollData: PollData = {
      subject,
      options: items.filter((item) => item.trim() !== ''),
      isMultiSelectEnabled,
    };
    onPollDataChange(
      pollData.subject && pollData.options.length > 2 ? pollData : null
    );
  }, [subject, items, isMultiSelectEnabled]);

  return (
    <div className=' max-h-80'>
      <div className='flex items-center pr-20'>
        <div className=' text-sm text-black font-semibold mr-1'>주제</div>
        <input
          type='text'
          className='border border-[#D9D9D9] w-48 h-7 ml-1 bg-[#F1F1F1] placeholder:text-[#6B6B6B] text-sm placeholder:text-xs pl-2 focus:outline-none'
          placeholder='투표 주제를 입력해주세요.'
          value={subject}
          onChange={handleSubjectChange}
        />
      </div>
      <div className='mt-1 max-h-48 overflow-y-auto pr-2 custom-scrollbar'>
        {items.map((item, index) => (
          <div key={index} className='flex pl-10 mt-2 items-center'>
            <img
              src='/assets/icon/nextpoll.svg'
              alt='nextpoll'
              className='w-2'
            />
            <input
              type='text'
              value={item}
              onChange={(e) => handleItemChange(e, index)}
              className='border border-[#D9D9D9] w-48 h-7 ml-2 placeholder:text-[#6B6B6B] placeholder:text-xs pl-2 focus:outline-none text-sm'
              placeholder='항목을 입력해주세요.'
            />
            {index === items.length - 1 ? (
              <img
                src='/assets/icon/poll_add.svg'
                alt='add poll'
                className='w-4 h-4 ml-2 cursor-pointer'
                onClick={addItem}
              />
            ) : (
              items.length > 2 && (
                <img
                  src='/assets/icon/poll_remove.svg'
                  alt='remove poll'
                  className='w-4 h-4 ml-2 cursor-pointer'
                  onClick={() => removeItem(index)}
                />
              )
            )}
          </div>
        ))}
      </div>
      <div className='mt-4'>
        <label className='flex items-center'>
          <input
            type='checkbox'
            checked={isMultiSelectEnabled}
            onChange={handleMultiSelectChange}
            className='mr-2'
          />
          <span className='text-xs'>복수 선택 허용</span>
        </label>
      </div>
      <div className='mt-4 flex justify-center'>
        <button
          className='bg-[#707070] text-white rounded-full px-6 py-[5px] text-sm font-semibold mr-2``'
          onClick={handleCancel}
        >
          취소
        </button>
        <button
          className='bg-hotduk text-white rounded-full px-6 py-[5px] text-sm font-semibold ml-2'
          onClick={handleSubmit}
        >
          투표 등록
        </button>
      </div>
    </div>
  );
}

export default MobileCreatePoll;
