import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getEditPost, getEditTempPost } from "../../apis/post";
import { useAppSelector } from "../../constants/types";
import MobileEditPost from "../../components/Posts/MobileEditPost";

function MobileEditPostPage() {
  const location = useLocation();
  const tempPostData = location.state?.tempPostData;

  const defalutUserId = useAppSelector((state) => state.auth.userId);
  const { postId } = useParams<{ postId: string }>();
  const [userId, setUserId] = useState<string | null>(null);
  const [text, setText] = useState<string>("");
  const url = window.location.href;

  useEffect(() => {
    if (!postId) {
      return;
    }

    const fetchData = async () => {
      try {
        let res;
        if (tempPostData?.isTempPost) {
          res = await getEditTempPost(tempPostData.tempPostId);
        } else {
          res = await getEditPost(postId);
        }
        setUserId(res.userId);
        setText(res.text);
      } catch (err) {
        // console.error(err);
      }
    };

    fetchData();
  }, [postId, tempPostData]);

  if (defalutUserId !== userId) {
    return <div> 잘못된 접근입니다.</div>;
  }

  if (!postId) {
    return <div>postId Loading...</div>;
  }

  if (!text) {
    return <div>text Loading...</div>;
  }

  return (
    <div className="flex mx-4">
      <MobileEditPost
        key={text}
        postId={tempPostData ? tempPostData.tempPostId : postId}
        isTempPost={tempPostData ? true : false}
        text={text}
      />
    </div>
  );
}

export default MobileEditPostPage;
