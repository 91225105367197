import ProgressBar from "../../components/NativeLogin/ProgressBar";
import TopLogoBar from "../../components/NativeLogin/TopLogoBar";
import PhoneVerify from "../../components/NativeLogin/PhoneVerify";

function VerifyIdentityBtnPage() {
  return (
    <>
      <PhoneVerify type='signup' />
    </>
  );
}

export default VerifyIdentityBtnPage;
