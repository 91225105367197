import React, { useState, useEffect } from "react";
import { UserPost, UserComment, UserBookmarks } from "../../constants/types";
import { useNavigate } from "react-router-dom";

interface MobileRenderUserHistoryProps {
  posts?: UserPost[];
  comments?: UserComment[];
  bookmarks?: UserPost[];
}

function MobileRenderUserHistory({
  posts,
  comments,
  bookmarks,
}: MobileRenderUserHistoryProps) {
  const navigate = useNavigate();

  const handlePostClick = (post: UserPost) => {
    //todo 여기 운영자일땐 이동하도록 isAdmin 넣어줘야함
    if (post.isBlocked) {
      alert("다수의 신고를 받아 가려진 게시물입니다.");
      return;
    }
    navigate(`/post/${post.boardNumber}/${post.id}`);
  };

  const handleCommentClick = (
    postId?: string,
    boardNumber?: number,
    isDeleted?: boolean
  ) => {
    if (!postId) {
      alert("글이 삭제되었습니다.");
    } else if (isDeleted) {
      //todo 삭제된 댓글입니다 다음에, 프로필에서 댓글 그만보기 로직 구현 가능하다면
      alert("삭제된 댓글입니다.");
    } else {
      navigate(`/post/${boardNumber}/${postId}`);
    }
  };

  if (posts) {
    return (
      <>
        <div className="w-full">
          {posts.length !== 0 ? (
            <>
              <div>
                {posts.map((post) => (
                  <div
                    key={post.id}
                    className="border-b border-[#A4A4A4] text-sm "
                    onClick={() => handlePostClick(post)}
                  >
                    {post.title.length < 17 ? (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex text-textcolor text-base font-normal ">
                            {" "}
                            {post.title}
                          </div>
                          <div className="flex text-hotduk font-bold text-sm px-2">
                            {post.commentCount > 0
                              ? `${post.commentCount}`
                              : ""}
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(post.createdAt)) / 3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(post.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(post.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(post.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(post.createdAt)
                                  ).getFullYear()}/${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex  text-textcolor text-base font-normal ">
                            {" "}
                            {post.title.slice(0, 17)}...
                          </div>
                          <div className="flex text-hotduk font-bold text-sm px-2">
                            {post.commentCount > 0
                              ? `${post.commentCount}`
                              : ""}
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(post.createdAt)) / 3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(post.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(post.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(post.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(post.createdAt)
                                  ).getFullYear()}/${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(post.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}/${new Date(
                                    new Date(post.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="py-2 pl-5 text-sm text-textcolor font-normal">
              게시물이 존재하지 않습니다.
            </div>
          )}
        </div>
      </>
    );
  }

  if (comments) {
    return (
      <>
        <div className="w-full">
          {comments.length !== 0 ? (
            <>
              <div>
                {comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="border-b border-[#A4A4A4] text-sm"
                    onClick={() =>
                      handleCommentClick(
                        comment.postId,
                        comment.boardNumber,
                        comment.isDeleted
                      )
                    }
                  >
                    {comment.text.trim() === "" && comment.image ? (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex text-textcolor text-base font-normal">
                            이미지 댓글입니다
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(comment.createdAt)) /
                              3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(comment.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(comment.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(comment.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(comment.createdAt)
                                  ).getFullYear()}.${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : comment.text.length < 17 ? (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex text-textcolor text-base font-normal">
                            {comment.text}
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(comment.createdAt)) /
                              3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(comment.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(comment.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(comment.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(comment.createdAt)
                                  ).getFullYear()}.${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex text-textcolor text-base font-normal">
                            {comment.text.slice(0, 17)}...
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(comment.createdAt)) /
                              3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(comment.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(comment.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(comment.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(comment.createdAt)
                                  ).getFullYear()}.${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(comment.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(comment.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="py-2 pl-5 text-sm text-textcolor font-normal">
              게시물이 존재하지 않습니다.
            </div>
          )}
        </div>
      </>
    );
  }

  if (bookmarks) {
    return (
      <>
        <div className="w-full">
          {bookmarks.length !== 0 ? (
            <>
              <div>
                {bookmarks.map((bookmark) => (
                  <div
                    key={bookmark.id}
                    className="border-b border-[#A4A4A4] text-sm"
                    onClick={() => handlePostClick(bookmark)}
                  >
                    {bookmark.title.length < 17 ? (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex text-textcolor text-base font-normal ">
                            {" "}
                            {bookmark.title}
                          </div>
                          <div className="flex text-hotduk font-bold text-sm pl-2">
                            {bookmark.commentCount > 0
                              ? `${bookmark.commentCount}`
                              : ""}
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(bookmark.createdAt)) /
                              3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(bookmark.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(bookmark.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(bookmark.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(bookmark.createdAt)
                                  ).getFullYear()}.${(
                                    new Date(
                                      new Date(bookmark.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(bookmark.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(bookmark.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(bookmark.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="py-3 px-5 flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="flex  text-textcolor text-base font-normal ">
                            {" "}
                            {bookmark.title.slice(0, 17)}...
                          </div>
                          <div className="flex text-hotduk font-bold text-sm pl-2">
                            {bookmark.commentCount > 0
                              ? `${bookmark.commentCount}`
                              : ""}
                          </div>
                        </div>
                        <div className="pr-3 items-center text-xs text-[#6B6B6B] font-normal">
                          {Math.floor(
                            (Date.now() - Date.parse(bookmark.createdAt)) /
                              3600000
                          ) < 24 ? (
                            <div>
                              {`${new Date(new Date(bookmark.createdAt))
                                .getHours()
                                .toString()
                                .padStart(2, "0")}:${new Date(
                                new Date(bookmark.createdAt)
                              )
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`}{" "}
                            </div>
                          ) : (
                            <>
                              {Math.floor(
                                (Date.now() - Date.parse(bookmark.createdAt)) /
                                  86400000
                              ) > 364 ? (
                                <div>
                                  {`${new Date(
                                    new Date(bookmark.createdAt)
                                  ).getFullYear()}.${(
                                    new Date(
                                      new Date(bookmark.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(bookmark.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              ) : (
                                <div>
                                  {`${(
                                    new Date(
                                      new Date(bookmark.createdAt)
                                    ).getMonth() + 1
                                  )
                                    .toString()
                                    .padStart(2, "0")}.${new Date(
                                    new Date(bookmark.createdAt)
                                  )
                                    .getDate()
                                    .toString()
                                    .padStart(2, "0")}`}{" "}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="py-2 pl-5 text-sm text-textcolor font-normal">
              게시물이 존재하지 않습니다.
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="py-2 pl-5 text-sm text-textcolor font-normal">
      게시물이 존재하지 않습니다.
    </div>
  );
}

export default MobileRenderUserHistory;
