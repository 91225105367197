import React, { useEffect, useState, useRef } from 'react';
import { getPost, deletePost, bookmarkPost, reportPost } from '../../apis/post';
import {
  PostDetailData,
  CommentData,
  useAppSelector,
  Poll,
} from '../../constants/types';
import CreateComment from '../Comments/CreateComment';
import CommetDetail from '../Comments/CommetDetail';
import AdminCommentDetail from '../Admin/Comment/AdminCommentDetail';
import HTMLReactParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { getBoardName } from '../../apis/board';
import VotePost from './VotePost';
import PostBookmark from './PostBookmark';
import { blockPostForever, unblockPost } from '../../apis/admin/adminPost';
import PollDetail from '../Poll/PollDetail';
import PollResult from '../Poll/PollResult';

interface PostDetailProps {
  postId: string;
  boardNumber: number;
}

function PostDetail({ postId, boardNumber }: PostDetailProps) {
  const [boardName, setBoardName] = useState<string | null>(null);
  const [post, setPost] = useState<PostDetailData | null>(null);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [hasVotedToPoll, setHasVotedToPoll] = useState<boolean>(
    post?.hasVotedToPoll || false
  );
  const [isMoreOpen, setIsMoreOpen] = useState<boolean>(false);
  const [comments, setComments] = useState<CommentData[] | null>(null);
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const userId = useAppSelector((state) => state.auth.userId);
  const isAdmin = useAppSelector((state) => state.auth.isAdmin);
  const moreRef = useRef<HTMLDivElement>(null);
  const deleteRef = useRef<HTMLDivElement>(null);
  const editRef = useRef<HTMLDivElement>(null);
  const reportRef = useRef<HTMLDivElement>(null);
  const [voteUpCount, setVoteUpCount] = useState<number>(
    post?.voteUpCount || 0
  );
  const [voteDownCount, setVoteDownCount] = useState<number>(
    post?.voteDownCount || 0
  );
  const [showVoteResult, setShowVoteResult] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleClickOutside = (e: MouseEvent) => {
    if (moreRef.current && !moreRef.current.contains(e.target as Node)) {
      if (post && userId === post.userId) {
        if (
          deleteRef.current &&
          !deleteRef.current.contains(e.target as Node) &&
          editRef.current &&
          !editRef.current.contains(e.target as Node)
        ) {
          setIsMoreOpen(false);
        }
      } else {
        if (
          reportRef.current &&
          !reportRef.current.contains(e.target as Node)
        ) {
          setIsMoreOpen(false);
        }
      }
    }
  };

  useEffect(() => {
    getPost(postId)
      .then((res) => {
        setPost(res);
        setComments(res.comments);
        setVoteUpCount(res.voteUpCount);
        setVoteDownCount(res.voteDownCount);
        setPoll(res.poll);
        setHasVotedToPoll(res.hasVotedToPoll);
      })
      .catch((err) => {});

    getBoardName(boardNumber)
      .then((res) => {
        setBoardName(res.boardName);
      })
      .catch((err) => {});

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [postId, boardNumber, hasVotedToPoll]);

  if (!post) return <div> 글 정보 불러오는 중..... </div>;

  if (!isAdmin && post.isDeleted) {
    alert('삭제된 게시물입니다.');
    navigate(`/board/${boardNumber}`);
  }

  const createdTime = new Date(post.createdAt);
  const dateTime = new Date(createdTime);
  const formattedDate = `${dateTime.getFullYear()}/${(dateTime.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${dateTime
    .getDate()
    .toString()
    .padStart(2, '0')} ${dateTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;

  const editedTime = new Date(post.editedAt);
  const editedDateTime = new Date(editedTime);
  const formattedEditedDate = `${editedDateTime.getFullYear()}/${(
    editedDateTime.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}/${editedDateTime
    .getDate()
    .toString()
    .padStart(2, '0')} ${editedDateTime
    .getHours()
    .toString()
    .padStart(2, '0')}:${editedDateTime
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;

  const goToBoard = () => {
    navigate(`/board/${boardNumber}`);
    window.scrollTo(0, 0);
  };

  const toggleMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const handleDeletePost = () => {
    // 글 삭제 시 한 번 더 확인
    const deleteConfirm = window.confirm('정말 삭제하시겠습니까?');
    if (deleteConfirm) {
      deletePost(postId).then((res) => {
        alert('삭제되었습니다');
      });
      navigate(`/board/${boardNumber}`);
      window.scrollTo(0, 0);
    } else {
      alert('삭제가 취소되었습니다');
    }
  };

  const handleEditPost = () => {
    navigate(`/post/edit/${postId}`);
  };

  const handleReportPost = () => {
    // 글 신고 시 한 번 더 확인
    const reportConfirm = window.confirm('정말 신고하시겠습니까?');
    if (reportConfirm) {
      reportPost(postId)
        .then((res) => {
          alert('신고되었습니다');
        })
        .catch((err) => {
          // 이미 신고한 게시글일 경우
          if (
            err.response.status === 403 &&
            err.response.data.message === 'This user has already reported'
          ) {
            alert('이미 신고한 게시글입니다.');
          }
        });
    }
  };

  const postDetailUrl = window.location.href;
  const handleCopyUrl = () => {
    // 클립보드 API를 사용하여 URL을 복사
    navigator.clipboard
      .writeText(postDetailUrl)
      .then(() => {
        alert('URL이 복사되었습니다.');
      })
      .catch((error) => {});
  };

  const handleBlockPost = () => {
    const blockConfirm = window.confirm('정말 차단하시겠습니까?');
    if (blockConfirm) {
      blockPostForever(postId)
        .then((res) => {
          alert('차단 되었습니다');
          window.location.reload();
        })
        .catch((err) => {});
    }
  };

  const handleUnblockPost = () => {
    const unblockConfirm = window.confirm('정말 차단 해제하시겠습니까?');
    if (unblockConfirm) {
      unblockPost(postId)
        .then((res) => {
          alert('차단 해제 되었습니다');
          window.location.reload();
        })
        .catch((err) => {});
    }
  };

  const toggleVoteResult = () => {
    setShowVoteResult((prev) => !prev);
  };

  const updateHasVotedToPoll = () => {
    setHasVotedToPoll(true);
  };

  if (post.isBlocked && !isAdmin) {
    return (
      <div className='flex flex-col w-full'>
        <div className='pl-4 text-textcolor text-2xl font-bold my-5'>
          {boardName}
        </div>
        <div className='flex flex-col w-full'>
          <div className=' bg-[#F4F4F4] pl-5 py-3 border-y border-[#A4A4A4]'>
            <div className='flex pb-1 items-center'>
              <div className='flex text-textcolor text-lg font-semibold opacity-50'>
                가려진 게시물입니다.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='flex flex-col w-full'>
        <div
          className='text-[#222222] text-2xl font-bold mb-5 cursor-pointer hover:text-gray'
          onClick={goToBoard}
        >
          {boardName}
        </div>
        <div className='flex px-3 py-2 bg-[#E9E9E9] justify-between items-center'>
          <div className='flex text-textcolor text-lg font-semibold'>
            {post.title}
          </div>
          {post.editedAt ? (
            <div className='flex text-[#555555] text-xs mr-4 items-center'>
              {formattedDate} ({formattedEditedDate} 수정됨)
            </div>
          ) : (
            <div className='flex text-[#555555] text-xs '>{formattedDate}</div>
          )}
        </div>
        <div className='flex justify-between border-b border-[#ECECEC] py-3'>
          <div className='flex text-sm text-[#6B6B6B] font-medium items-center px-3'>
            익명
            <div className='px-2 text-xs'>{postDetailUrl}</div>
          </div>
          <div className='flex mr-2 items-center'>
            <img src='/assets/icon/eye.svg' alt='eye' className='w-4 mr-1' />
            <div className='text-[#222222] text-xs font-normal mr-[3px]'>
              조회
            </div>
            <div className='text-[#555555] text-xs font-semibold mr-4'>
              {post.viewCount}
            </div>
            <img
              src='/assets/icon/thumbsup_title.svg'
              alt='thumbsup_title'
              className='w-4 mr-1'
            />
            <div className='text-[#222222] text-xs font-normal mr-[3px]'>
              추천수
            </div>
            <div className='text-[#555555] text-xs font-semibold mr-4'>
              {post.voteUpCount}
            </div>
            <img
              src='/assets/icon/comment_title.svg'
              alt='comment_title'
              className='w-4 mr-1'
            />
            <div className='text-[#222222] text-xs font-normal mr-[3px]'>
              댓글
            </div>
            <div className='text-[#555555] text-xs font-semibold mr-2'>
              {post.commentCount}
            </div>
            <div
              className='flex cursor-pointer px-2'
              onClick={toggleMoreOpen}
              ref={moreRef}
            >
              <img src='/assets/icon/more.svg' alt='more' className='h-4' />
              {/* <div className="text-[#222222] text-xs font-normal mr-[3px]">
                더보기
              </div> */}
            </div>
          </div>
        </div>
        <div className=' flex relative '>
          {isMoreOpen &&
            (userId === post.userId ? (
              <div className='absolute right-1 -top-2 flex flex-col p-1 bg-white border border-gray border-opacity-20 rounded-md '>
                <div
                  className='cursor-pointer bg-[#FFF4F4] rounded-md text-hotduk font-medium px-3 py-2 z-20 hover:bg-hotduk hover:text-white'
                  onClick={handleEditPost}
                  ref={editRef}
                >
                  <div className=' items-center'>
                    <div className=' text-xs font-semibold'>글 수정</div>
                  </div>
                </div>
                <div
                  className='cursor-pointer bg-white text-gray rounded-md px-3 py-2 z-20 hover:bg-gray hover:text-white'
                  onClick={handleDeletePost}
                  ref={deleteRef}
                >
                  <div className='flex justify-center items-center'>
                    <div className='text-xs font-semibold'>글 삭제</div>
                  </div>
                </div>
              </div>
            ) : (
              isLogin && (
                <div className='absolute right-1 -top-2 flex flex-col p-1 bg-white border border-gray border-opacity-20 rounded-md '>
                  <div
                    className='cursor-pointer bg-white text-gray rounded-md px-3 py-2 z-20 hover:bg-gray hover:text-white'
                    onClick={handleReportPost}
                    ref={reportRef}
                  >
                    <div className='flex justify-center items-center'>
                      <div className='text-xs font-semibold'>글 신고</div>
                    </div>
                  </div>
                </div>
              )
            ))}
        </div>
        <div className='my-5 text-base text-textcolor'>
          <div className='break-words'>{HTMLReactParser(post.text)}</div>
        </div>
        <div className='mb-10'>
          {poll &&
            (hasVotedToPoll || showVoteResult ? (
              <PollResult
                pollData={poll}
                toggleVoteResult={toggleVoteResult}
                hasVotedToPoll={hasVotedToPoll}
              />
            ) : (
              <PollDetail
                pollData={poll}
                toggleVoteResult={toggleVoteResult}
                updateHasVotedToPoll={updateHasVotedToPoll}
              />
            ))}
        </div>

        {isAdmin &&
          (post.isBlocked ? (
            <div>
              <button
                className='flex bg-green justify-center items-center mx-auto p-3 text-white font-semibold text-lg'
                onClick={handleUnblockPost}
              >
                글 차단 해제하기
              </button>
            </div>
          ) : (
            <div>
              <button
                className='flex bg-green justify-center items-center mx-auto p-3 text-white font-semibold text-lg'
                onClick={handleBlockPost}
              >
                글 차단하기
              </button>
            </div>
          ))}

        <div className='px-7 py-4 mt-8 mx-auto'>
          <div className='pb-3'>
            <VotePost
              postId={postId}
              voteUpCount={voteUpCount}
              voteDownCount={voteDownCount}
              onVoteChange={(newVoteUpCount, newVoteDownCount) => {
                setVoteUpCount(newVoteUpCount);
                setVoteDownCount(newVoteDownCount);
              }}
            />
          </div>
          <div className='flex justify-center items-center'>
            <div className='my-2 mx-3'>
              <PostBookmark postId={postId} isLogin={isLogin} />
            </div>
            <div className='my-2 mx-3'>
              <button
                type='button'
                onClick={handleCopyUrl}
                className='flex items-center border border-[#D9D9D9] px-3 py-2 text-sm font-normal text-textcolor hover:bg-[#D9D9D9]'
              >
                <img
                  src='/assets/icon/share.svg'
                  alt='share'
                  className=' items-center w-4'
                />
                <div className='flex pl-2'>퍼가기</div>
              </button>
            </div>
          </div>
        </div>

        <div>
          {isAdmin ? (
            <AdminCommentDetail
              postId={postId}
              commentLength={post.commentCount}
              boardNumber={post.boardNumber}
              ranker={post.ranker}
            />
          ) : (
            <CommetDetail
              postId={postId}
              commentLength={post.commentCount}
              boardNumber={post.boardNumber}
              ranker={post.ranker}
            />
          )}
        </div>

        {/* <CreateComment
            postId={postId}
            boardNumber={post.boardNumber}
            ranker={post.ranker}
          /> */}
      </div>
    </>
  );
}

export default PostDetail;
