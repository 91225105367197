import CreateBoardPost from "../../components/VoteBoard/CreateBoardPost";

function CreateBoardPostPage() {
  return (
    <>
      <div className="w-full flex">
        <CreateBoardPost />
      </div>
    </>
  );
}

export default CreateBoardPostPage;
