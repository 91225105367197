import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../apis/user";

function PasswordChangePage() {
  const [formData, setFormData] = useState({
    currentPassword: "",
    password: "",
    passwordConfirm: "",
  });

  const [passwordMessage, setPasswordMessage] = useState({
    text: "",
    color: "",
  });

  const [passwordMatchMessage, setPasswordMatchMessage] = useState({
    text: "",
    color: "",
  });

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const navigate = useNavigate();

  // 비밀번호 규칙 검사
  const validatePasswordRules = (password: string) => {
    if (!password) {
      setPasswordMessage({ text: "비밀번호를 입력하세요.", color: "text-red" });
      setIsPasswordValid(false);
      return;
    }
    if (password.length < 8 || password.length > 20) {
      setPasswordMessage({
        text: "비밀번호는 8~20자로 구성되어야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (!/[a-zA-Z]/.test(password) || !/\d/.test(password)) {
      setPasswordMessage({
        text: "비밀번호는 최소 1개의 영문 및 1개의 숫자를 포함해야 합니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }
    if (/[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':",./<>?~]/.test(password)) {
      setPasswordMessage({
        text: "허용되지 않는 특수문자가 포함되어 있습니다.",
        color: "text-red",
      });
      setIsPasswordValid(false);
      return;
    }

    setPasswordMessage({
      text: "사용 가능한 비밀번호입니다.",
      color: "text-blue",
    });
    setIsPasswordValid(true);
  };

  // 비밀번호 일치 여부 검사
  const validatePasswordMatch = (password: string, passwordConfirm: string) => {
    if (passwordConfirm === "") {
      setPasswordMatchMessage({ text: "", color: "" });
      setIsPasswordMatch(false);
      return;
    }
    if (password === passwordConfirm) {
      setPasswordMatchMessage({
        text: "비밀번호가 일치합니다.",
        color: "text-blue",
      });
      setIsPasswordMatch(true);
    } else {
      setPasswordMatchMessage({
        text: "비밀번호가 일치하지 않습니다.",
        color: "text-red",
      });
      setIsPasswordMatch(false);
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!formData.currentPassword) {
      alert("기존 비밀번호를 입력하세요.");
      return;
    }
    if (!formData.password) {
      alert("새 비밀번호를 입력하세요.");
      return;
    }

    if (!isPasswordValid) {
      alert("비밀번호가 올바르지 않습니다.");
      return;
    }
    if (!isPasswordMatch) {
      alert("비밀번호가 일치하지 않습니다.");
      return;
    }

    // TODO: API 호출하여 비밀번호 변경
    try {
      const result = await changePassword(
        formData.currentPassword,
        formData.password
      );
      // 성공적으로 비밀번호 변경 완료 시
      if (result.message === "Password change completed") {
        alert("비밀번호가 성공적으로 변경되었습니다.");
        navigate("/profile/info");
      }
    } catch (error: any) {
      // 4xx 또는 기타 오류에 대한 분기 처리
      const result = error.response?.data;

      if (
        result?.message ===
        "Password must be 8~20 characters long and contain both letters and numbers (special characters allowed)"
      ) {
        alert("기존 비밀번호가 올바르지 않습니다.");
        return;
      }
      if (result?.message === "User not found") {
        alert("사용자를 찾을 수 없습니다.");
      } else if (result?.message === "Current password is incorrect") {
        alert("기존 비밀번호가 올바르지 않습니다.");
      } else if (
        result?.message ===
        "New password cannot be the same as the current password"
      ) {
        alert("새 비밀번호는 기존 비밀번호와 다르게 설정해야 합니다.");
      } else {
        alert(result?.message || "비밀번호 변경 중 오류가 발생했습니다.");
      }
    }
  };

  const handleCancel = async () => {
    navigate("/profile/info");
  };

  return (
    <div className='max-w-3xl mx-auto mt-10 px-6'>
      <div className='py-4'>
        <h2 className='text-base border-b border-gray pb-2 mb-8'>
          비밀번호 변경
        </h2>

        <form>
          <div className='border border-gray rounded border-l-0 border-r-0'>
            {/* 기존 비밀번호 입력 */}
            <div className='border-b border-gray'>
              <div className='flex'>
                <label
                  htmlFor='currentPassword'
                  className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                  기존 비밀번호
                </label>
                <div className='flex-1 p-4 space-y-1'>
                  <input
                    id='currentPassword'
                    name='currentPassword'
                    type='password'
                    className='w-1/2 border border-gray rounded'
                    autoComplete='current-password'
                    value={formData.currentPassword}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        currentPassword: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            {/* 새 비밀번호 입력 */}
            <div className='border-b border-gray'>
              <div className='flex'>
                <label
                  htmlFor='password'
                  className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                  새 비밀번호
                </label>
                <div className='flex-1 p-4 space-y-1'>
                  <input
                    id='password'
                    name='password'
                    type='password'
                    className='w-1/2 border border-gray rounded'
                    autoComplete='new-password'
                    value={formData.password}
                    onChange={(e) => {
                      setFormData({ ...formData, password: e.target.value });
                      validatePasswordRules(e.target.value);
                      validatePasswordMatch(
                        e.target.value,
                        formData.passwordConfirm
                      );
                    }}
                  />
                  {passwordMessage.text && (
                    <p className={`text-sm ${passwordMessage.color}`}>
                      {passwordMessage.text}
                    </p>
                  )}
                  <p className='text-sm text-gray_text'>
                    비밀번호는 8~20자의 영문/숫자 조합으로 구성되어야 합니다.
                  </p>
                  <p className='text-sm text-gray_text'>
                    최소 1개 영문 및 최소 1개 숫자를 포함해야 합니다.
                  </p>
                  <p className='text-sm text-gray_text'>
                    허용되는 특수문자: !@#$%^&*()_+-=[]{};':",./&lt;&gt;?~
                  </p>
                  <p className='text-sm text-gray_text'>
                    허용되지 않는 특수문자: 공백, 한글, 이모지, 허용된 특수문자
                    외 모든 문자
                  </p>
                </div>
              </div>
            </div>

            {/* 새 비밀번호 확인 */}
            <div>
              <div className='flex'>
                <label
                  htmlFor='passwordConfirm'
                  className='w-1/4 p-4 text-sm flex items-center font-bold bg-gray_light border-r border-gray'>
                  새 비밀번호 확인
                </label>
                <div className='flex-1 p-4'>
                  <input
                    id='passwordConfirm'
                    name='passwordConfirm'
                    type='password'
                    className='w-1/2 border border-gray rounded'
                    autoComplete='new-password'
                    value={formData.passwordConfirm}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        passwordConfirm: e.target.value,
                      });
                      validatePasswordMatch(formData.password, e.target.value);
                    }}
                  />
                  {passwordMatchMessage.text && (
                    <p className={`text-sm mt-1 ${passwordMatchMessage.color}`}>
                      {passwordMatchMessage.text}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* 버튼 영역 */}
          <div className='flex justify-end gap-2 pt-8'>
            <button
              type='button'
              className='px-4 py-1 border border-gray rounded hover:bg-gray'
              onClick={handleCancel}>
              취소
            </button>
            <button
              type='submit'
              className='px-4 py-1 bg-hotduk text-white rounded hover:bg-hotduk_light'
              onClick={handleSubmit}>
              변경
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordChangePage;
