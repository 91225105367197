import { useParams } from "react-router-dom";
import VoteBoardDetail from "../../components/VoteBoard/VoteBoardDetail";
import MobileVoteBoardDetail from "../../components/VoteBoard/MobileVoteBoardDetail";

function MobileVoteBoardDetailPage() {
  const { voteBoardPostId } = useParams<{ voteBoardPostId: string }>();

  if (!voteBoardPostId) {
    return <div>postId Loading...</div>;
  }
  return (
    <>
      <div>
        <MobileVoteBoardDetail voteBoardPostId={voteBoardPostId} />
      </div>
    </>
  );
}

export default MobileVoteBoardDetailPage;
