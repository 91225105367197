import storage from "redux-persist/lib/storage";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import twitterReferrerSlice from "./twitterReferrerSlice";
import notificationSlice from "./notificationSlice";
import loginModalReducer from "./loginModalSlice";

const rootReducer = combineReducers({
  auth: authReducer.reducer,
  twitterReferrer: twitterReferrerSlice,
  notification: notificationSlice,
  loginModal: loginModalReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "twitterReferrer", "notification", "loginModal"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
