import React from "react";
import UserList from "../../../components/Admin/User/UserList";

function AdminUserPage() {
  return (
    <>
      <UserList />
    </>
  );
}

export default AdminUserPage;
