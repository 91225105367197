import { useEffect, useRef, useState } from "react";
import React from "react";
import { createSubComment } from "../../apis/subcomment";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import Btn from "../Common/Btn";
import { updateLoginRedirectUrl } from "../../store/authReducer";
import { editSubComment, uploadCommentImage } from "../../apis/comment";
import { openLoginModal } from "../../store/loginModalSlice";

interface CreateSubCommentProps {
  commentId: string;
  boardNumber: number;
  ranker: boolean;
  setSubcommentLengthDetail: React.Dispatch<React.SetStateAction<number>>;
  initialSubCommentText?: string;
  initialSubCommentImage?: string;
  subCommentId?: string;
  isEditing?: boolean;
  handleEditSubComment?: (newSubComment: any) => void;
}

function CreateSubComment({
  commentId,
  boardNumber,
  ranker,
  setSubcommentLengthDetail,
  initialSubCommentText,
  initialSubCommentImage,
  subCommentId,
  isEditing,
  handleEditSubComment,
}: CreateSubCommentProps) {
  const [subcomment, setSubcomment] = useState<string>("");
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const dispatch = useAppDispatch();
  const [uploadedImage, setUploadedImage] = useState<string | null>(
    initialSubCommentImage || null
  );
  const [previewImage, setPreviewImage] = useState<string | null>(
    initialSubCommentImage || null
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const isBlocked = useAppSelector((state) => state.auth.isBlocked);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setPreviewImage(URL.createObjectURL(file));
      try {
        const tempImgPath = await uploadCommentImage(file);
        setUploadedImage(tempImgPath);
      } catch (err) {
        // console.error("이미지 업로드 실패:", err);
      }
    }
  };

  useEffect(() => {
    if (isEditing && initialSubCommentText !== undefined) {
      setSubcomment(initialSubCommentText);
    }
    if (isEditing && initialSubCommentImage !== undefined) {
      setUploadedImage(initialSubCommentImage);
      setPreviewImage(initialSubCommentImage);
    }
  }, [isEditing, initialSubCommentText, initialSubCommentImage]);

  const handleSubmitSubcomment = (e?: React.FormEvent): void => {
    e?.preventDefault();
    const trimmedSubcomment = subcomment.trim();
    if (!trimmedSubcomment && !uploadedImage) {
      alert("댓글 내용을 입력해주세요");
      return;
    }
    if (isBlocked) {
      alert("차단된 사용자입니다.");
      return;
    }

    const tempImgPath = uploadedImage ? uploadedImage : null;
    const content = JSON.stringify({
      boardNumber: boardNumber,
      text: trimmedSubcomment,
      ranker: ranker,
      tempImgPath: tempImgPath,
    });

    if (isEditing && subCommentId) {
      editSubComment(commentId, subCommentId, content)
        .then((res) => {
          setSubcomment("");
          setUploadedImage(null);
          setPreviewImage(null);
          handleEditSubComment &&
            handleEditSubComment(
              res.subCommentList.filter(
                (subComment: any) => subComment.id === subCommentId
              )
            );
        })
        .catch((err) => {});
    } else {
      createSubComment(commentId, content)
        .then((res) => {
          setSubcommentLengthDetail((prevState) => prevState + 1); // subcommentLengthDetail 상태 증가
          setSubcomment("");
          setUploadedImage(null);
          setPreviewImage(null);
        })
        .catch((err) => {});
    }
  };
  const handleOpen = () => {
    dispatch(openLoginModal()); // Redux 상태를 변경해 모달 닫기
  };

  const handleLoginAlert = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    dispatch(updateLoginRedirectUrl(window.location.pathname));
    handleOpen();
  };

  return (
    <>
      {isLogin ? (
        <form
          action=''
          onSubmit={handleSubmitSubcomment}
          className='bg-[#FFF4F4] mt-5 mx-5'>
          {previewImage && (
            <div className='relative px-3 pt-3'>
              <div className='flex'>
                <div className='mb-2 max-w-full mr-2'>
                  <img
                    src={previewImage}
                    alt='preview'
                    className='w-full h-auto max-w-[400px] max-h-[300px] object-contain'
                  />
                </div>
                <button
                  type='button'
                  onClick={() => {
                    setPreviewImage(null);
                    setUploadedImage(null);
                  }}
                  className='flex items-center justify-center w-8 h-8 bg-white bg-opacity-70 rounded-full transition duration-200 hover:bg-opacity-100 hover:opacity-50'>
                  <img
                    src='/assets/icon/image_delete.svg'
                    alt='image_delete'
                    className='w-4 h-4'
                  />
                </button>
              </div>
            </div>
          )}
          <div className='flex p-3 items-center'>
            <textarea
              className='flex w-full h-16 p-2 mr-4 text-sm resize-none focus:outline-none'
              value={subcomment}
              onChange={(e) => setSubcomment(e.target.value)}
              placeholder='댓글 내용을 입력하세요'></textarea>
            <div className='mt-1 mb-2 flex flex-col justify-end'>
              <Btn
                text='대댓글 작성'
                textColor='white'
                btnFunc={handleSubmitSubcomment}
                className='px-1 text-xs bg-[#FFD8DA] font-normal hover:opacity-70 whitespace-nowrap'
              />
              <div
                className='p-1 bg-[#FFD8DA] text-xs text-white font-normal hover:opacity-70 whitespace-nowrap cursor-pointer mt-2'
                onClick={() => fileInputRef.current?.click()}>
                이미지 업로드
              </div>
              <input
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
                className='hidden'
                ref={fileInputRef}
              />
            </div>
          </div>
        </form>
      ) : (
        <div className='flex-col mt-5 mb-10'>
          <div className='flex w-full bg-[#FAFAFA] px-2 py-3 text-sm resize-none text-[#555555]'>
            대댓글은{" "}
            <div
              className='flex px-1 cursor-pointer hover:underline hover:bg-slate-400 font-bold '
              onClick={handleLoginAlert}>
              로그인
            </div>
            후 작성 가능합니다.
          </div>
        </div>
      )}
    </>
  );
}

export default CreateSubComment;
