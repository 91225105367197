import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation, Location } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../constants/types";
import { logout } from "../../apis/user";
import {
  updateAccessToken,
  updateAdminStatus,
  updateAdminAdminStatus,
  updateLoginStatus,
  updateUserId,
  updateLoginRedirectUrl,
  updateBlockStatus,
} from "../../store/authReducer";
import MobileHamburger from "./MobileHamburger";
import Notification from "../Notification/Notification";
import NativeLoginPage from "../Modal/NativeLoginPage";
import { setRedirectUrl } from "../../store/twitterReferrerSlice";
import { openLoginModal } from "../../store/loginModalSlice";

function MobileNavBar() {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("all");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const boardNameRef = useRef<HTMLDivElement>(null);
  const [isBoardNameOpen, setIsBoardNameOpen] = useState<boolean>(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);
  const notificationRef = useRef<HTMLDivElement>(null);
  const notificationBellRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation() as Location;
  const isLogin = useAppSelector((state) => state.auth.isLogin);
  const unreadNotifications = useAppSelector(
    (state) => state.notification.unreadNotifications
  );
  const hasUnreadNotification = unreadNotifications.length > 0;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("login") === "true") {
      setIsLoginModalOpen(true);
    }
  }, [location]);

  const goToMain = () => {
    navigate(`/`);
  };

  const toggleBoardNameOpen = () => {
    setIsBoardNameOpen(!isBoardNameOpen);
  };

  const handleClickOutside = (e: any) => {
    if (
      boardNameRef.current &&
      !boardNameRef.current.contains(e.target) &&
      hamburgerRef.current &&
      !hamburgerRef.current.contains(e.target)
    ) {
      setIsBoardNameOpen(false);
    }
    if (
      notificationRef.current &&
      !notificationRef.current.contains(e.target) &&
      notificationBellRef.current &&
      !notificationBellRef.current.contains(e.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const toggleNotificationOpen = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const handleSearch = (e: any) => {
    e.preventDefault();
    if (!searchQuery || searchQuery.trim() === "") {
      alert("검색어를 입력해주세요.");
      return;
    }
    navigate(`/search?type=${searchType}&query=${searchQuery}`);
    setSearchQuery("");
    setSearchType("all");
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };

  const openLoginModalHandler = () => {
    const currentUrl = window.location.pathname + window.location.search;
    dispatch(setRedirectUrl(currentUrl)); // 현재 URL을 저장
    dispatch(openLoginModal()); // 수정된 부분
  };

  const handleLogout = async () => {
    try {
      await logout();
      dispatch(updateLoginStatus(false));
      dispatch(updateAccessToken(""));
      dispatch(updateUserId(""));
      dispatch(updateAdminStatus(false));
      dispatch(updateAdminAdminStatus(false));
      dispatch(updateBlockStatus(false));
      //todo blocked 삭제
      alert("로그아웃 되었습니다");
    } catch (error) {}
  };

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <div className='w-full px-4 pt-4 border-header_line whitespace-nowrap'>
      <div className='flex justify-between mb-1'>
        <div className='flex items-center'>
          <img
            src='/assets/icon/10duk_newlogo_fin.svg'
            alt='로고이미지'
            width={100}
            onClick={goToMain}
            className='cursor-pointer'
            id='logo'
          />
        </div>

        <div className='flex items-center'>
          {isLogin ? (
            <div className='flex items-center py-2'>
              <button onClick={handleLogout} className='flex items-center'>
                <div className='flex text-base text-[#6B6B6B] px-[6PX] py-1'>
                  <img
                    src='/assets/icon/logout_mob.svg'
                    alt='logout'
                    width={20}
                  />
                </div>
              </button>
              <div className='flex items-center py-2'></div>
              <button onClick={goToProfile} className='flex items-center'>
                <div className='flex text-base text-[#6B6B6B] px-[6PX] py-1'>
                  <img
                    src='/assets/icon/profile_mobile.svg'
                    alt='profile'
                    width={18}
                  />
                </div>
              </button>
              {isLogin && (
                <div
                  className='flex justify-end cursor-pointer px-[6PX] py-1'
                  onClick={toggleNotificationOpen}
                  ref={notificationBellRef}>
                  {hasUnreadNotification ? (
                    <img src='/assets/icon/notification_red.svg' width={22} />
                  ) : (
                    <img src='/assets/icon/notification_white.svg' width={24} />
                  )}
                </div>
              )}
              <div
                className='flex items-center'
                onClick={toggleBoardNameOpen}
                ref={hamburgerRef}>
                <div className='pl-[6px]'>
                  <img
                    src='/assets/icon/hamburger_mobile.svg'
                    alt='hamburger'
                    width={24}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='flex items-center py-2'>
              <button
                onClick={openLoginModalHandler}
                className='flex items-center'>
                <div className='flex text-base text-[#6B6B6B] px-2 py-1'>
                  <img
                    src='/assets/icon/login_mobile.svg'
                    alt='login_mobile'
                    width={20}
                  />
                </div>
              </button>
              <div className='flex items-center py-2'></div>
              {/*<button onClick={openLoginModal} className='flex items-center'>
                <div className='flex text-base text-[#6B6B6B] px-2 py-1'>
                  <img
                    src='/assets/icon/profile_mobile.svg'
                    alt='profile'
                    width={18}
                  />
                </div>
              </button>*/}
              <div
                className='flex items-center'
                onClick={toggleBoardNameOpen}
                ref={hamburgerRef}>
                <div className='pl-2'>
                  <img
                    src='/assets/icon/hamburger_mobile.svg'
                    alt='hamburger'
                    width={24}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='flex relative'>
        {" "}
        {isBoardNameOpen && (
          <div
            className='absolute bg-white shadow-md z-20 right-0'
            ref={boardNameRef}>
            <MobileHamburger />
          </div>
        )}{" "}
      </div>
      <div className='flex relative'>
        {isNotificationOpen && (
          <div
            className='absolute right-0 top-full bg-white shadow-md z-50 w-48'
            ref={notificationRef}>
            <Notification toggleNotification={toggleNotificationOpen} />
          </div>
        )}
      </div>

      <div className='w-full flex items-center mt-2'>
        <form
          className='w-full px-2 py-3 flex justify-between border-navbar border-[1.5px] rounded-3xl '
          onSubmit={handleSearch}>
          <div className='flex'>
            <select
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)}
              className=' flex justify-center items-center px-1 text-textcolor text-sm outline-none bg-white'>
              <option value='all'>전체</option>
              <option value='title'>제목</option>
              <option value='text'>내용</option>
            </select>
            <input
              type='text'
              placeholder='검색어를 입력하세요.'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className='flex outline-none mx-2 items-center text-sm '
            />
            {/* bg-[#EBF4FF] */}
          </div>

          <div className='flex'>
            <button className=' px-2 flex items-center'>
              <img
                src='/assets/icon/search_mobile.svg'
                alt='search_mobile'
                id='search_mobile'
                width={20}
              />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MobileNavBar;
