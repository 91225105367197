import { useParams } from "react-router-dom";
import MobileAnnouncementDetail from "../../components/Announcements/MobileAnnouncementDetail";

function MobileIntegratedAnnouncementDetailPage() {
  const { postId } = useParams<{ postId: string }>();

  if (!postId) {
    return <div>postId Loading...</div>;
  }
  return (
    <div>
      <MobileAnnouncementDetail postId={postId} />
    </div>
  );
}

export default MobileIntegratedAnnouncementDetailPage;
